import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: ${theme.layout.spacing(7.5)};
    padding: ${theme.layout.spacing(2, 20)};

    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.text.white};

    p {
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.bold};
    }

    > div {
      display: flex;
      align-items: center;
      gap: ${theme.layout.spacing(4)};
    }

    @media (max-width: ${theme.layout.breakpoints.lg}) {
      padding: ${theme.layout.spacing(2, 4)};
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      padding: ${theme.layout.spacing(2, 4)};

      p {
        font-size: ${theme.typography.sizes.body2};
        width: ${theme.layout.spacing(19)};
      }

      > div > p {
        display: none;
      }
    }
  `}
`;

export const CountdownBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.layout.spacing(2)};

    > div {
      display: flex;
      align-items: baseline;
      gap: ${theme.layout.spacing(0.5)};

      strong {
        font-size: ${theme.typography.sizes.title2};
        font-weight: ${theme.typography.weight.bold};
      }

      span {
        font-size: ${theme.typography.sizes.body1};
        font-weight: ${theme.typography.weight.bold};
      }
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      gap: ${theme.layout.spacing(1.5)};

      > div span {
        font-size: ${theme.typography.sizes.body2};

        text-transform: capitalize;
        visibility: hidden;
        width: 8px;
        &::first-letter {
          visibility: visible;
        }
      }
    }
  `}
`;
