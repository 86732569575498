import Cookies from 'universal-cookie';

import { UTM } from '../contexts/AppContext';

const nonNullString = string => (string === 'null' ? undefined : string);

export const UseUTM = () => {
  const cookies = new Cookies();
  const utmSource = nonNullString(cookies.get(UTM.SOURCE));
  const utmMedium = nonNullString(cookies.get(UTM.MEDIUM));
  const utmCampaign = nonNullString(cookies.get(UTM.CAMPAIGN));
  const term = nonNullString(cookies.get(UTM.TERM));

  let utm;
  if (utmSource || utmMedium || utmCampaign) {
    utm = {
      source: utmSource,
      medium: utmMedium,
      campaign: utmCampaign,
    };
  }

  const facebookId = nonNullString(cookies.get(UTM.FACEBOOK_ID));

  const googleId = nonNullString(cookies.get(UTM.GOOGLE_ID));

  return {
    utm,
    facebookId,
    googleId,
    term,
  };
};
