import { makePhonesValidityRepository } from 'shared/infra/repositories';

import CheckPhoneValidityUseCase from './implementations/CheckPhoneValidityUseCase';

let instance: CheckPhoneValidityUseCase | null = null;

export default function makeCheckPhoneValidityUseCase() {
  if (!instance) {
    const phonesValidityRepository = makePhonesValidityRepository();

    instance = new CheckPhoneValidityUseCase(phonesValidityRepository);
  }

  return instance;
}
