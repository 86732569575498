import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";

const Base = styled(IconBase)`
  enable-background: new 0 0 130 130;
  .st0 {
    fill: #63b76b;
  }
  .st1 {
    fill: #ffffff;
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const TechnologyIcon = props => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 130 130"
    {...inproperProps}
    {...props}
  >
    <g>
      <path
        className="st0"
        d="M65,7.83C33.48,7.84,7.84,33.48,7.83,65c0,31.52,25.64,57.16,57.17,57.17c31.53,0,57.16-25.64,57.17-57.17
		C122.16,33.48,96.53,7.84,65,7.83z M65,117.69c-14.53,0-27.69-5.91-37.24-15.45C18.22,92.69,12.31,79.53,12.31,65
		c0-14.53,5.91-27.69,15.45-37.24C37.31,18.22,50.47,12.31,65,12.31s27.69,5.91,37.24,15.45c9.54,9.55,15.45,22.71,15.45,37.24
		c0,14.53-5.91,27.69-15.45,37.24C92.69,111.78,79.53,117.69,65,117.69z"
      />
      <g>
        <path
          className="st0"
          d="M65,32.75c0.92,0,1.67-0.75,1.67-1.67v-7.07c0-0.92-0.75-1.67-1.67-1.67s-1.67,0.75-1.67,1.67v7.07
			C63.33,32,64.08,32.75,65,32.75z"
        />
        <path
          className="st0"
          d="M49.48,35.68c0.31,0.54,0.87,0.84,1.45,0.84c0.28,0,0.57-0.07,0.83-0.22c0.8-0.46,1.07-1.49,0.61-2.29
			l-3.53-6.12c-0.46-0.8-1.49-1.07-2.29-0.61c-0.8,0.46-1.07,1.49-0.61,2.29L49.48,35.68z"
        />
        <path
          className="st0"
          d="M41.46,43.7l-6.12-3.53c-0.8-0.46-1.82-0.19-2.29,0.61c-0.46,0.8-0.19,1.82,0.61,2.29l6.12,3.53
			c0.26,0.15,0.55,0.22,0.83,0.22c0.58,0,1.14-0.3,1.45-0.84C42.54,45.18,42.26,44.16,41.46,43.7z"
        />
        <path
          className="st0"
          d="M38.53,59.22c0-0.92-0.75-1.67-1.67-1.67h-7.07c-0.92,0-1.67,0.75-1.67,1.67c0,0.92,0.75,1.67,1.67,1.67h7.07
			C37.78,60.89,38.53,60.14,38.53,59.22z"
        />
        <path
          className="st0"
          d="M39.79,71.84l-6.12,3.53c-0.8,0.46-1.07,1.49-0.61,2.29c0.31,0.54,0.87,0.84,1.45,0.84
			c0.28,0,0.57-0.07,0.83-0.22l6.12-3.53c0.8-0.46,1.07-1.49,0.61-2.29C41.61,71.66,40.59,71.38,39.79,71.84z"
        />
        <path
          className="st0"
          d="M96.33,75.38l-6.12-3.53c-0.8-0.46-1.82-0.19-2.29,0.61c-0.46,0.8-0.19,1.82,0.61,2.29l6.12,3.53
			c0.26,0.15,0.55,0.22,0.83,0.22c0.58,0,1.14-0.3,1.45-0.84C97.41,76.86,97.13,75.84,96.33,75.38z"
        />
        <path
          className="st0"
          d="M100.21,57.55h-7.07c-0.92,0-1.67,0.75-1.67,1.67c0,0.92,0.75,1.67,1.67,1.67h7.07
			c0.92,0,1.67-0.75,1.67-1.67C101.89,58.3,101.14,57.55,100.21,57.55z"
        />
        <path
          className="st0"
          d="M89.38,46.82c0.28,0,0.57-0.07,0.83-0.22l6.12-3.53c0.8-0.46,1.07-1.49,0.61-2.29
			c-0.46-0.8-1.49-1.07-2.29-0.61l-6.12,3.53c-0.8,0.46-1.07,1.49-0.61,2.29C88.23,46.52,88.8,46.82,89.38,46.82z"
        />
        <path
          className="st0"
          d="M78.24,36.29c0.26,0.15,0.55,0.22,0.83,0.22c0.58,0,1.14-0.3,1.45-0.84l3.53-6.12
			c0.46-0.8,0.19-1.82-0.61-2.29c-0.8-0.46-1.82-0.19-2.29,0.61l-3.53,6.12C77.16,34.81,77.43,35.83,78.24,36.29z"
        />
        <path
          className="st0"
          d="M80.86,42.96c-2.14-2.09-4.63-3.71-7.39-4.83c-2.87-1.15-5.9-1.7-9.02-1.63c-5.97,0.14-11.56,2.59-15.74,6.9
			c-4.18,4.31-6.45,9.97-6.42,15.94c0.02,3.15,0.67,6.2,1.95,9.06c1.23,2.76,2.97,5.22,5.18,7.3c3.03,2.86,4.77,6.91,4.77,11.1v1.7
			c0,2.21,1.8,4.01,4.01,4.01h13.6c2.21,0,4.01-1.8,4.01-4.01v-1.7c0-4.2,1.73-8.24,4.75-11.08c2.23-2.1,3.99-4.58,5.22-7.38
			c1.28-2.89,1.92-5.97,1.92-9.16c0-3.11-0.62-6.13-1.84-8.96C84.68,47.5,83,45.05,80.86,42.96z M78.26,73.3
			c-3.69,3.47-5.8,8.39-5.8,13.52v1.7c0,0.36-0.3,0.66-0.66,0.66H58.2c-0.36,0-0.66-0.3-0.66-0.66v-1.7
			c0-5.11-2.12-10.04-5.82-13.54c-3.88-3.66-6.04-8.62-6.07-13.95c-0.07-10.49,8.41-19.23,18.89-19.48c0.16,0,0.31-0.01,0.47-0.01
			c5.09,0,9.87,1.95,13.52,5.51c3.76,3.67,5.83,8.59,5.83,13.85C84.35,64.6,82.19,69.6,78.26,73.3z"
        />
        <path
          className="st0"
          d="M74.03,94.25H55.97c-0.92,0-1.67,0.75-1.67,1.67c0,0.92,0.75,1.67,1.67,1.67h18.07
			c0.92,0,1.67-0.75,1.67-1.67C75.71,94.99,74.96,94.25,74.03,94.25z"
        />
        <path
          className="st0"
          d="M74.03,99.31H55.97c-0.92,0-1.67,0.75-1.67,1.67c0,0.92,0.75,1.67,1.67,1.67h2.99
			c0.54,2.85,3.04,5.01,6.04,5.01s5.51-2.16,6.04-5.01h2.99c0.92,0,1.67-0.75,1.67-1.67C75.71,100.06,74.96,99.31,74.03,99.31z
			 M65,104.32c-1.14,0-2.12-0.68-2.56-1.66h5.12C67.12,103.64,66.14,104.32,65,104.32z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M65.24,70.95c-0.07,0-0.15,0-0.22,0c-0.47,0-0.93-0.03-1.41-0.08c-0.29-0.03-0.53-0.24-0.62-0.52l-0.45-1.49
			c-0.04-0.12-0.13-0.21-0.25-0.25c-0.22-0.06-0.44-0.13-0.65-0.21c-0.04-0.01-0.08-0.02-0.12-0.02c-0.08,0-0.16,0.03-0.22,0.07
			l-1.27,0.97c-0.13,0.1-0.28,0.15-0.44,0.15c-0.12,0-0.24-0.03-0.35-0.09c-0.83-0.45-1.61-1.01-2.31-1.64
			c-0.22-0.2-0.3-0.51-0.2-0.79l0.53-1.47c0.04-0.12,0.02-0.25-0.06-0.35c-0.13-0.16-0.26-0.34-0.39-0.52
			c-0.07-0.1-0.18-0.16-0.3-0.16c0,0-1.62,0.05-1.62,0.05c-0.3,0-0.56-0.16-0.68-0.42c-0.4-0.85-0.7-1.75-0.89-2.66
			c-0.06-0.29,0.06-0.59,0.31-0.75l1.31-0.89c0.1-0.07,0.17-0.19,0.16-0.32c0-0.1-0.01-0.21-0.01-0.32c0-0.11,0-0.21,0-0.32
			c0-0.13-0.06-0.25-0.16-0.31l-1.32-0.88c-0.25-0.17-0.37-0.46-0.31-0.75c0.18-0.92,0.48-1.81,0.88-2.67
			c0.12-0.26,0.38-0.42,0.69-0.42c0,0,1.61,0.04,1.61,0.04c0.12,0,0.23-0.06,0.3-0.16c0.12-0.18,0.25-0.36,0.39-0.52
			c0.08-0.1,0.1-0.23,0.06-0.35l-0.53-1.46c-0.1-0.28-0.03-0.59,0.19-0.79c0.7-0.64,1.47-1.2,2.3-1.65
			c0.11-0.06,0.23-0.09,0.36-0.09c0.16,0,0.31,0.05,0.44,0.15L61.24,50c0.06,0.05,0.14,0.07,0.22,0.07c0.04,0,0.08-0.01,0.12-0.02
			c0.22-0.08,0.44-0.15,0.65-0.21c0.12-0.03,0.22-0.13,0.25-0.25l0.44-1.5c0.08-0.28,0.32-0.48,0.61-0.52
			c0.44-0.05,0.93-0.08,1.46-0.09c0.46,0,0.93,0.03,1.4,0.08c0.29,0.03,0.53,0.24,0.62,0.52l0.45,1.49
			c0.04,0.12,0.13,0.21,0.25,0.25c0.21,0.06,0.43,0.13,0.65,0.21c0.04,0.01,0.08,0.02,0.12,0.02c0.08,0,0.16-0.03,0.22-0.07
			l1.27-0.97c0.13-0.1,0.28-0.15,0.44-0.15c0.12,0,0.24,0.03,0.35,0.09c0.83,0.46,1.61,1.01,2.31,1.64c0.22,0.2,0.3,0.51,0.2,0.79
			l-0.53,1.46c-0.04,0.12-0.02,0.25,0.06,0.35c0.13,0.17,0.27,0.35,0.39,0.52c0.07,0.1,0.18,0.16,0.3,0.16c0,0,1.62-0.05,1.62-0.05
			c0.3,0,0.56,0.16,0.68,0.42c0.41,0.85,0.71,1.75,0.89,2.67c0.06,0.29-0.06,0.59-0.31,0.75l-1.31,0.89
			c-0.1,0.07-0.17,0.19-0.16,0.32c0,0.1,0.01,0.21,0.01,0.32c0,0.11,0,0.21,0,0.32c0,0.13,0.06,0.25,0.16,0.32l1.31,0.88
			c0.25,0.17,0.37,0.46,0.31,0.75c-0.18,0.92-0.47,1.82-0.87,2.67c-0.12,0.26-0.38,0.42-0.67,0.42c0,0-1.63-0.04-1.63-0.04
			c-0.12,0-0.23,0.06-0.3,0.16c-0.12,0.18-0.25,0.35-0.39,0.52c-0.08,0.1-0.1,0.23-0.06,0.35L73.33,67c0.1,0.28,0.03,0.59-0.19,0.79
			c-0.7,0.64-1.47,1.19-2.3,1.65c-0.11,0.06-0.23,0.09-0.36,0.09c-0.16,0-0.31-0.05-0.44-0.15l-1.28-0.96
			c-0.06-0.05-0.14-0.07-0.22-0.07c-0.04,0-0.08,0.01-0.12,0.02c-0.22,0.08-0.44,0.15-0.65,0.21c-0.12,0.03-0.22,0.13-0.25,0.25
			l-0.45,1.5c-0.08,0.28-0.33,0.48-0.62,0.52C66.04,70.91,65.63,70.95,65.24,70.95z M61.34,66.76c0.1,0,0.2,0.02,0.29,0.06
			c0.51,0.22,1.05,0.39,1.59,0.5c0.26,0.06,0.47,0.25,0.55,0.5l0.42,1.38c0.04,0.15,0.18,0.25,0.33,0.26l0.06,0
			c0.12,0.01,0.23,0.01,0.34,0.01c0.04,0,0.09,0,0.13,0c0,0,0.01,0,0.01,0c0.15,0,0.32-0.01,0.49-0.01
			c0.16-0.01,0.29-0.11,0.33-0.26l0.41-1.38c0.08-0.26,0.29-0.45,0.55-0.51c0.54-0.12,1.07-0.29,1.58-0.51
			c0.09-0.04,0.19-0.06,0.29-0.06c0.16,0,0.31,0.05,0.44,0.15l1.18,0.89c0.07,0.05,0.14,0.07,0.22,0.07c0.07,0,0.14-0.02,0.2-0.06
			c0.28-0.18,0.56-0.38,0.82-0.59c0.12-0.1,0.17-0.26,0.12-0.41l-0.49-1.34c-0.09-0.26-0.04-0.54,0.14-0.74
			c0.37-0.41,0.69-0.85,0.97-1.32c0.13-0.22,0.38-0.36,0.66-0.36c0,0,1.48,0.04,1.48,0.04c0.15,0,0.29-0.09,0.34-0.23
			c0.12-0.3,0.22-0.61,0.3-0.93c0.04-0.15-0.02-0.31-0.15-0.4l-1.21-0.81c-0.22-0.15-0.35-0.41-0.32-0.68
			c0.03-0.29,0.04-0.55,0.04-0.82c0-0.27-0.01-0.54-0.04-0.82c-0.03-0.27,0.1-0.53,0.32-0.68l1.2-0.81c0.13-0.09,0.19-0.25,0.15-0.4
			c-0.09-0.31-0.19-0.62-0.31-0.93c-0.05-0.14-0.19-0.23-0.34-0.23c0,0-1.49,0.05-1.49,0.05c-0.27,0-0.52-0.14-0.65-0.36
			c-0.27-0.46-0.6-0.9-0.98-1.32c-0.18-0.2-0.24-0.49-0.15-0.74l0.48-1.34c0.05-0.15,0.01-0.31-0.12-0.41
			c-0.27-0.21-0.54-0.41-0.83-0.59c-0.06-0.04-0.13-0.06-0.2-0.06c-0.08,0-0.16,0.03-0.22,0.07l-1.17,0.89
			c-0.13,0.1-0.28,0.15-0.44,0.15c-0.1,0-0.2-0.02-0.29-0.06c-0.51-0.22-1.04-0.39-1.59-0.5c-0.26-0.06-0.47-0.25-0.55-0.5
			l-0.42-1.38c-0.05-0.15-0.18-0.25-0.33-0.26c-0.09,0-0.17-0.01-0.26-0.01c-0.08,0-0.16,0-0.24-0.01c-0.01,0-0.01,0-0.02,0
			c-0.18,0-0.35,0.01-0.52,0.01c-0.16,0.01-0.29,0.11-0.33,0.26l-0.41,1.38c-0.08,0.26-0.29,0.45-0.55,0.51
			c-0.54,0.12-1.07,0.29-1.59,0.51c-0.09,0.04-0.19,0.06-0.29,0.06c-0.16,0-0.32-0.05-0.44-0.15l-1.18-0.88
			c-0.07-0.05-0.14-0.07-0.22-0.07c-0.07,0-0.14,0.02-0.2,0.06c-0.28,0.18-0.56,0.38-0.82,0.59c-0.12,0.1-0.17,0.26-0.12,0.41
			l0.49,1.34c0.09,0.26,0.04,0.54-0.14,0.74c-0.37,0.41-0.7,0.86-0.97,1.32c-0.13,0.22-0.39,0.36-0.66,0.36c0,0-1.48-0.04-1.48-0.04
			c-0.15,0-0.29,0.09-0.34,0.23c-0.11,0.3-0.22,0.61-0.31,0.93c-0.04,0.15,0.02,0.32,0.15,0.4l1.21,0.81
			c0.23,0.15,0.35,0.41,0.32,0.68c-0.03,0.27-0.04,0.53-0.04,0.81c0,0.28,0.02,0.55,0.04,0.81c0.03,0.27-0.09,0.53-0.32,0.69
			l-1.2,0.81c-0.13,0.09-0.19,0.25-0.15,0.4c0.09,0.31,0.19,0.62,0.31,0.92c0.05,0.14,0.19,0.23,0.34,0.23c0,0,1.49-0.05,1.49-0.05
			c0.27,0,0.52,0.14,0.65,0.36c0.28,0.47,0.61,0.91,0.98,1.31c0.18,0.2,0.24,0.49,0.15,0.74l-0.48,1.34
			c-0.05,0.15-0.01,0.31,0.12,0.41c0.27,0.21,0.55,0.41,0.83,0.59c0.06,0.04,0.13,0.06,0.2,0.06c0.08,0,0.16-0.03,0.22-0.07
			l1.17-0.89C61.02,66.81,61.17,66.76,61.34,66.76z"
        />
        <path
          className="st0"
          d="M65,64.35c-2.83,0-5.13-2.3-5.13-5.13c0-2.83,2.3-5.13,5.13-5.13c0.9,0,1.79,0.24,2.57,0.69
			c0.35,0.2,0.47,0.65,0.27,1c-0.13,0.23-0.37,0.37-0.64,0.37c-0.13,0-0.25-0.03-0.37-0.1c-0.56-0.32-1.19-0.49-1.83-0.49
			c-2.02,0-3.67,1.64-3.67,3.67c0,2.02,1.64,3.67,3.67,3.67c2.02,0,3.67-1.64,3.67-3.67c0-0.4,0.33-0.73,0.73-0.73
			s0.73,0.33,0.73,0.73C70.13,62.05,67.83,64.35,65,64.35z"
        />
      </g>
    </g>
  </Base>
);

export default TechnologyIcon;
