import IVerificationCodeRepository from 'shared/domain/repositories/IVerificationCodeRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisVerificationCodeRepository from './implementations/VectisVerificationCodeRepository';

let instance: IVerificationCodeRepository | null = null;

export default function makeVerificationCodeRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisVerificationCodeRepository(httpProvider);
  }

  return instance;
}
