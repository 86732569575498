import { useState } from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Collapse from './components/Collapse';
import CustomHr from '../../components/CustomHr';
import { Questions } from '../../constants/Questions';
import Lisa from '../../img/lisa-faq-home.svg';
import { Container } from '../../styles/global';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.secondary,
    [theme.breakpoints.down('md')]: {
      marginTop: '14rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '8rem',
    },
  },
  smallLisa: {
    width: '70%',
    height: '25vh',
    display: 'block',
    background: `url("${Lisa}") no-repeat`,
    backgroundSize: 'contain',
    [theme.breakpoints.down('md')]: {
      margin: '-18rem auto 0',
      backgroundPositionX: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      height: '360px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-12rem auto 0',
      height: '200px',
      widrh: '200px',
    },
  },
  description: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
  },
  destak: {
    color: theme.palette.primary.main,
  },
  destakLink: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  subDescription: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

export default function FAQ() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('Panel1');
  const { t } = useTranslation();

  const handleChange = panel => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t('Vialaser loja online | Ecommerce de depilação a laser')}
        </title>
        <meta
          name="description"
          content={t(
            'Compre depilação a laser na loja online da Vialaser Um jeito ainda mais fácil de adquirir o seu melhor pacote de depilação com o melhor preço',
          )}
        />
        <meta
          name="keywords"
          content={t(
            'depilação a laser, comprar depilação a laser, depilação a laser preço',
          )}
        />
      </Helmet>
      <div className={clsx('home-container', classes.root)}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item sm={12} md={5} lg={4} container alignItems="center">
                <div className={classes.smallLisa} />
                <div className={classes.description}>
                  <Typography variant="h1" component="h3">
                    {t('Amiga que é amiga, não enrola,')}
                    <span className={classes.destak}> {t('descomplica!')}</span>
                  </Typography>
                  <CustomHr />
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.subDescription}
                  >
                    {t(
                      'Tire suas principais dúvidas sobre depilação a laser aqui Saiba se depilação a laser dói, se é definitiva, para quem o método é eficaz, vantagens, desvantagens e muito mais!',
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                {Questions.slice(0, 6).map(question => (
                  <Collapse
                    key={question.name}
                    data={question}
                    expanded={expanded}
                    change={handleChange}
                  />
                ))}
                <div className={classes.description}>
                  <Typography
                    className={classes.subDescription}
                    style={{ marginTop: '35px' }}
                    variant="h3"
                  >
                    {t('Se não encontrar sua dúvida entre as listadas,')}{' '}
                    <strong>{t('sem problemas!')}</strong>
                  </Typography>

                  <Typography
                    variant="h3"
                    className={classes.subDescription}
                    style={{ marginTop: '20px' }}
                  >
                    {' '}
                    <Link to="/faq">
                      <span className={classes.destakLink}>
                        {' '}
                        {t(
                          'Acesse nossa página de FAQ sobre depilação a laser e tire todas as dúvidas!',
                        )}
                      </span>
                    </Link>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
