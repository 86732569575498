import { TENANCY } from 'config/tenancy';
import IDistrictsRepository from 'shared/domain/repositories/IDistrictsRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisDistrictsRepository from './implementations/VectisDistrictsRepository';

let instance: IDistrictsRepository | null = null;

export default function makeDistrictsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider({ language: TENANCY });

    instance = new VectisDistrictsRepository(httpProvider);
  }

  return instance;
}
