/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions  */
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import * as Portal from '@radix-ui/react-portal';

import { ModalContext, TModalContext } from './context';
import * as S from './styles';

interface IModalProps {
  children?: ReactNode;
  initialOpen?: boolean;
  closeOnOverlayClick?: boolean;
  align?: S.TAlignment;
}

const Modal: ForwardRefRenderFunction<TModalContext, IModalProps> = (
  {
    children,
    initialOpen = false,
    closeOnOverlayClick = true,
    align = 'center',
  },
  ref,
) => {
  const [visible, setVisible] = useState(initialOpen);

  const actions = useMemo(
    () => ({
      visible,
      close: () => setVisible(false),
      open: () => setVisible(true),
      toggle: () => setVisible(visible => !visible),
    }),
    [visible],
  );

  useImperativeHandle(ref, () => actions, [actions]);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  return (
    <Portal.Root>
      <ModalContext.Provider value={actions}>
        <S.Overlay
          visible={visible}
          align={align}
          onClick={() => {
            if (closeOnOverlayClick) setVisible(false);
          }}
        >
          <div
            className="modal-dialog"
            onClick={event => event.stopPropagation()}
            role="dialog"
          >
            {children}
          </div>
        </S.Overlay>
      </ModalContext.Provider>
    </Portal.Root>
  );
};

export const ModalConsumer = ModalContext.Consumer;

export default forwardRef(Modal);
export { useModal } from './context';
