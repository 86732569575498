import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { StoreFooter } from 'shared/presentation/components/organisms';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container" style={{ flex: 1 }}>
        <Typography variant="h1" gutterBottom>
          {t('Página não encontrada')}
        </Typography>
      </div>

      <StoreFooter />
    </>
  );
}
