import { useQuery } from 'react-query';

import { makeFetchContractTermsUseCase } from 'shared/domain/useCases';
import { sharedQueries } from 'shared/presentation/constants';
import { useRequestErrorHandler } from 'shared/presentation/hooks';

export default function useContractTermsLink(params: { budgetId?: number }) {
  const handleError = useRequestErrorHandler('shared');

  const query = useQuery(
    sharedQueries.acceptanceTerms(params.budgetId),
    () => {
      if (!params.budgetId) throw new Error();

      return makeFetchContractTermsUseCase().execute({
        budgetId: params.budgetId,
      });
    },
    {
      enabled: !!params.budgetId,
      onError: error => handleError(error),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  return {
    loading: query.isLoading,
    link: query.data?.link,
  };
}
