import IParsingProvider from 'shared/domain/providers/IParsingProvider';

const DEFAULT_SEPARATOR = ',';

export default class ListParsingProvider implements IParsingProvider {
  constructor(private separator: string = DEFAULT_SEPARATOR) {}

  public parse<T = unknown>(text: string): T {
    if (!text) return text as T;

    const splitten = text.split(this.separator);

    return splitten.map(chunk => chunk.trim()) as T;
  }

  public stringify(data: unknown): string {
    if (!data) return '';

    if (typeof data === 'object') {
      return Object.values(data).join(this.separator);
    }

    return String(data);
  }
}
