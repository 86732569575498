export const emailUpdate = {
  'An error happened while retrieving the data, please try again later':
    'Se produjo un error al buscar información, inténtelo nuevamente más tarde.',
  'Email update': 'Actualización de correo electrónico',
  'Hello <1>{{name}}</1>! Please, confirm your document to procceed with your email update':
    '¡Hola <1>{{name}}</1>! Confirme su CI para continuar actualizando su correo electrónico.',
  '<0>{{name}}</0>, fill the field below with a new valid email':
    '<0>{{name}}</0>, completa el campo a continuación con su nuevo correo electrónico',
  'The email must be different than the current one':
    'El correo electrónico debe ser diferente al actual.',
  'The link will expire in': 'El enlace caducará en',
  'The link will expire soon': 'El enlace caducará pronto.',
  'Times up, your link has expired!':
    'Se acabó el tiempo, tu enlace ha caducado!',
  Update: 'Actualizar',
  'Your current email': 'Tu correo electrónico actual',
  'Your email was successfully updated!':
    '¡Tu correo electrónico ha sido actualizado exitosamente!',
};
