/* eslint-disable jsx-a11y/media-has-caption */
import { Modal } from "./styles";

function ModalVideo({ open, srcVideo, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <span onClick={handleClose}>X</span>
      <video width="100%" height="100%" loop autoPlay="autoplay" controls>
        <source src={srcVideo} type="video/mp4" />
      </video>
    </Modal>
  );
}

export default ModalVideo;
