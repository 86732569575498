import React from 'react';

import * as S from './styles';

const IMG_FALLBACK = 'https://i.ibb.co/HT2mVDj/placeholder.png';

interface IPackageImageProps {
  src: string | undefined;
  alt: string;
  size?: number;
  isCombo?: boolean;
}

const PackageImage: React.FC<IPackageImageProps> = ({
  src,
  alt,
  size,
  isCombo = false,
}) => (
  <S.Image
    src={src || IMG_FALLBACK}
    alt={alt}
    isRounded={!isCombo}
    size={size}
    onError={event => {
      (event.target as HTMLImageElement).src = IMG_FALLBACK;
    }}
  />
);

export default PackageImage;
