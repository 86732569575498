import { blackNovemberPalette } from './blackNovemberPalette';
import { darkPalette } from './darkPalette';
import { layout } from './layout';
import { lightPalette } from './lightPalette';
import { pinkOctoberPalette } from './pinkOctoberPalette';
import { typography } from './typography';
import { ITheme } from '../models/ITheme';

const palettes = {
  light: lightPalette,
  dark: darkPalette,
  pinkOctober: pinkOctoberPalette,
  blackNovember: blackNovemberPalette,
};

export const theme: Omit<ITheme, 'name'> = {
  typography,
  layout,
  palette: palettes.light,
};
