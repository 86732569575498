import { Grid, makeStyles } from '@material-ui/core';
import { transparentize } from 'polished';

import TestimonialList from './TestimonialList';
import { ReactComponent as AspasSvg } from '../../img/aspas.svg';
import { Container } from '../../styles/global';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: '3.3rem',
    backgroundPosition: '3rem 0',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '7rem 0',
      backgroundSize: '60%',
    },
    [theme.breakpoints.down('500')]: {
      backgroundPosition: '4rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: '0 0',
      backgroundSize: '80%',
    },
  },
  quote: {
    '& svg': {
      fill: `${transparentize(
        0.8,
        theme.palette.cardsBackgroundButton.primary,
      )}`,
      position: 'absolute',
      top: 90,
      left: 0,
      [theme.breakpoints.down('xs')]: {
        width: '300px',
      },
    },
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '-7rem',
    zIndex: 0,
  },
  quoteBottom: {
    '& svg': {
      fill: `${transparentize(
        0.8,
        theme.palette.cardsBackgroundButton.primary,
      )}`,
      position: 'absolute',
      bottom: 90,
      right: 0,
      transform: 'rotate(180deg)',
      [theme.breakpoints.down('xs')]: {
        width: '300px',
      },
    },
    height: '100%',
    width: '100%',
    position: 'absolute',
    bottom: '-7rem',
    zIndex: 0,
  },
}));

export default function Testimonials({ zIndex }) {
  const classes = useStyles();

  return (
    <div
      className="home-container"
      style={{
        position: 'relative',
        zIndex: zIndex || '',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <div className={classes.quote}>
        <AspasSvg />
      </div>
      <Container>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid item xs={12}>
            <TestimonialList />
          </Grid>
        </Grid>
      </Container>
      <div className={classes.quoteBottom}>
        <AspasSvg />
      </div>
    </div>
  );
}
