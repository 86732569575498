/* eslint-disable no-console */

const LoggerHandlerFactory = () => {
  const loggers = {
    CONSOLE: {
      info: (message, params) => console.info(message, ...params),
      debug: (message, params) => console.log(message, ...params),
      error: (message, params) => console.error(message, ...params),
    },
  };

  return {
    make: name => loggers[name] || loggers.CONSOLE,
  };
};

class Logger {
  constructor(handlerName) {
    this.handler = LoggerHandlerFactory().make(handlerName);
  }

  info = (message, ...params) =>
    this.handler.info('INFO', [message, ...params]);

  debug = (message, ...params) =>
    this.handler.debug('DEBUG', [message, ...params]);

  error = (message, ...params) =>
    this.handler.error('ERROR', [message, ...params]);
}

export default function useLogger() {
  return new Logger();
}
