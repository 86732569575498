import React from 'react';

import { Redirect, useRouteQuery } from 'shared/presentation/contexts';

const RecommenderRedirection: React.FC = () => {
  const params = useRouteQuery<'/indicador'>();

  return <Redirect to="/indicador" params={params} />;
};

export default RecommenderRedirection;
