import { lighten } from 'polished';
import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${theme.colors.primary};
  color: ${theme.colors.textBright};

  .description {
    display: flex;
    gap: 2rem;

    div {
      width: 43rem;

      h1 {
        font-size: 3.25rem;
        font-weight: bold;
        height: 5rem;

        strong {
          color: ${theme.colors.secondary};
          font-size: 4.875rem;
          font-style: italic;
        }
      }

      h2 {
        font-size: 2.5rem;
        font-weight: bold;
      }

      span {
        font-size: 1.25rem;
      }
    }

    a {
      background-color: ${theme.colors.secondary};
      color: ${theme.colors.textBright};
      font-size: 2.5rem;
      align-self: center;
      padding: 0.75rem;
      border-radius: 2rem;

      :hover {
        background-color: ${lighten(0.1, theme.colors.secondary)};
      }
    }
  }

  .strip {
    background-color: ${theme.colors.secondary};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    margin: 1.5rem 0;

    svg {
      height: 5rem;
      width: 5rem;
      margin: -0.45rem 0;
    }

    a {
      font-weight: bold;
      font-size: 2.25rem;
      color: ${theme.colors.textBright};

      &:hover {
        color: ${lighten(0.4, theme.colors.secondary)};
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    .description {
      flex-direction: column;

      div {
        width: 100%;
        text-align: center;

        h1 {
          font-size: 2.5rem;
          height: auto;

          strong {
            font-size: 3.5rem;
          }
        }

        h2 {
          font-size: 2rem;
        }
      }

      a {
        font-size: 2rem;
      }
    }
  }

  .strip {
    text-align: center;

    svg {
      display: none;
    }

    a {
      font-size: 1.75rem;
    }
  }
`;
