import { ICheckIfCustomerExistsByDocumentDTO } from 'shared/domain/dtos';
import { ICustomersRepository } from 'shared/domain/repositories';

export default class FindCustomerByDocumentUseCase {
  constructor(private repository: ICustomersRepository) {}

  public async execute({ document }: ICheckIfCustomerExistsByDocumentDTO) {
    return this.repository.findByDocument(document);
  }
}
