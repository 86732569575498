import IItemsRepository from 'shared/domain/repositories/IItemsRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisItemsRepository from './implementations/VectisItemsRepository';

let instance: IItemsRepository | null = null;

export default function makeItemsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisItemsRepository(httpProvider);
  }

  return instance;
}
