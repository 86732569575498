import { TLanguage } from 'config/tenancy';

const IDS_DICTIONARIES_BY_ENV: Record<string, Record<TLanguage, number>> = {
  production: {
    pt: 40,
    es: 97,
    'es-CR': 0,
  },
  development: {
    pt: 40,
    es: 97,
    'es-CR': 256,
  },
};

export const ECOMMERCE_UNIT_IDS = IDS_DICTIONARIES_BY_ENV[process.env.NODE_ENV];
