import React, { ComponentPropsWithoutRef } from 'react';

import { Route as StandardRoute } from 'react-router-dom';

import { TNavigatorPaths } from '../../models';
import Route from '../Route';
import Switch from '../Switch';

interface INavigatorProps {
  path: TNavigatorPaths;
  routes: Array<ComponentPropsWithoutRef<typeof Route> & { type: 'route' }>;
  container?: React.ElementType;
}

const Navigator: React.FC<INavigatorProps> = ({
  path,
  routes,
  container: Container = React.Fragment,
}) => (
  <StandardRoute path={path}>
    <Container>
      <Switch>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </Switch>
    </Container>
  </StandardRoute>
);

export default Navigator;
