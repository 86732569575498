import { RecoilRoot } from 'recoil';

import { ErrorProvider } from './ErrorContext';
import { HttpProvider } from './HttpContext';
import { NavigationProvider } from './NavigationContext';
import { ThemeProvider } from './ThemeContext';
import { ToastProvider } from './ToastContext';

export * from './LocaleContext';
export * from './NavigationContext';
export * from './ThemeContext';
export * from './ToastContext';

const SharedContainer: React.FC = ({ children }) => (
  <RecoilRoot>
    <ThemeProvider>
      <ToastProvider>
        <HttpProvider>
          <NavigationProvider>
            <ErrorProvider>{children}</ErrorProvider>
          </NavigationProvider>
        </HttpProvider>
      </ToastProvider>
    </ThemeProvider>
  </RecoilRoot>
);

export default SharedContainer;
