import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IPhonesValidityRepository, {
  TPhoneValidity,
} from 'shared/domain/repositories/IPhonesValidityRepository';

export default class VectisPhonesValidityRepository
  implements IPhonesValidityRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public async findOne(phone: string): Promise<TPhoneValidity> {
    const { valido, mensagem } = await this.httpProvider.post<{
      valido: boolean;
      mensagem: string;
    }>('/clientes/validade-celular', {
      numeroCelular: phone,
    });

    return { valid: valido, reason: mensagem };
  }
}
