import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 8rem;
  position: relative;

  ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 72rem;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;

      svg {
        height: 10rem;
        width: 10rem;

        &.bigger {
          height: 11.5rem;
          width: 11.5rem;
        }

        &.smaller {
          height: 9rem;
          width: 9rem;
        }
      }

      div {
        h3 {
          font-size: 1.75rem;
          color: ${theme.colors.secondary};
          font-weight: bold;
        }

        p {
          font-size: 1.375rem;
        }
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    ul {
      padding: 0 1rem;

      li {
        flex-direction: column;
        text-align: center;

        svg {
          height: 10rem;
          width: 10rem;

          &.bigger {
            height: 10rem;
            width: 10rem;
          }

          &.smaller {
            height: 10rem;
            width: 10rem;
          }
        }
      }
    }
  }
`;
