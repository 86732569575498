import { FiAlertCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Button } from 'shared/presentation/components/molecules';

import * as S from './styles';

const IngenicoTokenization: React.FC<{ handleClose(): void; id: number }> = ({
  handleClose,
  id,
}) => (
  <S.Container>
    <FiAlertCircle />

    <h1>Uma situação precisa de sua atenção</h1>

    <span>
      Realizamos mudanças nos nossos serviços de pagamento e precisamos que
      atualize os dados do seu cartão. Desta forma, você pode continuar a
      comprar e pagar suas parcelas sem problema!
    </span>

    <footer>
      <Link to={`/transferencia-ingenico?notification=${id}`}>
        <Button variant="primary" onClick={handleClose}>
          Atualizar meus dados
        </Button>
      </Link>

      <Button variant="primary" outline onClick={handleClose}>
        Fazer isso mais tarde
      </Button>
    </footer>
  </S.Container>
);

export default IngenicoTokenization;
