import { TFunction } from 'i18next';

import { ICONS } from 'shared/presentation/constants/icons';

type TParams = {
  t: TFunction<'shared'>;
};

const CONTRACTS = ({ t }: TParams) => ({
  to: '/area-cliente/contratos' as const,
  icon: ICONS.DOCUMENT,
  text: t('Contracts'),
});

const CUSTOMER_AREA = ({ t }: TParams) => ({
  to: '/area-cliente/inicio' as const,
  icon: ICONS.HOME,
  text: t('Customer area'),
});

const MY_DATA = ({ t }: TParams) => ({
  to: '/area-cliente/perfil' as const,
  icon: ICONS.LIST,
  text: t('My data'),
});

const RECOMMENDATIONS = ({ t }: TParams) => ({
  to: '/area-cliente/indicacoes' as const,
  icon: ICONS.GIFT,
  text: t('My recommendations'),
});

const SESSIONS = ({ t }: TParams) => ({
  to: '/area-cliente/tratamentos' as const,
  icon: ICONS.COUNTER_CLOCK,
  text: t('Sessions'),
});

const SCHEDULINGS = ({ t }: TParams) => ({
  to: '/area-cliente/agendamentos' as const,
  icon: ICONS.CALENDAR,
  text: t('Schedulings'),
});

export const CUSTOMER_MENU_LINKS = {
  CONTRACTS,
  CUSTOMER_AREA,
  MY_DATA,
  RECOMMENDATIONS,
  SCHEDULINGS,
  SESSIONS,
};
