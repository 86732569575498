import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 480px;
    padding: ${theme.layout.spacing(2)};

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    gap: ${theme.layout.spacing(2)};
    background-color: ${theme.palette.neutral[50]};
    border-radius: 1rem;

    h1,
    p {
      margin: 0;
    }

    svg {
      color: ${theme.palette.warning.main};
      font-size: 56px;
    }

    h1 {
      font-size: ${theme.typography.sizes.title2};
      font-weight: ${theme.typography.weight.bold};
      color: ${theme.palette.warning.main};
    }

    footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.layout.spacing(1)};
    }

    button {
      width: 200px;
    }
  `}
`;
