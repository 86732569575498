import { TChannelType } from 'shared/domain/entities';

type TParams = {
  availableChannels?: TChannelType[];
  customer: {
    email?: string;
    phone?: string;
    validWhatsapp?: boolean;
  };
};

const DEFAULT_CHANNEL_OPTIONS: TChannelType[] = ['EMAIL', 'WHATSAPP', 'SMS'];
const CUSTOMER_FIELD_REQUIRED_BY_CHANNEL: Record<
  TChannelType,
  keyof TParams['customer']
> = {
  EMAIL: 'email',
  SMS: 'phone',
  WHATSAPP: 'phone' && 'validWhatsapp',
};

export default function filterAvailableChannels({
  availableChannels = DEFAULT_CHANNEL_OPTIONS,
  customer,
}: TParams) {
  return availableChannels.filter(channel => {
    const customerField = CUSTOMER_FIELD_REQUIRED_BY_CHANNEL[channel];
    const isFieldValid = customer[customerField];

    return !!isFieldValid;
  });
}
