import { IFetchCustomerByEmailDTO } from 'shared/domain/dtos';
import ICustomersRepository from 'shared/domain/repositories/ICustomersRepository';

export default class FetchCustomerByEmailUseCase {
  constructor(private customerRepository: ICustomersRepository) {}

  public async execute({ email }: IFetchCustomerByEmailDTO) {
    return this.customerRepository.findByEmail(email);
  }
}
