export const paymentLink = {
  'Buy in cash': 'Comprar al contado',
  'Come on! This promotion is limited': '¡Vamos! Esta promoción es limitada',
  'The promotion is ending': 'La promoción está terminando',
  'You have selected the 1x option, this is considered a payment in cash':
    'Has seleccionado la opción de 1x, esto caracteriza una compra al contado',

  'Updating budget': 'Actualización del presupuesto',

  'Close payment link': 'Cerrar enlace de pago',

  'Hello, complete the payment': 'Hola completa el pago',
  'Hello {{customer}}, complete the payment':
    'Hola {{customer}},  completa el pago',
  'Of your order by informing the payment data':
    'De su pedido informando los datos de pago',

  Show: 'Mostrar',
  Hide: 'Esconder',

  'Order items_one': 'Artículo de su compra',
  'Order items_other': 'Artículos de su compra',
  Product: 'Producto',
  Package: 'Paquete',
  Combo: 'Combo',
  '{{item}} with {{sessions}} sessions of_one':
    '{{item}} con {{sessions}} sesione de',
  '{{item}} with {{sessions}} sessions of_other':
    '{{item}} con {{sessions}} sesiones de',

  'Card payment data': 'Datos de pago con tarjeta',
  'Card data': 'Datos da tarjeta',
  'Automatic debit payment data': 'Datos de pago con débito automático',

  'Order resume': 'Currículum de compra',
  'Payment method': 'Método de pago',
  'Coupon {{coupon}}': 'Cupón {{coupon}}',
  'Original value': 'Valor original',
  'Total discount': 'Descuento total',
  Total: 'Total',
  'You will pay': 'Tu vas a pagar',
  '{{installments}}x of {{value}}': '{{installments}}x de {{value}}',

  Select: 'Seleccionar',

  'Credit card': 'Tarjeta de crédito',
  'Recurrent card': 'Tarjeta recurrente',

  'Finish order': 'Finalizar compra',
  'Generate link': 'Generar enlace',

  'This payment is 100% secure!': '¡Este pago es 100% seguro!',
  'Vialaser ensures a secure transaction of your payment':
    'Vialaser garantiza una transacción segura de su pago.',

  'Alert!': '¡Alerta!',
  'As you chose to pay more than 12 installments, confirmation of your purchase will depend on the approval of the bank that issued the card Vialaser does not provide guarantees for approval of installments in excess of 12 installments':
    'Como optó por pagar en más de 12 cuotas, la confirmación de su compra dependerá de la aprobación del banco emisor de la tarjeta. Vialaser no otorga garantías para la aprobación de cuotas superiores a 12 cuotas.',

  Close: 'Cerca',
  Loading: 'Cargando...',

  'Ops something went wrong': 'Huy! Algo salió mal',
  'Something went wrong while trying to get your order data Please, try again!':
    'Se produjo un error al intentar obtener los datos de su pedido. ¡Inténtelo de nuevo!',
  'The budget status changed Please, contact the clinic for more information':
    'El estado del presupuesto cambió. Por favor, póngase en contacto con la clínica para obtener más información.',
  'Expired link! Contact the unit to get another one':
    '¡Enlace caducado! Póngase en contacto con la unidad para obtener otro.',

  'All right!': '¡Bueno!',
  'The payment was processed and the contract was generated!':
    '¡Se procesó el pago y se generó el contrato!',

  'Try again': 'Intentar otra vez',
  Home: 'Inicio',

  Copy: 'Copiar',
  Open: 'Abrir',

  'Link copied': 'Enlace copiado!',
  'Could not copy link': 'No se puede copiar el enlace',

  'Nupay link generated successfully!': '¡Enlace de Nupay generado con éxito!',

  'The purchase discount refers to the default payment method discount + {{discount}} of your coupon {{coupon}} applied':
    'El descuento en tu compra se refiere al descuento por forma de pago + {{discount}} de tu cupón {{coupon}} aplicado.',

  'We made a transaction on your card in the amount of {{value}} that will be deducted from your first full installment':
    'Hicimos una transacción en su tarjeta por {{value}} el monto que se deducirá de su primera cuota completa',

  'The time to make the payment is over! Please contact the unit':
    '¡Se acabó el tiempo de hacer el pago! Por favor, póngase en contacto con la clínica.',

  Important: 'Importante',
  'This promotion soon will end! Complete the payment before the timer above ends':
    '¡Esta promoción pronto terminará! Complete el pago antes de que finalice el temporizador anterior.',
  'Please, confirm your data below to continue with your purchase':
    'Por favor, confirme los datos para continuar con su compra.',
  '* When filling in data other than those registered, your registration will be updated':
    '*Al rellenar datos distintos a los registrados, se actualizará tu inscripción.',
  'Your data was updated successfully!':
    '¡Tus datos se actualizaron exitosamente!',
  'Got it': 'Entiendo',

  'Talk to an attendant': 'Hablar con una representante',
  'Please confirm your document to continue your purchase':
    'Confirme su CI para continuar con su compra.',
  "Please confirm your guardian's document to continue your purchase":
    'Confirme el CI de su responsable legal para continuar con su compra.',
  'Confirm Document': 'Confirmar CI',
  'The document entered does not match the registration number, please contact the unit or try again':
    'El CI ingresado no coincide con el registro, comuníquese con la unidad o intente nuevamente',
  'Type the document here': 'Ingrese el CI aquí',
  'I agree with the <1>Contract Accession terms</1>':
    'Acepto los <1>términos de Adhesión al Contrato</1>',
  'Not long to finish your purchase, but before please choose where do you want to receive your confirmation code':
    'Falta poco para realizar tu compra, antes escoge por donde deseas recibir el código de confirmación:',

  'You have added an item that makes it impossible to make a purchase Please contact the clinic to adjust your budget':
    'Has añadido un artículo que imposibilita realizar una compra. Por favor contacte con la clínica para ajustar su presupuesto.',
  'You have areas with outstanding financial obligations Therefore, you will be unable to make this purchase until you resolve your pending issues Even so, if you wish in other payment method, you may acquire other areas that do not have financial liabilities':
    'Tienes áreas con pendientes financieras. Por lo tanto, no podrá realizar esta compra hasta que resuelva sus pendientes. Aun así, si desea utilizar otra forma de pago, podrá adquirir otras áreas que no tengan pendientes financieras.',
};
