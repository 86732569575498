import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import '../i18n/locales/es/es';
import moment from 'moment';

const languageMapping = {
  pt: 'pt-BR',
  es: 'es',
};

export default function SetMomentLocale() {
  const { i18n } = useTranslation();
  useEffect(() => {
    moment.locale(languageMapping[i18n.language]);
  }, [i18n.language]);

  return null;
}
