import IEventTrackerProvider, {
  TMeta,
} from 'shared/domain/providers/IEventTrackerProvider';

export default class LogEventTrackerProvider implements IEventTrackerProvider {
  public register(
    event: string,
    meta: TMeta,
    payload?: object | undefined,
  ): void {
    const data = {
      meta,
      payload,
    };

    console.log(`===== TRACKING EVENT: ${event} =====`);
    console.log(data);
  }
}
