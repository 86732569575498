import styled from 'styled-components';

const TransparentBigText = styled.span`
  position: absolute;
  bottom: ${({ distance = '2.5rem' }) => distance};
  left: 0;
  display: block;
  font-size: ${({ size = '20rem' }) => size};
  height: 19rem;
  opacity: 0.1;
  pointer-events: none;
  font-weight: bold;
`;

export default TransparentBigText;
