import es from './es';
import esCR from './esCR';
import pt from './pt';

const locale = {
  pt,
  es,
  'es-CR': esCR as typeof es,
};

export default locale;
