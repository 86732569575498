import { ICity } from 'shared/domain/entities';

export interface IVectisCity {
  id: number;
  nome: string;
}

export class City implements ICity {
  public id: ICity['id'];
  public name: ICity['name'];

  constructor({ id, nome }: IVectisCity) {
    this.id = id;
    this.name = nome;
  }
}
