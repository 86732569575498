import { useState, FC } from 'react';

import { ExpiryTimerContext } from '../../hooks/useExpiryTimer';

export const ExpiryTimerProvider: FC = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState<Date>(new Date());

  return (
    <ExpiryTimerContext.Provider
      value={{
        timeLeft,
        setTimeLeft,
      }}
    >
      {children}
    </ExpiryTimerContext.Provider>
  );
};
