import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;

  .error {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;

    &__informations {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 0.5rem;
    }

    &__title {
      color: ${props => props.theme.palette.text.main};
      font-size: ${props => props.theme.typography.sizes.title1};
    }

    &__message {
      color: ${props => props.theme.palette.text.light};
      font-size: ${props => props.theme.typography.sizes.body1};
      width: 60%;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }

    &__button {
      padding: 0 1.5rem;
    }
  }

  @media (max-width: ${props => props.theme.layout.breakpoints.md}) {
    .error .error__actions {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  }
`;
