export default function validateCardExpiryDate(expirationDate: string) {
  const date = new Date();

  const lastDayOfCurrentMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  );

  const year = date.getFullYear().toString();
  const formatedDate = new Date(
    year[0] + year[1] + expirationDate.split('/').reverse().join('-'),
  );

  return formatedDate > lastDayOfCurrentMonth;
}
