import IParsingProvider from 'shared/domain/providers/IParsingProvider';

import JsonParsingProvider from './implementations/JsonParsingProvider';
import ListParsingProvider from './implementations/ListParsingProvider';

let instance: IParsingProvider | null = null;

const FACTORIES = {
  JSON: () => new JsonParsingProvider(),
  COMMA: (params: { separator?: string }) =>
    new ListParsingProvider(params.separator),
};

type TImplementation = keyof typeof FACTORIES;

type TOptions<T extends TImplementation> = {
  type: T;
} & Parameters<(typeof FACTORIES)[T]>[0];

export default function makeParsingProvider<T extends TImplementation>({
  type,
  ...args
}: TOptions<T>): IParsingProvider {
  if (!instance) {
    const factory = FACTORIES[type];

    instance = factory(args);
  }

  return instance;
}
