import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.layout.spacing(2)};

    ul {
      a {
        display: flex;
        align-items: center;
        gap: ${theme.layout.spacing(1.5)};
        font-size: ${theme.typography.sizes.body1};
        transition: all 0.2s;

        svg {
          font-size: 24px;
          color: ${theme.palette.primary.main};
        }

        :hover {
          color: ${theme.palette.primary.main};
        }
      }
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      align-items: center;
      padding: ${theme.layout.spacing(0, 2)};
    }
  `}
`;
