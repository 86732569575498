import { Component, ReactNode } from 'react';

import { makeRegisterErrorUseCase } from 'shared/domain/useCases';

interface IErrorBoundaryProps {
  children: ReactNode;
  fallback: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  private errorRegisterer = makeRegisterErrorUseCase();

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  /** @public */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: { componentStack: string }) {
    console.error(error);
    console.error(errorInfo);

    const commonErrorData = {
      origin: window.location.href,
      browser: navigator.userAgent,
      componentStack: errorInfo.componentStack,
    };

    if (error instanceof Error) {
      return this.errorRegisterer.execute({
        key: 'REACT_ERROR',
        data: {
          ...commonErrorData,
          error: error.message,
        },
        stackTrace: error.stack,
      });
    }

    return this.errorRegisterer.execute({
      key: 'REACT_ERROR',
      data: {
        ...commonErrorData,
        error: String(error),
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
