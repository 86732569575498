import React from "react";

import { Header } from "./styles";

function GreenColoredHeader({ children }) {
  return (
    <Header>
      <h2>{children}</h2>
    </Header>
  );
}

export default GreenColoredHeader;
