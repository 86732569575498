export const leadViaRecommendation = {
  'Because it hurts less than the methods that rip out the hairs':
    'Porque duele menos que los métodos para arrancar el cabello',
  'Because its cheaper on the long run':
    'Porque a la larga es mucho más económico',
  'Because its much more practical and lasts forever':
    'Porque es mucho más práctico y dura para siempre',
  'Because its the most sustainable method':
    'Porque es el método más sostenible',
  'Because you deal less damage to your skin':
    'Porque eres menos agresivo con tu piel',
  'Giving goodbye to the hairs is possible':
    'Decir adios al cabello es posible',
  'Its not magic its Vialaser!': '¡No es magia es Vialaser!',
  Recommendation: 'Indicación',
  'Why switch from traditional hair removal to laser hair removal?':
    '¿Por qué cambiar su depilación tradicional por láser?',
};
