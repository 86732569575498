import { IErrorLoggerRepository } from 'shared/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisErrorLoggerRepository from './implementations/VectisErrorLoggerRepository';

let instance: IErrorLoggerRepository | null = null;

export default function makeErrorLoggerRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisErrorLoggerRepository(httpProvider);
  }

  return instance;
}
