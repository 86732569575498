import { makeNpsRepository } from 'shared/infra/repositories';

import VerifyNpsSessionFeedbackUseCase from './implementations/VerifyNpsSessionFeedbackUseCase';

let instance: VerifyNpsSessionFeedbackUseCase | null = null;

export default function makeVerifyNpsSessionFeedbackUseCase() {
  if (!instance) {
    const repository = makeNpsRepository();

    instance = new VerifyNpsSessionFeedbackUseCase(repository);
  }

  return instance;
}
