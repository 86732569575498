import styled from 'styled-components';

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 1.5rem;
  }
`;
