import { IVerifyNpsSessionFeedbackDTO } from 'shared/domain/dtos';
import { INpsRepository } from 'shared/domain/repositories';

export default class VerifyNpsSessionFeedbackUseCase {
  constructor(private npsRepository: INpsRepository) {}

  public async execute({ id }: IVerifyNpsSessionFeedbackDTO) {
    return await this.npsRepository.findById(id);
  }
}
