import { ICreateCustomerDTO } from 'shared/domain/dtos';
import ICustomersRepository from 'shared/domain/repositories/ICustomersRepository';

export default class CreateCustomerUseCase {
  constructor(private customerRepository: ICustomersRepository) {}

  public async execute({
    name,
    email,
    countryCode,
    phone,
    terms,
    origin,
    unitId,
    optin,
    password,
    trackingMeta,
    leadOrigin,
  }: ICreateCustomerDTO) {
    return this.customerRepository.create({
      name,
      email,
      countryCode,
      phone,
      terms,
      origin,
      unitId,
      optin,
      password,
      trackingMeta,
      leadOrigin,
    });
  }
}
