import { IconBase, IconType } from 'react-icons';

const Pix: IconType = props => (
  <IconBase
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 72.17 71.84"
    xmlSpace="preserve"
  >
    <g>
      <g id="g992" transform="translate(782.8699,645.7084)">
        <path
          id="path994"
          color="fillColor"
          d="M-729.12-593.06c-2.48,0-4.81-0.96-6.56-2.71l-9.47-9.47c-0.66-0.67-1.82-0.66-2.49,0l-9.5,9.5
			c-1.75,1.75-4.08,2.71-6.56,2.71h-1.87l11.99,11.99c3.74,3.74,9.82,3.74,13.56,0l12.02-12.02H-729.12z"
        />
      </g>
      <g id="g996" transform="translate(666.4241,758.5073)">
        <path
          id="path998"
          color="fillColor"
          d="M-647.25-739.35c2.48,0,4.81,0.96,6.56,2.71l9.5,9.5c0.68,0.68,1.8,0.69,2.49,0l9.47-9.47
			c1.75-1.75,4.08-2.71,6.56-2.71h1.14l-12.02-12.02c-3.74-3.74-9.82-3.74-13.56,0l-11.99,11.99H-647.25z"
        />
      </g>
      <g id="g1000" transform="translate(820.2131,724.8881)">
        <path
          id="path1002"
          color="fillColor"
          d="M-755.38-695.75l-7.27-7.27c-0.16,0.06-0.33,0.1-0.52,0.1h-3.3c-1.71,0-3.38,0.69-4.59,1.9
			l-9.47,9.47c-0.89,0.89-2.05,1.33-3.21,1.33c-1.16,0-2.33-0.44-3.21-1.33l-9.5-9.5c-1.21-1.21-2.88-1.9-4.59-1.9h-4.06
			c-0.17,0-0.34-0.04-0.49-0.1l-7.3,7.3c-3.74,3.74-3.74,9.82,0,13.56l7.29,7.29c0.15-0.06,0.32-0.1,0.49-0.1h4.06
			c1.71,0,3.38-0.69,4.59-1.9l9.5-9.5c1.72-1.72,4.71-1.72,6.43,0l9.47,9.47c1.21,1.21,2.88,1.9,4.59,1.9h3.3
			c0.18,0,0.36,0.04,0.52,0.1l7.27-7.27C-751.64-685.93-751.64-692-755.38-695.75"
        />
      </g>
    </g>
  </IconBase>
);

export default Pix;
