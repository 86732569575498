import React from "react";
import GreenColoredHeader from "../GreenColoredHeader";

import { Container } from "./styles";

function FAQ({ questions = [] }) {
  return (
    <Container>
      <GreenColoredHeader>PREGUNTAS FRECUENTES</GreenColoredHeader>

      <ul>
        {questions.map(question => (
          <li key={question.title}>
            <h5>{question.title}</h5>
            <p>{question.text}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}

export default FAQ;
