import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 2rem;

  z-index: 90000;

  > div {
    position: relative;

    > button {
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;

      width: 28px;
      height: 28px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100%;
      border: 1px solid ${props => props.theme.palette.neutral[100]};
      background-color: ${props => props.theme.palette.neutral[100]};

      > svg {
        color: ${props => props.theme.palette.text.main};
      }
    }

    > a {
      width: 64px;
      height: 64px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100%;
      border: 1px solid #2bd757;
      background-color: #2bd757;
      box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);

      > svg {
        font-size: 1.75rem;
        color: #fff;
      }
    }
  }
`;
