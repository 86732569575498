import es from './es';
import pt from './pt';

const locale = {
  pt,
  es,
  'es-CR': {} as typeof es,
};

export default locale;
