export const mapUnits = {
  bairro: 'Vila Monte Alegre',
  cep: '00430-401',
  cidade: 'São Paulo',
  codigo: 'BRSP09',
  ddd: 11,
  descricaoWeb: 'São Judas - Ed Houston Office Center',
  disponivelOnline: true,
  email: 'atendimento@vectiscrm.com.br',
  equipamentoTecnologia: null,
  fechada: false,
  fone: null,
  hrFimAtendimentoDomingo: '16:00',
  hrFimAtendimentoSabado: '21:00',
  hrFimAtendimentoSegundaAteSexta: '21:00',
  hrFimExpediente: '21:00',
  hrInicioAtendimentoDomingo: '10:00',
  hrInicioAtendimentoSabado: '09:00',
  hrInicioAtendimentoSegundaAteSexta: '09:00',
  hrInicioExpediente: '09:00',
  id: 92,
  latitude: '-23.626195',
  logradouro: 'Avenida Fagundes Filho, Loja 04, Térreo',
  longitude: ' -46.639431',
  nomeFantasia: 'São Paulo | São Judas',
  numeroLogradouro: 191,
  razaoSocial: 'Vialaser Serviços Estéticos Ltda',
  regiao: 'ZL',
  tokenEcommerce: 'L2kVTPmpjRL8H6GJVfMlVLxAO00gV_U1KhRb896W2O8',
  uf: 'SP',
};
