import { Typography } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { MdShoppingCart } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, Image } from './styles';

function Card({ data }) {
  return (
    <Link to={data.link}>
      <Container>
        <Image src={data.image} alt={data.title} />
        <Typography
          style={{
            marginBottom: '1rem',
            fontWeight: 'bold',
          }}
          align="center"
          variant="h2"
        >
          {data.title}
        </Typography>
        <Typography align="center" variant="body1">
          {ReactHtmlParser(data.text)}
        </Typography>

        <div>
          <span>
            <MdShoppingCart size={22} />
          </span>
          <Typography variant="body1">Ir às compras</Typography>
        </div>
      </Container>
    </Link>
  );
}

export default Card;
