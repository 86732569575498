import moment from 'moment';

export default function getDaysElapsedSinceDate(rawChargeDate: string) {
  const [day, month, year] = rawChargeDate.split('/');

  if (!day || !month || !year) return 0;

  const today = moment();
  const chargeDate = moment({
    day: Number(day),
    month: Number(month) - 1,
    year: Number(year),
  });

  const difference = moment.duration(today.diff(chargeDate));

  return Math.abs(Math.floor(difference.asDays()));
}
