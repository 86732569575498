import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;

        scrollbar-width: thin;
    }

    html{
        scroll-behavior: smooth;
        @media(max-width: 475px) {
            font-size: 90%;
        }
    }


    body,
    button,
    input,
    textarea {
        font-family: ${theme.typography.family.beauSans};
        -webkit-font-smoothing: antialiased;
        font-smooth: always;
    }

    body {
       background-color: ${theme.colors.body};
        color: ${theme.colors.text};
        transition: .4s; /*For animation dark mode*/
    }

    button {
        cursor: pointer;
        border: none;
        outline: none;
    }

    ul{
        list-style: none;
    }

    a{
        text-decoration: none;
        transition: all .2s;
    }

    img{
        max-width: 100%;
        height: auto;
    }
`;
