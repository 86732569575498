import { makeVerificationCodeAvailabilityRepository } from 'shared/infra/repositories';

import FetchVerificationCodeAvailabilityUseCase from './implementations/FetchVerificationCodeAvailabilityUseCase';

let instance: FetchVerificationCodeAvailabilityUseCase | null = null;

export default function makeFetchVerificationCodeAvailabilityUseCase() {
  if (!instance) {
    const repository = makeVerificationCodeAvailabilityRepository();

    instance = new FetchVerificationCodeAvailabilityUseCase(repository);
  }

  return instance;
}
