const CI_BLACKLIST = ['123456', '1234567'];

const REPEATED_NUMBERS_REGEX = /^(\d)\1+$/;

export default function isCIValid(ci: string) {
  const customerCI = ci.replace(/[^\d]+/g, '');

  if (customerCI.match(REPEATED_NUMBERS_REGEX)) return false;

  if (CI_BLACKLIST.includes(customerCI)) return false;

  if (customerCI.length < 6 || customerCI.length > 7) return false;

  return true;
}
