import { makeCitiesRepository } from 'shared/infra/repositories';

import FetchCitiesUseCase from './implementations/FetchCitiesUseCase';

let instance: FetchCitiesUseCase | null = null;

export default function makeFetchCitiesUseCase() {
  if (!instance) {
    const citiesRepository = makeCitiesRepository();

    instance = new FetchCitiesUseCase(citiesRepository);
  }

  return instance;
}
