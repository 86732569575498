import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.layout.spacing(2)};

    h3 {
      font-size: ${theme.typography.sizes.title3};
      font-weight: ${theme.typography.weight.bold};
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: ${theme.layout.spacing(1)};
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: ${theme.layout.spacing(1.5)};

        font-size: ${theme.typography.sizes.caption};
        color: ${theme.palette.text.light};

        svg {
          font-size: 20px;
          color: ${theme.palette.secondary.light};
        }
      }
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      ul {
        flex-direction: row;
        justify-content: center;

        li {
          span {
            display: none;
          }
        }
      }
    }
  `}
`;
