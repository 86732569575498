import theme from "Theme";
import styled, { css } from "styled-components";

interface IVisibleProps {
  visible: boolean;
}

export const Overlay = styled.div<IVisibleProps>`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  opacity: 0;
  z-index: -1;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      z-index: 1200;
      visibility: visible;
    `};

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    overflow: hidden;
    background-color: ${theme.palette.background.default};
  }
`;
