import IMicroEventsRepository from 'shared/domain/repositories/IMicroEventsRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisMicroEventsRepository from './implementations/VectisMicroEventsRepository';

let instance: IMicroEventsRepository | null = null;

export default function makeMicroEventsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisMicroEventsRepository(httpProvider);
  }

  return instance;
}
