import axios, { AxiosError, AxiosInstance } from 'axios';
import lodash from 'lodash';

import { TLanguage } from 'config/tenancy';
import { HTTPError } from 'shared/domain/errors';
import IHTTPProvider, {
  IRequestConfig,
  TAvailableHeaders,
} from 'shared/domain/providers/IHTTPProvider';

const isAxiosError = (
  error: Error | AxiosError<{ message?: string }>,
): error is AxiosError<{ message?: string }> =>
  (error as AxiosError).isAxiosError;

const FORBIDDEN_STATUS = 401;

const HEADERS: Record<TAvailableHeaders, string> = {
  AUTHENTICATION: 'Authorization',
};

class VectisHTTPError extends HTTPError {
  public message: string;
  public unauthorized: boolean;
  public response: Record<string, any> = {};

  constructor(error: Error | AxiosError<{ message?: string }>) {
    super();

    const axiosError = isAxiosError(error);

    this.name = error.name;
    this.stack = error.stack;
    this.message = axiosError
      ? (error.response?.data as AxiosError)?.message || error.message
      : error.message;
    this.unauthorized =
      axiosError && error.response?.status === FORBIDDEN_STATUS;
    if (axiosError) this.response = error.response?.data || {};
  }
}

const DOMAINS = {
  pt: process.env.REACT_APP_API_URL || '',
  es: process.env.REACT_APP_API_URL_PARAGUAY || '',
  'es-CR': process.env.REACT_APP_API_URL_COSTARICA || '',
};

export default class AxiosVectisHTTPProvider implements IHTTPProvider {
  private instance: AxiosInstance;

  public get domain() {
    return String(this.instance.defaults.baseURL);
  }

  constructor(language: TLanguage) {
    const baseURL = DOMAINS[language];

    const VectisAPI = axios.create({
      baseURL,
      headers: {
        Language: language,
      },
    });

    this.instance = VectisAPI;
  }

  public async delete<T = unknown>(
    path: string,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.delete(path, config);
      return response.data;
    } catch (error: any) {
      throw new VectisHTTPError(error);
    }
  }

  public async get<T = unknown>(
    path: string,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.get(path, config);
      return response.data;
    } catch (error: any) {
      throw new VectisHTTPError(error);
    }
  }

  public async patch<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.patch(path, body, config);
      return response.data;
    } catch (error: any) {
      throw new VectisHTTPError(error);
    }
  }

  public async post<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.post(path, body, config);
      return response.data;
    } catch (error: any) {
      throw new VectisHTTPError(error);
    }
  }

  public async put<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.put(path, body, config);
      return response.data;
    } catch (error: any) {
      throw new VectisHTTPError(error);
    }
  }

  public setLanguage(language: TLanguage): void {
    const baseURL = DOMAINS[language];
    this.instance.defaults.baseURL = baseURL;
    lodash.set(this.instance.defaults.headers, 'Language', language);
  }

  public setHeader(headerKey: TAvailableHeaders, content: string | null): void {
    const header = HEADERS[headerKey];

    if (!content) {
      delete this.instance.defaults.headers.common[header];
      return;
    }

    this.instance.defaults.headers.common[header] = content;
  }
}
