import { IFindUserBenefitDTO } from 'shared/domain/dtos';
import { IUserBenefitsRepository } from 'shared/domain/repositories';

export default class FindUserBenefitUseCase {
  constructor(private userBenefitsRepository: IUserBenefitsRepository) {}

  public async execute({ userId, origin, key }: IFindUserBenefitDTO) {
    return this.userBenefitsRepository.findById({ userId, origin, key });
  }
}
