import { changeCard } from './changeCard';
import { components } from './components';
import { constants } from './constants';
import { contract } from './contract';
import { contractCheckout } from './contractCheckout';
import { contracts } from './contracts';
import { emailUpdate } from './emailUpdate';
import { pendencies } from './pendencies';
import { phoneUpdate } from './phoneUpdate';
import { profile } from './profile';
import { reparceling } from './reparceling';
import { success } from './success';

const pt = {
  ...changeCard,
  ...components,
  ...constants,
  ...contract,
  ...contracts,
  ...contractCheckout,
  ...emailUpdate,
  ...pendencies,
  ...phoneUpdate,
  ...profile,
  ...reparceling,
  ...success,
};

export default pt;
