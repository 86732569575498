import React from 'react';

import { ExpiryTimerProvider } from './ExpiryTimerContext';
import { InstallmentsProvider } from './InstallmentsContext';
import { PaymentLinkProvider } from './PaymentLinkContext';

export * from './PaymentLinkContext';
export * from './ExpiryTimerContext';
export * from './InstallmentsContext';

const CheckoutContainer: React.FC = ({ children }) => (
  <PaymentLinkProvider>
    <InstallmentsProvider>
      <ExpiryTimerProvider>{children}</ExpiryTimerProvider>
    </InstallmentsProvider>
  </PaymentLinkProvider>
);

export default CheckoutContainer;
