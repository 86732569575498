import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './Collapse-style';

export default function Collapse({ data, expanded, change }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Accordion
      className={classes.panelQuestion}
      expanded={expanded === data.name}
      onChange={change(data.name)}
    >
      <AccordionSummary
        className={expanded === data.name ? classes.summary2 : classes.summary}
        expandIcon={
          <FontAwesomeIcon
            className={classes.ballIconF}
            color={expanded === data.name ? '#fff' : '#ccc'}
            icon={faAngleDown}
          />
        }
        aria-controls={`${data.name}bh-content`}
        id={`${data.name}bh-header`}
      >
        <Typography className={classes.heading}>{t(data.question)}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography variant="body1">{t(data.answer)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
