import { keyframes } from 'styled-components';

import errorAnimation from 'shared/presentation/assets/animations/error.json';
import successAnimation from 'shared/presentation/assets/animations/success.json';

export const ANIMATIONS = {
  ERROR: errorAnimation,
  SUCCESS: successAnimation,
};

export const CSSANIMATIONS = {
  PULSE: keyframes`
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.05);
    }
 `,
  SLIDE_IN_UP: keyframes`
    0% {
      transform: translateY(1000px);
    }

    100% {
      transform: translateY(0)
    }
 `,
  SLIDE_OUT_DOWN: keyframes`
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(1000px)
    }
`,
  ACCORDION_SLIDE_DOWN: keyframes`
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
`,
  ACCORDION_SLIDE_UP: keyframes`
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
`,
};
