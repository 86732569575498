import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";

const Base = styled(IconBase)`
  enable-background: new 0 0 130 130;
  .st0 {
    fill: #63b76b;
  }
  .st1 {
    fill: #ffffff;
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const ClinicsIcon = () => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 130 130"
    {...inproperProps}
  >
    <path
      className="st0"
      d="M65,7.84C33.48,7.84,7.84,33.47,7.83,65c0,31.52,25.64,57.16,57.17,57.16c31.53,0,57.16-25.64,57.17-57.16
	C122.16,33.47,96.53,7.84,65,7.84z M65,117.69c-14.53,0-27.69-5.91-37.24-15.45C18.22,92.7,12.31,79.52,12.31,65
	c0-14.53,5.91-27.7,15.45-37.24C37.31,18.22,50.47,12.31,65,12.31c14.53,0,27.69,5.91,37.24,15.45
	c9.54,9.54,15.45,22.71,15.45,37.24c0,14.52-5.91,27.69-15.45,37.24C92.69,111.78,79.53,117.69,65,117.69z"
    />
    <path
      className="st0"
      d="M80.82,43.23v-3.88c0-8.66-7.04-15.7-15.69-15.7c-8.66,0-15.69,7.04-15.69,15.7v3.88h-17.2
	c0,0,0.63,18.66,1.17,26.91c0.54,8.24,2.88,20.68,3.52,23.46c0.63,2.8,4.05,3.43,4.05,3.43h48.13c3.42,0,4.23-3.77,4.23-3.77
	S96.41,77.39,96.95,70c0.54-7.39,1.35-26.77,1.35-26.77H80.82z M55.89,39.35c0-5.1,4.15-9.24,9.24-9.24c5.09,0,9.23,4.14,9.23,9.24
	v3.88H55.89V39.35z M64.89,87.62L42.5,44.38h11.35c0.93,1.8,1.85,3.62,2.79,5.44c1.52,3.02,3.03,5.92,4.51,8.72
	c1.49,2.8,2.73,5.22,3.75,7.26c1.28-2.46,2.74-5.27,4.4-8.46c1.65-3.2,3.28-6.35,4.89-9.48c0.62-1.2,1.2-2.36,1.78-3.48h11.39
	L64.89,87.62z"
    />
  </Base>
);

export default ClinicsIcon;
