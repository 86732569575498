import React from 'react';

import * as S from './styles';

function getNameInitials(name: string) {
  const [firstname, ...othernames] = name.split(' ');

  if (!othernames.length) return firstname[0];

  const [lastname] = othernames.reverse();

  return firstname[0] + ' ' + lastname[0];
}

interface IUserAvatarProps {
  fullName: string;
  size?: number;
}

const UserAvatar: React.FC<IUserAvatarProps> = ({ fullName, size }) => (
  <S.Container size={size}>
    <span>{getNameInitials(fullName)}</span>
  </S.Container>
);

export default UserAvatar;
