import { CircularProgress } from '@material-ui/core';

export default function CircularLoading() {
  return (
    <div
      className="container text-center"
      style={{ marginTop: '10vw', marginBottom: '15vw', flex: 1 }}
    >
      <CircularProgress size={80} />
    </div>
  );
}
