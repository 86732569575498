import { styled } from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  maxWidth: '1280px',
  [theme.breakpoints.down('1367')]: {
    maxWidth: '1200px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}));
