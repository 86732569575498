import { useMutation } from 'react-query';

import { IRegisterMicroEventDTO } from 'shared/domain/dtos';
import { makeRegisterMicroEventUseCase } from 'shared/domain/useCases';

export default function useMicroEvents() {
  const registerMutation = useMutation(
    (params: IRegisterMicroEventDTO) =>
      makeRegisterMicroEventUseCase().execute(params),
    {
      onError: (_, values) => {
        console.error(`Could not register micro event: ${values.event}`);
      },
    },
  );

  return {
    register: registerMutation.mutateAsync,
  };
}
