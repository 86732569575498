export const checkoutCustomerRegistration = {
  ADDRESS_PLACEHOLDER: 'Ex: Av. Choferes Del Chaco',
  CITY_PLACEHOLDER: 'Ex: Asunción',
  DISTRICT_PLACEHOLDER: 'Ex: San Roque',
  DOCUMENT_PLACEHOLDER: '0.000.000',
  PHONE_PLACEHOLDER: '0000 00000',
  Address: 'Dirección',
  'Age confirmation': 'Confirmación de edad',
  'Alternative document': 'RUC',
  Birthdate: 'Fecha de nacimiento',
  City: 'Ciudad',
  'Confirm and procceed': 'Confirmar y avanzar',
  'Confirm and procceed to checkout': 'Confirmar y avanzar para la compra',
  'Customer registration': 'Registro del cliente',
  "Customer's data": 'Datos del cliente',
  District: 'Distrito',
  Document: 'CI',
  'E-mail': 'E-mail',
  Error: 'Error',
  "Fill in the customer's data": 'Completa los datos del cliente',
  "In order to proceed, please confirm the customer's age":
    'Para continuar, por favor confirme a edad del cliente',
  'Incomplete customer': 'Registro incompleto',
  "Inform the legal guardian's document": 'Libere la CI del responsable legal',
  'Legal guardian document': 'CI del responsable legal',
  'Please, inform a document': 'Por favor, libere una CI',
  'Please, inform a valid city': 'Por favor, libere una ciudad válida',
  'Please, inform a valid date': 'Por favor, libere una fecha válida',
  'Please, inform a valid district': 'Por favor, libere un distrito válido',
  'Please, informa a valid postal code':
    'Por favor, libere un código postal válido',
  'Please, inform an alternative document': 'Por favor, libere un RUC',
  'Please, inform a postal code': 'Por favor, lbere un código postal',
  "Please, inform the customer's birthdate":
    'Por favor, libere la fecha de nacimiento del cliente',
  "Please, inform the customer's email":
    'Por favor, libere el email del cliente',
  'Please, inform the street name': 'Por favor, libere el nombre de la calle',
  'Please, inform the street number': 'Por favor, libere el número de la calle',
  'Please, select a city': 'Por favor, seleccione una ciudad',
  'Please, select a district': 'Por favor, seleccione un distrito',
  'Postal code': 'Código postal',
  'Street No': 'Nº de calle',
  'The current customer is registered as a minor To proceed with the payment, please finish its register inside Vectis':
    'El cliente actual está registrado como menor de edad. Para continuar con el pago, por favor finalice su registro en Vectis.',
  'The customer associated to this budget was not found':
    'No se ha encontrado el cliente asociado con este presupuesto',
  "The customer's birthdate": 'La fecha de nacimiento del cliente',
  "The customer's alternative document": 'El ruc del cliente',
  "The customer's email": 'El email del cliente',
  "The customer's postal code": 'El código postal del cliente',
  'Please, inform a valid document': 'Por favor, libere un CI válido',
  'Please, inform a valid e-mail': 'Por favor, libere un email válido',
  'Please, inform a valid name': 'Por favor, libere un nombre válido',
  'Please, inform a valid number': 'Por favor, libere un numero válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Please, inform a name and surname': 'Por favor, libere un nombre e apellido',
  'Please, inform an existing document': 'Por favor, libere un CI existente',
  'Please, inform your cellphone': 'Por favor, libere tu celular',
  'Please, inform your document': 'Por favor, libere tu CI',
  'Please, inform your e-mail': 'Por favor, libere tu email',
  'Please, inform your name': 'Por favor, libere tu nombre',
  'Preferable billing day': 'Mejor día de pago',
};
