import React, { createContext } from 'react';

import axios from 'axios';

import putUserInStorage from '../helpers/PutUserInStorage';

const LoginContext = createContext({});

export function LoginProvider({ children }) {
  const login = async (email, password) => {
    if (!email || !password) {
      return 'Usuário ou senha inválido';
    }
    const body = {
      email,
      password,
      remember: true,
    };
    const response = await axios.post('/user/login', body);
    if (response) {
      const { user, access_token, refresh_token } = response.data;
      putUserInStorage(user, access_token, refresh_token);
      if (!user.email) {
        return {
          status: false,
          user,
        };
      }

      if (!user.foneCelular) {
        sessionStorage.setItem('@vialaser:noPhone', true);
      }
      return {
        status: true,
        user,
      };
    } else {
      return {
        status: false,
        user: {},
      };
    }
  };

  return (
    <LoginContext.Provider value={{ login }}>{children}</LoginContext.Provider>
  );
}
