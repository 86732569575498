export const profile = {
  ADDRESS_PLACEHOLDER: 'Ex: Av. Choferes Del Chaco',
  CITY_PLACEHOLDER: 'Ex: Asunción',
  DISTRICT_PLACEHOLDER: 'Ex: San Roque',
  DOCUMENT_PLACEHOLDER: 'X.XXX.XXX',
  PHONE_PLACEHOLDER: 'XXXX XXXX',
  'Additional information': 'Complemento',
  'Alternative document': 'R.U.C',
  'Block, apartment, etc': 'Bloque, departamento, etc.',
  City: 'Ciudad',
  Close: 'Cerrar',
  District: 'Distrito',
  Document: 'C.I',
  Email: 'Correo electrónico',
  'First name': 'Nombre',
  'Full name': 'Nombre completo',
  'My data': 'Mis datos',
  'Last name': 'Apellido',
  None: 'Ninguno',
  Phone: 'Teléfono',
  'Please, inform a postal code': 'Por favor, lbere un código postal',
  'Please, inform a valid city': 'Por favor, libere una ciudad válida',
  'Please, inform a valid district': 'Por favor, libere un distrito válido',
  'Please, inform a valid document': 'Por favor, libere un CI válido',
  'Please, inform a valid e-mail': 'Por favor, libere un email válido',
  'Please, inform a valid number': 'Por favor, libere un numero válido',
  'Please, inform a valid name': 'Por favor, libere un nombre válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Postal code': 'Código postal',
  Profile: 'Perfil',
  'Public place': 'Lugar público',
  Save: 'Guardar',
  Select: 'Seleccionar',
  'This field is required': 'Este campo es obligatorio',
  'Update data': 'Actualizar datos',
  'Update my data': 'Actualizar mis datos',
  'View and update your profile data':
    'Visualice y actualice los datos de su registro',
};
