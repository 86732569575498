import {
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Combobox } from '@headlessui/react';
import { useField } from 'formik';
import { debounce } from 'lodash';

import { TUseSearch, useSearch } from './hooks';
import * as S from './styles';

interface ISearchBoxRef {
  setInnerText(text: string): void;
}

interface ISearchBoxProps {
  name: string;
  label?: string;
  placeholder?: string;
  fallbackText?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: string) => void;
  className?: string;
  search: TUseSearch;
}

type TOption = { value: string; label: string };

const DEBOUNCE_TIMEOUT = 350;

function SearchBox(
  {
    name,
    label,
    placeholder,
    fallbackText,
    onFocus,
    onChange,
    className,
    search,
  }: PropsWithChildren<ISearchBoxProps>,
  ref: ForwardedRef<ISearchBoxRef>,
) {
  const [, meta, helpers] = useField<string | number>(name);
  const searchController = useSearch(search);
  const inputRef = useRef<HTMLInputElement>(null);

  const hasError = Boolean(meta.error && meta.touched);

  const [isOpen, setIsOpen] = useState(false);

  const isHoveringOptions = useRef(false);

  useEffect(() => {
    if (!fallbackText || !meta.value) return;

    helpers.setValue(meta.value);
    onChange?.(meta.value.toString());
    if (inputRef.current) inputRef.current.value = fallbackText;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fallbackText]);

  useImperativeHandle(
    ref,
    () => ({
      setInnerText: text => {
        if (inputRef.current) inputRef.current.value = text;
      },
    }),
    [],
  );

  return (
    <S.Container
      className={className}
      onBlur={() => {
        if (!isHoveringOptions.current) setIsOpen(false);
      }}
    >
      <label htmlFor={name}>{label}</label>

      <Combobox
        as="div"
        onChange={(option: TOption) => {
          helpers.setValue(option.value);
          onChange?.(option.value);
          if (inputRef.current) inputRef.current.value = option.label;
          setIsOpen(false);
          isHoveringOptions.current = false;
        }}
      >
        <S.Input
          ref={inputRef}
          as="input"
          $hasError={hasError}
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={debounce(event => {
            const value = event.target.value;

            if (!value) {
              helpers.setValue(value);
              onChange?.(value);
            }

            searchController.setSearch(value);
            setIsOpen(true);
          }, DEBOUNCE_TIMEOUT)}
          onBlur={() => {
            helpers.setTouched(true);
          }}
        />

        {isOpen && (
          <S.Content
            onMouseEnter={() => {
              isHoveringOptions.current = true;
            }}
            onMouseLeave={() => {
              isHoveringOptions.current = false;
            }}
            numberOfItems={searchController.data.length}
          >
            <Combobox.Options static>
              {searchController.data.map(option => (
                <Combobox.Option key={option.label} value={option}>
                  {option.label}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </S.Content>
        )}
      </Combobox>

      {hasError && <span>{meta.error}</span>}
    </S.Container>
  );
}

export default forwardRef(SearchBox);
