import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { ICustomer } from 'shared/domain/entities';
import FetchCustomerByEmailUseCase from 'shared/domain/useCases/implementations/FetchCustomerByEmailUseCase';

const CACHE: Record<string, { lastValidity: boolean; lastReason?: string }> =
  {};

let pendingValue: string | null = null;

type TStrategyParams = {
  value: string;
  customer: ICustomer | null;
  t: TFunction<'shared'>;
};

const VALIDATION_STRATEGIES = {
  unique: ({ customer, t, value }: TStrategyParams) => {
    const key = 'unique:' + value;

    if (!customer) {
      CACHE[key] = {
        lastValidity: true,
      };

      return { valid: true, finished: true };
    }

    const message = t('This email is already registered');

    CACHE[key] = {
      lastValidity: false,
      lastReason: message,
    };

    return { valid: false, message, finished: true };
  },
  bonuses: ({ customer, value }: TStrategyParams) => {
    const key = 'bonuses:' + value;

    const valid = !customer?.totalBonuses;

    CACHE[key] = {
      lastValidity: valid,
    };

    return { valid, finished: true };
  },
};

type TParams = {
  value: string | undefined;
  t: TFunction<'shared'>;
  useCase: FetchCustomerByEmailUseCase;
  strategy: keyof typeof VALIDATION_STRATEGIES;
};

export default async function validateEmail({
  value,
  t,
  useCase,
  strategy,
}: TParams): Promise<{ valid: boolean; message?: string; finished: boolean }> {
  if (!value) return { valid: false, finished: true };

  try {
    await Yup.string().email(t('Please, inform a valid email')).validate(value);

    const key = strategy + ':' + value;

    const cache = CACHE[key];

    if (cache) {
      if (cache.lastReason) {
        return { message: cache.lastReason, valid: false, finished: true };
      }

      return { valid: cache.lastValidity, finished: true };
    }

    if (pendingValue === value) return { valid: true, finished: false };

    pendingValue = value;

    const customer = await useCase.execute({
      email: value,
    });

    const chosenStrategy = VALIDATION_STRATEGIES[strategy];

    return chosenStrategy({
      t,
      value,
      customer,
    });
  } catch (error) {
    if (error instanceof Error) {
      return { message: error.message, valid: false, finished: true };
    }

    return { valid: false, finished: true };
  }
}
