import IHashProvider from 'shared/domain/providers/IHashProvider';
import { makeParsingProvider } from 'shared/infra/providers';

import Base64HashProvider from './implementations/Base64HashProvider';

let instance: IHashProvider | null = null;

type TOptions = {
  parsingType?: Parameters<typeof makeParsingProvider>[0]['type'];
};

export default function makeHashProvider({
  parsingType = 'JSON',
}: TOptions = {}): IHashProvider {
  if (!instance) {
    const parsingProvider = makeParsingProvider({ type: parsingType });

    instance = new Base64HashProvider(parsingProvider);
  }

  return instance;
}
