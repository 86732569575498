import { FC } from 'react';

import { useField } from 'formik';

import * as S from './styles';

interface ITextAreaProps {
  name: string;
  label?: string;
  placeholder?: string;
  shouldResize?: boolean;
  height?: number;
  className?: string;
  valueFormatter?: (value: string) => string;
}

const TextArea: FC<ITextAreaProps> = ({
  name,
  label,
  placeholder,
  shouldResize = false,
  height,
  className,
  valueFormatter = value => value,
}) => {
  const [field, meta, helpers] = useField<string>(name);
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <S.Container className={className}>
      <label htmlFor={name}>{label}</label>

      <S.TextArea
        {...field}
        id={name}
        name={name}
        placeholder={placeholder}
        hasError={hasError}
        shouldResize={shouldResize}
        height={height}
        onChange={event => helpers.setValue(valueFormatter(event.target.value))}
      />

      {hasError && <span>{meta.error}</span>}
    </S.Container>
  );
};

export default TextArea;
