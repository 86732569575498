import { IDistrict } from 'shared/domain/entities';

export interface IVectisDistrict {
  id: number;
  nome: string;
}

export class District implements IDistrict {
  public id: IDistrict['id'];
  public name: IDistrict['name'];

  constructor({ id, nome }: IVectisDistrict) {
    this.id = id;
    this.name = nome;
  }
}
