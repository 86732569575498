import { makeNpsRepository } from 'shared/infra/repositories';

import SendNpsUseCase from './implementations/SendNpsUseCase';

let instance: SendNpsUseCase | null = null;

export default function makeSendNpsUseCase() {
  if (!instance) {
    const repository = makeNpsRepository();

    instance = new SendNpsUseCase(repository);
  }

  return instance;
}
