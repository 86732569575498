import { IBenefit } from 'shared/domain/entities/campaign';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IUserBenefitsRepository, {
  TFindByIdParams,
} from 'shared/domain/repositories/IUserBenefitsRepository';
import { Benefit, IVectisBenefit } from 'shared/infra/adapters/Benefit';

export default class VectisUserBenefitsRepository
  implements IUserBenefitsRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public async findById(params: TFindByIdParams): Promise<IBenefit> {
    const benefit = await this.httpProvider.get<IVectisBenefit>(
      `/indicacao/${params.userId}/promocaoApto`,
      {
        params: {
          origemLead: params.origin,
          identificador: params.key,
        },
      },
    );

    return new Benefit(benefit);
  }
}
