import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  summary: {
    '& .MuiIconButton-root': {
      borderColor: '#ccc',
      border: '2px solid #c1c1c1c1',
      width: '36px',
      height: '36px',
    },
  },
  summary2: {
    '& .MuiIconButton-root': {
      width: '36px',
      height: '36px',
      background: theme.palette.primary.main,
    },
  },
  panelQuestion: {
    background: 'transparent',
    borderTop: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&::before': {
      content: ' ',
      display: 'block',
      height: -90,
    },
  },
  heading: {
    fontWeight: 500,
    fontSize: '1rem',
    height: '30px',
    margin: '5px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
    },
  },
  details: {
    background: 'transparent',
    color: theme.palette.text.primary,
    borderRadius: '0 0 25px 25px',
  },
}));

export default useStyles;
