import { Content } from '@radix-ui/react-hover-card';
import styled, { css } from 'styled-components';

import { CSSANIMATIONS } from 'shared/presentation/constants';
import { Link } from 'shared/presentation/contexts';

export const Trigger = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 0.75rem;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s;

    padding: ${theme.layout.spacing(1)};
    margin: ${theme.layout.spacing(-1, 0)};
    gap: ${theme.layout.spacing(1)};
    font-family: ${theme.typography.family};
    color: ${theme.palette.text.main};

    > span {
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.light};
    }

    svg {
      font-size: ${theme.typography.sizes.body1};
    }

    &:hover,
    &[data-state='open'] {
      background-color: ${theme.palette.neutral[100]};
      box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35);
    }
  `}
`;

export const MenuContent = styled(Content)`
  ${({ theme }) => css`
    display: flex;
    width: ${theme.layout.spacing(31)};
    padding: ${theme.layout.spacing(3, 2)};
    flex-direction: column;
    gap: ${theme.layout.spacing(3)};

    border-radius: 1rem;
    background-color: ${theme.palette.neutral[100]};
    box-shadow: 0px 10px 20px -15px rgba(22, 23, 24, 0.2),
      0px 10px 38px -10px rgba(22, 23, 24, 0.35);
  `}
`;

export const RecommendWinLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.layout.spacing(2, 1.5)};
    align-items: center;
    gap: ${theme.layout.spacing(1)};
    align-self: stretch;

    border-radius: 1rem;
    background: ${theme.palette.secondary.main};
    transition: all 0.2s;

    color: ${theme.palette.text.white};

    animation: ${CSSANIMATIONS.PULSE} 1.5s infinite;

    svg {
      font-size: 1.5rem;
    }

    span {
      font-size: ${theme.typography.sizes.title3};
      font-weight: ${theme.typography.weight.bold};
      flex: 1;
      text-align: center;
    }

    &:hover {
      color: ${theme.palette.text.white};
      background: ${theme.palette.secondary.light};
    }
  `}
`;
