import * as Checkbox from '@radix-ui/react-checkbox';

import { ICONS } from 'shared/presentation/constants';

import * as S from './styles';

const Icon = ICONS.CHECK;

interface IControlledCheckInputProps {
  id: string;
  defaultValue?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const ControlledCheckInput: React.FC<IControlledCheckInputProps> = ({
  id,
  defaultValue,
  onChange,
  value,
  disabled,
  children,
  className,
}) => (
  <S.Container className={className}>
    <Checkbox.Root
      defaultChecked={defaultValue}
      checked={value}
      onCheckedChange={onChange}
      disabled={disabled}
      id={id}
    >
      <Checkbox.Indicator>
        <Icon />
      </Checkbox.Indicator>
    </Checkbox.Root>

    <label htmlFor={id}>{children}</label>
  </S.Container>
);

export default ControlledCheckInput;
