import { createContext, useContext } from 'react';

import { ITheme } from '../models/ITheme';

interface IThemeContextData {
  theme: ITheme;
}

export const ThemeContext = createContext<IThemeContextData>(
  {} as IThemeContextData,
);

export const useTheme = () => useContext(ThemeContext);
