import axios from 'axios';

import { makeHTTPProvider } from 'shared/infra/providers';

export default function putUserInStorage(user, token, refreshToken) {
  try {
    localStorage.setItem('id', user.id);
  } catch {
    sessionStorage.setItem('id', user.id);
  }
  localStorage.setItem('email', user.email);
  localStorage.setItem('nome', user.nome);
  localStorage.setItem('sexo', user.sexo);
  localStorage.setItem('sobrenome', user.sobrenome);
  localStorage.setItem('fototipoEcommerce', user.fototipoEcommerce);
  localStorage.setItem('nome', user.nome);
  localStorage.setItem('@vialaser:token', token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  makeHTTPProvider().setHeader('AUTHENTICATION', `Bearer ${token}`);
  if (refreshToken) {
    localStorage.setItem('@vialaser:refreshToken', refreshToken);
  }
}
