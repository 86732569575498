import React from "react";
import ColoredDottedH2 from "../ColoredDottedH2";
import TransparentBigText from "../TransparentBigText";
import testNicole from "../../assets/img/testimonial_01.png";
import testVanessa from "../../assets/img/testimonial_02.png";
import testRaphaela from "../../assets/img/testimonial_03.png";

import { Container } from "./styles";

const TESTIMONIALS = [
  {
    name: "Nicole Latoya",
    text: "¡Increíble resultado evidente en la primera sesión! ¡Profesionales atentos y educados!",
    image: testNicole,
  },
  {
    name: "Vanessa Silva",
    text: "Gran servicio ¡Me impresionó que en una sesión noté mucha diferencia! Recomendado! ",
    image: testVanessa,
  },
  {
    name: "Raphaela Zyla",
    text: "¡Gran servicio y resultados increíbles desde la primera sesión! ¡Estoy enamorado!",
    image: testRaphaela,
  },
];

function Testimonials() {
  return (
    <Container>
      <ColoredDottedH2>TESTIMONIOS</ColoredDottedH2>

      <ul>
        {TESTIMONIALS.map(testimonial => (
          <li key={testimonial.name}>
            <img src={testimonial.image} alt={testimonial.name} />

            <strong>{testimonial.name}</strong>

            <p>{testimonial.text}</p>
          </li>
        ))}
      </ul>

      <TransparentBigText size="14rem" distance="-3.75rem">
        TESTIMONIOS
      </TransparentBigText>
    </Container>
  );
}

export default Testimonials;
