import { Link } from 'shared/presentation/contexts';

import { Container } from './styles';

function Footer({ children }) {
  return (
    <Container>
      {children}

      <Link to="/termos-de-uso">Accede nuestros terminos de uso</Link>

      <Link to="/politicas-de-privacidade">
        Accede nuestras políticas de privacidad
      </Link>
    </Container>
  );
}

export default Footer;
