import { useCallback, useMemo, useState, useEffect } from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

import WhereToFindSelector from './WhereToFindSelector';
import CircularLoading from '../CircularLoading';
import CustomHr from '../CustomHr';
import MapComponent from '../MapComponent';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.secondary,
  },
  wrap: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns:
        'minmax(100px, auto) 640px 640px minmax(100px, auto)',
      gridTemplateAreas: `". form map map"`,
      [theme.breakpoints.down('1367')]: {
        gridTemplateColumns:
          'minmax(50px, auto) 600px 600px minmax(50px, auto)',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateAreas: `". form map"`,
        gridTemplateColumns: '2% 48% 50%',
      },
    },
  },
  form: {
    gridArea: 'form',
    paddingTop: '5rem',
    paddingBottom: props => (props.units > 2 ? '2rem' : '5rem'),
    marginRight: '3rem',
    [theme.breakpoints.down('lg')]: {
      marginRight: '3rem',
      '& h2': {
        textAlign: 'left',
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      margin: '0 auto',
      '& h2': {
        textAlign: 'center',
      },
    },
  },
  mapWrapper: {
    gridArea: 'map',
    overflow: 'hidden',
  },
}));

export default function WhereToFind() {
  const { t } = useTranslation();

  const [markers, setMarkers] = useState([]);

  const classes = useStyles({
    units: markers.length,
  });

  const [initialUnit, setInitialUnit] = useState(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);

  const initialUnitMap = unit => {
    setInitialUnit(unit);
  };

  const panTo = latLng => map.panTo(latLng);

  const handleUnitSelected = unit => {
    const { latitude, longitude } = unit;

    if (map && latitude && longitude) {
      panTo({
        lat: parseFloat(unit.latitude),
        lng: parseFloat(unit.longitude),
      });
    }
  };

  const handleUnitsLoaded = units => {
    setMarkers(
      units
        .filter(unit => unit.latitude && unit.longitude)
        .map(unit => (
          <Marker
            key={unit.id}
            position={{
              lat: parseFloat(unit.latitude),
              lng: parseFloat(unit.longitude),
            }}
            title={unit.nomeFantasia}
          />
        )),
    );

    setLoading(false);
  };

  const onMapLoaded = useCallback(mapInstance => {
    setMap(mapInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapComponent = useMemo(() => {
    return loading ? (
      <CircularLoading />
    ) : (
      <MapComponent onLoaded={onMapLoaded}>{markers}</MapComponent>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers, loading]);

  useEffect(() => {
    if (map && initialUnit) {
      handleUnitSelected(initialUnit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <Element name="where-to-find" id="clinics" className={classes.root}>
      <Grid className={classes.wrap}>
        <Grid item xs={12} md={12} lg={12} xl={12} className={classes.form}>
          <Grid item xs={12} md={12}>
            <Typography variant="h1" component="h2">
              {t(
                'Encontre uma clínica de depilação a Laser da Vialaser mais próxima de você!',
              )}
            </Typography>

            <CustomHr />
          </Grid>

          <WhereToFindSelector
            onUnitsLoaded={handleUnitsLoaded}
            onUnitSelected={handleUnitSelected}
            initialUnitMap={initialUnitMap}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.mapWrapper}
        >
          {mapComponent}
        </Grid>
      </Grid>
    </Element>
  );
}
