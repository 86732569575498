import { Combobox } from '@headlessui/react';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IContainer {
  disabled?: boolean;
  hasError?: boolean;
  value?: string;
  textTransform?: 'uppercase' | 'lowercase';
}

export const Container = styled.div<IContainer>`
  ${({ theme, disabled }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing(1)};

    label {
      cursor: pointer;
      font-size: ${theme.typography.sizes.body1};
      width: fit-content;
    }

    > div {
      position: relative;

      ${disabled &&
      css`
        opacity: 0.7;
        pointer-events: none;
      `}
    }

    span {
      font-size: ${theme.typography.sizes.caption};
      color: ${theme.palette.error.main};
    }
  `}
`;

interface IInputProps {
  $hasError: boolean;
}

export const Input = styled(Combobox.Input)<IInputProps>`
  ${({ theme, $hasError }) => css`
    font-size: ${theme.typography.sizes.body1};
    padding: 1rem 1.5rem;
    background: ${theme.palette.neutral[100]};
    border: none;
    width: 100%;
    border-radius: 0.75rem;
    color: ${theme.palette.text.main};
    outline: none;
    transition: all 0.3s linear;
    border: 1px solid ${theme.palette.neutral[100]};

    ${$hasError
      ? css`
          border: 1px solid ${theme.palette.error.main};
          svg {
            color: ${theme.palette.error.main};
          }
        `
      : css`
          :focus {
            border: 1px solid ${theme.palette.primary};
          }
        `}
  `}
`;

const ITEM_HEIGHT = 6;
const MAX_ITEMS = 3;
const CONTENT_OFFSET = 1;

const getBottomDistance = (numberOfItems: number) => {
  if (numberOfItems <= MAX_ITEMS) {
    return numberOfItems * ITEM_HEIGHT + CONTENT_OFFSET;
  }

  return ITEM_HEIGHT * MAX_ITEMS + CONTENT_OFFSET;
};

export const Content = styled.div<{ numberOfItems: number }>`
  ${({ theme, numberOfItems }) => css`
    overflow: auto;
    background-color: ${theme.palette.neutral[100]};
    border-radius: 0.75rem;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
      0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    position: absolute;
    z-index: 500;

    bottom: -${theme.layout.spacing(getBottomDistance(numberOfItems))};
    height: ${theme.layout.spacing(numberOfItems * ITEM_HEIGHT)};
    max-height: ${theme.layout.spacing(MAX_ITEMS * ITEM_HEIGHT)};
    width: 100%;

    ul {
      padding-left: 1.5rem;
    }

    li {
      list-style: none;
      text-transform: uppercase;
      cursor: pointer;
      padding: 1rem 0;

      transition: color 0.2s;

      height: ${theme.layout.spacing(ITEM_HEIGHT)};

      outline: none;

      &[data-state='checked'] {
        color: ${theme.palette.secondary};
      }

      &:hover {
        color: ${theme.palette.primary.main};
      }

      &.placeholder {
        text-transform: none;
        color: ${darken(0.5, theme.palette.text.white)};
      }
    }
  `}
`;
