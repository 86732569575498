import React, { useMemo } from 'react';

import { useLocale } from 'shared/presentation/contexts';

import appStorePng from './assets/appstore.png';
import appStoreWebp from './assets/appstore.webp';
import googlePlayPng from './assets/google-play.png';
import googlePlayWebp from './assets/google-play.webp';
import * as S from './styles';

const AppStoreIcons: React.FC = () => {
  const { t } = useLocale('shared');

  const STORE_LINKS = useMemo(
    () => [
      {
        key: 'playStore',
        url: 'http://bit.ly/2Dr86xQ',
        title: t('App at google play'),
        images: [
          { src: googlePlayWebp, type: 'image/webp' },
          { src: googlePlayPng, type: 'image/png' },
        ],
      },
      {
        key: 'appStore',
        url: 'https://apple.co/2Aqm7rN',
        title: t('App at appstore'),
        images: [
          { src: appStoreWebp, type: 'image/webp' },
          { src: appStorePng, type: 'image/png' },
        ],
      },
    ],
    [t],
  );

  return (
    <S.Container>
      {STORE_LINKS.map(({ key, url, title, images }) => (
        <a key={key} href={url} rel="noopener noreferrer" title={title}>
          <picture>
            {images.map(image => (
              <source key={image.type} type={image.type} srcSet={image.src} />
            ))}
            <img alt={title} loading="lazy" />
          </picture>
        </a>
      ))}
    </S.Container>
  );
};

export default AppStoreIcons;
