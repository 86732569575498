import React, { ReactElement } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import * as S from './styles';

interface ITooltipProps {
  asChild?: boolean;
  content: ReactElement;
}

const DELAY = 200;

const Tooltip: React.FC<ITooltipProps> = ({ asChild, content, children }) => (
  <RadixTooltip.Provider delayDuration={DELAY}>
    <RadixTooltip.Root>
      <S.Trigger asChild={asChild}>{children}</S.Trigger>

      <RadixTooltip.Portal>
        <S.Content>
          <S.Arrow />

          {content}
        </S.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  </RadixTooltip.Provider>
);

export default Tooltip;
