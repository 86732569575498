import { IValidateVerificationCodeDTO } from 'shared/domain/dtos';
import { IVerificationCodeRepository } from 'shared/domain/repositories';

export default class ValidateVerificationCodeUseCase {
  constructor(
    private verificationCodeRepository: IVerificationCodeRepository,
  ) {}

  public async execute(params: IValidateVerificationCodeDTO) {
    return this.verificationCodeRepository.findByCode(params);
  }
}
