import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import styled, { css } from 'styled-components';

import { CSSANIMATIONS } from 'shared/presentation/constants';

export const Item = styled(AccordionItem)`
  ${({ theme }) => css`
    background-color: ${theme.palette.neutral[50]};
    border-radius: 16px;
  `}
`;

export const Trigger = styled(AccordionTrigger)`
  ${({ theme }) => css`
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    cursor: pointer;

    width: 100%;

    padding: ${theme.layout.spacing(3)};

    color: ${theme.palette.text.main};

    > svg {
      font-size: 22px;
      color: ${theme.palette.neutral[500]};
    }

    :disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  `}
`;

export const Content = styled(AccordionContent)`
  padding: ${({ theme }) => theme.layout.spacing(0, 3)};

  overflow: hidden;

  &[data-state='open'] {
    animation: ${CSSANIMATIONS.ACCORDION_SLIDE_DOWN} 0.3s
      cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state='closed'] {
    animation: ${CSSANIMATIONS.ACCORDION_SLIDE_UP} 0.3s
      cubic-bezier(0.87, 0, 0.13, 1);
  }
`;
