import { lightPalette } from './lightPalette';
import { ITheme } from '../models/ITheme';

export const pinkOctoberPalette: ITheme['palette'] = {
  ...lightPalette,
  primary: {
    light: '#F6D6D5',
    main: '#F0ABA8',
    dark: '#F58E89',
  },
};
