import { lazy } from 'react';

import type { TRoute } from 'shared/presentation/modules/definitions';

const PaymentLink = lazy(
  () => import('modules/checkout/presentation/pages/PaymentLink'),
);

const routes: TRoute[] = [
  {
    type: 'route',
    path: '/checkout/link-pagamento/:hash',
    component: PaymentLink,
  },
];

export default routes;
