import { EXTERNAL_LINKS } from 'pages/StaticHomepage/constants/links';

export const LOCATIONS = {
  es: [
    {
      name: 'Multiplaza Shopping',
      address: { top: 'Av Eusebio Ayala, 4501', bottom: 'Villa Aurelia' },
      location: 'Asunción - DC',
      phone: '(595) 2172-89303',
      maps: 'https://www.google.com/maps/search/?api=1&query=-25.31636492614862,-57.57078327417855',
      url: EXTERNAL_LINKS.es.WHATSAPP,
    },
    {
      name: 'Mariscal López',
      address: {
        top: 'Quesada, 5050',
        bottom: 'Villa Morra',
      },
      location: 'Asunción - DC',
      phone: '(595) 2172-89303',
      maps: 'https://www.google.com/maps/search/?api=1&query=-25.29477367934738,-57.58082094534371',
      url: EXTERNAL_LINKS.es.WHATSAPP,
    },
    {
      name: 'Felix Bogado',
      address: {
        top: 'Av. José Felix Bogado',
      },
      location: 'Asunción - DC',
      maps: 'https://www.google.com/maps/place/Av.+Jos%C3%A9+Felix+Bogado,+Asunci%C3%B3n,+Paraguai/@-25.3149562,-57.6336382,17z/data=!4m6!3m5!1s0x945da8103daaa2bf:0xa1414cb79bf25dd3!8m2!3d-25.3145356!4d-57.6305028!16s%2Fg%2F1q5bfddmb',
      url: EXTERNAL_LINKS.es.WHATSAPP,
    },
    {
      name: 'Pinedo Shopping',
      address: {
        top: 'Avda. Mariscal López, 321',
        bottom: 'Villa Amelia',
      },
      location: 'San Lorenzo - CE',
      phone: '(595) 2172-89303',
      maps: 'https://goo.gl/maps/RaLioLNZYumeosu19',
      url: EXTERNAL_LINKS.es.WHATSAPP,
    },
  ],
  'es-CR': [
    {
      name: 'Plaza El Encuentro',
      address: { top: '8HR9+Q63, 141', bottom: 'Barrio San Roque' },
      location: 'Alajuela Province, Quesada',
      maps: 'https://goo.gl/maps/fv7vMCeg6YQXrv767',
      url: '',
    },
    {
      name: 'Oxígeno Human Playground',
      address: {
        top: '111, Heredia',
      },
      location: 'San Francisco de Heredia',
      maps: 'https://www.google.com.br/maps/place/Ox%C3%ADgeno+Human+Playground/@9.9944145,-84.1334295,17z/data=!3m1!4b1!4m5!3m4!1s0x8fa0fae4d6f2f3c9:0xca979eb2e17fba1d!8m2!3d9.9944145!4d-84.1312408?coh=164777&entry=tt',
      url: '',
    },
  ],
};
