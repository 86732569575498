import { FC, useState } from 'react';

import {
  TChannelType,
  TCodeVerificationProccessType,
} from 'shared/domain/entities';
import { useModal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { ICONS } from 'shared/presentation/constants';
import { useLocale, Link, Trans } from 'shared/presentation/contexts';

import { TValidationData } from '../../constants';
import { maskEmail, maskPhoneNumber } from '../../helpers';
import { useSendVerificationCode } from '../../hooks';
import { useDisabledChannels } from '../../store';

interface IChooseChannel {
  message: string;
  entityId: number;
  proccess: TCodeVerificationProccessType;
  channels: TChannelType[];
  channelsEnabledOnce?: TChannelType[];
  showUpdateDataLink?: boolean;
  customer: {
    email?: string;
    phone?: string;
  };
  onNext: (data: TValidationData) => void;
}

const chooseFirstOption = (
  channels: TChannelType[],
  disabledChannels: TChannelType[],
) => {
  const firstOption = channels[0];
  const isDisabledChannel = disabledChannels.includes(firstOption);

  if (!isDisabledChannel) return firstOption;
  return channels.find(channel => !disabledChannels.includes(channel))!;
};

const ChooseChannel: FC<IChooseChannel> = ({
  message,
  entityId,
  proccess,
  customer,
  channels,
  channelsEnabledOnce = [],
  showUpdateDataLink,
  onNext,
}) => {
  const { t, language } = useLocale('shared');

  const [disabledChannels, setDisabledChannels] = useDisabledChannels();
  const [selectedChannel, setSelectedChannel] = useState<TChannelType>(
    chooseFirstOption(channels, disabledChannels),
  );

  const modal = useModal();

  const controller = useSendVerificationCode({
    onSuccess: code => {
      if (channelsEnabledOnce.includes(selectedChannel)) {
        setDisabledChannels(currentValue => [...currentValue, selectedChannel]);
      }

      onNext({ code, channel: selectedChannel });
    },
  });

  const channelData: Record<
    TChannelType,
    { name: string; channelSent: string }
  > = {
    EMAIL: {
      name: t('E-mail'),
      channelSent: `(${maskEmail(customer.email)})`,
    },
    WHATSAPP: {
      name: 'WhatsApp',
      channelSent: maskPhoneNumber(customer.phone, language),
    },
    SMS: {
      name: 'SMS',
      channelSent: maskPhoneNumber(customer.phone, language),
    },
  };

  return (
    <>
      <div className="modal__header">
        <h2 className="modal__title">{t('Almost there!')}</h2>
        <p className="modal__message">{message}</p>
      </div>

      <div className="modal__content">
        <div className="modal__options">
          {channels.map(channel => (
            <button
              key={channel}
              className="channel"
              disabled={
                channelsEnabledOnce.includes(channel) &&
                disabledChannels.includes(channel)
              }
              onClick={() => setSelectedChannel(channel)}
            >
              <div
                className={`channel__circle ${
                  selectedChannel === channel ? `channel__circle-selected` : ''
                }`}
              />

              <span className="channel__name">
                {channelData[channel].name}{' '}
                <small>{channelData[channel].channelSent}</small>
              </span>
            </button>
          ))}
        </div>
      </div>

      {showUpdateDataLink && (
        <p className="modal__smallmessage">
          <Trans
            t={t}
            localeKey="To update your contact data <1>click here</1>"
          >
            {'To update your contact data '}
            <Link to="/area-cliente/perfil">click here</Link>
          </Trans>
        </p>
      )}

      <div className="modal__footer">
        <Button
          variant="primary"
          icon={ICONS.ARROW_RIGHT}
          iconPosition="right"
          loading={controller.loading}
          onClick={() => {
            controller.send({ channel: selectedChannel, entityId, proccess });
          }}
        >
          {t('Next')}
        </Button>

        <Button
          icon={ICONS.X}
          iconPosition="right"
          disabled={controller.loading}
          onClick={modal.close}
        >
          {t('Close')}
        </Button>
      </div>
    </>
  );
};

export default ChooseChannel;
