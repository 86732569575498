import React from 'react';

import {
  DesktopNavbar,
  MobileNavbar,
} from 'shared/presentation/components/organisms';
import { useLocale } from 'shared/presentation/contexts';
import { useMediaQuery } from 'shared/presentation/hooks';

const AppNavbar: React.FC = () => {
  const isMobile = useMediaQuery('md');
  const { language } = useLocale();

  const shouldShowNavbar = language === 'pt';

  if (!shouldShowNavbar) return null;

  if (isMobile) return <MobileNavbar />;

  return <DesktopNavbar />;
};

export default AppNavbar;
