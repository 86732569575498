import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    label {
      font-size: ${theme.typography.sizes.caption};
      font-weight: ${theme.typography.weight.regular};
    }

    span {
      font-size: ${theme.typography.sizes.caption};
      color: ${theme.palette.error.main};
      font-weight: ${theme.typography.weight.regular};
    }
  `}
`;

interface ITextArea {
  hasError: boolean;
  height?: number;
  shouldResize?: boolean;
}

export const TextArea = styled.textarea<ITextArea>`
  width: 100%;
  height: ${props => (props.height ? props.height + 'px' : 'auto')};
  outline: none;
  padding: 1.25rem;
  border-radius: 1rem;
  border: 1px solid ${props => props.theme.palette.neutral[100]};
  font-size: ${props => props.theme.typography.sizes.body2};
  background-color: ${props => props.theme.palette.neutral[100]};
  color: ${props => props.theme.palette.text.main};
  transition: all 0.3s linear;

  ${props =>
    !props.shouldResize &&
    css`
      resize: none;
    `};

  ${props =>
    props.hasError
      ? css`
          border: 1px solid ${props.theme.palette.error.main};
          background-color: ${transparentize(
            0.9,
            props.theme.palette.error.main,
          )};
        `
      : css`
          :focus {
            border: 1px solid ${props.theme.palette.primary};
          }
        `}
`;
