import React from 'react';

import { ErrorPageFallback } from 'shared/presentation/components/organisms';
import { ICONS } from 'shared/presentation/constants';
import { useLocale, useNavigation } from 'shared/presentation/contexts';

import ErrorBoundary from '../ErrorBoundary';

const ErrorProvider: React.FC = ({ children }) => {
  const navigation = useNavigation();
  const { t } = useLocale('shared');

  return (
    <ErrorBoundary
      fallback={
        <ErrorPageFallback
          title={t('Ops something went wrong!')}
          message={t(
            'Something did not worked as expected Try again realoding the page or go to the home',
          )}
          actions={[
            {
              text: t('Refresh page'),
              icon: ICONS.REFRESH,
              onClick: () => navigation.refresh(),
            },
            {
              text: t('Go to home'),
              icon: ICONS.HOME,
              onClick: () => (window.location.href = '/'),
            },
          ]}
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorProvider;
