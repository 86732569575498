import { useCallback, useEffect, useState } from 'react';

import { atom, useRecoilState } from 'recoil';

const REQUEST_TIMEOUT = 8000;

const coordsAtom = atom<GeolocationCoordinates | null>({
  key: 'geolocationCoords',
  default: null,
});

export default function useGeolocationCoords() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [coords, setCoords] = useRecoilState(coordsAtom);

  const request = useCallback(async () => {
    if (!navigator.geolocation) {
      return Promise.resolve(null);
    }

    setIsRequesting(true);

    return new Promise<GeolocationCoordinates | null>(resolve =>
      navigator.geolocation.getCurrentPosition(
        position => {
          setCoords(position.coords);

          setIsRequesting(false);
          resolve(position.coords);
        },
        () => {
          setIsRequesting(false);
          resolve(null);
        },
        {
          timeout: REQUEST_TIMEOUT,
        },
      ),
    );
  }, [setCoords]);

  useEffect(() => {
    try {
      if (!navigator?.permissions?.query) return;

      navigator.permissions.query({ name: 'geolocation' }).then(status => {
        if (status.state === 'denied') return;
        request();
      });
    } catch (error) {
      console.log('Navegador sem suporte a geolocalização!');
    }
  }, [request]);

  return { isRequesting, coords, request };
}
