import { useState } from 'react';

import { useQuery } from 'react-query';

import { IItem } from 'shared/domain/entities';
import { makeFetchItemsUseCase } from 'shared/domain/useCases';
import { sharedQueries } from 'shared/presentation/constants';
import { useRequestErrorHandler } from 'shared/presentation/hooks';

type TFilterItems = { items?: IItem[]; search: string };
function filterItems({ items = [], search }: TFilterItems) {
  if (!search) return [];

  return items.filter(item =>
    item.title.toLowerCase().includes(search.toLowerCase()),
  );
}

export default function useSearch() {
  const [search, setSearch] = useState('');

  const handleError = useRequestErrorHandler('shared');

  const packagesQuery = useQuery(
    sharedQueries.items('PACKAGE'),
    () => makeFetchItemsUseCase().execute({ type: 'PACKAGE' }),
    {
      onError: error => handleError(error),
    },
  );

  const combosQuery = useQuery(
    sharedQueries.items('COMBO'),
    () => makeFetchItemsUseCase().execute({ type: 'COMBO' }),
    {
      onError: error => handleError(error),
    },
  );

  return {
    packages: filterItems({ items: packagesQuery.data, search }),
    combos: filterItems({ items: combosQuery.data, search }),
    search,
    setSearch,
  };
}
