import { makeDistrictsRepository } from 'shared/infra/repositories';

import FetchDistrictsUseCase from './implementations/FetchDistrictsUseCase';

let instance: FetchDistrictsUseCase | null = null;

export default function makeFetchDistrictsUseCase() {
  if (!instance) {
    const districtsRepository = makeDistrictsRepository();

    instance = new FetchDistrictsUseCase(districtsRepository);
  }

  return instance;
}
