import { TLanguage } from 'config/tenancy';

const getDigits = (phone?: string) => {
  if (!phone) return '0000';
  return phone.slice(phone.length - 4, phone.length);
};

const MASK_PHONE_DICTIONARY: Record<TLanguage, (phone?: string) => string> = {
  pt: phone => '(**) *****-' + getDigits(phone),
  es: phone => '**** *' + getDigits(phone),
  'es-CR': phone => '**** ' + getDigits(phone),
};

const maskPhoneNumber = (phone: string | undefined, language: TLanguage) => {
  return MASK_PHONE_DICTIONARY[language](phone);
};

export default maskPhoneNumber;
