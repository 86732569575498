import { TLanguage } from 'config/tenancy';

const LANGUAGES_DICTIONARY: Record<TLanguage, string[]> = {
  pt: ['pt', 'pt-BR'],
  es: ['es', 'es-PY'],
  'es-CR': ['es-CR'],
};

export default function getValidLanguage(language: string): TLanguage {
  for (const languageKey of Object.keys(LANGUAGES_DICTIONARY)) {
    const equivalentLanguages = LANGUAGES_DICTIONARY[languageKey];

    if (equivalentLanguages.includes(language)) return languageKey;
  }

  return 'pt';
}
