import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    gap: ${theme.layout.spacing(2)};

    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.light};
      color: ${theme.palette.text.main};

      transition: all 0.2s;

      &:hover {
        color: ${theme.palette.text.light};
      }
    }
  `}
`;
