import { FiAlertCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Button } from 'shared/presentation/components/molecules';

import * as S from './styles';

const MissingPaymentMethod: React.FC<{ handleClose(): void; id: number }> = ({
  handleClose,
}) => (
  <S.Container>
    <FiAlertCircle />

    <span>
      Parece que seu cadastro não tem um cartão ativo, que tal adicionar um
      cartão novo e ficar tranquilo?
    </span>

    <span>
      Para isso, vá até seu contrato mais recente e clique em{' '}
      <strong>Adicionar cartão de cobrança</strong>
    </span>

    <footer>
      <Link to="/area-cliente/financeiro">
        <Button variant="primary" onClick={handleClose}>
          Adicionar cartão de crédito
        </Button>
      </Link>

      <Button variant="primary" outline onClick={handleClose}>
        Fazer isso mais tarde
      </Button>
    </footer>
  </S.Container>
);

export default MissingPaymentMethod;
