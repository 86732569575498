import { ICity } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import ICitiesRepository, {
  TFindAllByParams,
} from 'shared/domain/repositories/ICitiesRepository';
import { City, IVectisCity } from 'shared/infra/adapters';

const MAXIMUM_RETURNS_PER_SEARCH = 10;

export default class VectisCitiesRepository implements ICitiesRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public async findAll({ name }: TFindAllByParams = {}): Promise<ICity[]> {
    const params: { cidade?: string; limit: number } = {
      limit: MAXIMUM_RETURNS_PER_SEARCH,
    };
    if (name) params.cidade = name;

    const { cidades } = await this.httpProvider.get<{ cidades: IVectisCity[] }>(
      '/cidades',
      {
        params,
      },
    );

    return cidades.map(city => new City(city));
  }
}
