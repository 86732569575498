import {
  ComponentRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  TChannelType,
  TCodeVerificationProccessType,
} from 'shared/domain/entities';
import { Modal } from 'shared/presentation/components/atoms';

import { ChooseChannel, NoChannels, ValidateCode } from './components';
import { TValidationData } from './constants';
import { filterAvailableChannels } from './helpers';
import * as S from './styles';

type TWizardStep = 'CHOOSE_CHANNEL' | 'VALIDATE_CODE';

interface ICodeValidationModalRef {
  close(): void;
  open(): void;
}

interface ICodeValidationModal {
  message: string;
  entityId: number;
  proccess: TCodeVerificationProccessType;
  customer: {
    email?: string;
    phone?: string;
    validWhatsapp?: boolean;
  };
  /**
   * @param availableChannels
   * The channels the modal is enabled to send the validation code to. Defaults to all existing channels
   */
  availableChannels?: TChannelType[];
  channelsEnabledOnce?: TChannelType[];
  onSuccess: (data: { id: number }) => void;
  closeOnOverlayClick?: boolean;
  showUpdateDataLink?: boolean;
}

const CodeValidationModal: ForwardRefRenderFunction<
  ICodeValidationModalRef,
  ICodeValidationModal
> = (
  {
    message,
    entityId,
    proccess,
    customer,
    onSuccess,
    closeOnOverlayClick,
    showUpdateDataLink = false,
    availableChannels,
    channelsEnabledOnce = [],
  },
  ref,
) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const channels = useMemo(
    () =>
      filterAvailableChannels({
        customer,
        availableChannels,
      }),
    [availableChannels, customer],
  );

  const [wizardStep, setWizardStep] = useState<TWizardStep>('CHOOSE_CHANNEL');
  const [validationData, setValidationData] = useState<TValidationData | null>(
    null,
  );

  useImperativeHandle(
    ref,
    () => ({
      close: () => modalRef.current?.close(),
      open: () => modalRef.current?.open(),
    }),
    [],
  );

  if (!channels.length) {
    return (
      <Modal ref={modalRef} closeOnOverlayClick={closeOnOverlayClick}>
        <S.Container>
          <NoChannels />
        </S.Container>
      </Modal>
    );
  }

  const content = {
    CHOOSE_CHANNEL: (
      <ChooseChannel
        message={message}
        entityId={entityId}
        proccess={proccess}
        customer={customer}
        channels={channels}
        channelsEnabledOnce={channelsEnabledOnce}
        showUpdateDataLink={showUpdateDataLink}
        onNext={data => {
          setValidationData(data);
          setWizardStep('VALIDATE_CODE');
        }}
      />
    ),
    VALIDATE_CODE: validationData && (
      <ValidateCode
        entityId={entityId}
        proccess={proccess}
        validationData={validationData}
        customer={customer}
        onReturn={() => setWizardStep('CHOOSE_CHANNEL')}
        onNext={data => {
          onSuccess(data);
          setWizardStep('CHOOSE_CHANNEL');
          modalRef.current?.close();
        }}
      />
    ),
  };

  return (
    <Modal ref={modalRef} closeOnOverlayClick={closeOnOverlayClick}>
      <S.Container>{content[wizardStep]}</S.Container>
    </Modal>
  );
};

export default forwardRef(CodeValidationModal);
