import { ComponentPropsWithoutRef, FC } from 'react';

import { Trans as I18nTrans } from 'react-i18next';

type TTransProps = ComponentPropsWithoutRef<typeof I18nTrans>;

interface ITransProps {
  t: TTransProps['t'];
  localeKey: TTransProps['i18nKey'];
  count?: number;
}

const Trans: FC<ITransProps> = ({ localeKey, ...props }) => (
  <I18nTrans i18nKey={localeKey} {...props} />
);

export default Trans;
