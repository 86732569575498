export const contractCheckout = {
  'Card number': 'Numero de la tarjeta',
  'Name printed on card': 'Nombre impreso en la tarjeta',
  'Choose your payment method, {{name}}': 'Elige su método de pago, {{name}}',
  Credit: 'Crédito',
  CVC: 'Código de Seguridad',
  'Expiry date': 'Fecha de vencimiento',
  Installments: 'Cuotas',
  main: 'principal',
  'Make payment': 'Hacer el pago',
  'New card': 'Nueva tarjeta',
  'New credit card': 'Nueva tarjeta de crédito',
  'Pay with {{flag}}': 'Pagar con {{flag}}',
  'Pending issues successfully paid!': 'Asuntos pendientes pagados con éxito!',
  'Select the credit card': 'Selecciona la tarjeta de crédito',
};
