import { useMutation } from 'react-query';

import { makeRegisterErrorUseCase } from 'shared/domain/useCases';

type TParams = {
  key: string;
  error: unknown;
};

export default function useRegisterError() {
  const mutation = useMutation(async ({ key, error }: TParams) => {
    const commonErrorData = {
      origin: window.location.href,
      browser: navigator.userAgent,
    };

    if (error instanceof Error) {
      return makeRegisterErrorUseCase().execute({
        key,
        data: {
          ...commonErrorData,
          error: error.message,
        },
        stackTrace: error.stack,
      });
    }

    return makeRegisterErrorUseCase().execute({
      key,
      data: {
        ...commonErrorData,
        error: String(error),
      },
    });
  });

  return {
    register: mutation.mutateAsync,
  };
}
