import { TPaymentMethodType } from 'shared/domain/entities';

export type TVectisPaymentMethodType =
  | 'CARTAO_RECORRENTE'
  | 'CARTAO_ONLINE'
  | 'PIX'
  | 'NUPAY'
  | 'DEBITO_AUTOMATICO';

export const PAYMENT_METHOD_TO_DOMAIN: Record<
  TVectisPaymentMethodType,
  TPaymentMethodType
> = {
  CARTAO_RECORRENTE: 'RECURRING_CARD',
  CARTAO_ONLINE: 'CREDIT_CARD',
  PIX: 'PIX',
  NUPAY: 'NUPAY',
  DEBITO_AUTOMATICO: 'AUTOMATIC_DEBIT',
};

export const PAYMENT_METHOD_TO_API: Record<
  TPaymentMethodType,
  TVectisPaymentMethodType
> = {
  RECURRING_CARD: 'CARTAO_RECORRENTE',
  CREDIT_CARD: 'CARTAO_ONLINE',
  PIX: 'PIX',
  NUPAY: 'NUPAY',
  AUTOMATIC_DEBIT: 'DEBITO_AUTOMATICO',
};
