import IStorageProvider from 'shared/domain/providers/IStorageProvider';

import LocalStorageProvider from './implementations/LocalStorageProvider';
import SessionStorageProvider from './implementations/SessionStorageProvider';

type TParams = {
  type?: 'LOCAL' | 'SESSION';
};

const IMPLEMENTATIONS = {
  LOCAL: LocalStorageProvider,
  SESSION: SessionStorageProvider,
};

const instances = new Map<TParams['type'], IStorageProvider>();

export default function makeStorageProvider({
  type = 'LOCAL',
}: TParams = {}): IStorageProvider {
  if (!instances.has(type)) {
    const Implementation = IMPLEMENTATIONS[type];

    instances.set(type, new Implementation());
  }

  return instances.get(type)!;
}
