const DOMAIN_TENANCIES = {
  br: 'pt',
  cr: 'es-CR',
  py: 'es',
} as const;

const initTenancy = () => {
  const domain = window.location.hostname;

  for (const key of Object.keys(DOMAIN_TENANCIES)) {
    if (domain.includes(key)) return DOMAIN_TENANCIES[key];
  }

  const validTenancies = Object.values(DOMAIN_TENANCIES);
  const storageTenancy = localStorage.getItem('TENANCY') as
    | (typeof validTenancies)[number]
    | null;

  if (storageTenancy && validTenancies.includes(storageTenancy)) {
    return storageTenancy;
  }
};

export type TLanguage =
  (typeof DOMAIN_TENANCIES)[keyof typeof DOMAIN_TENANCIES];

export const TENANCY = initTenancy();
