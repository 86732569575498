import { useQuery } from 'react-query';

import { IAddress } from 'shared/domain/entities';
import { makeFetchAddressByPostalCodeUseCase } from 'shared/domain/useCases';
import { sharedQueries } from 'shared/presentation/constants';
import { useRequestErrorHandler } from 'shared/presentation/hooks';

interface IParams {
  postalCode?: string;
  onSuccess: (address: IAddress) => void;
}

const MIN_LENGTH_FOR_SEARCH = 8;

export default function useAddressByPostalCode({
  postalCode,
  onSuccess,
}: IParams) {
  const handleError = useRequestErrorHandler('shared');

  const query = useQuery(
    sharedQueries.addressData(postalCode),
    async () => {
      if (!postalCode) return;

      const formattedPostalCode = postalCode.replace(/[_-]/, '');
      if (formattedPostalCode.length !== MIN_LENGTH_FOR_SEARCH) return;

      return makeFetchAddressByPostalCodeUseCase().execute({ postalCode });
    },
    {
      enabled: !!postalCode,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: error => handleError(error),
    },
  );

  return {
    loading: query.isLoading,
    address: query.data,
  };
}
