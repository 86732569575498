import lodash, { noop } from 'lodash';

import IEventTrackerProvider from 'shared/domain/providers/IEventTrackerProvider';

type TMetaFunction = (command: 'track', event: string, payload: object) => void;

export default class MetaEventTrackerProvider implements IEventTrackerProvider {
  private fbq: TMetaFunction;

  constructor() {
    this.fbq = lodash.get(window, 'fbq', noop) as any;
  }

  public register: IEventTrackerProvider['register'] = (
    event,
    _,
    payload = {},
  ) => {
    this.fbq('track', event, payload);
  };
}
