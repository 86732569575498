import { FC, useCallback, useRef } from 'react';

import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';

import { ICONS } from 'shared/presentation/constants';

import * as S from './styles';
import { ToastContext } from '../../hooks/useToast';

const IconX = ICONS.X_CIRCLE;

const InnerContext: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const success = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: 'success' }),
    [enqueueSnackbar],
  );

  const info = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: 'info' }),
    [enqueueSnackbar],
  );

  const error = useCallback(
    (message: string) =>
      enqueueSnackbar(message, {
        variant: 'error',
        id: message,
      }),
    [enqueueSnackbar],
  );

  return (
    <ToastContext.Provider value={{ success, info, error }}>
      {children}
    </ToastContext.Provider>
  );
};

const SnackbarCloseButton: FC<{ key: SnackbarKey }> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <S.CloseButton onClick={() => closeSnackbar(key)}>
      <IconX />
    </S.CloseButton>
  );
};

const NotistackToastProvider: FC = ({ children }) => {
  const ref = useRef<{ closeSnackbar?: (key: SnackbarKey) => void }>();

  return (
    <SnackbarProvider
      ref={ref}
      maxSnack={3}
      hideIconVariant
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={snackbarKey => <SnackbarCloseButton key={snackbarKey} />}
      classes={{
        containerAnchorOriginTopRight: 'notistack-index',
        containerRoot: 'notistack-index',
      }}
    >
      <InnerContext>{children}</InnerContext>
    </SnackbarProvider>
  );
};

export default NotistackToastProvider;
