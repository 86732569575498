import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";

const Base = styled(IconBase)`
  enable-background: new 0 0 130 130;
  .st0 {
    fill: #ffffff;
  }
  .st1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const WhatsappIcon = () => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 130 130"
    {...inproperProps}
  >
    <g>
      <path
        className="st0"
        d="M212.49,11.93c-27.44,0-49.78,21.82-49.78,48.6c0,26.78,22.34,48.6,49.78,48.6c27.41,0,49.75-21.82,49.75-48.6
		C262.24,33.75,239.9,11.93,212.49,11.93 M224.86,62.22h-8.09V90.4h-12.02V62.22h-5.67v-9.94h5.67v-6.47
		c0-4.61,2.28-11.81,12.12-11.81l8.89,0.03v9.67h-6.45c-1.05,0-2.54,0.52-2.54,2.71v5.87h9.16L224.86,62.22z"
      />
      <path
        className="st0"
        d="M362.03,70.23c5.49,0,9.95-4.37,9.95-9.72c0-2.1-0.69-4.07-1.86-5.68c-1.83-2.42-4.75-4.04-8.09-4.04
		c-3.32,0-6.24,1.61-8.08,4.04c-1.18,1.61-1.86,3.58-1.86,5.68C352.09,65.86,356.55,70.23,362.03,70.23"
      />
      <polygon
        className="st0"
        points="383.79,48.61 383.79,39.25 382.55,39.25 374.2,39.28 374.25,48.65 	"
      />
      <path
        className="st0"
        d="M362.03,11.93c-27.41,0-49.74,21.82-49.74,48.6c0,26.78,22.33,48.6,49.74,48.6c27.44,0,49.78-21.82,49.78-48.6
		C411.81,33.75,389.47,11.93,362.03,11.93 M390.34,54.84v22.63c0,5.87-4.89,10.68-10.93,10.68h-34.71
		c-6.05,0-10.96-4.81-10.96-10.68V43.56c0-5.88,4.92-10.68,10.96-10.68h34.71c6.04,0,10.93,4.79,10.93,10.68V54.84z"
      />
      <path
        className="st0"
        d="M377.52,60.51c0,8.33-6.94,15.1-15.49,15.1c-8.52,0-15.46-6.77-15.46-15.1c0-2.01,0.41-3.93,1.12-5.68h-8.43
		v22.63c0,2.94,2.43,5.3,5.43,5.3h34.71c2.99,0,5.43-2.36,5.43-5.3V54.83h-8.46C377.09,56.58,377.52,58.5,377.52,60.51"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M113.6,65.72c0,26.14-21.36,47.34-47.71,47.34c-8.37,0-16.23-2.14-23.06-5.89l-26.42,8.4l8.61-25.4
		c-4.35-7.14-6.84-15.5-6.84-24.45c0-26.15,21.36-47.35,47.72-47.35C92.24,18.38,113.6,39.57,113.6,65.72L113.6,65.72z M65.89,25.92
		c-22.12,0-40.12,17.85-40.12,39.81c0,8.71,2.84,16.77,7.64,23.34l-5.01,14.78l15.41-4.9c6.33,4.15,13.92,6.58,22.07,6.58
		C88,105.52,106,87.67,106,65.72C106,43.77,88,25.92,65.89,25.92L65.89,25.92z M89.98,76.63c-0.3-0.48-1.08-0.77-2.24-1.36
		c-1.17-0.58-6.92-3.39-7.99-3.77c-1.07-0.39-1.86-0.58-2.63,0.58c-0.78,1.16-3.02,3.78-3.71,4.55c-0.68,0.78-1.36,0.87-2.53,0.29
		c-1.17-0.58-4.94-1.81-9.41-5.76c-3.47-3.08-5.82-6.88-6.51-8.04c-0.68-1.16-0.07-1.79,0.51-2.37c0.53-0.52,1.17-1.36,1.76-2.03
		c0.59-0.68,0.78-1.17,1.17-1.94c0.39-0.77,0.2-1.45-0.1-2.03c-0.29-0.58-2.63-6.29-3.61-8.62c-0.97-2.32-1.95-1.93-2.63-1.93
		c-0.68,0-1.46-0.1-2.24-0.1c-0.78,0-2.05,0.29-3.12,1.45c-1.07,1.16-4.09,3.97-4.09,9.68c0,5.71,4.19,11.23,4.77,12
		c0.59,0.78,8.09,12.88,19.98,17.52c11.89,4.65,11.89,3.1,14.04,2.9c2.14-0.19,6.92-2.81,7.89-5.52
		C90.27,79.43,90.27,77.11,89.98,76.63L89.98,76.63z M89.98,76.63"
      />
    </g>
  </Base>
);

export default WhatsappIcon;
