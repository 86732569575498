import { makeMicroEventsRepository } from 'shared/infra/repositories';

import RegisterMicroEventUseCase from './implementations/RegisterMicroEventUseCase';

let instance: RegisterMicroEventUseCase | null = null;

export default function makeRegisterMicroEventUseCase() {
  if (!instance) {
    const microEventsRepository = makeMicroEventsRepository();

    instance = new RegisterMicroEventUseCase(microEventsRepository);
  }

  return instance;
}
