import { FC } from 'react';

import { Animation } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { ANIMATIONS, TIconType } from 'shared/presentation/constants';

import * as S from './styles';

interface IAction {
  text: string;
  icon: TIconType;
  onClick: () => void;
}

interface IErrorPageFallback {
  title: string;
  message: string;
  actions?: IAction[];
}

const ErrorPageFallback: FC<IErrorPageFallback> = ({
  title,
  message,
  actions = [],
}) => {
  return (
    <S.Container>
      <main className="error">
        <Animation
          animation={{
            data: ANIMATIONS.ERROR,
            width: 300,
            height: 150,
          }}
        />

        <section className="error__informations">
          <h1 className="error__title">{title}</h1>
          <p className="error__message">{message}</p>
        </section>

        <div className="error__actions">
          {actions.map((action, index) => (
            <Button
              key={index.toString() + action.text}
              icon={action.icon}
              className="error__button"
              onClick={action.onClick}
            >
              {action.text}
            </Button>
          ))}
        </div>
      </main>
    </S.Container>
  );
};

export default ErrorPageFallback;
