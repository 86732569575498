import { styled } from '@material-ui/core/styles';

export const Cards = styled('ul')(({ theme }) => ({
  margin: '0',
  gridArea: 'bottom',
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  listStyle: 'none',
  padding: '0',
  '& .points': {
    width: '5%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& div': {
      height: '5px',
      width: '5px',
      background: theme.palette.primary.main,
      borderRadius: '50%',
    },
    '& .middlePoint': {
      height: '10px',
      width: '10px',
    },
  },
}));

export const CardInfo = styled('li')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  background: theme.palette.cardsBackground.primary,
  color: theme.palette.text.primary,
  height: 150,
  width: 350,
  cursor: 'pointer',
  [theme.breakpoints.up('lg')]: {
    width: '20%',
  },
  [theme.breakpoints.up('md')]: {
    width: '29%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '35%',
  },
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: '0 5px 20px 0 rgb(0,0,0,0.3)',
  borderRadius: '20px',
  padding: '15px 0',
  textAlign: 'left',
  position: 'relative',
  '& .information': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .description': {
      width: '70%',
      fontSize: '1.25rem',
      fontWeight: 500,
      margin: '0px 0px 0px 30px',
    },
    [theme.breakpoints.down('1366')]: {
      marginLeft: 10,
    },
    [theme.breakpoints.down('1100')]: {
      '& header': {
        fontSize: '1em',
      },
      '& .description': {
        fontSize: '1em',
      },
    },
  },
  '& .more-info': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'center',
    marginLeft: '10px',
    '& button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '40px',
      border: 0,
      borderRadius: '50%',
      background: theme.palette.primary.main,
      color: theme.palette.cardsBackground.primary,
      fontSize: '1.5rem',
      boxShadow: '0 4px 10px 1px rgba(0,0,0,0.3)',
      cursor: 'pointer',
      outline: 'none',
      transition: 'filter  0.3s',
      '&:hover': {
        filter: 'brightness(90%)',
      },
    },
  },
}));

export const Image = styled('div')(() => ({
  height: '80%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    marginLeft: '30px',
  },
  // [theme.breakpoints.down("lg")]: {
  // },
}));
