import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  border-radius: 2rem;

  width: 425px;
  background-color: ${props => props.theme.palette.neutral[50]};
  box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.25);

  .modal__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .modal__title {
    color: ${props => props.theme.palette.text.main};
    font-size: ${props => props.theme.typography.sizes.title2};
  }

  .modal__message {
    color: ${props => props.theme.palette.text.main};
    font-size: ${props => props.theme.typography.sizes.body2};

    > button {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;

      color: ${props => props.theme.palette.primary.main};
      font-weight: ${props => props.theme.typography.weight.bold};

      &:disabled {
        cursor: no-drop;
      }
    }
  }

  .modal__smallmessage {
    color: ${props => props.theme.palette.text.main};
    font-size: ${props => props.theme.typography.sizes.caption};

    > a {
      color: ${props => props.theme.palette.primary.main};
      text-decoration: underline;
    }
  }

  .modal__content,
  .modal__form,
  .modal__options {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .modal__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 1rem;

    background: none;
    outline: none;
    border: none;
    cursor: pointer;

    color: ${props => props.theme.palette.text.main};
    font-size: ${props => props.theme.typography.sizes.caption};

    svg {
      font-size: 1rem;
      color: ${props => props.theme.palette.primary.main};
    }

    &:disabled {
      cursor: no-drop;
      color: ${props => props.theme.palette.text.light};
    }
  }

  .modal__footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > button {
      width: 100%;
    }
  }

  .channel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    gap: 1rem;

    background: none;
    outline: none;
    border: none;
    cursor: pointer;

    &__name {
      color: ${props => props.theme.palette.text.main};
      font-size: ${props => props.theme.typography.sizes.body2};
      transition: 0.3s;

      > small {
        font-size: ${props => props.theme.typography.sizes.caption};
        color: ${props => props.theme.palette.text.light};
      }

      &:hover {
        color: ${props => props.theme.palette.primary.main};
      }
    }

    &__circle {
      width: 24px;
      height: 24px;
      border-radius: 100%;

      background-color: transparent;
      border: 5px solid ${props => props.theme.palette.neutral[200]};

      transition: 0.3s;

      &-selected {
        border-color: ${props => props.theme.palette.primary.main};
      }
    }

    &:disabled {
      cursor: no-drop;

      .channel__name {
        color: ${props => props.theme.palette.text.light};

        &:hover {
          color: ${props => props.theme.palette.text.light};
        }
      }

      .channel__circle {
        border-color: ${props => props.theme.palette.neutral[100]};
      }
    }
  }

  @media (max-width: ${props => props.theme.layout.breakpoints.md}) {
    height: 100vh;
    width: 100vw;
  }
`;
