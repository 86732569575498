import { FC } from 'react';

import { TLanguage } from 'config/tenancy';
import { useLocale, useTheme } from 'shared/presentation/contexts';

import { PtLogo, EsLogo } from './components';
import { TVariantLogoProps } from './constants';

const LOGOS: Record<TLanguage, FC<TVariantLogoProps>> = {
  pt: PtLogo,
  es: EsLogo,
  'es-CR': EsLogo,
};

interface IVialaserLogo {
  color?: string;
  colors?: {
    via?: string;
    laser?: string;
    line?: string;
    bottom?: string;
  };
  size?: number;
}

const VialaserLogo: React.FC<IVialaserLogo> = ({
  color,
  colors = {},
  size = 200,
}) => {
  const { theme } = useTheme();
  const { language } = useLocale();

  const defaultColor = color || theme.palette.text.white;

  const LogoComponent = LOGOS[language];

  return (
    <LogoComponent
      size={size}
      colors={{
        via: colors.via || defaultColor,
        laser: colors.laser || defaultColor,
        bottom: colors.bottom || defaultColor,
        line: colors.line || defaultColor,
      }}
    />
  );
};

export default VialaserLogo;
