import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { Container, CardInfo, Image } from './styles';
import logoActive from '../../../img/ativo.svg';
import logoPaint from '../../../img/paint.svg';
import exclusiveTech from '../../../img/technologies/anexos/laser.svg';
import logoBrasil from '../../../img/technologies/anexos/MarcadorAtivo 21.svg';
import Carousel from '../../Carousel';

const SliderCards = memo(() => {
  const { t } = useTranslation();

  return (
    <Container>
      <Carousel
        autoPlay
        swipeable
        showArrows
        stopOnHover
        emulateTouch
        infiniteLoop
      >
        <CardInfo>
          <Image>
            <img
              src={exclusiveTech}
              alt="Tecnologia Exlusiva"
              width="50"
              height="50"
            />
          </Image>

          <div className="description">
            {t('Tecnologia Exclusiva no Brasil')}
          </div>
        </CardInfo>
        <CardInfo>
          <Image>
            <img src={logoBrasil} alt="Tecnologia" width="50" height="50" />
          </Image>
          <div className="description">{t('Depilação a Laser sem dor!')}</div>
        </CardInfo>
        <CardInfo>
          <Image>
            <img src={logoPaint} alt="Gênero" width="50" height="50" />
          </Image>
          <div className="description">
            {t('Maior abrangência de tons de pele!')}
          </div>
        </CardInfo>
        <CardInfo>
          <Image>
            <img src={logoActive} alt="Portabilidade" width="50" height="50" />
          </Image>
          <div className="description">{t('Mais segurança e rapidez!')}</div>
        </CardInfo>
      </Carousel>
    </Container>
  );
});

SliderCards.displayName = 'SliderCards';

export default SliderCards;
