import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

export default function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(location => {
      setTimeout(() => {
        if (!location.hash) {
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      }, 200);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}
