import React, { ComponentProps } from 'react';

import { TIconType } from 'shared/presentation/constants';
import { Link } from 'shared/presentation/contexts';

import * as S from './styles';

type TNavbarMenuIconProps = {
  Icon: TIconType;
  title: string;
  onClick?: () => void;
  color?: string;
  badge?: string;
} & (
  | { type: 'external'; to: string }
  | { type: 'internal'; to: ComponentProps<typeof Link>['to'] }
);

const NavbarMenuIcon: React.FC<TNavbarMenuIconProps> = props =>
  props.type === 'internal' ? (
    <S.Container
      as={Link}
      to={props.to}
      title={props.title}
      onClick={props.onClick}
    >
      <props.Icon color={props.color} />

      {props.badge && <div className="badge">{props.badge}</div>}
    </S.Container>
  ) : (
    <S.Container
      href={props.to}
      title={props.title}
      target="_blank"
      rel="noopener noreferrer"
      onClick={props.onClick}
    >
      <props.Icon color={props.color} />

      {props.badge && <div className="badge">{props.badge}</div>}
    </S.Container>
  );

export default NavbarMenuIcon;
