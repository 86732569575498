import IContractTermsRepository from 'shared/domain/repositories/IContractTermsRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisContractTermsRepository from './implementations/VectisContractTermsRepository';

let instance: IContractTermsRepository | null = null;

export default function makeContractTermsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisContractTermsRepository(httpProvider);
  }

  return instance;
}
