import { makeUserBenefitsRepository } from 'shared/infra/repositories';

import FindUserBenefitUseCase from './implementations/FindUserBenefitUseCase';

let instance: FindUserBenefitUseCase | null = null;

export default function makeFindUserBenefitUseCase() {
  if (!instance) {
    const repository = makeUserBenefitsRepository();

    instance = new FindUserBenefitUseCase(repository);
  }

  return instance;
}
