import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    height: calc(100vh - ${theme.layout.spacing(4)});
    min-width: ${theme.layout.spacing(67.5)};

    background-color: ${theme.palette.neutral[50]};
    padding: ${theme.layout.spacing(3, 4)};
    overflow: auto;

    border-radius: 16px;

    .document {
      margin: 0 auto;
      width: 100%;
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      height: 100vh;
      border-radius: 0;

      .document {
        width: 100vw;
      }
    }
  `}
`;

export const InfoAndActionsContainer = styled.section<{ full?: boolean }>`
  ${({ theme, full }) => css`
    ${full &&
    css`
      width: 100%;
      height: 100%;
    `}

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.layout.spacing(2)};

    button {
      min-width: 164px;
    }

    p {
      color: ${theme.palette.error.main};
      text-align: center;
    }
  `}
`;
