import { makeErrorLoggerRepository } from 'shared/infra/repositories';

import RegisterErrorUseCase from './implementations/RegisterErrorUseCase';

let instance: RegisterErrorUseCase | null = null;

export default function makeRegisterErrorUseCase() {
  if (!instance) {
    const errorLoggerRepository = makeErrorLoggerRepository();

    instance = new RegisterErrorUseCase(errorLoggerRepository);
  }

  return instance;
}
