import { IUpdateCustomerDTO } from 'shared/domain/dtos';
import ICustomersRepository from 'shared/domain/repositories/ICustomersRepository';

export default class UpdateCustomerUseCase {
  constructor(private customersRepository: ICustomersRepository) {}

  public async execute({ customer }: IUpdateCustomerDTO) {
    return this.customersRepository.update(customer);
  }
}
