import { TLanguage } from 'config/tenancy';
import { ICustomer, TMinorityStatus } from 'shared/domain/entities';

type TCustomerPaths = ObjectPaths<ICustomer>;

export const COMPLETE_REGISTRATION_ABSENT_FIELDS_BY_TENANCY: Record<
  TLanguage,
  TCustomerPaths[]
> = {
  es: ['address.postalCode', 'guardian.address.postalCode'],
  'es-CR': [
    'address.postalCode',
    'alternativeDocument',
    'guardian.address.alternativeDocument',
    'guardian.address.postalCode',
  ],
  pt: [],
};

export const COMPLETE_REGISTRATION_REQUIRED_FIELDS_BY_STATUS: Record<
  TMinorityStatus,
  Array<TCustomerPaths>
> = {
  ADULT: [
    'birthdate',
    'email',
    'document',
    'alternativeDocument',
    'phone',
    'address.city.id',
    'address.district.id',
    'address.number',
    'address.street',
    'address.postalCode',
  ],
  TEEN: [
    'birthdate',
    'email',
    'phone',
    'address.city.id',
    'address.district.id',
    'address.number',
    'address.street',
    'address.postalCode',
    'guardian.firstName',
    'guardian.lastName',
    'guardian.document',
    'guardian.alternativeDocument',
  ],
  MINOR: [
    'birthdate',
    'email',
    'phone',
    'guardian.firstName',
    'guardian.lastName',
    'guardian.phone',
    'guardian.address.city.id',
    'guardian.address.district.id',
    'guardian.address.number',
    'guardian.address.street',
    'guardian.address.postalCode',
    'guardian.document',
    'guardian.alternativeDocument',
  ],
};

export type TCustomerFields =
  (typeof COMPLETE_REGISTRATION_REQUIRED_FIELDS_BY_STATUS)[keyof typeof COMPLETE_REGISTRATION_REQUIRED_FIELDS_BY_STATUS][number];
