import styled, { css } from 'styled-components';

interface IImageProps {
  isRounded: boolean;
  size?: number;
}

const DEFAULT_SIZE = 80;

export const Image = styled.img<IImageProps>`
  ${({ size = DEFAULT_SIZE, isRounded }) => css`
    object-fit: cover;
    width: ${size}px;
    height: ${size}px;
    border-radius: ${isRounded ? '50%' : '0.5rem'};
  `}
`;
