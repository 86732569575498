import { createNumberMask } from 'text-mask-addons';

import { TLanguage } from 'config/tenancy';

export type TMask =
  | (string | RegExp)[]
  | ((value: string) => (string | RegExp)[]);

const DOCUMENT: Record<TLanguage, TMask> = {
  // 999.999.999-99
  pt: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  // 9.999.999
  es: [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/],
  // 9 9999 99999 | 999999999999
  'es-CR': value => {
    const validValue = value.replace(/\D/g, '');

    if (validValue.length <= 9) {
      return [
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }

    if (validValue.length >= 12) return Array.from({ length: 12 }, () => /\d/);

    return Array.from({ length: validValue.length + 1 }, () => /\d/);
  },
};

// 9.999.999
const RUC = [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];

// 99/99/9999
const DATE = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const PHONE: Record<TLanguage, TMask> = {
  // (99) 99999 9999
  pt: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  // 9999 99999
  es: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
  // 9999 9999
  'es-CR': [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
};

const CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const CARD_NUMBER = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const EXPIRY_DATE = [/\d/, /\d/, '/', /\d/, /\d/];

const SECURITY_CODE = [/\d/, /\d/, /\d/, /\d/];
const AMEX_SECURITY_CODE = [/\d/, /\d/, /\d/, /\d/];

const ZIP_CODE = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const VALIDATION_CODE = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

const CURRENCY: Record<TLanguage, TMask> = {
  pt: createNumberMask({
    prefix: 'R$ ',
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
  }),
  es: createNumberMask({
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    prefix: '₲ ',
  }),
  'es-CR': createNumberMask({
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: ',',
    prefix: '₡ ',
  }),
};

export const MASKS = {
  DOCUMENT,
  RUC,
  DATE,
  PHONE,
  CEP,
  CARD_NUMBER,
  EXPIRY_DATE,
  SECURITY_CODE,
  AMEX_SECURITY_CODE,
  ZIP_CODE,
  VALIDATION_CODE,
  CURRENCY,
};
