import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.layout.spacing(4)};
    padding: ${theme.layout.spacing(4)};
    border-radius: ${theme.layout.spacing(4)};

    width: 425px;
    background-color: ${theme.palette.neutral[50]};

    .modal__content {
      display: flex;
      flex-direction: column;
      gap: ${theme.layout.spacing(2)};
    }

    .modal__title {
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.sizes.title2};
    }

    .modal__message {
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.sizes.body2};
    }

    .modal__footer {
      display: flex;
      align-items: center;
      gap: ${theme.layout.spacing(2)};

      > button {
        flex-grow: 1;
      }
    }
  `}
`;
