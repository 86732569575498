import IVerificationCodeAvailabilityRepository from 'shared/domain/repositories/IVerificationCodeAvailabilityRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisVerificationCodeAvailabilityRepository from './implementations/VectisVerificationCodeAvailabilityRepository';

let instance: IVerificationCodeAvailabilityRepository | null = null;

export default function makeVerificationCodeAvailabilityRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisVerificationCodeAvailabilityRepository(httpProvider);
  }

  return instance;
}
