import styled, { css } from 'styled-components';

export const Container = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.palette.neutral[50]};
    height: ${theme.layout.spacing(10)};

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    flex-shrink: 0;

    border-top: 1px solid ${theme.palette.neutral[200]};

    small {
      color: ${theme.palette.neutral[400]};
      font-size: ${theme.typography.sizes.caption};
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      display: none;
    }
  `}
`;
