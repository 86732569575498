import styled, { css } from 'styled-components';

export const Container = styled.div<{ size: number }>`
  ${({ theme, size }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: ${theme.layout.spacing(2)};

    border: 1px solid ${theme.palette.neutral[100]};
    border-radius: 1rem;

    width: ${size}px;
    height: ${size}px;
    overflow: hidden;

    > svg {
      font-size: 3rem;
    }

    > button {
      outline: none;
      cursor: pointer;
      border: none;
      background: none;

      font-family: ${theme.typography.family};
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.bold};

      color: ${theme.palette.primary.main};

      &:disabled {
        color: ${theme.palette.neutral[300]};
        cursor: no-drop;
      }
    }
  `}
`;
