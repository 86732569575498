import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IContractTermsRepository } from 'shared/domain/repositories';

export default class VectisContractTermsRepository
  implements IContractTermsRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public findByBudget: IContractTermsRepository['findByBudget'] = async id => {
    const { linkClausulas } = await this.httpProvider.get<{
      linkClausulas: string;
    }>('/contratos/termo/', {
      params: {
        idOrcamento: id,
      },
    });

    return { link: linkClausulas };
  };
}
