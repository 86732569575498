import es from './es';
import esCR from './es-CR';
import pt from './pt';

const customerLocale = {
  pt,
  es,
  'es-CR': esCR as typeof es,
};

export default customerLocale;
