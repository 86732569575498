export const contractCheckout = {
  'Card number': 'Número do cartão',
  'Name printed on card': 'Nome impresso no cartão',
  'Choose your payment method, {{name}}':
    'Escolha seu método de pagamento, {{name}}',
  Credit: 'Crédito',
  CVC: 'Código de segurança',
  'Expiry date': 'Data de vencimento',
  Installments: 'Parcelamento',
  main: 'principal',
  'Make payment': 'Efetuar pagamento',
  'New card': 'Novo cartão',
  'New credit card': 'Novo cartão de crédito',
  'Pay with {{flag}}': 'Pagar com {{flag}}',
  'Pending issues successfully paid!': 'Pendências pagas com sucesso!',
  'Select the credit card': 'Selecione o cartão de crédito',
};
