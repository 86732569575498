import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Helmet } from 'react-helmet';

import { useStorageState } from 'shared/presentation/components/atoms/BlipChatBubble/hooks';
import { ICONS } from 'shared/presentation/constants';
import { useLocale, useTheme } from 'shared/presentation/contexts';

import lisa from './assets/lisa.jpg';
import * as S from './styles';

const CONFIG = {
  URL: 'https://unpkg.com/blip-chat-widget',
  APP_KEY:
    'YXRlbmRpbWVudG92aWFsYXNlcjE6ZGFmYzAwYTktYWFiYy00Y2RkLWJiMmYtNGM0YzdjNWNjYjQ4',
  COMMON_URL: 'https://vialaserdepilacao.chat.blip.ai/',
  CUSTOM_STYLE: `
    .blip-chat-container {
      left: 80px;
    }
    .blip-chat-container > img {
      display: none;
    }
    .blip-chat-container::before {
      content: "";
      display: block;
      background-image: url("${lisa}");
      background-size: 35px 35px;
      background-position 1rem 0;
      width: 35px;
      height: 35px;
      margin-left: 1rem;
      margin-right: 1rem;
      border-radius: 100%;
    }
    .blip-card-photo {
      background-image: url("${lisa}") !important;
    }
  `,
};
const HEAD_STYLE = `
  #blip-chat-container #blip-chat-open-iframe {
    left: 35px;
  }
  #blip-chat-iframe {
    left: 38px;
  }
  #blip-chat-notifications {
    left: 80px;
    bottom: 80px;
  }
`;
const CloseIcon = ICONS.X;
const LISA_BUBBLE_AUTO_HIDE_TIME_MS = 15000;

const BlipChatBubble: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useLocale('shared');

  const clientRef = useRef<BlipChat | null>(null);
  const isInitialLoadedPage = useRef(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isDismissed, setIsDismissed] = useStorageState('LISA_CHAT_DISMISSED', {
    defaultValue: false,
    type: 'SESSION',
  });
  const [hideLisaBubble, setHideLisaBubble] = useStorageState(
    'HIDE_LISA_CHAT_BUBBLE',
    { defaultValue: false, type: 'SESSION' },
  );

  const createBlipClient = useCallback(() => {
    if (!window.BlipChat || isDismissed) return;

    const client = new window.BlipChat()
      .withAppKey(CONFIG.APP_KEY)
      .withButton({
        color: theme.palette.primary.main,
        icon: lisa,
      })
      .withCustomCommonUrl(CONFIG.COMMON_URL)
      .withCustomStyle(CONFIG.CUSTOM_STYLE);

    client
      .withEventHandler(window.BlipChat.CREATE_ACCOUNT_EVENT, () => {
        client.sendMessage({
          type: 'application/vnd.lime.chatstate+json',
          content: {
            state: 'Starting',
          },
        });
      })
      .withEventHandler(window.BlipChat.ENTER_EVENT, () => {
        setHideLisaBubble(true);
      })
      .build();

    clientRef.current = client;
    setIsLoaded(true);
  }, [theme.palette.primary.main, setHideLisaBubble, isDismissed]);

  useEffect(() => {
    // This triggers when the user enters a page where the bubble is present
    window.addEventListener('load', () => {
      isInitialLoadedPage.current = true;
      createBlipClient();
    });

    // This handles SPA navigation. The document maybe loaded, but the user might be entering
    // a bubble enabled page from a page where this component was absent
    if (!isInitialLoadedPage.current && !clientRef.current) createBlipClient();

    // When a page dismounts, we disconnect from blip. So if we go to a page without this component, the chat button
    // will not be displayed
    return () => {
      clientRef.current?.destroy();
      clientRef.current = null;
    };
  }, [createBlipClient]);

  useEffect(() => {
    // Auto hides the welcome chat bubble to draw less attention from uninterested users
    const timeout = setTimeout(
      () => setHideLisaBubble(true),
      LISA_BUBBLE_AUTO_HIDE_TIME_MS,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [setHideLisaBubble]);

  const handleToggleChat: MouseEventHandler = event => {
    event.preventDefault();
    clientRef.current?.toogleChat();
  };

  const handleHideChatBubble: MouseEventHandler = event => {
    event.stopPropagation();
    setHideLisaBubble(true);
  };

  const handleDismissBlipChat: MouseEventHandler = event => {
    event.preventDefault();
    setIsDismissed(true);
    handleHideChatBubble(event);
    clientRef.current?.destroy();
    clientRef.current = null;
  };

  return (
    <>
      <Helmet>
        <style>{HEAD_STYLE}</style>
        <script src={CONFIG.URL} defer async id="blip" />
      </Helmet>

      {isLoaded && !hideLisaBubble && (
        <S.ChatText type="button" onClick={handleToggleChat}>
          {t("If you have any questions, I'm here!")}
          <button type="button" onClick={handleHideChatBubble}>
            <CloseIcon />
          </button>
        </S.ChatText>
      )}

      {isLoaded && !isDismissed && (
        <S.CloseButton type="button" onClick={handleDismissBlipChat}>
          <CloseIcon />
        </S.CloseButton>
      )}
    </>
  );
};

export default BlipChatBubble;
