import fetchCoupon from 'contexts/core/coupons';
import { DISCOUNT_TYPES, ITEM_TYPES } from 'contexts/core/utils';

type TCoupon = {
  id: number;
  codigo: string;
  dataCriacao: string;
  valorDesconto: number;
  percentualDesconto?: number;
};

type TItem = {
  idProduto: number;
  tipo: string;
  valorTabela: number;
  valorEcommerce: number;
  valorCoupon?: number;
};

type TParams = {
  couponCode: string;
  onSuccess(data: {
    coupon: TCoupon;
    discounts: Record<string, number>;
    removedCoupon: boolean;
    items: TItem[];
  }): void;
  onFailure(reason: string): void;

  unitId: number;
  items: Array<TItem>;
  discounts: Record<string, number>;
  ecommerceValue: number;
  businessValue: number;

  onInvalidCoupon(): void;
};

const getCoupon = async (
  couponCode: string,
  unitId: number,
  items: number[],
) => {
  try {
    const coupon: TCoupon | null = await fetchCoupon(
      couponCode,
      unitId,
      items.toString(),
    );

    return {
      coupon,
    };
  } catch (error) {
    return {
      error,
    };
  }
};

export async function applyCoupon({
  couponCode,
  onSuccess,
  onFailure,
  items,
  unitId,
  ecommerceValue,
  onInvalidCoupon,
}: TParams) {
  if (!couponCode) return onInvalidCoupon();

  const hasCombo = items.find(item => item.tipo === ITEM_TYPES.COMBO);
  if (hasCombo) return onFailure('coupon_discount_combo');

  const itemIds = items.map(item => item.idProduto);

  const { coupon, error } = await getCoupon(couponCode, unitId, itemIds);

  if (error || !coupon) return onFailure('invalid_coupon');

  if (coupon.valorDesconto >= ecommerceValue) {
    return onFailure('coupon_discount_price_product');
  }

  const discounts = coupon.percentualDesconto
    ? {
        [DISCOUNT_TYPES.COUPON]: coupon.percentualDesconto,
      }
    : {
        [DISCOUNT_TYPES.FIXED]: coupon.valorDesconto,
      };

  return onSuccess({
    coupon,
    discounts,
    removedCoupon: false,
    items,
  });
}
