import React from 'react';

import { ICONS } from 'shared/presentation/constants';

type TParams = {
  currentPage: number;
  totalPages: number;
  onClick(page: number): void;
};

type TButtonType = {
  key: string;
  children: React.ReactChild;
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const FIRST_PAGE = 1;
const PAGE_INDEXES_OFFSET = 2;
const MAXIMUM_DISPLAYABLE_PAGES = 1 + 2 * PAGE_INDEXES_OFFSET;

const getButtonsConfiguration = (
  onClick: (page: number) => void,
  currentPage: number,
  totalPages: number,
) => ({
  EXTREME_LEFT: {
    key: 'extreme-left',
    children: React.createElement(ICONS.ARROW_FIRST),
    active: true,
    disabled: currentPage === FIRST_PAGE,
    onClick: () => onClick(FIRST_PAGE),
  },
  LEFT: {
    key: 'left',
    children: React.createElement(ICONS.ARROW_LEFT),
    active: true,
    disabled: currentPage === FIRST_PAGE,
    onClick: () => onClick(Math.max(FIRST_PAGE, currentPage - 1)),
  },
  RIGHT: {
    key: 'right',
    children: React.createElement(ICONS.ARROW_RIGHT),
    active: true,
    disabled: currentPage === totalPages,
    onClick: () => onClick(Math.min(totalPages, currentPage + 1)),
  },
  EXTREME_RIGHT: {
    key: 'extreme-right',
    children: React.createElement(ICONS.ARROW_LAST),
    active: true,
    disabled: currentPage === totalPages,
    onClick: () => onClick(totalPages),
  },
  LEFT_ELLIPSIS: {
    key: 'left-ellipsis',
    children: React.createElement(ICONS.ELLIPSIS),
    active: false,
    disabled: true,
  },
  RIGHT_ELLIPSIS: {
    key: 'right-ellipsis',
    children: React.createElement(ICONS.ELLIPSIS),
    active: false,
    disabled: true,
  },
});

const getDisplayableIndexes = (currentPage: number, totalPages: number) => {
  if (totalPages < MAXIMUM_DISPLAYABLE_PAGES) {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  let displayablePageIndexes = [currentPage];

  for (let i = 1; i <= PAGE_INDEXES_OFFSET; i++) {
    displayablePageIndexes.unshift(currentPage - i);
    displayablePageIndexes.push(currentPage + i);
  }

  const lastIndex = displayablePageIndexes.length - 1;

  const initialPageOffset = FIRST_PAGE - displayablePageIndexes[0];
  if (initialPageOffset > 0) {
    displayablePageIndexes = displayablePageIndexes.map(
      index => index + initialPageOffset,
    );
  }

  const totalPagesOffset = totalPages - displayablePageIndexes[lastIndex];
  if (totalPagesOffset < 0) {
    displayablePageIndexes = displayablePageIndexes.map(
      index => index + totalPagesOffset,
    );
  }

  return displayablePageIndexes;
};

export default function getPaginationStructure({
  currentPage,
  onClick,
  totalPages,
}: TParams) {
  const BUTTONS_CONFIGURATION = getButtonsConfiguration(
    onClick,
    currentPage,
    totalPages,
  );

  const leftButtons: TButtonType[] = [
    BUTTONS_CONFIGURATION.EXTREME_LEFT,
    BUTTONS_CONFIGURATION.LEFT,
  ];
  const rightButtons: TButtonType[] = [
    BUTTONS_CONFIGURATION.RIGHT,
    BUTTONS_CONFIGURATION.EXTREME_RIGHT,
  ];

  const displayablePageIndexes = getDisplayableIndexes(currentPage, totalPages);

  const pageButtons: TButtonType[] = displayablePageIndexes.map(page => ({
    key: String(page),
    children: String(page),
    active: page === currentPage,
    onClick: () => onClick(page),
  }));

  const lastButtonIndex = pageButtons.length - 1;

  const initialButton = pageButtons[0];
  if (Number(initialButton.key) > FIRST_PAGE) {
    pageButtons[0] = BUTTONS_CONFIGURATION.LEFT_ELLIPSIS;
  }

  const lastButton = pageButtons[lastButtonIndex];
  if (Number(lastButton.key) < totalPages) {
    pageButtons[lastButtonIndex] = BUTTONS_CONFIGURATION.RIGHT_ELLIPSIS;
  }

  return [...leftButtons, ...pageButtons, ...rightButtons];
}
