export const pendencies = {
  Attention: 'Atenção',
  'Click to select the installments': 'Clique para selecionar as parcelas',
  '{{name}}, search your contracts for the installments you wish to settle':
    '{{name}}, procure em seus contratos pelas parcelas que deseja acertar.',
  '{{name}}, select the installments you wish to settle to proceed':
    '{{name}}, selecione as parcelas que deseja acertar para prosseguir.',
  '{{name}}, you have the option to reparcel your debts How do you wanna procceed?':
    '{{name}}, existe a possibilidade de reparcelar as suas dívidas. Como deseja prosseguir?',
  'Hello {{name}}! Please, confirm your document to see your pending issues':
    'Olá {{name}}! Por favor, confirme seu CPF ver suas pendências.',
  'Log out and identify yourself again': 'Sair e se identificar novamente',
  'Pending issues': 'Pendências',
  'Procceed settling my pending issues':
    'Prosseguir com o acerto de parcelas pendentes',
  'Reparcel all my debts': 'Reparcelar toda a minha dívida',
  'Touch to select the installments': 'Toque para selecionar as parcelas',
  'You are trying to access a page belonging to another customer':
    'Você está tentando acessar uma página pertencente a outro cliente.',
};
