import { useState, createContext, useEffect } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const BonificationContext = createContext({});

function BonificationProvider({ children }) {
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [isIndicator, setIsIndicator] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    async function getDiscountPercentage() {
      const { data } = await axios.get(
        `discount-percentage?idunidade=42&indicador=${isIndicator}`,
      );

      setDiscountPercentage(data);
    }
    if (i18n.language === 'es') return;

    if (!discountPercentage) {
      getDiscountPercentage();
    }
  }, [discountPercentage, isIndicator, i18n.language]);

  return (
    <BonificationContext.Provider
      value={{
        discountPercentage,
        setIsIndicator,
      }}
    >
      {children}
    </BonificationContext.Provider>
  );
}

export { BonificationProvider };
