import { lighten } from 'polished';
import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.primary};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 5rem 0;

    ul {
      display: flex;
      gap: 3rem;

      li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        background-color: ${theme.colors.body};
        border-radius: 2rem;

        padding: 2rem;
        width: 400px;

        font-size: 1.375rem;

        h3 {
          font-size: 1.75rem;
          color: ${theme.colors.secondary};
          text-align: center;
        }

        address {
          font-style: normal;

          & + address {
            margin: 2rem 0;
          }

          &.address {
            display: flex;
            flex-direction: column;
            text-align: center;
          }
        }

        > span {
          margin-bottom: 2rem;
        }

        a {
          color: ${theme.colors.textBright};
          padding: 1rem;
          border-radius: 2rem;

          &.maps {
            background-color: ${theme.colors.primary};
            margin-bottom: 1rem;
            :hover {
              background-color: ${lighten(0.1, theme.colors.primary)};
            }
          }

          &.whatsapp {
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: ${theme.colors.secondary};

            svg {
              height: 3rem;
              width: 3rem;
              margin: -1rem 0;
            }
            :hover {
              background-color: ${lighten(0.1, theme.colors.secondary)};
            }
          }
        }
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    > div {
      ul {
        flex-direction: column;
      }
    }
  }
`;
