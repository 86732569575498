import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  containerClass: {
    background: theme.palette.background.secondary,
    padding: "8rem 0",
    width: "100%",
  },
}));

export default function GreenBackground({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.containerClass || "container"}>{children}</div>
  );
}
