import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 380,
  width: 300,
  marginTop: '1.5rem',
  marginBottom: 5,
  borderRadius: 10,
  padding: '0 0.5rem',
  background: theme.palette.cardsBackground.primary,
  boxShadow: 'rgba(0, 0, 0, 0.1) 3px 9px 12px -2px',
  zIndex: 1,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  transition: 'all 0.2s',
  cursor: 'pointer',

  '&:hover': {
    transform: 'scale(1.05)',
    paddingBottom: 0,
    background: theme.palette.cardsBackgroundButton.primary,
    boxShadow: '0px 0px 13px 18px rgba(0,0,0,0.2)',
    color: theme.palette.text.secondary,
    zIndex: 2,
    '& div': {
      top: '85%',
      background: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
      '& span': {
        background: theme.palette.text.secondary,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  '& div': {
    position: 'absolute',
    top: '82%',
    height: 67,
    width: '100%',
    background: theme.palette.cardsBackgroundButton.primary,
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    transition: 'all 0.2s',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      height: 40,
      width: 40,
      background: theme.palette.text.secondary,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 15,
      transition: 'all 0.2s',

      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '3rem',
  },

  [theme.breakpoints.down('xs')]: {
    height: 320,
    width: 250,
    marginTop: '2.1rem',
    pointerEvents: 'none',
    background: theme.palette.cardsBackground.primary,
    '& div': {
      background: theme.palette.primary.main,
      color: '#fff',
      '& span': {
        background: '#fff',
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

export const Image = styled('img')(({ theme }) => ({
  height: 120,
  width: 120,
  position: 'relative',
  margin: '2rem 0',
  [theme.breakpoints.down('xs')]: {
    height: '100px !important',
    width: '100px !important',
  },
  '&:after': {
    content: "''",
    position: 'absolute',
    height: '200px !important',
    width: '200px !important',
    top: '20px',
    left: '-20px',
    border: `"1px solid ${theme.palette.primary.main}"`,
    background: theme.palette.primary.main,
    borderRadius: 20,
    zIndex: -1,
  },
}));
