import { makeVerificationCodeRepository } from 'shared/infra/repositories';

import SendVerificationCodeUseCase from './implementations/SendVerificationCodeUseCase';

let instance: SendVerificationCodeUseCase | null = null;

export default function makeSendVerificationCodeUseCase() {
  if (!instance) {
    const repository = makeVerificationCodeRepository();

    instance = new SendVerificationCodeUseCase(repository);
  }

  return instance;
}
