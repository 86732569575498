import { IPaymentDirectivesRepository } from 'shared/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import { VectisPaymentDirectivesRepository } from './implementations/VectisPaymentDirectivesRepository';

let instance: IPaymentDirectivesRepository | null = null;

export default function makePaymentDirectivesRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisPaymentDirectivesRepository(httpProvider);
  }

  return instance;
}
