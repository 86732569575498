import React, { ComponentProps } from 'react';

import { TIconType } from 'shared/presentation/constants';
import { Link } from 'shared/presentation/contexts';

import * as S from './styles';

type TNavigationMenuItemProps = {
  icon: TIconType;
  text: string;
  color?: string;
  onClick?: () => void;
  badge?: number | boolean;
} & (
  | {
      type: 'link';
      to: ComponentProps<typeof Link>['to'];
    }
  | {
      type: 'button';
      onClick: () => void;
    }
);

const NavigationMenuItem: React.FC<TNavigationMenuItemProps> = ({
  icon: Icon,
  text,
  color,
  onClick,
  badge,
  ...props
}) => {
  const commonProps = {
    color,
    onClick,
    children: [<Icon key="icon" />, <span key="text">{text}</span>],
  };

  if (badge) {
    commonProps.children.push(<div className="badge">{badge}</div>);
  }

  if (props.type === 'link') {
    return <S.Container as={Link} to={props.to} {...commonProps} />;
  }

  return <S.Container as="button" type="button" {...commonProps} />;
};

export default NavigationMenuItem;
