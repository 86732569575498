import { useQuery } from 'react-query';

import { ISession } from 'shared/domain/entities';
import { makeVerifyNpsSessionFeedbackUseCase } from 'shared/domain/useCases';
import { sharedQueries } from 'shared/presentation/constants';

interface IParams {
  id: number;
  onSuccess: (session: ISession) => void;
  enabled?: boolean;
}

export default function useVerifyNpsSessionFeedback({
  id,
  onSuccess,
  enabled,
}: IParams) {
  const query = useQuery(
    sharedQueries.verifyNpsSessionFeedback(id),
    async () => {
      return makeVerifyNpsSessionFeedbackUseCase().execute({ id });
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess,
      enabled,
    },
  );

  return {
    session: query.data,
    loading: query.isLoading,
  };
}
