import { createContext, useContext } from 'react';

export type TModalContext = {
  visible: boolean;
  open(): void;
  close(): void;
  toggle(): void;
};

export const ModalContext = createContext<TModalContext>({
  visible: false,
  open: () => undefined,
  close: () => undefined,
  toggle: () => undefined,
});

export function useModal() {
  return useContext(ModalContext);
}
