import styled, { css } from 'styled-components';

const SVG_SIZE_FACTOR = 0.6;

const VARIANTS = {
  default: css`
    ${({ theme }) => css`
      color: ${theme.palette.neutral[500]};
      background: ${theme.palette.neutral[300]};
    `}
  `,
  error: css`
    ${({ theme }) => css`
      color: ${theme.palette.error.main};
      background: ${theme.palette.text.white};
    `}
  `,
};

export type TVariant = keyof typeof VARIANTS;

export const Container = styled.button<{
  size: number;
  variant?: TVariant;
}>`
  ${({ theme, size, variant = 'default' }) => css`
    padding: ${theme.layout.spacing(0.5)};
    border-radius: ${theme.layout.spacing(0.5)};
    transition: 0.2s;
    width: ${theme.layout.spacing(size)};
    height: ${theme.layout.spacing(size)};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    ${VARIANTS[variant]}

    &:hover {
      transform: scale(1.2);
    }

    :disabled {
      cursor: not-allowed;

      &:hover {
        transform: scale(1);
      }
    }

    svg {
      transition: 0.2s;
      width: ${theme.layout.spacing(size * SVG_SIZE_FACTOR)};
      height: ${theme.layout.spacing(size * SVG_SIZE_FACTOR)};
    }
  `}
`;
