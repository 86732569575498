import React from 'react';

import { useTranslation } from 'react-i18next';

import CostaRica from './costaRica';
import Paraguay from './paraguay';

function StaticHomepage() {
  const { i18n } = useTranslation();

  if (i18n.language === 'es') return <Paraguay />;

  if (i18n.language === 'es-CR') return <CostaRica />;

  return null;
}

export default StaticHomepage;
