const CPF_BLACKLIST = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

const VERIFICATION_MULTIPLIER = 10;
const VERIFICATION_DIVIDER = 11;

const isDigitValid = (cpf: string, position: number) => {
  const verifiableDigits = cpf.substring(0, position - 1);
  const digitArray = [...verifiableDigits];

  const sum = digitArray.reduce((acc, digit, index) => {
    const multiplier = position - index;
    return acc + parseInt(digit) * multiplier;
  }, 0);

  const rest = (sum * VERIFICATION_MULTIPLIER) % VERIFICATION_DIVIDER;
  const verifiedDigit = rest >= VERIFICATION_MULTIPLIER ? 0 : rest;

  return verifiedDigit === parseInt(cpf[position - 1]);
};

export default function isCPFValid(cpf: string) {
  if (/[a-zA-Z]/.test(cpf)) return false;

  const trimmedCpf = cpf.replace(/[^\d]+/g, '');

  if (CPF_BLACKLIST.includes(trimmedCpf)) return false;
  if (!isDigitValid(trimmedCpf, 10)) return false;
  if (!isDigitValid(trimmedCpf, 11)) return false;

  return true;
}
