import { IFetchVerificationCodeAvailabilityDTO } from 'shared/domain/dtos';
import { IVerificationCodeAvailabilityRepository } from 'shared/domain/repositories';

export default class FetchVerificationCodeAvailabilityUseCase {
  constructor(
    private verificationCodeAvailabilityRepository: IVerificationCodeAvailabilityRepository,
  ) {}

  public async execute(params: IFetchVerificationCodeAvailabilityDTO) {
    return this.verificationCodeAvailabilityRepository.find(params);
  }
}
