/* eslint-disable no-undef */
//! moment.js locale configuration

(function (global, factory) {
  typeof exports === 'object' &&
  typeof module !== 'undefined' &&
  typeof require === 'function'
    ? factory(require('moment'))
    : typeof define === 'function' && define.amd
    ? define(['moment'], factory)
    : factory(global.moment);
})(this, moment => {
  const monthsShort = 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split(
    '_',
  );

  const monthsParse = [
    /^Ene/i,
    /^Feb/i,
    /^Mar/i,
    /^Abr/i,
    /^May/i,
    /^Jun/i,
    /^Jul/i,
    /^Ago/i,
    /^Sep/i,
    /^Oct/i,
    /^Nov/i,
    /^Dic/i,
  ];
  const monthsRegex =
    /^(Enero|Febrero|Marzo|Abril|Mayo|Junio|Julio|Agosto|Septiembre|Octubre|Noviembre|Diciembre|Ene\.?|Feb\.?|Mar\.?|Abr\.?|May\.?|Jun\.?|Jul\.?|Ago\.?|Sep\.?|Oct\.?|Nov\.?|Dic\.?)/i;

  return moment.defineLocale('es', {
    months:
      'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
        '_',
      ),
    monthsShort(m, format) {
      if (!m) {
        return monthsShort;
      }
      if (/-MMM-/.test(format)) {
        return monthsShort[m.month()];
      }
      return monthsShort[m.month()];
    },
    monthsRegex,
    monthsShortRegex: monthsRegex,
    monthsStrictRegex:
      /^(Enero|Febrero|Marzo|Abril|Mayo|Junio|Julio|Agosto|Septiembre|Octubre|Noviembre|Diciembre)/i,
    monthsShortStrictRegex:
      /^(Ene\.?|Feb\.?|Mar\.?|Abr\.?|May\.?|Jun\.?|Jul\.?|Ago\.?|Sep\.?|Oct\.?|Nov\.?|Dic\.?)/i,
    monthsParse,
    longMonthsParse: monthsParse,
    shortMonthsParse: monthsParse,
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'MM/DD/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY h:mm A',
      LLLL: 'dddd, D [de] MMMM [de] YYYY h:mm A',
    },
    calendar: {
      sameDay() {
        return `[hoy a la${hours() !== 1 ? 's' : ''}] LT`;
      },
      nextDay() {
        return `[mañana a la${hours() !== 1 ? 's' : ''}] LT`;
      },
      nextWeek() {
        return `dddd [a la${hours() !== 1 ? 's' : ''}] LT`;
      },
      lastDay() {
        return `[ayer a la${hours() !== 1 ? 's' : ''}] LT`;
      },
      lastWeek() {
        return `[el] dddd [pasado a la${hours() !== 1 ? 's' : ''}] LT`;
      },
      sameElse: 'L',
    },
    relativeTime: {
      future: 'en %s',
      past: 'hace %s',
      s: 'unos segundos',
      ss: '%d segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un día',
      dd: '%d días',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
    dayOfMonthOrdinalParse: /\d{1,2}º/,
    ordinal: '%dº',
    week: {
      dow: 0, // Sunday is the first day of the week.
      doy: 6, // The week that contains Jan 6th is the first week of the year.
    },
  });
});
