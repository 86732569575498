import { useParams } from 'react-router-dom';

import { TRouteParams, TRoutePaths } from '../models';

type TUseTRouteParams<T extends TRoutePaths> = TRouteParams<T> extends never
  ? Record<string, never>
  : TRouteParams<T>;

export default function useRouteParams<
  T extends TRoutePaths,
>(): TUseTRouteParams<T> {
  return useParams() as TUseTRouteParams<T>;
}
