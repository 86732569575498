import React from 'react';

import { useLocale } from 'shared/presentation/contexts';
import { useTimeLeft } from 'shared/presentation/hooks';

import * as S from './styles';

const config = {
  launch: new Date('03/01/2023 23:59:59'),
  end: new Date('03/22/2023 23:59:59'),
};

interface IBlackNovemberCountdownProps {
  maxWidth?: number;
}

const BlackNovemberCountdown: React.FC<IBlackNovemberCountdownProps> = ({
  maxWidth,
}) => {
  const { t } = useLocale('shared');
  const { remaining, finished } = useTimeLeft(config.end.toISOString());

  const today = new Date();

  if (today < config.launch || finished) return null;

  return (
    <S.Container maxWidth={maxWidth}>
      <h2>{t('CUSTOMER WEEK ⚡ BUY A COMBO AND GET A FREE AREA!')}</h2>

      <ul>
        {remaining.days > 0 && (
          <li>
            <span>{remaining.days}</span>
            <strong>{t('Day', { count: remaining.days })}</strong>
          </li>
        )}

        <li>
          <span>{remaining.hours}</span>
          <strong>{t('Hour', { count: remaining.hours })}</strong>
        </li>

        <li>
          <span>{remaining.minutes}</span>
          <strong>{t('Minutes')}</strong>
        </li>

        <li>
          <span>{remaining.seconds}</span>
          <strong>{t('Seconds')}</strong>
        </li>
      </ul>
    </S.Container>
  );
};

export default BlackNovemberCountdown;
