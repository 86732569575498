import { FC } from 'react';

import * as S from './styles';

const DEFAULT_SIZE = 1.5;

interface ISpinnerProps {
  // The spinner size em rem
  size?: number;
  color?: string;
}

const Spinner: FC<ISpinnerProps> = ({
  size = DEFAULT_SIZE,
  color = '#FFF',
}) => (
  <S.Svg className="spinner" viewBox="25 25 50 50" size={size} color={color}>
    <circle cx="50" cy="50" r="20"></circle>
  </S.Svg>
);

export default Spinner;
