import IParsingProvider from 'shared/domain/providers/IParsingProvider';

export default class JsonParsingProvider implements IParsingProvider {
  public parse<T = unknown>(text: string): T {
    return JSON.parse(text);
  }

  public stringify(data: unknown): string {
    return JSON.stringify(data);
  }
}
