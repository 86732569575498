import { IRegisterMicroEventDTO } from 'shared/domain/dtos';
import { IMicroEventsRepository } from 'shared/domain/repositories';

export default class RegisterMicroEventUseCase {
  constructor(private microEventsRepository: IMicroEventsRepository) {}

  public async execute({ userId, event }: IRegisterMicroEventDTO) {
    return this.microEventsRepository.create({
      userId,
      event,
      channel: 'SITE',
    });
  }
}
