import { TFunction } from 'i18next';

import { TLanguage } from 'config/tenancy';
import CheckPhoneValidityUseCase from 'shared/domain/useCases/implementations/CheckPhoneValidityUseCase';
import { COUNTRY_CODES, REGEXES } from 'shared/presentation/constants';

type TParams = {
  value: string | undefined;
  t: TFunction<'shared'>;
  language: TLanguage;
  useCase: CheckPhoneValidityUseCase;
};

const CACHE: Record<string, { lastValidity: boolean; lastReason?: string }> =
  {};

let pendingValue: string | null = null;

export default async function validateCellphone({
  value,
  t,
  useCase,
  language,
}: TParams) {
  if (!value) return { valid: false, finished: true };

  const matches = REGEXES.PHONE[language].test(value);

  if (!matches) {
    return { message: t('Invalid phone'), valid: false, finished: true };
  }

  const countryCode = COUNTRY_CODES[language];

  const formattedValue = countryCode + value?.replace(/\D/g, '');

  const cache = CACHE[formattedValue];

  if (cache) {
    if (cache.lastReason) {
      return { message: cache.lastReason, valid: false, finished: true };
    }

    return { valid: cache.lastValidity, finished: true };
  }

  if (pendingValue === value) return { valid: true };

  pendingValue = value;

  try {
    const validity = await useCase.execute({
      phone: formattedValue,
    });

    CACHE[formattedValue] = {
      lastValidity: validity.valid,
      lastReason: validity.reason,
    };

    if (validity.reason) {
      return { message: validity.reason, valid: false, finished: true };
    }

    return { valid: validity.valid, finished: true };
  } catch (error) {
    return { valid: false, finished: true };
  }
}
