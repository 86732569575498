import { makeItemsRepository } from 'shared/infra/repositories';

import FetchItemsUseCase from './implementations/FetchItemsUseCase';

let instance: FetchItemsUseCase | null = null;

export default function makeFetchItemsUseCase() {
  if (!instance) {
    const itemsRepository = makeItemsRepository();

    instance = new FetchItemsUseCase(itemsRepository);
  }

  return instance;
}
