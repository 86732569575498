type TItem = { valorEcommerce: number; valorTabela: number };

export function calculateCartValue(items: TItem[]) {
  const ecommerceValue = items
    .map(item => item.valorEcommerce)
    .reduce((soma, valor) => soma + valor, 0);

  const businessValue = items
    .map(item => item.valorTabela)
    .reduce((soma, valor) => soma + valor, 0);

  return {
    ecommerceValue,
    businessValue,
  };
}
