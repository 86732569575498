export const components = {
  '* The time is up, send another code to continue':
    '* El tiempo se terminó, envíe otro código para continuar.',
  'All rights reserved to Vialaser Servicos Esteticos S.A - {{year}}':
    'Todos los derechos reservados a Vialaser Servicios Estéticos S.A - {{year}}',
  'Almost there!': '¡Casi allí!',
  'App at appstore': 'App en appstore',
  'App at google play': 'App en google play',
  'Attention!': '¡Atención!',
  'Be franchised': 'Conviértete en franquiciado',
  Blog: 'Blog',
  'Buy now': 'Comprar ahora',
  Cart: 'Cesto',
  'Choose other sending option': 'Escoger otra opción de envío',
  'Citizen Service': 'Atención Ciudadana',
  'Click here to sign in!': '¡Haz clic aquí para registrarte!',
  Close: 'Cerrar',
  Combos: 'Combos',
  Contact: 'Contacto',
  Contracts: 'Contratos',
  'Credit card': 'Tarjeta de crédito',
  'Create your account': 'Crea tu cuenta',
  'Customer area': 'Área del cliente',
  'CUSTOMER WEEK ⚡ BUY A COMBO AND GET A FREE AREA!':
    'SEMANA DEL CONSUMIDOR ⚡ ¡COMPRA UN COMBO Y OBTÉN ÁREA GRATIS!',
  Day_one: 'Día',
  Day_other: 'Días',
  "Don't have a Vialaser account yet?":
    '¿Todavía no tienes tu cuenta en Vialaser?',
  'E-mail': 'Correo electrónico',
  Enable: 'Habilitar',
  Enter: 'Entrar',
  Exit: 'Salir',
  'Find a clinic': 'Encuentra una clínica',
  'Follow Vialaser at our Social Networks':
    'Sigue a Vialaser en nuestras Redes Sociales:',
  'Hello,<1></1>{{name}}!': '¡Hola,<1></1>{{name}}!',
  Home: 'Inicio',
  Hour_one: 'Hora',
  Hour_other: 'Horas',
  "If you have any questions, I'm here!": 'Cualquier duda, estoy aquí!',
  "I'm interested in knowing more about laser hair removal promotions":
    'Tengo interés en saber más sobre promociones de depilación láser',
  'Loading terms': 'Cargando terminos...',
  Minutes: 'Minutos',
  'My data': 'Mis datos',
  'My recommendations': 'Mis recomendaciones',
  Next: 'Próximo',
  'Our technology': 'Nuestra tecnología',
  Packages: 'Paquetes',
  'Payment methods': 'Formas de pago',
  'Privacy policies': 'Políticas de privacidad',
  'Please, contact an attendant to solve the issue':
    'Comuníquese con un representante de servicio al cliente para resolver el problema.',
  'Recommend and Win!': 'Indique y Gane!',
  'Recommend your friends and gain priceless discounts':
    'Indica a tus amigos y gane descuentos imperdíbles',
  'Recurrent card': 'Tarjeta recurrente',
  'Sales center': 'Centro de Ventas',
  'Schedule session': 'Programar sesión',
  Schedulings: 'Cítas',
  'Search packages at Vialaser': 'Buscar paquetes en Vialaser',
  Seconds: 'Segundos',
  'Send again': 'Enviar de nuevo',
  'Send again in {{count}}s': 'Enviar de nuevo en {{count}}s',
  Sessions: 'Sesuibes',
  'Sign up': 'Iniciar sesión',
  'Something unexpected happened!': '¡Algo inesperado sucedió!',
  'Terms of use': 'Terminos de uso',
  'The code is valid only for {{count}} minutes_one':
    'El código sólo es válido durante {{count}} minuto.',
  'The code is valid only for {{count}} minutes_other':
    'El código sólo es válido durante {{count}} minutos.',
  'There is no channel available for sending you the code':
    'No hay ningún canal disponible para enviarle el código de validación',
  'There was an error finding the accession terms':
    'Se produjo un error al obtener los términos de adhesión.',
  'Time left': 'Tiempo restante:',
  'To be able to sign you must enable the real time location of your browser':
    'Para poder suscribirse, debe habilitar la ubicación en tiempo real en su navegador..',
  'Type the last code you received in your email {{email}}':
    'Digite el último código que llegó por e-mail a {{email}}.',
  'Type the last code you received in your whatsapp with final number of {{phone}}':
    'Digite el último código que llegó por WhatsApp al número {{phone}}.',
  'Type the last code you received in your phone with final number of {{phone}}':
    'Digite el último código que llegó por SMS al número {{phone}}.',
  'Where to find': 'Dónde encontrar',
  'Who we are': 'Quienes somos',
  'Work with us': 'Trabaja con nosotros',
  'Validate code': 'Validar codigo',
  Validation: 'Validación',
  'Verification code': 'Código de verificación',
  'Vialaser - Hair removal clinic with exclusive technology':
    'Vialaser - Clínica de depilación láser con tecnología exclusiva',
  Visitor: 'Visitante',

  'Ops something went wrong!': 'Ops... Algo salió mal',
  'Something did not worked as expected Try again realoding the page or go to the home':
    'Alguna cosa no funcionó como esperamos. Intenta recargar la página o vuelva al inicio',
  'Refresh page': 'Recargar la página',
  'Go to home': 'Vuelva al inicio',

  'To update your contact data <1>click here</1>':
    'Para cambiar sus datos de contacto haga <1>click aquí.</1>',

  hour_one: 'hora',
  hour_other: 'horas',
  minute_one: 'minuto',
  minute_other: 'minutos',
  second_one: 'segundo',
  second_other: 'segundos',
};
