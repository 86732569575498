import styled, { css } from 'styled-components';

export const Container = styled.section<{ type: 'viewport' | 'flex' }>`
  ${({ type }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    ${type === 'viewport'
      ? css`
          height: 100vh;
          width: 100vw;
        `
      : css`
          flex: 1;
        `}
  `}
`;
