import { ComponentPropsWithoutRef } from 'react';

import { TFunction } from 'i18next';

import { ContractTag } from 'modules/customer/presentation/components/atoms';
import { ITheme } from 'shared/presentation/contexts';

type TDefaultTagParams = {
  t: TFunction<'customer'>;
  theme: ITheme;
  contract: { nextInstallment: string };
};

type TContractTagProps = ComponentPropsWithoutRef<typeof ContractTag>;

export const CONTRACT_TAGS = {
  PAID: (params: TDefaultTagParams): TContractTagProps => ({
    color: params.theme.palette.primary.main,
    children: params.t('Paid_installments'),
  }),
  PENDING: (params: TDefaultTagParams): TContractTagProps => ({
    color: params.theme.palette.secondary.main,
    variant: 'outlined',
    children: params.t('Next installment {{date}}', {
      date: params.contract.nextInstallment || '???',
    }),
  }),
  OVERDUE: (params: TDefaultTagParams): TContractTagProps => ({
    color: params.theme.palette.error.main,
    children: params.t('Late installments'),
  }),
};
