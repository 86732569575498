import { makeCustomersRepository } from 'shared/infra/repositories';

import CreateCustomerUseCase from './implementations/CreateCustomerUseCase';

let instance: CreateCustomerUseCase | null;

export default function makeCreateCustomerUseCase() {
  if (!instance) {
    const repository = makeCustomersRepository();

    instance = new CreateCustomerUseCase(repository);
  }

  return instance;
}
