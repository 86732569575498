import { lighten } from 'polished';
import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.nav`
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.textBright};

  display: flex;
  justify-content: space-around;
  padding: 0.5rem;

  font-size: 1.375rem;

  .links {
    display: flex;
    gap: 1.25rem;
    align-items: center;

    a {
      color: ${theme.colors.textBright};

      text-align: center;

      padding: 0.5rem 0;

      &:hover {
        color: ${lighten(0.3, theme.colors.secondary)};
      }
    }

    .verticalLine {
      height: 100%;
      border-left: 2px solid ${theme.colors.textBright};
    }
  }

  .icons {
    display: flex;
    align-items: center;

    gap: 0.5rem;

    a {
      display: flex;

      svg {
        width: 2rem;
        height: 2rem;

        transition: color 0.2s;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    .icons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      span {
        display: none;
      }
    }
  }
`;
