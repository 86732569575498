import { IUserBenefitsRepository } from 'shared/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisUserBenefitsRepository from './implementations/VectisUserBenefitsRepository';

let instance: IUserBenefitsRepository | null;

export default function makeUserBenefitsRepository() {
  if (!instance) {
    const provider = makeHTTPProvider();

    instance = new VectisUserBenefitsRepository(provider);
  }

  return instance;
}
