import styled, { css } from 'styled-components';

export const Container = styled.footer`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.layout.spacing(2)};

    text-align: center;

    color: ${theme.palette.primary.main};
    font-family: ${theme.typography.family};
    font-size: 1.375rem;

    gap: ${theme.layout.spacing(2)};

    a {
      padding: ${theme.layout.spacing(2, 4)};
      border: 1px solid ${theme.palette.primary.main};
      font-size: 0.75rem;
      width: 20rem;
    }
  `}
`;
