import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  background-color: ${theme.colors.primary};

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 5rem 0;

    li {
      width: 80rem;

      h5 {
        color: ${theme.colors.secondary};
        font-size: 1.375rem;
      }

      p {
        font-size: 1.375rem;
        color: ${theme.colors.textBright};
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    ul {
      padding: 2rem;

      li {
        width: 100%;
      }
    }
  }
`;
