export const checkoutCustomerRegistration = {
  CITY_PLACEHOLDER: 'Ex: San José',
  DOCUMENT_PLACEHOLDER: '0.0000.0000',
  PHONE_PLACEHOLDER: '0000 0000',
  Document: 'CI / DIMEX',
  "Inform the legal guardian's document":
    'Libere la CI o DIMEX del responsable legal',
  'Legal guardian document': 'CI o DIMEX del responsable legal',
  'Please, inform a document': 'Por favor, libere una CI o DIMEX',
};
