import { makeAddressRepository } from 'shared/infra/repositories';

import FetchAddressByPostalCodeUseCase from './implementations/FetchAddressByPostalCodeUseCase';

let instance: FetchAddressByPostalCodeUseCase | null = null;

export default function makeFetchAddressByPostalCodeUseCase() {
  if (!instance) {
    const repository = makeAddressRepository();

    instance = new FetchAddressByPostalCodeUseCase(repository);
  }

  return instance;
}
