import { makeCustomersRepository } from 'shared/infra/repositories';

import FindCustomerByDocumentUseCase from './implementations/FindCustomerByDocumentUseCase';

let instance: FindCustomerByDocumentUseCase | null = null;

export default function makeFindCustomerByDocumentUseCase() {
  if (!instance) {
    const repository = makeCustomersRepository();

    instance = new FindCustomerByDocumentUseCase(repository);
  }

  return instance;
}
