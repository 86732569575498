import React, {
  useImperativeHandle,
  useRef,
  useState,
  ComponentRef,
  forwardRef,
} from 'react';

import { Document, Page } from 'react-pdf';

import { Modal, Spinner } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale, useTheme, useToast } from 'shared/presentation/contexts';
import { useMediaQuery } from 'shared/presentation/hooks';

import { useContractTermsLink, useWindowSize } from './hooks';
import * as S from './styles';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

interface IAcceptanceTermsModalRef {
  open(): void;
}

interface IAcceptanceTermsModalProps {
  budgetId: number | undefined;
}

const AcceptanceTermsModal: React.ForwardRefRenderFunction<
  IAcceptanceTermsModalRef,
  IAcceptanceTermsModalProps
> = ({ budgetId }, ref) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);
  const controller = useContractTermsLink({ budgetId });
  const { theme } = useTheme();

  const { t } = useLocale('shared');
  const [pages, setPages] = useState(0);
  const [error, setError] = useState(false);

  const isSm = useMediaQuery('md');
  const windowSize = useWindowSize();
  const toast = useToast();

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        if (!isSm) return modalRef.current?.open();

        if (!controller.link) return toast.info(t('Loading terms'));

        if (error || !controller.link) {
          return toast.error(
            t('There was an error finding the accession terms'),
          );
        }

        return window.open(controller.link, '_blank');
      },
    }),
    [controller.link, t, toast, isSm, error],
  );

  if (controller.loading) {
    return (
      <Modal ref={modalRef}>
        <S.Container>
          <S.InfoAndActionsContainer full>
            <Spinner color={theme.palette.primary.main} size={3} />

            <Button
              variant="primary"
              outline
              type="button"
              onClick={() => modalRef.current?.close()}
            >
              {t('Close')}
            </Button>
          </S.InfoAndActionsContainer>
        </S.Container>
      </Modal>
    );
  }

  if (error || !controller.link) {
    return (
      <Modal ref={modalRef}>
        <S.Container>
          <S.InfoAndActionsContainer full>
            <p>{t('There was an error finding the accession terms')}</p>

            <Button
              variant="primary"
              outline
              type="button"
              onClick={() => modalRef.current?.close()}
            >
              {t('Close')}
            </Button>
          </S.InfoAndActionsContainer>
        </S.Container>
      </Modal>
    );
  }

  return (
    <Modal ref={modalRef}>
      <S.Container>
        <Document
          className="document"
          loading={() => (
            <S.InfoAndActionsContainer>
              <Spinner size={3} color={theme.palette.primary.main} />
            </S.InfoAndActionsContainer>
          )}
          onLoadError={() => setError(true)}
          onLoadSuccess={data => {
            setPages(data.numPages);
            setError(false);
          }}
          file={controller.link}
        >
          {Array.from({ length: pages }, (_, index) => (
            <Page
              key={index}
              pageNumber={index + 1}
              width={isSm ? windowSize.width : undefined}
            />
          ))}
        </Document>

        <S.InfoAndActionsContainer>
          <Button
            variant="primary"
            outline
            type="button"
            onClick={() => modalRef.current?.close()}
          >
            {t('Close')}
          </Button>
        </S.InfoAndActionsContainer>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(AcceptanceTermsModal);
