import { ISession } from 'shared/domain/entities';

export interface IVectisSession {
  id: number;
  nomeAplicadora: string;
  fotoAplicadora?: string;
  avaliouNps?: boolean;
  data: string;
  hora: string;
  areasString: string;
  areas: string[];
  unidade: string;
  hashAgenda?: string;
}

export class Session implements ISession {
  public id: ISession['id'];
  public idHash: ISession['idHash'];
  public unit: ISession['unit'];
  public applicator: ISession['applicator'];
  public areas: ISession['areas'];
  public date: ISession['date'];
  public time: ISession['time'];
  public hasNpsFeedback: ISession['hasNpsFeedback'];

  constructor({
    id,
    nomeAplicadora,
    fotoAplicadora,
    avaliouNps,
    data,
    hora,
    areas,
    unidade,
    hashAgenda,
  }: IVectisSession) {
    this.id = id;
    this.idHash = hashAgenda;

    this.unit = {
      name: unidade,
    };

    this.applicator = {
      name: nomeAplicadora,
      photo: fotoAplicadora,
    };

    this.areas = areas;
    this.date = data;
    this.time = hora;
    this.hasNpsFeedback = avaliouNps;
  }
}
