import {
  CONTACTS,
  DEFAULT_CUSTOMER_SERVICE_LINK,
  ICONS,
} from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

const PhoneIcon = ICONS.PHONE;

const FooterContact: React.FC = () => {
  const { language } = useLocale('shared');

  const contact = CONTACTS[language];

  return (
    <S.Container>
      <ul>
        <li>
          <a href={DEFAULT_CUSTOMER_SERVICE_LINK}>
            <PhoneIcon />
            {contact}
          </a>
        </li>
      </ul>
    </S.Container>
  );
};

export default FooterContact;
