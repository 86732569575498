export const paymentLink = {
  'Please confirm your document to continue your purchase':
    'Confirme su CI/DIMEX para continuar con su compra.',
  "Please confirm your guardian's document to continue your purchase":
    'Confirme el CI/DIMEX de su responsable legal para continuar con su compra.',
  'Confirm Document': 'Confirmar CI/DIMEX',
  'The document entered does not match the registration number, please contact the unit or try again':
    'El CI/DIMEX ingresado no coincide con el registro, comuníquese con la unidad o intente nuevamente',
  'Type the document here': 'Ingresa el CI/DIMEX aquí',
};
