import IFlagParametersRepository from 'shared/domain/repositories/IFlagParametersRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisFlagParametersRepository from './implementations/VectisFlagParametersRepository';

let instance: IFlagParametersRepository | null = null;

export default function makeFlagParametersRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisFlagParametersRepository(httpProvider);
  }

  return instance;
}
