import styled, { css } from 'styled-components';

const CHECK_STYLES = {
  light: css`
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.neutral[100]};
      border-color: ${theme.palette.neutral[300]};

      &[data-state='checked'] {
        background-color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};
        color: ${theme.palette.text.white};
      }
    `}
  `,
  dark: css`
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.neutral[100]};
      border-color: ${theme.palette.neutral[100]};

      &[data-state='checked'] {
        border-color: ${theme.palette.primary.main};
      }
    `}
  `,
};

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 1rem;
    align-items: center;

    > button {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.5rem;
      flex-shrink: 0;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid ${theme.palette.neutral[100]};
      transition: border-color 0.2s, color 0.2s;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ${CHECK_STYLES[theme.name]}
    }

    label {
      cursor: pointer;
    }
  `}
`;
