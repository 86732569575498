import { PAYMENT_TYPES } from 'constants/PaymentTypes';

import {
  defaultPaymentMethodFor,
  fetchPaymentMethods,
} from 'contexts/core/paymentMethods';

type TParams = {
  paymentType: string;
};

export async function getPaymentMethods({ paymentType }: TParams) {
  try {
    const res: { formaPagamento: string }[] = await fetchPaymentMethods(
      'CONTRATO',
      'vialaser',
    );
    const defaultMethod = Object.values(PAYMENT_TYPES).reduce(
      (object, value) => {
        const method = defaultPaymentMethodFor(value, res);

        return method
          ? {
              ...object,
              [value]: method,
            }
          : object;
      },
      {},
    );

    const methods = res.reduce(
      (acc, method) => ({
        ...acc,
        [paymentType]: {
          ...acc[paymentType],
          [method.formaPagamento]: method,
        },
      }),
      {} as Record<string, (typeof res)[number]>,
    );

    return {
      defaultPaymentMethods: defaultMethod,
      paymentMethods: methods,
    };
  } catch (error) {
    console.error(error);

    return {};
  }
}
