import React from 'react';

import { useModal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { ICONS } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';

const NoChannels: React.FC = () => {
  const { t } = useLocale('shared');
  const modal = useModal();

  return (
    <>
      <div className="modal__header">
        <h2 className="modal__title">{t('Something unexpected happened!')}</h2>
      </div>

      <div className="modal__content">
        <p className="modal__message">
          {t('There is no channel available for sending you the code')}
        </p>
        <p className="modal__message">
          {t('Please, contact an attendant to solve the issue')}
        </p>
      </div>

      <div className="modal__footer">
        <Button icon={ICONS.X} iconPosition="right" onClick={modal.close}>
          {t('Close')}
        </Button>
      </div>
    </>
  );
};

export default NoChannels;
