import IEventTrackerProvider from 'shared/domain/providers/IEventTrackerProvider';
import makeHTTPProvider from 'shared/infra/providers/makeHTTPProvider';
import makeStorageProvider from 'shared/infra/providers/makeStorageProvider';

import DataLayerEventTrackerProvider from './implementations/DataLayerEventTrackerProvider';
import LogEventTrackerProvider from './implementations/LogEventTrackerProvider';
import MetaEventTrackerProvider from './implementations/MetaEventTrackerProvider';

type TEventTracker = 'DATA_LAYER' | 'META' | 'LOG';

const instances = new Map<TEventTracker, IEventTrackerProvider>();

const FACTORIES: Record<TEventTracker, () => IEventTrackerProvider> = {
  META: () => new MetaEventTrackerProvider(),
  LOG: () => new LogEventTrackerProvider(),
  DATA_LAYER: () => {
    const httpProvider = makeHTTPProvider({
      source: 'METRICS',
    });
    const storageProvider = makeStorageProvider();

    return new DataLayerEventTrackerProvider(httpProvider, storageProvider);
  },
};

type TParams = {
  type?: Exclude<TEventTracker, 'LOG'>;
};

export default function makeEventTrackerProvider({
  type = 'DATA_LAYER',
}: TParams = {}): IEventTrackerProvider {
  const trackerType: TEventTracker =
    process.env.NODE_ENV === 'production' ? type : 'LOG';

  if (!instances.has(trackerType)) {
    const factory = FACTORIES[trackerType];

    instances.set(trackerType, factory());
  }

  return instances.get(trackerType)!;
}
