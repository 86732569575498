import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  gridArea: 'bottom',
  '& .slick-slider': {
    width: '60%',
    margin: '0 auto',
    '& .slick-slide': {
      padding: '0 10px',
    },
    '& .slick-prev, .slick-next': {
      display: 'none !important',
    },
    '& .slick-dots': {
      bottom: '5px',
      '& li button::before': {
        fontSize: '10px',
        color: theme.palette.primary.light,
        opacity: 0.5,
      },
      '& li.slick-active button::before': {
        color: theme.palette.primary.main,
        opacity: 0.7,
      },
    },
    '& > div': {
      height: 165,
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('455')]: {
      width: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
  },
}));

export const CardInfo = styled('div')(({ theme }) => ({
  background: theme.palette.cardsBackground.primary,
  color: theme.palette.text.primary,
  height: '100px',
  width: '370px',
  borderRadius: '20px',
  padding: '15px',
  paddingLeft: '0',
  display: 'flex !important',
  alignItems: 'center',
  textAlign: 'left',
  boxShadow: '0px 10px 14px 4px rgba(0,0,0,0.1)',
  '& .description': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1.25rem',
      fontWeight: 500,
      margin: '0px 0px 0px -40px',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '70% !important',
    margin: '0 auto',
  },
  [theme.breakpoints.down('758')]: {
    height: '150px',
    width: '90% !important',
    padding: '15px !important',
    '& .content': {
      height: '110px',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  [theme.breakpoints.down('xs')]: {
    height: '150px !important',
    width: '95% !important',
    paddingLeft: '0 !important',
  },
  [theme.breakpoints.down('md')]: {
    width: '70% !important',
    margin: '0 auto',
  },
  [theme.breakpoints.down('758')]: {
    height: '150px',
    width: '90% !important',
    padding: '15px !important',
    '& .content': {
      height: '110px',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  [theme.breakpoints.down('xs')]: {
    height: '150px !important',
    width: '95% !important',
    paddingLeft: '0 !important',
    transform: 'scale(0.9)',
  },
  [theme.breakpoints.down('380')]: {
    width: '320px !important',
  },
  '& .more-info': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'center',
    margin: '5px',
    top: '3px',
    '& button': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '40px',
      border: 0,
      borderRadius: '50%',
      background: theme.palette.primary.main,
      color: theme.palette.cardsBackground.primary,
      fontSize: '1.5rem',
      boxShadow: '0 4px 10px 1px rgba(0,0,0,0.3)',
      cursor: 'pointer',
      outline: 'none',
      transition: 'filter  0.3s',
      '&:hover': {
        filter: 'brightness(90%)',
      },
    },
  },
}));

export const Image = styled('div')(() => ({
  height: '100%',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '20px',
}));
