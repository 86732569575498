import { ReactChild } from 'react';

import { HashLink } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';

import { Link, TRoutePathsWithParams } from 'shared/presentation/contexts';

import * as S from './styles';

interface INavigationMenuProps {
  items: Array<
    {
      title: string;
      content: ReactChild;
      onClick?: () => void;
    } & (
      | {
          type: 'default';
          to: TRoutePathsWithParams;
        }
      | {
          type: 'external' | 'scroll' | 'hash';
          to: string;
        }
    )
  >;
}

const NavigationMenu: React.FC<INavigationMenuProps> = ({ items }) => (
  <S.Container>
    {items.map(({ content, title, to, onClick, type = 'default' }) => {
      if (type === 'external') {
        return (
          <a
            key={title}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={title}
            onClick={onClick}
          >
            {content}
          </a>
        );
      }

      if (type === 'scroll') {
        return (
          <ScrollLink key={title} to={to} aria-label={title} onClick={onClick}>
            {content}
          </ScrollLink>
        );
      }

      if (type === 'hash') {
        return (
          <HashLink key={title} to={to} aria-label={title} onClick={onClick}>
            {content}
          </HashLink>
        );
      }

      return (
        <Link
          key={title}
          to={to as TRoutePathsWithParams}
          aria-label={title}
          onClick={onClick}
        >
          {content}
        </Link>
      );
    })}
  </S.Container>
);

export default NavigationMenu;
