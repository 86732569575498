export const components = {
  '* The time is up, send another code to continue':
    '* O tempo acabou, envie outro código para poder continuar.',
  'All rights reserved to Vialaser Servicos Esteticos S.A - {{year}}':
    'Todos os direitos reservados a Vialaser Serviços Estéticos S.A - {{year}}',
  'Almost there!': 'Quase lá!',
  'App at appstore': 'App na appstore',
  'App at google play': 'App na google play',
  'Attention!': 'Atenção!',
  'Be franchised': 'Seja franqueado',
  Blog: 'Blog',
  'Buy now': 'Comprar agora',
  Cart: 'Carrinho',
  'Choose other sending option': 'Escolher outra opção de envio',
  'Citizen Service': 'Central de Atendimento',
  'Click here to sign in!': 'Clique aqui para se cadastrar!',
  Close: 'Fechar',
  Combos: 'Combos',
  Contact: 'Contato',
  Contracts: 'Contratos',
  'Credit card': 'Cartão de crédito',
  'Create your account': 'Crie sua conta',
  'Customer area': 'Área do cliente',
  'CUSTOMER WEEK ⚡ BUY A COMBO AND GET A FREE AREA!':
    'SEMANA DO CONSUMIDOR ⚡ COMPRE UM COMBO E GANHE ÁREA GRÁTIS!',
  Day_one: 'Dia',
  Day_other: 'Dias',
  "Don't have a Vialaser account yet?": 'Ainda não possui sua conta Vialaser?',
  'E-mail': 'E-mail',
  Enable: 'Habilitar',
  Enter: 'Entrar',
  Exit: 'Sair',
  'Find a clinic': 'Encontre uma clínica',
  'Follow Vialaser at our Social Networks':
    'Siga a Vialaser nas nossas Redes Sociais:',
  'Hello,<1></1>{{name}}!': 'Olá,<1></1>{{name}}!',
  Home: 'Início',
  Hour_one: 'Hora',
  Hour_other: 'Horas',
  "If you have any questions, I'm here!": 'Qualquer dúvida, estou aqui!',
  "I'm interested in knowing more about laser hair removal promotions":
    'Tenho interesse em saber mais sobre promoções de depilação a laser',
  'Loading terms': 'Carregando termos...',
  Minutes: 'Minutos',
  'My data': 'Meus dados',
  'My recommendations': 'Minhas indicações',
  Next: 'Próximo',
  'Our technology': 'Nossa tecnologia',
  Packages: 'Pacotes',
  'Payment methods': 'Formas de pagamento',
  'Privacy policies': 'Políticas de privacidade',
  'Please, contact an attendant to solve the issue':
    'Por favor, contate uma atendente para resolver o problema.',
  'Recommend and Win!': 'Indique e Ganhe!',
  'Recommend your friends and gain priceless discounts':
    'Indique seus amigos e ganhe descontos imperdíveis',
  'Recurrent card': 'Cartão recorrente',
  'Sales center': 'Central de Vendas',
  'Schedule session': 'Agendar sessão',
  Schedulings: 'Agendamentos',
  'Search packages at Vialaser': 'Buscar pacotes na Vialaser',
  Seconds: 'Segundos',
  'Send again': 'Enviar novamente',
  'Send again in {{count}}s': 'Enviar novamente em {{count}}s',
  Sessions: 'Sessões',
  'Sign up': 'Fazer login',
  'Something unexpected happened!': 'Aconteceu algo inesperado!',
  'Terms of use': 'Termos de uso',
  'The code is valid only for {{count}} minutes_one':
    'O código é válido apenas por {{count}} minuto.',
  'The code is valid only for {{count}} minutes_other':
    'O código é válido apenas por {{count}} minutos.',
  'There is no channel available for sending you the code':
    'Não há nenhum canal disponível para enviar o código de validação para você.',
  'There was an error finding the accession terms':
    'Houve um erro ao buscar os termos de adesão.',
  'Time left': 'Falta apenas:',
  'To be able to sign you must enable the real time location of your browser':
    'Para poder assinar voce precisa habilitar a localização em tempo real do seu navegador.',
  'Type the last code you received in your email {{email}}':
    'Digite o último código que chegou via e-mail para {{email}}.',
  'Type the last code you received in your whatsapp with final number of {{phone}}':
    'Digite o último código que chegou via WhatsApp para o número de final {{phone}}.',
  'Type the last code you received in your phone with final number of {{phone}}':
    'Digite o último código que chegou via SMS para o número de final {{phone}}.',
  'Where to find': 'Onde encontrar',
  'Who we are': 'Quem somos',
  'Work with us': 'Trabalhe conosco',
  'Validate code': 'Validar código',
  Validation: 'Validação',
  'Verification code': 'Código de verificação',
  'Vialaser - Hair removal clinic with exclusive technology':
    'Vialaser - Clínica de depilação a Laser com tecnologia exclusiva',
  Visitor: 'Visitante',

  'Ops something went wrong!': 'Ops... algo deu errado!',
  'Something did not worked as expected Try again realoding the page or go to the home':
    'Alguma coisa não funcionou como esperado. Tente recarregar a página ou volte para o início.',
  'Refresh page': 'Recarregar página',
  'Go to home': 'Voltar para o início',

  'To update your contact data <1>click here</1>':
    'Para alterar seus dados de contato <1>clique aqui.</1>',

  hour_one: 'hora',
  hour_other: 'horas',
  minute_one: 'minuto',
  minute_other: 'minutos',
  second_one: 'segundo',
  second_other: 'segundos',
};
