import { makeCustomersRepository } from 'shared/infra/repositories';

import UpdateCustomerUseCase from './implementations/UpdateCustomerUseCase';

let instance: UpdateCustomerUseCase | null = null;

export default function makeUpdateCustomerUseCase() {
  if (!instance) {
    const customersRepository = makeCustomersRepository();

    instance = new UpdateCustomerUseCase(customersRepository);
  }

  return instance;
}
