import {
  TCodeVerificationProccessType,
  TChannelType,
  IVerificationCode,
} from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IVerificationCodeRepository } from 'shared/domain/repositories';
import {
  TCreateParams,
  TFindByCodeParams,
} from 'shared/domain/repositories/IVerificationCodeRepository';

interface IVectisVerificationCode {
  dataExpiracaoCodigo: string;
  enviadoPara: string;
  dataHoraParaReenvio: string;
  mensagem?: string;
}

const PROCCESS_TRANSLATION: Record<TCodeVerificationProccessType, string> = {
  ADDITIVE: 'GERACAO_ADITIVO_CONTRATO',
  SCHEDULE: 'CADASTRO_AGENDA',
  CHECKOUT: 'ASSINATURA_CONTRATO',
};

const CHANNEL_TRANSLATION: Record<TChannelType, string> = {
  WHATSAPP: 'WHATSAPP',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

export default class VectisVerificationCodeRepository
  implements IVerificationCodeRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public async findByCode(
    params: TFindByCodeParams,
  ): Promise<{ id: number; valid: boolean; message?: string }> {
    const data = await this.httpProvider.post<{
      idLogTentativaValidacaoCodigoConfirmacao: number;
      valido: boolean;
      mensagem?: string;
    }>('/codigoConfirmacao/validar', {
      idEntidade: params.entityId,
      processo: PROCCESS_TRANSLATION[params.proccess],
      codigo: params.code,
    });

    return {
      id: data.idLogTentativaValidacaoCodigoConfirmacao,
      valid: data.valido,
      message: data.mensagem,
    };
  }

  public async create(params: TCreateParams): Promise<IVerificationCode> {
    const data = await this.httpProvider.post<IVectisVerificationCode>(
      '/codigoConfirmacao/enviar',
      {
        idEntidade: params.entityId,
        processo: PROCCESS_TRANSLATION[params.proccess],
        canal: CHANNEL_TRANSLATION[params.channel],
      },
    );

    return {
      expiration: {
        toValidateCode: data.dataExpiracaoCodigo,
        toSendAgain: data.dataHoraParaReenvio,
      },
      channelSent: data.enviadoPara,
      message: data.mensagem,
    };
  }
}
