export default async function getCurrentIp() {
  try {
    const data = await fetch('https://api.ipify.org/?format=json').then(
      response => response.json() as Promise<{ ip: string }>,
    );

    return data.ip;
  } catch {
    return undefined;
  }
}
