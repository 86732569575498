import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.layout.spacing(3)};

    strong {
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.weight.extraBold};
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
    }
  `}
`;
