import { ComponentPropsWithoutRef } from 'react';

import { TFunction } from 'i18next';

import { IContract } from 'modules/customer/domain/entities';
import { ContractTag } from 'modules/customer/presentation/components/atoms';
import { CONTRACT_TAGS } from 'modules/customer/presentation/constants';
import { ITheme } from 'shared/presentation/contexts';

type TContractTagProps = ComponentPropsWithoutRef<typeof ContractTag> & {
  key: string;
};

type TParams = {
  contract: IContract;
  t: TFunction<'customer'>;
  theme: ITheme;
};

export default function getContractTags({ contract, t, theme }: TParams) {
  const tags: TContractTagProps[] = [];

  const contractTagsParams = { contract, t, theme };

  const statusTag = CONTRACT_TAGS[contract.status](contractTagsParams);

  tags.push({ ...statusTag, key: contract.status });

  return tags;
}
