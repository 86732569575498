import React from 'react';

import { QUESTIONS } from 'pages/StaticHomepage/constants/faq';

import {
  Banner,
  FAQ,
  Footer,
  Navbar,
  Pitch,
  Promotion,
  Testimonials,
  WhereToFind,
} from '../components';
import { EXTERNAL_LINKS } from '../constants/links';
import { LOCATIONS } from '../constants/locations';
import { NAVBAR_ICONS, NAVBAR_LINKS } from '../constants/navbar';
import { GlobalStyle } from '../globalStyles';
import { Container } from '../styles';

function Paraguay() {
  return (
    <>
      <GlobalStyle />

      <Container>
        <Navbar links={NAVBAR_LINKS.es} icons={NAVBAR_ICONS.es} />

        <Banner />

        <Promotion
          promotion={{ link: EXTERNAL_LINKS.es.PROMOTION, external: true }}
          contactLink={EXTERNAL_LINKS.es.WHATSAPP}
        />

        <Pitch />

        <Navbar links={NAVBAR_LINKS.es} icons={NAVBAR_ICONS.es} />

        <WhereToFind locations={LOCATIONS.es} />

        <Testimonials />

        <Navbar links={NAVBAR_LINKS.es} icons={NAVBAR_ICONS.es} />

        <FAQ questions={QUESTIONS.es} />

        <Navbar links={NAVBAR_LINKS.es} icons={NAVBAR_ICONS.es} />

        <Footer>
          <p>Via Laser Paraguay Sociedad Anónima - 80108897-6</p>
        </Footer>
      </Container>
    </>
  );
}

export default Paraguay;
