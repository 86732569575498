import { AnchorHTMLAttributes, FC, HTMLAttributeAnchorTarget } from 'react';

import { Link as DefaultLink } from 'react-router-dom';

import { TRoutePathsWithParams } from '../../models';

interface ILinkProps
  extends Omit<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    'dangerouslySetInnerHTML'
  > {
  to: TRoutePathsWithParams;
  target?: HTMLAttributeAnchorTarget;
}

const Link: FC<ILinkProps> = ({ to, ...rest }) => (
  <DefaultLink to={to} {...rest} />
);

export default Link;
