import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

const AllRightsFooter: React.FC = () => {
  const { t } = useLocale('shared');

  return (
    <S.Container>
      <small>
        {t(
          'All rights reserved to Vialaser Servicos Esteticos S.A - {{year}}',
          {
            year: new Date().getFullYear(),
          },
        )}
      </small>
    </S.Container>
  );
};

export default AllRightsFooter;
