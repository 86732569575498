import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ul {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 3rem 0 8rem;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 25rem;

      img {
        width: 10rem;
        height: 10rem;
      }

      strong {
        color: ${theme.colors.secondary};
        font-size: 1.75rem;
      }

      p {
        font-size: 1.375rem;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    ul {
      flex-direction: column;
    }
  }
`;
