import styled, { css } from 'styled-components';

export const Container = styled.nav`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    padding: ${theme.layout.spacing(2, 1.5)};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.layout.spacing(2)};

    border-bottom: 1px solid ${theme.palette.neutral[300]};
    background: ${theme.palette.neutral[50]};
  `}
`;

const MENU_MAX_WIDTH = 160;

export const TopMenu = styled.section`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    max-width: ${theme.layout.spacing(MENU_MAX_WIDTH)};
    justify-content: center;
    align-items: center;
    gap: ${theme.layout.spacing(12)};

    > a {
      border: none;
    }
  `}
`;

export const IconMenu = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.layout.spacing(3)};
  `}
`;

export const BottomMenu = styled.section`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    max-width: ${theme.layout.spacing(MENU_MAX_WIDTH)};
    justify-content: space-between;
    align-items: center;
  `}
`;
