import axios from 'axios';

const tracker = async (idClient, actionName) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(' ----- Tracker -----');
    console.log(` - CLIENT ID: ${idClient}`);
    console.log(` - ACTION NAME: ${actionName}`);
    console.log(' - CHANELL: SITE');
    console.log(' -------------------');
  } else {
    try {
      await axios.post('microeventoscliente/registrar', {
        idCliente: idClient,
        microEvento: actionName,
        canal: 'SITE',
      });
    } catch (err) {
      console.error('Could not register micro-event!');
    }
  }
};

export default tracker;
