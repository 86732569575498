import styled, { css } from 'styled-components';

const DEFAULT_SIZES = {
  DESKTOP: 2.25,
  MOBILE: 3,
};

export const Container = styled.div<{ size?: number }>`
  ${({ theme, size }) => css`
    display: flex;
    width: ${size || DEFAULT_SIZES.DESKTOP}rem;
    height: ${size || DEFAULT_SIZES.DESKTOP}rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;
    border-radius: 50%;

    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.text.white};

    font-size: ${theme.typography.sizes.title3};
    font-weight: ${theme.typography.weight.bold};

    @media (max-width: ${theme.layout.breakpoints.md}) {
      width: ${size || DEFAULT_SIZES}rem;
      height: ${size || DEFAULT_SIZES}rem;

      font-size: ${theme.typography.sizes.title2};
    }
  `}
`;
