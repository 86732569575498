import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;

    gap: ${theme.layout.spacing(1.5)};

    a {
      color: ${theme.palette.primary.main};
      transition: all 0.2s;

      svg {
        font-size: 24px;
      }

      &:hover {
        color: ${theme.palette.primary.light};
      }
    }
  `}
`;
