import { IAddress } from 'shared/domain/entities';

export interface IVectisAddress {
  estado: {
    codigo: string;
    nome: string;
  };
  cidade?: {
    id: number;
    nome: string;
  };
  bairro?: {
    id: number;
    nome: string;
  };
  logradouro: string;
  cep: string;
}

export default class Address implements IAddress {
  public state: IAddress['state'];
  public city: IAddress['city'];
  public district: IAddress['district'];
  public street: IAddress['street'];
  public postalCode: IAddress['postalCode'];

  constructor({ estado, cidade, bairro, logradouro, cep }: IVectisAddress) {
    this.state = {
      code: estado.codigo,
      name: estado.nome,
    };

    if (cidade) {
      this.city = {
        id: cidade.id,
        name: cidade.nome,
      };
    }

    if (bairro) {
      this.district = {
        id: bairro.id,
        name: bairro.nome,
      };
    }

    this.street = logradouro;
    this.postalCode = cep;
  }
}
