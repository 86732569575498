import { IFetchCitiesDTO } from 'shared/domain/dtos';
import { ICitiesRepository } from 'shared/domain/repositories';

export default class FetchCitiesUseCase {
  constructor(private citiesRepository: ICitiesRepository) {}

  public async execute({ search }: IFetchCitiesDTO) {
    return this.citiesRepository.findAll(search);
  }
}
