import { IAddress } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IAddressRepository from 'shared/domain/repositories/IAddressRepository';
import Address, { IVectisAddress } from 'shared/infra/adapters/Address';

export default class VectisAddressRepository implements IAddressRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public async findByPostalCode(postalCode: string): Promise<IAddress | null> {
    const address = await this.httpProvider.get<IVectisAddress>(
      '/cep/' + postalCode,
    );

    if (!address) return null;
    return new Address({ ...address, cep: postalCode });
  }
}
