/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions  */
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import * as Portal from "@radix-ui/react-portal";

import * as S from "./styles";

interface IModalRef {
  open(): void;
  close(): void;
  toggle(): void;
}

interface IModalProps {
  className?: string;
  children?: ReactNode;
}

const Modal: ForwardRefRenderFunction<IModalRef, IModalProps> = (
  { className, children },
  ref
) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      close: () => setVisible(false),
      open: () => setVisible(true),
      toggle: () => setVisible(visible => !visible),
    }),
    []
  );

  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  return (
    <Portal.Root>
      <S.Overlay visible={visible} onClick={() => setVisible(false)}>
        <div
          className={className}
          onClick={event => event.stopPropagation()}
          role="dialog"
        >
          {children}
        </div>
      </S.Overlay>
    </Portal.Root>
  );
};

export default forwardRef(Modal);
