import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.layout.spacing(1.5)};

    > div {
      width: ${theme.layout.spacing(19)};
      display: flex;
      flex-direction: column;
      gap: ${theme.layout.spacing(2)};

      h3 {
        font-size: ${theme.typography.sizes.title3};
        font-weight: ${theme.typography.weight.bold};
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: ${theme.layout.spacing(1)};

        a {
          font-size: ${theme.typography.sizes.caption};
          color: ${theme.palette.text.light};

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;

      ul {
        align-items: center;
      }
    }
  `}
`;
