/* eslint-disable camelcase */
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeHTTPProvider } from 'shared/infra/providers';

import getValidLanguage from './getValidLanguage';

const API_URL = {
  pt: process.env.REACT_APP_API_URL,
  es: process.env.REACT_APP_API_URL_PARAGUAY,
  'es-CR': process.env.REACT_APP_API_URL_COSTARICA,
};

export default function InitAxios() {
  const { i18n } = useTranslation();
  const history = useHistory();

  axios.defaults.baseURL = API_URL[getValidLanguage(i18n.language)];

  const token = localStorage.getItem('@vialaser:token');
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    makeHTTPProvider().setHeader('AUTHENTICATION', `Bearer ${token}`);
  }

  // Add a request interceptor
  axios.interceptors.request.use(
    config => {
      config.headers.Language = i18n.language;
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  function redirectLogin() {
    makeHTTPProvider().setHeader('AUTHENTICATION', null);
    delete axios.defaults.headers.common.Authorization;
    localStorage.clear();
    localStorage.clear();
    history.push('/login');
  }

  axios.interceptors.response.use(undefined, async error => {
    if (error.response && error.response.status === 401) {
      const refreshToken = localStorage.getItem('@vialaser:refreshToken');
      if (refreshToken) {
        const response = await axios.get('user/refreshToken', {
          params: {
            token: refreshToken,
          },
        });
        if (response.data) {
          const { access_token } = response.data;
          localStorage.setItem('@vialaser:token', access_token);
          axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
          error.config.headers.Authorization = `Bearer ${access_token}`;
          makeHTTPProvider().setHeader(
            'AUTHENTICATION',
            `Bearer ${access_token}`,
          );
          return axios.request(error.config);
        }
      } else {
        redirectLogin();
      }
    }
    if (error.response && error.response.status === 403) {
      redirectLogin();
    }
    return Promise.reject(error);
  });
  return null;
}
