import { makeCustomersRepository } from 'shared/infra/repositories';

import FetchCustomerByEmailUseCase from './implementations/FetchCustomerByEmailUseCase';

let instance: FetchCustomerByEmailUseCase | null;

export default function makeFetchCustomerByEmailUseCase() {
  if (!instance) {
    const repository = makeCustomersRepository();

    instance = new FetchCustomerByEmailUseCase(repository);
  }

  return instance;
}
