import { FC } from 'react';

import IngenicoTokenization from './IngenicoTokenization';
import MissingPaymentMethod from './MissingPaymentMethod';
import { TNotification } from '../constants';

export const NotificationsComponents: Record<
  TNotification['type'],
  FC<{ handleClose(): void; id: number }>
> = {
  INGENICO_TOKENIZATION: IngenicoTokenization,
  MISSING_PAYMENT_METHOD: MissingPaymentMethod,
};
