export const pendencies = {
  Attention: 'Atención',
  'Click to select the installments': 'Haga clic para seleccionar cuotas',
  '{{name}}, search your contracts for the installments you wish to settle':
    '{{name}}, busca en tus contratos las cuotas que deseas saldar.',
  '{{name}}, select the installments you wish to settle to proceed':
    '{{name}}, selecciona las cuotas que deseas liquidar para continuar.',
  '{{name}}, you have the option to reparcel your debts How do you wanna procceed?':
    '{{name}}, existe la posibilidad de pagar tus deudas. ¿Como quieres proceder?',
  'Hello {{name}}! Please, confirm your document to see your pending issues':
    'Hola {{name}}! Confirme su CI para ver sus asuntos pendientes.',
  'Log out and identify yourself again': 'Salir e identificarse nuevamente',
  'Pending issues': 'Pendencias',
  'Procceed settling my pending issues':
    'Continuar con la liquidación de cuotas pendientes',
  'Reparcel all my debts': 'Refinanciar toda mi deuda',
  'Touch to select the installments': 'Toque para seleccionar cuotas',
  'You are trying to access a page belonging to another customer':
    'Estás intentando acceder a una página perteneciente a otro cliente.',
};
