import React from 'react';

import * as S from './styles';

interface IPixAboutProps {
  className?: string;
}

const PixAbout: React.FC<IPixAboutProps> = ({ className }) => (
  <S.Container className={className}>
    <p>
      Para realizar seu pagamento PIX basta seguir os passos descritos abaixo:
    </p>

    <ul>
      <li>
        <strong>1.</strong>
        {' Abra o app da sua instituição financeira e vá até a '}
        <strong>area PIX</strong>;
      </li>

      <li>
        <strong>2.</strong> Escolha a opção{' '}
        <strong>pagar via QRCode PIX</strong>;
      </li>

      <li>
        <strong>3.</strong> Aponte a camera para o QRCode gerado;
      </li>

      <li>
        <strong>4.</strong> Confirme todos os dados e realize o pagamento.
      </li>
    </ul>
  </S.Container>
);

export default PixAbout;
