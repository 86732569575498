import { memo, useContext, useEffect, useState } from 'react';

import { withWidth } from '@material-ui/core';
import { Image } from 'react-shimmer';

import * as S from './styles';
import Carousel from '../../../components/Carousel';
import CircularLoading from '../../../components/CircularLoading';
import { BANNER_WIDTH_MAPPING } from '../../../constants/App';
import { AppContext } from '../../../contexts/AppContext';

const Banners = memo(({ width }) => {
  const context = useContext(AppContext);
  const [banners, setBanners] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { globalState } = context;
  const bannersHome = globalState?.banners?.home;
  const bannersHomeLength = globalState?.banners?.home.length;

  const isLaoding =
    !globalState?.banners ||
    !globalState?.banners?.home ||
    globalState?.banners?.home.length <= 0;
  const bannersLength = banners.length > 0;

  useEffect(() => {
    const bs = [];
    if (bannersHomeLength > 0) {
      for (let index = 0; index < bannersHomeLength; index++) {
        const banner = bannersHome[index];
        bs.push({
          ...banner,
          image: banner.imagemBannerList.reduce((prev, image) => {
            if (image.resolucaoBanner === BANNER_WIDTH_MAPPING[width]) {
              return image.url;
            }
            return prev;
          }, ''),
        });
      }
      setBanners(bs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.banners]);

  if (isLaoding) {
    return (
      <S.Container>
        <CircularLoading />
      </S.Container>
    );
  }

  return (
    <>
      {bannersLength && (
        <Carousel showArrows emulateTouch infiniteLoop stopOnHover autoPlay>
          {banners.map(banner => {
            return (
              <S.CarrouselItem
                key={`banner-${banner.id}`}
                title={banner.descricao}
              >
                <a
                  id="banner-link"
                  alt={banner.descricao}
                  href={banner.linkRedirecionamento}
                >
                  <Image
                    NativeImgProps={{
                      style: {
                        width: '100%',
                        cursor: 'pointer',
                        minHeight: '12vh',
                        pointerEvents: 'all',
                      },
                    }}
                    alt={banner.descricao}
                    src={banner.image}
                    onLoad={() => {
                      setImageLoaded(true);
                    }}
                    fallback={<CircularLoading />}
                  />
                </a>
                {banner.linkDetalhes && imageLoaded && (
                  <S.BannerButton
                    color="primary"
                    style={{
                      backgroundColor: banner.corDetalhesAdicionais,
                    }}
                    variant="contained"
                    onClick={e => {
                      e.preventDefault();
                      window.location = banner.linkDetalhes;
                    }}
                  >
                    {banner.detalhesAdicionais}
                  </S.BannerButton>
                )}
              </S.CarrouselItem>
            );
          })}
        </Carousel>
      )}
    </>
  );
});

Banners.displayName = 'Banners';

export default withWidth()(Banners);
