import {
  Card,
  CardContent,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1.5rem',
    maxHeight: 410,
    overflowY: props => (props.units.length >= 2 ? 'scroll' : 'hidden'),
    [theme.breakpoints.down('sm')]: {
      maxHeight: 400,
      overflowY: 'hidden !important',
      overflowX: props => (props.units.length > 1 ? 'scroll' : 'hidden'),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  card: {
    marginBottom: '0.6rem',
    background: theme.palette.cardsBackground.primary,
    [theme.breakpoints.down('sm')]: {
      minHeight: 190,
    },
  },
  title: {
    fontWeight: 600,
  },
  button: {
    margin: 0,
    paddingLeft: 0,
  },
}));

export default function WhereToFindList({ city, unitInMap, setUnitInMap }) {
  const { t } = useTranslation();

  // const citySelected = units[uf] && units[uf].cities[city];
  const classes = useStyles({
    units: (city && city.units) || [],
  });

  if (!city || !city.units) {
    return null;
  }

  return (
    <Grid
      id="units"
      item
      xs={12}
      md={10}
      container
      justifyContent="center"
      spacing={2}
      className={classes.root}
    >
      {city.units.map(unit => {
        return (
          <Grid item xs={12} key={unit.id}>
            <Card className={classes.card} key={unit.id}>
              <CardContent>
                <Typography variant="body1" className={classes.title}>
                  {unit.descricaoWeb}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {`${unit.logradouro} nº${unit.numeroLogradouro} - ${
                    unit.bairro ? unit.bairro : t('Bairro')
                  }`}
                </Typography>

                <Typography variant="body1" className={classes.title}>
                  {t('Horário de atendimento')}
                </Typography>
                <Typography variant="body1">
                  {`Segunda a sexta das ${unit.hrInicioAtendimentoSegundaAteSexta} às ${unit.hrFimAtendimentoSegundaAteSexta} horas`}
                </Typography>
                {unit.hrInicioAtendimentoSabado ? (
                  <Typography variant="body1">
                    {`Sábado das ${unit.hrInicioAtendimentoSabado} às ${unit.hrFimAtendimentoSabado} horas`}
                  </Typography>
                ) : (
                  ''
                )}
                {unit.hrInicioAtendimentoDomingo ? (
                  <Typography variant="body1">
                    {unit.hrInicioAtendimentoDomingo !== '00:00' &&
                      `Domingo das ${unit.hrInicioAtendimentoDomingo} às ${unit.hrFimAtendimentoDomingo} horas`}
                  </Typography>
                ) : (
                  ''
                )}

                {city.units.length > 1 && unitInMap.id !== unit.id && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    color="primary"
                    variant="body1"
                    onClick={() => {
                      setUnitInMap(unit);
                      document.getElementById('units-map').scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}
                  >
                    {t('Ver no mapa')}
                  </Link>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
