import styled, { css } from 'styled-components';

export const Container = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.palette.neutral[50]};
    border: 1px solid ${theme.palette.neutral[200]};

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.layout.spacing(5.5)};
      padding: ${theme.layout.spacing(5.5)};

      > div {
        display: flex;
        width: 100%;
        max-width: ${theme.layout.breakpoints.lg};

        justify-content: space-between;
        align-items: center;

        &:first-child {
          align-items: flex-start;
        }
      }

      @media (max-width: ${theme.layout.breakpoints.md}) {
        padding: ${theme.layout.spacing(4, 2)};
        gap: ${theme.layout.spacing(4)};

        > div {
          flex-direction: column;
          justify-content: flex-start;
          gap: ${theme.layout.spacing(3)};

          &:first-child {
            align-items: center;
          }
        }
      }
    }
  `}
`;
