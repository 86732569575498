import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IPaymentDirectivesRepository } from 'shared/domain/repositories';

const PROCESS_URL_DICTIONARY: Record<
  Parameters<IPaymentDirectivesRepository['findByUnit']>[0]['process'],
  string
> = {
  CHECKOUT: 'checkout',
  CARD_CHANGE: 'troca-cartao',
};

export class VectisPaymentDirectivesRepository
  implements IPaymentDirectivesRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public findByUnit: IPaymentDirectivesRepository['findByUnit'] = async ({
    unitId,
    process,
  }): Promise<{ active: boolean; value: number }> => {
    const { transacaoValidacao } = await this.httpProvider.get<{
      transacaoValidacao: { ativo: boolean; valorTransacao: number };
    }>(`/diretivas/${PROCESS_URL_DICTIONARY[process]}`, {
      params: {
        unidade: unitId,
      },
    });

    return {
      active: transacaoValidacao.ativo,
      value: transacaoValidacao.valorTransacao,
    };
  };
}
