import { fetchPromocoes } from 'contexts/core/promocoes';

export async function getPromotions() {
  try {
    const promos: unknown[] = await fetchPromocoes();

    return {
      promotionsConfig: promos,
    };
  } catch (error) {
    return {
      promotionsConfig: [],
    };
  }
}
