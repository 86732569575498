export const completeRegistrationForm = {
  'It will not be long until you buy':
    'Ops, vimos que faltaron algunos datos para finalizar su compra',
  'Complete your registration below and do not miss this offer':
    'Complete ahora su registro abajo y no pierdas esta oferta.',

  'Almost there': 'Casi llegamos',
  'Complete your registration so you can purchase':
    'Completa tu registro para poder comprar',

  'Inform the following data so we can confirm your age':
    'Infórmanos los siguientes datos para que podamos confirmar tu edad',
  'Please, inform the document of your guardian':
    'Por favor, informe el documento de su tutor',
  'We noticed that your guardian is a minor please, inform the document of an adult guardian!':
    'Nos damos cuenta de que su tutor es menor de edad. Por favor proporcione el documento de un tutor adulto',
  'Inform the guardian data!': 'Informa a los datos del guardián!',
  'Inform your remaining data': 'Informa tus datos restantes',

  Next: 'Próximo',
  'Complete registration': 'Registro completo',

  Email: 'Correo electrónico',
  Birthdate: 'Fecha de nacimiento',
  Document: 'C.I',
  'Alternative document': 'R.U.C',
  Name: 'Nombre',
  Surname: 'Apellido',
  Phone: 'Teléfono',
  'Postal code': 'Código postal',
  City: 'Ciudad',
  District: 'Distrito',
  Street: 'Calle',
  'Street number': 'Número de dirección',

  'Please, inform an email': 'Por favor, informe un correo electrónico',
  'Email is invalid': 'El correo electrónico es invalido',
  'Please, inform your birthdate': 'Por favor, informa tu fecha de nacimiento',
  'Complete the birthdate': 'Completa la fecha de nacimiento',
  'Please, inform your document': 'Por favor, informe su C.I',
  'Document is invalid': 'C.I no es válido',
  'Please, inform your alternative document': 'Por favor, informe a su R.U.C.',
  'Alternative document must only have numbers':
    'R.U.C solo debe tener números',
  'Please, inform your postal code': 'Por favor, informa tu código postal',
  'Please, inform your city': 'Por favor, informe a su ciudad',
  'Please, inform your district': 'Por favor, informe a su distrito',
  'Please, inform your street': 'Por favor, informe a su calle',
  'Street number can not be lower than zero':
    'El número de dirección no puede ser inferior a cero',
  'Please, inform your street number':
    'Por favor, informe su número de dirección',
  'Please, inform your name': 'Por favor, informa tu nombre',
  'Please, inform your surname': 'Por favor, informa tu apellido',
  'Please, inform your phone': 'Por favor, informe a su teléfono',
  'Please, inform a valid phone': 'Por favor, informa un teléfono válido',
};
