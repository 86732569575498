import { EXTERNAL_LINKS } from './links';
import FacebookIcon from '../assets/icons/facebook';
import InstagramIcon from '../assets/icons/instagram';
import WhatsappIcon from '../assets/icons/whatsapp';

export const NAVBAR_LINKS = {
  es: [
    {
      href: EXTERNAL_LINKS.es.WHATSAPP,
      external: true,
      text: 'Reservar una sesión',
    },
    {
      href: 'where-to-find',
      external: false,
      text: 'Visitanos',
    },
  ],
  'es-CR': [
    {
      href: EXTERNAL_LINKS['es-CR'].WHATSAPP,
      external: true,
      text: 'Reservar una sesión',
    },
    {
      href: 'where-to-find',
      external: false,
      text: 'Visitanos',
    },
  ],
};

export const NAVBAR_ICONS = {
  es: [
    {
      href: EXTERNAL_LINKS.es.WHATSAPP,
      key: 'whatsapp',
      Icon: WhatsappIcon,
    },
    {
      href: 'https://www.facebook.com/vialaserdepilacionpy',
      key: 'facebook',
      Icon: FacebookIcon,
    },
    {
      href: 'https://www.instagram.com/vialaserdepilacionpy/',
      key: 'instagram',
      Icon: InstagramIcon,
    },
  ],
  'es-CR': [
    {
      href: EXTERNAL_LINKS['es-CR'].WHATSAPP,
      key: 'whatsapp',
      Icon: WhatsappIcon,
    },
    {
      href: 'https://www.facebook.com/people/Vialaser-Costa-Rica/100089848462680/',
      key: 'facebook',
      Icon: FacebookIcon,
    },
    {
      href: 'https://www.instagram.com/vialaserdepilacioncr/',
      key: 'instagram',
      Icon: InstagramIcon,
    },
  ],
};
