import styled from 'styled-components';

import { theme } from '../../theme';

export const Header = styled.header`
  background-color: ${theme.colors.primaryDark};
  display: flex;
  justify-content: center;

  h2 {
    color: ${theme.colors.textBright};
    font-size: 3.75rem;
    font-weight: bold;
    padding: 0 2rem;
    border-left: 4px solid ${theme.colors.textBright};
    margin: 3rem 0;
  }
`;
