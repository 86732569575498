import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IFlagParametersRepository from 'shared/domain/repositories/IFlagParametersRepository';

export default class VectisFlagParametersRepository
  implements IFlagParametersRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public findByKey: IFlagParametersRepository['findByKey'] = key => {
    if (key === 'DIGITAL_SIGNING_DISABLED') {
      return this.httpProvider.get(
        'area-cliente/financeiro/configuracaoAssinaturaContratoManual/',
      );
    }

    throw new Error(`Unknown flag parameter requested: ${key}`);
  };
}
