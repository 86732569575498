import { TLanguage } from 'config/tenancy';

export const CONTACTS: Record<TLanguage, string> = {
  pt: '0800 942 2345',
  es: '217289303',
  'es-CR': '70999808',
};

export const COUNTRY_CODES: Record<TLanguage, string> = {
  pt: '55',
  es: '595',
  'es-CR': '506',
};

export const CONTACT_DEFAULT_MESSAGES: Record<TLanguage, string> = {
  pt: 'Tenho interesse em saber mais sobre promoções de depilação a laser',
  es: 'Tengo interés en saber más sobre promociones de depilación láser',
  'es-CR': 'Tengo interés en saber más sobre promociones de depilación láser',
};

export const DEFAULT_CUSTOMER_SERVICE_LINK =
  'https://bitly.vialaser.com.br/atendimento-cliente';
