import { useHistory } from 'react-router-dom';

import { addQueryParamToUrl } from '../helpers';
import type {
  TRoutePaths,
  TRoutePathsWithParams,
  TRouteQuery,
} from '../models';

interface IUseNavigation {
  push<T extends TRoutePaths = TRoutePaths>(
    path: TRoutePathsWithParams<T>,
    params?: TRouteQuery<T>,
  ): void;
  replace<T extends TRoutePaths = TRoutePaths>(
    path: TRoutePathsWithParams<T>,
    params?: TRouteQuery<T>,
  ): void;
  goBack(): void;
  refresh(): void;
}

export default function useNavigation(): IUseNavigation {
  const navigation = useHistory();

  return {
    push: (path, params) => navigation.push(addQueryParamToUrl(path, params)),
    replace: (path, params) =>
      navigation.replace(addQueryParamToUrl(path, params)),
    goBack: navigation.goBack,
    refresh: () => navigation.go(0),
  };
}
