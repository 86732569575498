export const testimonials = {
  'Initally I was afraid of laser hair removal because I have dark skin, and the fear of wounds and stains was huge When I met Vialaser and they explained me about the techonology used and the care I should take with my skin the fear ended and everything worked out right! My session was quick and painless I left without any burning, stains or discomfort and I was excited for the next sessions and to buy new packages for other areas I highly recommend it!':
    'Inicialmente tive medo de fazer depilação a laser porque tenho pele negra e o medo de lesões e manchas era grande. Quando conheci a Vialaser e elas me explicaram sobre a tecnologia utilizada e sobre os cuidados que eu deveria ter o medo passou e deu super certo! A minha sessão foi rápida e indolor. Saí sem ardência, manchas, queimação ou desconforto e já fiquei animada para marcar outras sessões e comprar novos pacotes de outras áreas. Super recomendo!',
  'I am very happy with the results I got at Vialaser, they are visible at the first session, I loved it very much And the service is also excellent, they clear all our doubts and are very thoughtfull! I liked it a lot, and recommend it!':
    'Sem contar o atendimento que é excelente, elas esclarecem todas as nossas dúvidas e são super atenciosos! Gostei muito, recomendo! Estou mega feliz com os resultados que obtive na Vialaser, na primeira sessão já são visíveis, amei demais.',
  "I'm living a dream, that is my hair removal With each session I'm happier and accomplished with the results and the professionals' service Really, Vialaser has the most painless and comfortable method I've ever known, and I recommend it to everyone! 🥰":
    'Tô realizando um sonho, que é fazer minha depilação a laser. A cada sessão fico mais feliz e realizada com os resultados e o atendimento dos profissionais de lá. Realmente a Vialaser tem o método mais indolor e confortável que já conheci, indico pra tooodos! 🥰',
  "It was surprising to find there is hair removal for dark skin The service is really good, I was well treated sinced the first session and well informed about sessions' steps":
    'Foi surpreendente descobrir que existe depilação a laser para a pele negra. O atendimento é muito bom, desde a primeira sessão sou muito bem tratada e sou informada sobre o passo a passo das sessões.',
};
