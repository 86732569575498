import { styled } from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 24,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  zIndex: 99999,

  [theme.breakpoints.down('xs')]: {
    width: 'auto',
    right: 0,
    left: 0,
    bottom: 0,
  },
}));

export const Box = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 4px 12px rgba(39, 39, 39, 0.3)',
  borderRadius: 12,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'strecth',
  width: 800,

  [theme.breakpoints.down('md')]: {
    width: 700,
  },

  [theme.breakpoints.down('xs')]: {
    width: 'auto',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const Banner = styled('img')(({ theme }) => ({
  width: 250,
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Section = styled('div')(({ theme }) => ({
  flex: 1,
  padding: '12px 16px',

  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 4,

    '& .header__title': {
      margin: 0,
      color: theme.palette.primary.main,
      fontSize: 20,
      cursor: 'default',
    },

    '& .header__close__button': {
      width: 32,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 18,
      borderRadius: 8,
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      color: '#4F4F4F',
      backgroundColor: 'rgba(39, 39, 39, 0.1)',
    },
  },

  '& .content': {
    cursor: 'pointer',

    '& .content__description': {
      margin: 0,
      lineHeight: '150%',
      fontSize: 16,
      fontWeight: 400,

      '& .content__link': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));
