import * as Checkbox from '@radix-ui/react-checkbox';
import { useField } from 'formik';

import { ICONS } from 'shared/presentation/constants';

import * as S from './styles';

const Icon = ICONS.CHECK;

interface ICheckInputProps {
  name: string;
  onValueChange?: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const CheckInput: React.FC<ICheckInputProps> = ({
  name,
  onValueChange,
  disabled,
  className,
  children,
}) => {
  const [field, , helpers] = useField<boolean>({ name });

  return (
    <S.Container className={className}>
      <Checkbox.Root
        checked={field.value}
        onCheckedChange={state => {
          helpers.setValue(Boolean(state));
          if (onValueChange) onValueChange(Boolean(state));
        }}
        name={name}
        id={name}
        disabled={disabled}
      >
        <Checkbox.Indicator>
          <Icon />
        </Checkbox.Indicator>
      </Checkbox.Root>

      <label htmlFor={name}>{children}</label>
    </S.Container>
  );
};

export default CheckInput;
