import { IFetchAddressByPostalCodeDTO } from 'shared/domain/dtos';
import { IAddressRepository } from 'shared/domain/repositories';

export default class FetchAddressByPostalCodeUseCase {
  constructor(private repository: IAddressRepository) {}

  public async execute({ postalCode }: IFetchAddressByPostalCodeDTO) {
    return this.repository.findByPostalCode(postalCode);
  }
}
