import { ComponentRef, useCallback, useRef } from 'react';

import Modal from 'components/Modal';

import { NotificationsComponents } from './components';
import { useCurrentNotification } from './hooks';

type TModalRef = ComponentRef<typeof Modal>;

const NotificationsContext: React.FC = ({ children }) => {
  const modalRef = useRef<TModalRef>(null);
  const { notification } = useCurrentNotification({
    onSuccess: useCallback(() => modalRef.current?.open(), []),
  });

  if (!notification) return <>{children}</>;

  const Component = NotificationsComponents[notification.type];

  return (
    <>
      {children}

      <Modal ref={modalRef}>
        <Component
          handleClose={() => modalRef.current?.close()}
          id={notification.id}
        />
      </Modal>
    </>
  );
};

export default NotificationsContext;
