import React from 'react';

import * as S from './styles';

interface IDottedStringfiedArrayProps {
  array: string[];
  color?: string;
}

const DottedStringfiedArray: React.FC<IDottedStringfiedArrayProps> = ({
  array,
  color,
}) => {
  const dottedStringifiedArray = array.reduce<React.ReactChild | null>(
    (acc, item) =>
      acc ? (
        <>
          {acc} <S.Dot color={color}>•</S.Dot> {item}
        </>
      ) : (
        <>{item}</>
      ),
    null,
  );

  if (!dottedStringifiedArray) return null;

  return <>{dottedStringifiedArray}</>;
};

export default DottedStringfiedArray;
