import { TVariantLogoProps } from '../../constants';

const EsLogo: React.FC<TVariantLogoProps> = ({ colors, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 494.5 167.3"
    xmlSpace="preserve"
    width={size}
  >
    <g>
      <g>
        <path
          fill={colors.laser}
          d="M472.9,40.3v14.2h-7.2c-3,0-5.8,0.6-8.4,1.7c-2.6,1.1-4.9,2.7-6.9,4.6c-2,2-3.5,4.3-4.6,6.9c-1.1,2.6-1.7,5.4-1.7,8.4
          v35.9h-14.4V76c0-5,0.9-9.7,2.8-14c1.9-4.3,4.4-8.1,7.7-11.3c3.3-3.2,7.1-5.8,11.5-7.7c4.4-1.9,9-2.8,13.9-2.8H472.9z M379.4,61
          c-4.3,4.3-6.4,9.4-6.4,15.3c0,3.3,0.7,6.4,2.2,9.4l5.8-5.8l8.3-8.3c2.9-2.9,5.8-5.7,8.5-8.4c2.7-2.7,4.8-4.7,6.3-6.2
          c-4-2-8.3-2.7-12.8-2.1C386.7,55.6,382.8,57.6,379.4,61 M414.9,86.7L425,96.8l-4.9,4.9c-3.4,3.4-7.3,6-11.7,7.8
          c-4.4,1.8-8.9,2.7-13.7,2.7c-4.8,0-9.4-0.9-13.7-2.7c-4.4-1.8-8.2-4.4-11.7-7.8c-3.5-3.5-6.1-7.5-7.8-11.9
          c-1.7-4.4-2.6-8.9-2.6-13.5s0.8-9,2.6-13.4c1.7-4.4,4.3-8.3,7.8-11.8c3.5-3.5,7.5-6.2,11.9-7.9c4.4-1.8,8.9-2.6,13.5-2.6
          c4.6,0,9.1,0.9,13.5,2.6c4.4,1.7,8.4,4.4,11.9,7.9l4.9,4.9l-39.7,39.7c4,2,8.3,2.7,12.8,2c4.5-0.7,8.5-2.7,11.9-6.1L414.9,86.7z
           M347.3,40.3v14.2H304c-2,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.1-2.1,5.1s0.7,3.8,2.1,5.2c1.4,1.4,3.1,2.2,5.1,2.2h28.7
          c3,0,5.8,0.6,8.4,1.7c2.6,1.1,4.9,2.6,6.9,4.5c2,1.9,3.5,4.2,4.6,6.8c1.1,2.6,1.7,5.4,1.7,8.4c0,3-0.6,5.8-1.7,8.4
          c-1.1,2.6-2.7,4.9-4.6,6.9c-2,2-4.3,3.5-6.9,4.6c-2.6,1.1-5.4,1.7-8.4,1.7h-43.1V97.7h43.1c2.1,0,3.9-0.7,5.3-2.1
          c1.4-1.4,2.1-3.1,2.1-5.3c0-2-0.7-3.7-2.1-5.1c-1.4-1.4-3.1-2.1-5.3-2.1H304c-3,0-5.8-0.6-8.4-1.7c-2.6-1.1-4.9-2.7-6.8-4.6
          c-1.9-2-3.4-4.3-4.6-6.9c-1.1-2.6-1.7-5.4-1.7-8.4c0-3,0.6-5.8,1.7-8.4c1.1-2.6,2.6-4.9,4.6-6.8c1.9-1.9,4.2-3.4,6.8-4.5
          c2.6-1.1,5.4-1.7,8.4-1.7H347.3z M264.4,97.7V76c0-3-0.6-5.8-1.7-8.4c-1.1-2.6-2.7-4.9-4.6-6.9c-2-2-4.3-3.5-6.9-4.6
          c-2.6-1.1-5.4-1.7-8.4-1.7c-3,0-5.8,0.6-8.5,1.7c-2.7,1.1-5,2.7-6.9,4.6c-2,2-3.5,4.3-4.6,6.9c-1.1,2.6-1.7,5.4-1.7,8.4
          c0,3,0.6,5.8,1.7,8.5c1.1,2.7,2.7,5,4.6,6.9c2,2,4.3,3.5,6.9,4.6c2.7,1.1,5.5,1.7,8.5,1.7H264.4z M242.9,40.3c5,0,9.7,0.9,14,2.8
          c4.3,1.9,8.1,4.4,11.3,7.7c3.2,3.2,5.8,7,7.7,11.3c1.9,4.3,2.8,9,2.8,14v35.9h-35.8c-4.9,0-9.5-0.9-13.9-2.8
          c-4.4-1.9-8.2-4.4-11.5-7.7c-3.3-3.3-5.9-7.1-7.7-11.5c-1.9-4.4-2.8-9-2.8-13.9c0-5,0.9-9.7,2.8-14c1.9-4.3,4.4-8.1,7.7-11.3
          c3.3-3.2,7.1-5.8,11.5-7.7C233.4,41.2,238,40.3,242.9,40.3 M187.7,11.5h14.2v100.4h-14.2V11.5z"
        />
        <path
          fill={colors.via}
          d="M165.8,98.3V76.9c0-2.9-0.5-5.7-1.6-8.2c-1.1-2.6-2.6-4.8-4.5-6.8c-1.9-1.9-4.2-3.4-6.8-4.5
				c-2.6-1.1-5.3-1.7-8.2-1.7c-2.9,0-5.7,0.6-8.3,1.7c-2.6,1.1-4.9,2.6-6.8,4.5c-1.9,1.9-3.5,4.2-4.6,6.8c-1.1,2.6-1.7,5.3-1.7,8.2
				c0,2.9,0.6,5.7,1.7,8.3c1.1,2.6,2.6,4.9,4.6,6.8c1.9,1.9,4.2,3.5,6.8,4.6c2.6,1.1,5.4,1.7,8.3,1.7H165.8z M145.8,41.7
				c4.9,0,8.3,0.9,12.5,2.7c4.2,1.8,8,4.4,11.2,7.5c3.2,3.2,5.7,6.9,7.5,11.1c1.8,4.2,2.7,8.8,2.7,13.7v35.3h-34
				c-4.8,0-9.4-0.9-13.7-2.7c-4.3-1.8-8.1-4.4-11.3-7.6c-3.2-3.2-5.8-7-7.6-11.3c-1.8-4.3-2.7-8.9-2.7-13.7c0-4.9,0.9-9.5,2.7-13.7
				c1.8-4.2,4.4-8,7.6-11.1c3.2-3.2,7-5.7,11.3-7.5C136.5,42.6,141,41.7,145.8,41.7 M91.6,41.7h12.6v70.5H91.6V41.7z M85.1,41.4
				l-36,69.2L13.3,41.4l13.8,0.2c1.5,2.8,4.3,7.8,6.2,11.4c1.9,3.6,3.8,7.3,5.7,11c1.9,3.7,3.7,7.3,5.6,10.8c1.8,3.5,3.4,6.4,4.6,9
				c1.6-3,3.4-6.5,5.4-10.4c2-3.9,4.1-7.8,6-11.7c2-3.9,3.8-7.5,5.5-10.8c1.7-3.3,3.8-7.7,4.8-9.4L85.1,41.4z"
        />
        <g>
          <rect
            fill={colors.line}
            x="14.3"
            y="120.7"
            className="st1"
            width="457.9"
            height="1.3"
          />
          <path
            fill={colors.line}
            d="M477.3,116.9c2.8,0,5.1,2.3,5.1,5.1c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1
					C472.2,119.2,474.5,116.9,477.3,116.9"
          />
        </g>
      </g>
      <g>
        <path
          fill={colors.bottom}
          d="M105.3,155.7h-5.3v-15.4h5.3c4.8,0,8.2,3.3,8.2,7.7S110.1,155.7,105.3,155.7z M105.3,142h-3.6v12.2h3.6
			c3.9,0,6.4-2.6,6.4-6.1S109.2,142,105.3,142z"
        />
        <path
          fill={colors.bottom}
          d="M132.3,141.9h-9.4v5.2h8.4v1.6h-8.4v5.4h9.5v1.6h-11.2v-15.4h11.1V141.9z"
        />
        <path
          fill={colors.bottom}
          d="M145.4,150.3h-3.7v5.4h-1.7v-15.4h5.8c3.5,0,5.8,1.8,5.8,4.9C151.4,148.6,148.6,150.3,145.4,150.3z M145.5,142h-3.9v6.8
			h3.8c2.5,0,4.2-1.4,4.2-3.4C149.6,143.1,148,142,145.5,142z"
        />
        <path fill={colors.bottom} d="M160.5,140.4v15.4h-1.7v-15.4H160.5z" />
        <path fill={colors.bottom} d="M169,140.4h1.7v13.8h8.7v1.6H169V140.4z" />
        <path
          fill={colors.bottom}
          d="M201,155.7h-1.9l-1.8-4.1h-8.4l-1.8,4.1h-1.8l7-15.5h1.6L201,155.7z M193.1,142.3l-3.5,7.8h7L193.1,142.3z"
        />
        <path
          fill={colors.bottom}
          d="M214.2,156c-4.4,0-7.7-3.5-7.7-7.9c0-4.4,3.2-8,7.8-8c2.8,0,4.5,1,6,2.4l-1.2,1.3c-1.3-1.2-2.7-2.1-4.8-2.1
			c-3.4,0-6,2.7-6,6.3s2.6,6.4,6,6.4c2.1,0,3.5-0.8,5-2.2l1.1,1.1C218.7,154.9,216.9,156,214.2,156z"
        />
        <path fill={colors.bottom} d="M229.3,140.4v15.4h-1.7v-15.4H229.3z" />
        <path
          fill={colors.bottom}
          d="M244.9,156c-4.7,0-7.8-3.7-7.8-7.9s3.2-8,7.9-8s7.8,3.7,7.8,7.9S249.6,156,244.9,156z M244.9,141.7c-3.5,0-6,2.8-6,6.3
			c0,3.5,2.5,6.4,6.1,6.4s6-2.8,6-6.3C251,144.5,248.4,141.7,244.9,141.7z M244.4,139l2.1-3.2l1.8,0.8l-2.5,2.4H244.4z"
        />
        <path
          fill={colors.bottom}
          d="M271.8,140.4h1.7v15.4h-1.4l-9.9-12.6v12.6h-1.7v-15.4h1.6l9.7,12.3V140.4z"
        />
        <path
          fill={colors.bottom}
          d="M303.1,140.4h1.7v13.8h8.7v1.6h-10.4V140.4z"
        />
        <path
          fill={colors.bottom}
          d="M335.1,155.7h-1.9l-1.8-4.1H323l-1.8,4.1h-1.8l7-15.5h1.6L335.1,155.7z M327.2,142.3l-3.5,7.8h7L327.2,142.3z M326.6,139
			l2.1-3.2l1.8,0.8l-2.5,2.4H326.6z"
        />
        <path
          fill={colors.bottom}
          d="M347.6,147.2c3.4,0.7,5,2,5,4.4c0,2.7-2.2,4.4-5.2,4.4c-2.4,0-4.4-0.8-6.3-2.5l1.1-1.3c1.6,1.4,3.1,2.2,5.3,2.2
			c2.1,0,3.4-1.1,3.4-2.6c0-1.4-0.8-2.3-4-2.9c-3.5-0.8-5.2-1.9-5.2-4.5c0-2.5,2.1-4.2,5.1-4.2c2.2,0,3.8,0.6,5.4,1.9l-1,1.3
			c-1.4-1.2-2.9-1.7-4.4-1.7c-2,0-3.3,1.1-3.3,2.5C343.5,145.7,344.3,146.5,347.6,147.2z"
        />
        <path
          fill={colors.bottom}
          d="M371.3,141.9h-9.4v5.2h8.4v1.6h-8.4v5.4h9.5v1.6h-11.2v-15.4h11.1V141.9z"
        />
        <path
          fill={colors.bottom}
          d="M389.5,155.7l-4.5-6h-4.4v6H379v-15.4h6.6c3.4,0,5.6,1.8,5.6,4.6c0,2.6-1.8,4.1-4.2,4.5l4.7,6.3H389.5z M385.4,142h-4.7
			v6.2h4.7c2.3,0,4-1.2,4-3.2C389.4,143.1,387.9,142,385.4,142z"
        />
      </g>
    </g>
  </svg>
);

export default EsLogo;
