import IStorageProvider, {
  TStorageBody,
  TStorageKey,
} from 'shared/domain/providers/IStorageProvider';

const STORAGE_KEYS: Record<TStorageKey, string> = {
  SESSION_ID: '@vialaser:sessionId',
  CHECKOUT_CUSTOMER_ID: '@vialaser:checkoutCustomerId',
  CHECKOUT_UNIQUE_DEVICE_TOKEN: '@vialaser:checkoutUniqueDeviceToken',
  CHECKOUT_TPDF: '@vialaser:checkoutTpdf',
  HIDE_LISA_CHAT_BUBBLE: '@vialaser:hideLisaChatBubble',
  LISA_CHAT_DISMISSED: '@vialaser:lisaChatDismissed',
};

export default class LocalStorageProvider implements IStorageProvider {
  public clear(): void {
    localStorage.clear();
  }

  public delete(key: TStorageKey): void {
    const translatedKey = STORAGE_KEYS[key];

    localStorage.removeItem(translatedKey);
  }

  public get<T extends TStorageKey>(key: T): TStorageBody<T> | null {
    const translatedKey = STORAGE_KEYS[key];

    try {
      const serialized = localStorage.getItem(translatedKey);

      if (!serialized) return null;

      const parsed = JSON.parse(serialized) as TStorageBody<T>;

      return parsed;
    } catch (error) {
      return null;
    }
  }

  public store<T extends TStorageKey>(key: T, data: TStorageBody<T>): void {
    const translatedKey = STORAGE_KEYS[key];

    const serialized = JSON.stringify(data);

    localStorage.setItem(translatedKey, serialized);
  }
}
