import { benefit } from './benefit';
import { components } from './components';
import { leadViaRecommendation } from './leadViaRecommendation';
import { recommenderIdentification } from './recommenderIdentification';
import { testimonials } from './testimonials';
import { threeSessionsGift } from './threeSessionsGift';
import { validations } from './validations';

const pt = {
  ...benefit,
  ...components,
  ...leadViaRecommendation,
  ...recommenderIdentification,
  ...testimonials,
  ...threeSessionsGift,
  ...validations,
};

export default pt;
