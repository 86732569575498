export const completeRegistrationForm = {
  'It will not be long until you buy':
    'Ops, vimos que faltou alguns dados para completar a sua compra',
  'Complete your registration below and do not miss this offer':
    'Complete agora seu cadastro abaixo e não perca esta oferta',

  'Almost there': 'Quase lá',
  'Complete your registration so you can purchase':
    'Complete seu cadastro para poder comprar',

  'Inform the following data so we can confirm your age':
    'Preencha os dados abaixo para confirmar a sua idade',
  'Please, inform the document of your guardian':
    'Por favor, informe o CPF do responsável legal',
  'We noticed that your guardian is a minor please, inform the document of an adult guardian!':
    'Percebemos que o seu responsável é menor de idade. Por favor, nos informe o CPF de um responsável maior de idade',
  'Inform the guardian data!': 'Preencha os dados do responsável legal!',
  'Inform your remaining data': 'Preencha os seus dados restantes!',

  Next: 'Avançar',
  'Complete registration': 'Completar cadastro',

  Email: 'E-mail',
  Birthdate: 'Data de nascimento',
  Document: 'CPF',
  'Alternative document': 'RG',
  Name: 'Nome',
  Surname: 'Sobrenome',
  Phone: 'Número de celular',
  'Postal code': 'CEP',
  City: 'Cidade',
  District: 'Bairro',
  Street: 'Endereço',
  'Street number': 'Número do Endereço',

  'Please, inform an email': 'Por favor, informe um e-mail',
  'Email is invalid': 'O e-mail está inválido',
  'Please, inform your birthdate': 'Por favor, informe sua data de nascimento',
  'Complete the birthdate': 'Complete a data de nascimento',
  'Please, inform your document': 'Por favor, informe seu CPF',
  'Document is invalid': 'O CPF está inválido',
  'Please, inform your alternative document': 'Por favor, informe seu RG',
  'Alternative document must only have numbers':
    'O RG precisa ter apenas números',
  'Please, inform your postal code': 'Por favor, informe seu CEP',
  'Please, inform your city': 'Por favor, informe sua cidade',
  'Please, inform your district': 'Por favor, informe seu bairro',
  'Please, inform your street': 'Por favor, informe seu endereço',
  'Street number can not be lower than zero':
    'O número não pode ser menor que zero',
  'Please, inform your street number':
    'Por favor, informe seu número de endereço',
  'Please, inform your name': 'Por favor, informe seu nome',
  'Please, inform your surname': 'Por favor, informe seu sobrenome',
  'Please, inform your phone': 'Por favor, informe um celular',
  'Please, inform a valid phone': 'Por favor, informe um celular válido',
};
