import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";

const Base = styled(IconBase)`
  enable-background: new 0 0 130 130;
  .st0 {
    fill: #63b76b;
  }
  .st1 {
    fill: #ffffff;
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const HairRemovalIcon = () => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 130 130"
    {...inproperProps}
  >
    <g>
      <g>
        <path
          className="st0"
          d="M65,121.23C34,121.23,8.77,96.01,8.77,65S34,8.77,65,8.77c31.01,0,56.23,25.22,56.23,56.23
			S96.01,121.23,65,121.23 M65,11.37c-29.57,0-53.63,24.06-53.63,53.63S35.43,118.63,65,118.63S118.63,94.57,118.63,65
			S94.57,11.37,65,11.37"
        />
        <path
          className="st0"
          d="M65,121.23v-0.94c-15.25,0-29.06-6.2-39.08-16.21C15.91,94.06,9.71,80.25,9.71,65s6.2-29.06,16.21-39.08
			C35.94,15.91,49.75,9.71,65,9.71c15.25,0,29.06,6.2,39.08,16.21c10.01,10.02,16.21,23.83,16.21,39.08s-6.2,29.06-16.21,39.08
			C94.06,114.09,80.25,120.29,65,120.29V121.23v0.94c31.53,0,57.16-25.64,57.17-57.17c0-31.52-25.64-57.16-57.17-57.17
			C33.48,7.84,7.84,33.48,7.83,65c0,31.52,25.64,57.16,57.17,57.17V121.23z M65,11.37v-0.94c-30.09,0-54.56,24.47-54.56,54.56
			c0,30.09,24.47,54.56,54.56,54.56S119.56,95.09,119.57,65c0-30.09-24.48-54.56-54.57-54.56V11.37v0.94
			c14.53,0,27.69,5.91,37.24,15.45c9.54,9.55,15.45,22.71,15.45,37.24c0,14.53-5.91,27.69-15.45,37.24
			c-9.55,9.54-22.71,15.45-37.24,15.45s-27.69-5.91-37.24-15.45C18.22,92.69,12.31,79.53,12.31,65c0-14.53,5.91-27.69,15.45-37.24
			C37.31,18.22,50.47,12.31,65,12.31V11.37z"
        />
        <path
          className="st0"
          d="M74.36,35.7c-0.34,1.34-0.8,3.13-1.28,5.14c8.4,2.95,14.44,10.85,14.44,20.12c0,4.03-1.14,7.8-3.12,11.02
			h6.05c1.56-3.36,2.43-7.09,2.43-11.02C92.88,49.21,85.1,39.22,74.36,35.7 M65.82,34.34c-14.92,0-27.05,11.94-27.05,26.62
			c0,3.93,0.87,7.65,2.43,11.02h6.05c-1.97-3.22-3.12-6.99-3.12-11.02c0-11.77,9.73-21.34,21.69-21.34c0.87,0,1.72,0.05,2.57,0.15
			c0.97-1.92,1.88-3.63,2.6-4.93C69.32,34.51,67.59,34.34,65.82,34.34"
        />
        <path
          className="st0"
          d="M65.82,27.18c-18.93,0-34.33,15.15-34.33,33.78c0,3.85,0.66,7.56,1.87,11.01h3.37
			c-1.34-3.42-2.08-7.13-2.08-11.01c0-16.91,13.98-30.66,31.16-30.66s31.16,13.75,31.16,30.66c0,3.88-0.73,7.59-2.08,11.01h3.37
			c1.21-3.45,1.88-7.16,1.88-11.01C100.16,42.33,84.76,27.18,65.82,27.18"
        />
        <path
          className="st0"
          d="M68.86,87.44c-1.05-5.29-2.67-15.29-1.85-23.36c1.06-10.53,5.08-27.13,5.08-27.13S64.49,51.5,61.55,62.16
			c-2.45,8.91-3.49,21.95-3.76,26c-1.49,1.61-2.4,3.84-2.37,6.28c0.06,4.86,3.83,8.75,8.41,8.69c4.58-0.06,8.24-4.05,8.18-8.91
			C71.97,91.47,70.75,89.03,68.86,87.44"
        />
        <polygon
          className="st0"
          points="55.66,79.84 27.22,79.84 25.65,74.79 56.23,74.79 		"
        />
        <polygon
          className="st0"
          points="104.09,79.84 70.39,79.84 69.88,74.79 105.26,74.79 		"
        />
      </g>
    </g>
  </Base>
);

export default HairRemovalIcon;
