import { ISession } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import INpsRepository, {
  TCreateParams,
} from 'shared/domain/repositories/INpsRepository';
import { IVectisSession, Session } from 'shared/infra/adapters';

export default class VectisNpsRepository implements INpsRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public async findById(id: number): Promise<ISession> {
    const vectisSession = await this.httpProvider.get<IVectisSession>(
      '/area-cliente/sessoes/ultima-sessao/' + id,
    );

    return new Session(vectisSession);
  }

  public async create(params: TCreateParams): Promise<void> {
    return this.httpProvider.post('/pesquisa-nps/resposta', {
      nota: params.evaluation,
      hashAgenda: params.scheduleHash,
      resposta: params.comment,
    });
  }
}
