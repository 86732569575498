import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

interface IToastContextData {
  error(message: string): void;
  info(message: string): void;
  success(message: string): void;
}

export const ToastContext = createContext<IToastContextData>(
  {} as IToastContextData,
);

export const useToast = () => {
  const context = useContext(ToastContext);

  if (isEmpty(context)) {
    throw new Error('useToast should be called within a ToastContext');
  }

  return context;
};
