export const leadViaRecommendation = {
  'Because it hurts less than the methods that rip out the hairs':
    'Porque dói menos que os métodos que arrancam pelos',
  'Because its cheaper on the long run':
    'Porque a longo prazo é muito mais barata',
  'Because its much more practical and lasts forever':
    'Porque é muito mais prática e dura pra sempre',
  'Because its the most sustainable method':
    'Porque é o método mais sustentável',
  'Because you deal less damage to your skin':
    'Porque você agride menos a sua pele',
  'Giving goodbye to the hairs is possible': 'Dar adeus aos pelos é possível',
  'Its not magic its Vialaser!': 'Não é mágica é Vialaser!',
  Recommendation: 'Indicação',
  'Why switch from traditional hair removal to laser hair removal?':
    'Por que trocar a sua depilação tradicional por depilação a laser?',
};
