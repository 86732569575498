import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";

const Base = styled(IconBase)`
  enable-background: new 0 0 130 130;
  .st0 {
    fill: #63b76b;
  }
  .st1 {
    fill: #ffffff;
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const PracticityIcon = props => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 130 130"
    {...inproperProps}
    {...props}
  >
    <g>
      <path
        className="st0"
        d="M65,7.83C33.48,7.85,7.83,33.49,7.83,65c0,31.52,25.64,57.16,57.17,57.16c31.52,0,57.16-25.64,57.16-57.16
		C122.17,33.49,96.52,7.85,65,7.83z M107.21,96.5c-0.25,0.34-0.51,0.68-0.77,1.01c-0.52,0.66-1.06,1.31-1.61,1.95
		c-0.41,0.47-0.83,0.93-1.26,1.39c-0.43,0.47-0.87,0.93-1.32,1.38c-1.2,1.19-2.45,2.33-3.75,3.41c-0.65,0.54-1.32,1.06-2,1.57
		c-0.67,0.5-1.36,0.99-2.07,1.47c-0.7,0.48-1.41,0.93-2.13,1.37c-3.62,2.2-7.53,3.98-11.66,5.26c-2.06,0.65-4.17,1.17-6.33,1.55
		H74.3c-3.02,0.55-6.13,0.83-9.3,0.83c-3.18,0-6.29-0.28-9.32-0.83c-2.16-0.38-4.27-0.9-6.33-1.55c-4.12-1.28-8.03-3.06-11.65-5.26
		c-0.72-0.44-1.44-0.9-2.14-1.37c-0.7-0.48-1.39-0.97-2.07-1.47c-0.67-0.51-1.34-1.03-1.99-1.57c-1.3-1.08-2.55-2.22-3.75-3.41
		c-0.45-0.45-0.89-0.91-1.32-1.38c-0.44-0.46-0.85-0.92-1.26-1.39c-0.54-0.64-1.08-1.29-1.61-1.95c-0.26-0.33-0.52-0.67-0.77-1.01
		c-0.5-0.68-0.99-1.36-1.47-2.06c-0.24-0.36-0.48-0.71-0.7-1.07c-0.45-0.71-0.89-1.43-1.32-2.16c-0.01-0.01,0-0.01,0-0.01
		c-4.44-7.72-6.99-16.67-6.99-26.2c0-9.54,2.55-18.48,6.99-26.21c0.43-0.73,0.87-1.46,1.32-2.17c0.23-0.35,0.46-0.71,0.7-1.06
		c0.38-0.55,0.76-1.09,1.16-1.63c1.59-2.19,3.36-4.25,5.27-6.16c1.2-1.19,2.45-2.33,3.75-3.41c0.65-0.54,1.32-1.06,1.99-1.57
		c0.68-0.5,1.37-0.99,2.07-1.47c0.7-0.47,1.42-0.93,2.14-1.37c3.62-2.2,7.53-3.98,11.65-5.26c2.06-0.65,4.17-1.17,6.33-1.55
		c3.03-0.55,6.14-0.83,9.32-0.83c3.17,0,6.28,0.28,9.3,0.83h0.01c2.16,0.38,4.27,0.9,6.33,1.55c4.13,1.28,8.04,3.06,11.66,5.26
		c0.72,0.44,1.43,0.89,2.13,1.37c0.71,0.48,1.4,0.97,2.07,1.47c0.68,0.51,1.35,1.03,2,1.57c1.3,1.08,2.55,2.22,3.75,3.41
		c1.91,1.91,3.68,3.97,5.27,6.16c0.4,0.53,0.79,1.08,1.16,1.63c5.68,8.41,9.01,18.56,9.01,29.44s-3.33,21.03-9.01,29.44
		C108.21,95.14,107.73,95.83,107.21,96.5z"
      />
      <path
        className="st2"
        d="M19.3,91.2c-4.45-7.73-7-16.68-7-26.2c0-9.52,2.55-18.48,7-26.21c-4.44,7.73-6.99,16.67-6.99,26.21
		C12.31,74.54,14.86,83.49,19.3,91.2z"
      />
      <path
        className="st2"
        d="M56.01,32.31c-0.76-1.05-0.51-2.52,0.54-3.28c1.06-0.75,2.53-0.5,3.28,0.55l3.22,4.51
		c0.76,1.05,0.51,2.53-0.54,3.28c-1.06,0.75-2.53,0.51-3.28-0.55L56.01,32.31z"
      />
      <path
        className="st2"
        d="M67.17,33.07v-7.54c0-1.29,1.05-2.35,2.36-2.35c1.29,0,2.34,1.06,2.34,2.35v7.54c0,1.3-1.05,2.35-2.34,2.35
		C68.21,35.42,67.17,34.37,67.17,33.07z"
      />
      <path
        className="st2"
        d="M76.23,34.1l3.23-4.51c0.75-1.05,2.22-1.3,3.27-0.55c1.06,0.76,1.31,2.23,0.55,3.28l-3.22,4.51
		c-0.75,1.06-2.22,1.3-3.28,0.55S75.48,35.14,76.23,34.1z"
      />
      <path
        className="st2"
        d="M90.54,38.87c1.85,2.35,1.44,5.75-0.91,7.59L76.55,56.75c2.03-0.54,4.27,0.14,5.64,1.89
		c1.85,2.34,1.44,5.74-0.9,7.58l-2.94,2.31c2.03-0.53,4.26,0.13,5.63,1.88c1.85,2.35,1.44,5.75-0.9,7.59l-0.77,0.61l0.06,0.17
		c1.34,0.17,2.62,0.86,3.52,2.02c1.85,2.34,1.44,5.74-0.91,7.58l-9.54,7.51l-0.68,0.46c-0.5,0.43-1,0.86-1.53,1.28
		c-4.4,3.46-9.29,5.41-13.55,5.73c-6.76,1.18-14.39-1.77-19.43-8.17c-3.27-4.17-4.86-9.06-4.83-13.7c0,0-0.1-5.82,1.7-9.97
		c1.8-4.16,4.8-9.06,6.35-12.69c1.54-3.62,3.34-7.14,4.89-13.23c1.56-6.09,1.76-6.63,2.07-7.63c0.31-1.01,0.99-2.09,1.89-2.12
		c0.9-0.03,2.56,0.36,4.46,2.71c1.89,2.36,2.1,4.35,2.04,6.31c-0.08,1.95-0.78,5.84-1.34,8.57c-0.37,1.77-0.75,3.99-0.98,5.33
		l26.45-20.81C85.3,36.12,88.69,36.52,90.54,38.87z"
      />
      <g>
        <path
          className="st0"
          d="M89.62,46.46L76.55,56.75c2.03-0.54,4.27,0.14,5.64,1.89c1.85,2.34,1.44,5.74-0.9,7.58l-2.94,2.31
			c2.03-0.53,4.26,0.13,5.63,1.88c1.85,2.35,1.44,5.75-0.9,7.59l-0.77,0.61l0.06,0.17c1.34,0.17,2.62,0.86,3.52,2.02
			c1.85,2.34,1.44,5.74-0.91,7.58l-9.54,7.51l-0.68,0.46c-0.5,0.43-1,0.86-1.53,1.28c-4.4,3.46-9.29,5.41-13.55,5.73
			c-6.76,1.18-14.39-1.77-19.43-8.17c-3.27-4.17-4.86-9.06-4.83-13.7c0,0-0.1-5.82,1.7-9.97c1.8-4.16,4.8-9.06,6.35-12.69
			c1.54-3.62,3.34-7.14,4.89-13.23c1.56-6.09,1.76-6.63,2.07-7.63c0.31-1.01,0.99-2.09,1.89-2.12c0.9-0.03,2.56,0.36,4.46,2.71
			c1.89,2.36,2.1,4.35,2.04,6.31c-0.08,1.95-0.78,5.84-1.34,8.57c-0.37,1.77-0.75,3.99-0.98,5.33l26.45-20.81
			c2.35-1.84,5.75-1.44,7.59,0.91C92.38,41.23,91.98,44.62,89.62,46.46z"
        />
        <path
          className="st0"
          d="M71.86,25.54v7.54c0,1.3-1.05,2.35-2.34,2.35c-1.31,0-2.36-1.05-2.36-2.35v-7.54c0-1.29,1.05-2.35,2.36-2.35
			C70.81,23.18,71.86,24.24,71.86,25.54z"
        />
        <path
          className="st0"
          d="M83.27,32.31l-3.22,4.51c-0.75,1.06-2.22,1.3-3.28,0.55s-1.3-2.23-0.55-3.28l3.23-4.51
			c0.75-1.05,2.22-1.3,3.27-0.55C83.79,29.8,84.04,31.26,83.27,32.31z"
        />
        <path
          className="st0"
          d="M62.51,37.37c-1.06,0.75-2.53,0.51-3.28-0.55l-3.22-4.51c-0.76-1.05-0.51-2.52,0.54-3.28
			c1.06-0.75,2.53-0.5,3.28,0.55l3.22,4.51C63.81,35.14,63.56,36.62,62.51,37.37z"
        />
      </g>
    </g>
  </Base>
);

export default PracticityIcon;
