import { ICheckPhoneValidityDTO } from 'shared/domain/dtos';
import { IPhonesValidityRepository } from 'shared/domain/repositories';

export default class CheckPhoneValidityUseCase {
  constructor(private phonesValidityRepository: IPhonesValidityRepository) {}

  public async execute({ phone }: ICheckPhoneValidityDTO) {
    return this.phonesValidityRepository.findOne(phone);
  }
}
