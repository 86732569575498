import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${props => props.theme.layout.spacing(1.5)};

  img {
    width: 84px;
  }
`;
