import { useTranslation } from 'react-i18next';

import { Cards, CardInfo, Image } from './styles';
import logoActive from '../../../img/ativo.svg';
import logoPaint from '../../../img/paint.svg';
import exclusiveTech from '../../../img/technologies/anexos/laser.svg';
import logoBrasil from '../../../img/technologies/anexos/MarcadorAtivo 21.svg';

export default function CardList() {
  const { t } = useTranslation();

  const PointList = () => (
    <div className="points">
      <div />
      <div className="middlePoint" />
      <div />
    </div>
  );

  return (
    <Cards>
      <CardInfo>
        <div className="information">
          <Image>
            <img
              src={exclusiveTech}
              alt="Tecnologia Exclusiva"
              width="50"
              height="50"
            />
          </Image>
          <div>
            <div className="description">
              {t('Tecnologia exclusiva no Brasil')}
            </div>
          </div>
        </div>
      </CardInfo>
      <PointList />
      <CardInfo>
        <div className="information">
          <Image>
            <img src={logoBrasil} alt="Tecnologia" width="50" height="50" />
          </Image>
          <div>
            <h2 className="description">{t('Depilação a Laser sem dor')}</h2>
          </div>
        </div>
      </CardInfo>
      <PointList />
      <CardInfo>
        <div className="information">
          <Image>
            <img src={logoPaint} alt="Gênero" width="50" height="50" />
          </Image>
          <div>
            <div className="description">
              {t('Maior abrangência de tons de pele')}
            </div>
          </div>
        </div>
      </CardInfo>
      <PointList />
      <CardInfo>
        <div className="information">
          <Image>
            <img src={logoActive} alt="Tecnologia" width="50" height="50" />
          </Image>
          <div>
            <div className="description">{t('Mais segurança e rapidez')}</div>
          </div>
        </div>
      </CardInfo>
    </Cards>
  );
}
