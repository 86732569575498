import ICustomersRepository from 'shared/domain/repositories/ICustomersRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisCustomersRepository from './implementations/VectisCustomersRepository';

let instance: ICustomersRepository | null = null;

export default function makeCustomersRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisCustomersRepository(httpProvider);
  }

  return instance;
}
