export const reparceling = {
  Calculating: 'Calculando...',
  Cancel: 'Cancelar',
  Confirm: 'Confirmar',
  CONFIRMATION: 'CONFIRMAÇÃO',
  Contract: 'Contrato',
  'Do you confirm the reparceling of your contract with {{cash}} in cash, plus {{installments}} installments of {{installmentValue}} each?':
    'Você confirma o reparcelamento do seu contrato com uma entrada de {{cash}}, mais {{installments}} parcelas de {{installmentValue}} cada?',
  'Go back to home': 'Voltar para o início',
  'Hello {{name}}! Please, confirm your document to procceed with the reparceling':
    'Olá {{name}}! Por favor, confirme seu CPF para prosseguir com o reparcelamento.',
  'I am aware that in case of default on this installment plan, <1>Penalty and Interest</1> will be applied, according to the terms of the contract':
    'Estou ciente que havendo inadimplemento deste reparcelamento, serão aplicados <1>Multa e Juros</1>, nos termos do contrato.',
  'Installment no {{number}}': 'Parcela nº {{number}}',
  'Invalid cash value': 'Valor de entrada inválido',
  'Minimum cash value {{value}}': 'Valor mínimo de entrada: {{value}}',
  '{{name}}, use the fields below to defined the reparceling values':
    '{{name}}, use os campos abaixo para definir os valores do reparcelamento.',
  'Next installments': 'Parcelas a vencer',
  'Pending installments': 'Parcelas vencidas',
  'Please, inform the in cash value to choose the reparceling method':
    'Por favor, informe o valor de entrada para escolher a nova forma de parcelamento',
  Reparceling: 'Reparcelamento',
  'Reparceling value': 'Valor do reparcelamento',
  "There's been a problem": 'Houve um problema',
  'The minimum in cash value is calculated considering {{percentage}}% of the pending value':
    'O valor de entrada minimo é calculado considerando {{percentage}}% do valor vencido',
  'The reparceling is no longer valid Please, talk to an attendant':
    'O reparcelamento não é mais válido. Por favor, fale com uma atendente',
  'The value must be greater than {{value}}':
    'O valor precisa ser maior que {{value}}',
  'Value with fine and interest applied': 'Valor com multa e juros aplicado',
  'Where the <1>{{value}}</1> is the applied fine':
    'Onde <1>{{value}}</1> é a multa aplicada',
};
