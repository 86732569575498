import Axios from 'axios';

const fetchCoupon = async (cupom, unidade, produtos) => {
  const url = `/coupon/${cupom}?unidade=${unidade || 40}&produtos=${produtos}`;

  return Axios.get(url).then(({ data }) => {
    if (!data) return null;

    return {
      id: data.id,
      codigo: data.codigoCupom,
      dataCriacao: data.dataCriacao,
      valorDesconto: data.valor,
      percentualDesconto: data.percentual,
    };
  });
};

export default fetchCoupon;
