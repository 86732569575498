export const DISCOUNT_TYPES = {
  COUPON: 'COUPON',
  PAYMENT_TYPE: 'PAYMENT_TYPE',
  FIXED: 'DISCOUNT_FIXED',
};

export const ITEM_TYPES = {
  COMBO: 'COMBO',
  PACOTE: 'PACOTE',
  PRODUTO: 'PRODUTO',
};

export const ITEM_MAPPERS = {
  [ITEM_TYPES.COMBO]: combo => {
    const valorTabela = combo.pacotesCombo
      .map(pc => pc.pacote.valorPacote)
      .reduce((soma, valor) => soma + valor, 0);

    const valorEcommerce = combo.pacotesCombo
      .map(pc => pc.valorPacoteCombo)
      .reduce((soma, valor) => soma + valor, 0);

    const quantidadeSessoes = Math.max(
      ...combo.pacotesCombo.map(pc => pc.pacote.quantidadeSessoes),
    );

    return {
      tipo: 'COMBO',
      id: combo.id,
      descricao: combo.descricao,
      valorTabela,
      valorEcommerce,
      quantidadeSessoes,
      hairColor: combo.hairColor,
      skinColor: combo.skinColor,
      sexo: combo.sexo,
      pacotes: combo.pacotesCombo.map(it => ({
        ...ITEM_MAPPERS[ITEM_TYPES.PACOTE](it.pacote),
        id: it.idPacote,
        hairColor: combo.hairColor,
        valorEcommerce: it.valorPacoteCombo,
      })),
      imagens: {
        feminino: {
          alta: combo.urlImagemFemininaAltaResolucao,
          baixa: combo.urlImagemFemininaBaixaResolucao,
        },
        masculino: {
          alta: combo.urlImagemMasculinaAltaResolucao,
          baixa: combo.urlImagemMasculinaBaixaResolucao,
        },
      },
    };
  },

  [ITEM_TYPES.PACOTE]: pacote => {
    return {
      tipo: 'PACOTE',
      id: pacote.id,
      idProduto: pacote.idProduto,
      descricao: pacote.descricao,
      valorTabela: pacote.valorPacote,
      valorEcommerce: pacote.valorVendaOnline,
      quantidadeSessoes: pacote.quantidadeSessoes,
      hairColor: pacote.hairColor,
      skinColor: pacote.skinColor,
      sexo: pacote.sexo,
      imagens: {
        feminino: {
          alta: pacote.urlImagemFemininaAltaResolucao,
          baixa: pacote.urlImagemFemininaBaixaResolucao,
        },
        masculino: {
          alta: pacote.urlImagemMasculinaAltaResolucao,
          baixa: pacote.urlImagemMasculinaBaixaResolucao,
        },
      },
    };
  },

  // eslint-disable-next-line no-unused-vars
  [ITEM_TYPES.PRODUTO]: () => {
    throw Error(`Mapper not implemented for type PRODUTO`);
  },
};
