export const contracts = {
  Contracts: 'Contratos',
  'Days overdue': 'Dias em atraso',
  '{{days}} days': '{{days}} dias',
  'Final installment value': 'Valor final da parcela',
  'Fine of': 'Multa de',
  'Load more': 'Carregar mais',
  'More details': 'Mais detalhes',
  Overdue: 'Vencidas',
  'Original installment value': 'Valor original da parcela',
  Paid: 'Pagas',
  Pay: 'Pagar',
  'Pay all debts': 'Pagar todas as dívidas',
  'Payment methods': 'Métodos de pagamento',
  Pending: 'A vencer',
  'View your contracts, handle your pendencies and see payment options':
    'Visualize seus contratos, acerte pendências e veja opções de pagamento',
  "Your don't have any contracts yet": 'Você ainda não possui nenhum contrato.',
};
