export const validations = {
  'Card name can not have less than {{characters}} characters':
    'El nombre de la tarjeta no puede tener menos de {{characters}} caracteres',
  'Card name can not have more than {{characters}} characters':
    'El nombre de la tarjeta no puede tener más de {{characters}} caracteres',
  'Inform the card number': 'Informa el numero de la tarjeta',
  "Inform the card's cvc": 'Informa el código de de seguridad de la tarjeta',
  'Inform the expiry date': 'Informa la fecha de vencimiento',
  'Inform the name printed on the card':
    'Informar el nombre impreso en la tarjeta',
  'Inform the number of installments': 'Informa el numero de cuotas',
  'It must be a valid date': 'Debe ser una fecha válida',
  'It must have at least {{count}} digits':
    'Debe tener mínimo {{count}} dígitos',
  'It must have {{count}} digits maximum':
    'Debe tener máximo {{count}} dígitos',
  'It should contain only numbers': 'Sólo debe contener números',
  'Please, inform a valid name': 'Informa un nombre válido',
  'Unrecognized flag': 'Bandera no reconocida',
};
