type TSpace = number | string;

interface ISpacing {
  (value: TSpace): string;
  (vertical: TSpace, horizontal: TSpace): string;
  (top: TSpace, horizontal: TSpace, bottom: TSpace): string;
  (top: TSpace, right: TSpace, bottom: TSpace, left: TSpace): string;
}

const DEFAULT_REM_FACTOR = 0.5; // 8px
const PX_TO_REM_RATIO = 16;

const valueToString = (value: TSpace, factor: number): string => {
  if (typeof value !== 'string') return `${value * factor}rem`;

  if (value.includes('px')) {
    const numericOnly = value.replace(/[a-z]/gi, '');

    const remValue = Number(numericOnly) / PX_TO_REM_RATIO;

    return `${remValue}rem`;
  }

  return value;
};

const spacing =
  (factor: number): ISpacing =>
  (...args: TSpace[]) => {
    const argsToString = args.map(arg => valueToString(arg, factor));
    const spacingString = argsToString.join(' ');

    return spacingString;
  };

export const layout = {
  breakpoints: {
    sm: '600px',
    md: '992px',
    lg: '1280px',
  },
  spacing: spacing(DEFAULT_REM_FACTOR),
  shadows: {
    card: '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 1px 12px rgba(0, 0, 0, 0.04)',
    popup:
      '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  },
};
