import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { TRoutePaths, TRouteQuery } from '../models';

type TUseRouteQuery<T extends TRoutePaths> = TRouteQuery<T> extends never
  ? Record<string, never>
  : TRouteQuery<T>;

const convertSearchValue = (value: string | null) => {
  if (value === null) return false;

  const numeric = Number(value);

  if (!isNaN(numeric)) return numeric;

  if (value === 'true') return true;

  if (value === 'false') return false;

  return value;
};

const useRouteQuery = <T extends TRoutePaths>(): TUseRouteQuery<T> => {
  const location = useLocation();

  const query = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    const query: Record<string, string | number | boolean> = {};

    const searchKeys = searchParams.keys();

    let searchKeyItem = searchKeys.next();

    while (!searchKeyItem.done) {
      const searchKey = searchKeyItem.value;
      const searchValue = searchParams.get(searchKey);

      query[searchKey] = convertSearchValue(searchValue);

      searchKeyItem = searchKeys.next();
    }

    return query as TUseRouteQuery<T>;
  }, [location.search]);

  return query;
};

export default useRouteQuery;
