import { ISendVerificationCodeDTO } from 'shared/domain/dtos';
import { IVerificationCodeRepository } from 'shared/domain/repositories';

export default class SendVerificationCodeUseCase {
  constructor(
    private verificationCodeRepository: IVerificationCodeRepository,
  ) {}

  public async execute(params: ISendVerificationCodeDTO) {
    return this.verificationCodeRepository.create(params);
  }
}
