import { darkPalette } from './darkPalette';
import { ITheme } from '../models/ITheme';

export const blackNovemberPalette: ITheme['palette'] = {
  ...darkPalette,
  neutral: {
    900: '#FFFFFF',
    800: '#F5F5F5',
    700: '#E9E9E9',
    600: '#D6D6D6',
    500: '#A6A6A6',
    400: '#767676',
    300: '#585858',
    200: '#444444',
    100: '#2B2B2B',
    50: '#1D1D1D',
  },
};
