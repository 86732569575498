import { ISendNpsDTO } from 'shared/domain/dtos';
import { UseCaseError } from 'shared/domain/errors';
import INpsRepository from 'shared/domain/repositories/INpsRepository';

export default class SendNpsUseCase {
  constructor(private npsRepository: INpsRepository) {}

  public async execute(params: ISendNpsDTO) {
    if (params.evaluation <= 0) {
      throw new UseCaseError('Você precisa informar uma nota para a sessão!');
    }

    return this.npsRepository.create(params);
  }
}
