import styled, { css } from 'styled-components';

import { Link } from 'shared/presentation/contexts';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    span {
      font-size: ${theme.typography.sizes.caption};
      color: ${theme.palette.error.main};
    }
  `}
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border: 1px solid transparent;

    border-radius: 0.75rem;
    background: ${theme.palette.neutral[100]};

    label {
      display: flex;
      align-items: center;
      padding: ${theme.layout.spacing(0, 1.5)};
      font-size: 1.25rem;
      color: ${theme.palette.text.light};
    }

    input {
      padding: ${theme.layout.spacing(1.5, 1, 1.5, 0)};
      width: 100%;
      color: ${theme.palette.text.main};
      outline: none;
      background: transparent;
      border: none;
      transition: all 0.3s linear;
      font-size: ${theme.typography.sizes.body2};
      font-weight: ${theme.typography.weight.light};
      transition: all 0.2s;

      &::placeholder {
        color: ${theme.palette.text.light};
      }
    }

    &:focus-within {
      border-color: ${theme.palette.primary.light};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    overflow: auto;
    background-color: ${theme.palette.neutral[100]};
    border-radius: 0.75rem;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
      0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    position: absolute;
    z-index: 500;

    top: ${theme.layout.spacing(6)};
    max-height: ${theme.layout.spacing(48)};
    width: 100%;

    display: flex;
    padding: ${theme.layout.spacing(1)};
    flex-direction: column;
    gap: ${theme.layout.spacing(1)};

    h3 {
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.sizes.title3};
      font-weight: ${theme.typography.weight.bold};
    }

    ul {
      list-style: none;

      display: flex;
      flex-direction: column;
    }

    li {
      cursor: pointer;
    }
  `}
`;

export const ItemLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.layout.spacing(1.5, 1)};
    align-items: center;
    gap: ${theme.layout.spacing(1.5)};
    background: transparent;
    transition: all 0.2s;
    border-radius: 0.5rem;

    > div {
      flex: 1;
      display: flex;
      gap: ${theme.layout.spacing(1.5)};

      > span {
        color: ${theme.palette.text.main};
        font-size: ${theme.typography.sizes.body1};
        font-weight: ${theme.typography.weight.regular};
        flex: 1;
      }

      > div {
        display: flex;
        width: ${theme.layout.spacing(30)};
        padding: ${theme.layout.spacing(0, 2)};
        align-items: center;
        gap: ${theme.layout.spacing(1)};

        span {
          flex: 1;
        }

        .from {
          color: ${theme.palette.neutral[500]};
          font-size: ${theme.typography.sizes.body2};
          font-weight: ${theme.typography.weight.bold};
          text-align: center;
          text-decoration: line-through;
        }

        .to {
          color: ${theme.palette.primary.dark};
          font-size: ${theme.typography.sizes.body1};
          font-weight: ${theme.typography.weight.extraBold};
          text-align: center;
        }
      }
    }

    :hover {
      background: ${theme.palette.neutral[200]};
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      > div {
        flex-direction: column;

        > span {
          text-align: center;
        }

        > div {
          width: auto;
          padding: 0;
        }
      }
    }
  `}
`;
