import React, { ComponentProps, useState } from 'react';

import { TextInput } from 'shared/presentation/components/molecules';
import { ICONS } from 'shared/presentation/constants';

import * as S from './styles';

type TInputProps = Omit<ComponentProps<typeof TextInput>, 'type' | 'icon'>;

const PasswordInput: React.FC<TInputProps> = props => {
  const [type, setType] = useState<'password' | 'text'>('password');

  const Icon = type === 'password' ? ICONS.EYE : ICONS.EYE_OFF;

  const handleToggleType = () =>
    setType(type => (type === 'password' ? 'text' : 'password'));

  return (
    <TextInput
      {...props}
      type={type}
      icon={() => (
        <S.Button type="button" onClick={handleToggleType}>
          <Icon />
        </S.Button>
      )}
    />
  );
};

export default PasswordInput;
