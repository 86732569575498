import { useMutation } from 'react-query';

import { ISendVerificationCodeDTO } from 'shared/domain/dtos';
import { IVerificationCode } from 'shared/domain/entities';
import { makeSendVerificationCodeUseCase } from 'shared/domain/useCases';
import { useRequestErrorHandler } from 'shared/presentation/hooks';

interface IParams {
  onSuccess: (data: IVerificationCode) => void;
}

export default function useSendVerificationCode(params: IParams) {
  const handleError = useRequestErrorHandler('shared');

  const mutation = useMutation(
    (values: ISendVerificationCodeDTO) =>
      makeSendVerificationCodeUseCase().execute(values),
    {
      onSuccess: params.onSuccess,
      onError: error => handleError(error),
    },
  );

  return {
    loading: mutation.isLoading,
    send: mutation.mutateAsync,
  };
}
