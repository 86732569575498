import { ButtonHTMLAttributes } from 'react';

import * as S from './styles';

interface IIconButtonProps {
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  size?: number;
  children: JSX.Element;
  variant?: S.TVariant;
}

const IconButton: React.FC<IIconButtonProps> = ({
  onClick,
  type,
  disabled,
  children,
  size = 3,
  variant,
}) => (
  <S.Container
    type={type}
    onClick={onClick}
    disabled={disabled}
    size={size}
    variant={variant}
  >
    {children}
  </S.Container>
);

export default IconButton;
