import { IPhonesValidityRepository } from 'shared/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisPhonesValidityRepository from './implementations/VectisPhonesValidityRepository';

let instance: IPhonesValidityRepository | null = null;

export default function makePhonesValidityRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisPhonesValidityRepository(httpProvider);
  }

  return instance;
}
