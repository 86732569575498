import { memo } from 'react';

import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Slider = styled(ReactCarousel)(({ theme }) => ({
  '& .control-dots': {
    '& .dot': {
      background: `${theme.palette.primary.main} !important`,
      width: '12px !important',
      height: '12px !important',
      boxShadow: '1px 1px 2px rgba(0,0,0,0.4) !important',
      outline: 0,
    },
  },
  '& .carousel': {
    '& .thumbs-wrapper': {
      height: 0,
      margin: 0,
    },
  },
  '& button': {
    padding: '0 !important',
  },
  '& .slide': {
    paddingBottom: '1rem',
    background: 'transparent !important',
    alignSelf: 'center',
  },
  '& #banner-link': {
    width: '100%',
  },
}));

const Carousel = memo(
  ({
    children,
    autoPlay,
    swipeable,
    showArrows,
    stopOnHover,
    emulateTouch,
    infiniteLoop,
    showIndicators,
  }) => {
    return (
      <Slider
        autoPlay={autoPlay}
        showArrows={showArrows}
        swipeable={swipeable}
        emulateTouch={emulateTouch}
        infiniteLoop={infiniteLoop}
        stopOnHover={stopOnHover}
        interval={6000}
        showStatus={false}
        showIndicators={showIndicators}
        showThumbs={false}
      >
        {children}
      </Slider>
    );
  },
);

Carousel.defaultProps = {
  children: [],
  autoPlay: false,
  showArrows: false,
  swipeable: false,
  emulateTouch: false,
  infiniteLoop: false,
  stopOnHover: false,
  showIndicators: false,
};

Carousel.propTypes = {
  children: PropTypes.array,
  autoPlay: PropTypes.bool,
  showArrows: PropTypes.bool,
  swipeable: PropTypes.bool,
  emulateTouch: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  stopOnHover: PropTypes.bool,
  showIndicators: PropTypes.bool,
};

export default Carousel;
