import styled from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.section`
  display: flex;

  justify-content: space-evenly;

  padding: 3rem 8rem 0;

  overflow: hidden;

  .logo {
    margin-top: -6rem;
    width: 22rem;
  }

  .models {
    width: 37rem;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    padding: 0;
    padding-top: 2rem;
    align-items: center;

    .logo {
      margin-top: 0;
    }
  }
`;
