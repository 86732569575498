import React from 'react';

import { Listbox } from '@headlessui/react';
import styled, { css } from 'styled-components';

const ERROR_SPAN_HEIGHT = 2.25;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing(1)};
    position: relative;

    label {
      cursor: pointer;
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.regular};
    }

    .error {
      font-size: ${theme.typography.sizes.caption};
      color: ${theme.palette.error.main};
      font-weight: ${theme.typography.weight.regular};
      height: ${theme.layout.spacing(ERROR_SPAN_HEIGHT)};
    }
  `}
`;

interface ITriggerProps {
  $hasError: boolean;
  value: string;
  backgroundColor?: string;
  children: React.ReactNode;
}

export const Trigger = styled.button<ITriggerProps>`
  ${({ theme, value, $hasError, backgroundColor }) => css`
    font-size: ${theme.typography.sizes.body1};
    padding: ${theme.layout.spacing(2, 3)};
    background: ${backgroundColor || theme.palette.neutral[100]};
    border: none;
    width: 100%;
    border-radius: 0.75rem;
    outline: none;
    transition: border 0.3s linear;
    border: 1px solid ${backgroundColor || theme.palette.neutral[100]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-align: left;

    ${$hasError &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

    ${value
      ? css`
          color: ${theme.palette.text.main};
        `
      : css`
          color: ${theme.palette.text.light};
        `}

    option {
      text-transform: uppercase;
      color: ${theme.palette.text.main};
    }

    .placeholder {
      text-transform: none;
    }

    :disabled {
      cursor: not-allowed;
      border-color: ${theme.palette.neutral[50]};
      color: ${theme.palette.text.light};
      opacity: 0.7;
    }

    svg {
      color: ${$hasError
        ? theme.palette.error.main
        : value
        ? theme.palette.primary.main
        : theme.palette.neutral[500]};
      font-size: 1.2rem;
      transition: all 0.2s;
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      svg {
        display: none;
      }
    }
  `}
`;

const ITEM_HEIGHT = 5.5;
const MAX_ITEMS = 5;
const CONTENT_OFFSET = 2;

const getBottomDistance = (numberOfItems: number, hasError: boolean) => {
  const errorSpanSpacing = hasError ? ERROR_SPAN_HEIGHT : 0;

  if (numberOfItems <= MAX_ITEMS) {
    return numberOfItems * ITEM_HEIGHT + CONTENT_OFFSET - errorSpanSpacing;
  }

  return ITEM_HEIGHT * MAX_ITEMS + CONTENT_OFFSET - errorSpanSpacing;
};

type TContentProps = {
  numberOfItems: number;
  $hasError: boolean;
  asAbsolute?: boolean;
  children: React.ReactNode;
};

export const Content = styled(Listbox.Options)<TContentProps>`
  ${({ theme, numberOfItems, $hasError, asAbsolute }) => css`
    overflow: auto;
    background-color: ${theme.palette.neutral[50]};
    border-radius: 0.75rem;
    box-shadow: ${theme.layout.shadows.popup};

    ${asAbsolute &&
    css`
      position: absolute;
      z-index: 500;
    `};

    bottom: -${theme.layout.spacing(getBottomDistance(numberOfItems, $hasError))};
    height: ${theme.layout.spacing(numberOfItems * ITEM_HEIGHT)};
    max-height: ${theme.layout.spacing(MAX_ITEMS * ITEM_HEIGHT)};
    width: 100%;

    font-size: ${theme.typography.sizes.body1};

    li {
      display: flex;
      align-items: center;

      cursor: pointer;
      padding: ${theme.layout.spacing(2, 3)};

      transition: color 0.2s;

      height: ${theme.layout.spacing(ITEM_HEIGHT)};

      outline: none;

      &[data-headlessui-state='selected'] {
        color: ${theme.palette.primary.main};
      }

      &:hover {
        color: ${theme.palette.primary.main};
      }

      &.placeholder {
        text-transform: none;
        color: ${theme.palette.neutral[400]};
        font-size: ${theme.typography.sizes.body2};
      }
    }
  `}
`;

export const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 100%;

    height: ${theme.layout.spacing(ITEM_HEIGHT)};

    padding: ${theme.layout.spacing(2, 3)};

    outline: none;

    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${theme.palette.primary.main};

    font-size: ${theme.typography.sizes.body1};

    color: ${theme.palette.text.main};
  `}
`;
