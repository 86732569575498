export const phoneUpdate = {
  'Phone update': 'Actualización del teléfono',
  'Hello <1>{{name}}</1>! Please, confirm your document to procceed with your phone update':
    '¡Hola <1>{{name}}</1>! Confirme su CI para continuar actualizando su teléfono.',
  '<0>{{name}}</0>, fill the field below with a new valid phone':
    '<0>{{name}}</0>, completa el campo a continuación con su nuevo teléfono',
  'The phone must be different than the current one':
    'El teléfono debe ser diferente al actual.',
  'Your current phone': 'Tu teléfono actual',
  'Your phone was successfully updated!':
    '¡Tu teléfono ha sido actualizado exitosamente!',
};
