import { memo } from 'react';

import { Container, useMediaQuery } from '@material-ui/core';
import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Banners from './banners';
import * as S from './styles';
import CardList from '../../../components/TopPresentationTechnology/CardList';
import SliderCards from '../../../components/TopPresentationTechnology/SliderCards';

const HomePresentation = memo(() => {
  const history = useHistory();

  const isUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  function HandleLink(hash) {
    history.push(`quem-somos/#${hash}`);
  }

  return (
    <S.Container>
      <Banners />
      <S.Wrapper>
        <Container>
          {isUpMd ? (
            <CardList iconButton={<FiPlus size={20} />} click={HandleLink} />
          ) : (
            <SliderCards click={HandleLink} />
          )}
        </Container>
      </S.Wrapper>
    </S.Container>
  );
});
HomePresentation.displayName = 'HomePresentation';

export default HomePresentation;
