import { Buffer } from 'buffer';

import IHashProvider from 'shared/domain/providers/IHashProvider';
import IParsingProvider from 'shared/domain/providers/IParsingProvider';

export default class Base64HashProvider implements IHashProvider {
  constructor(private parsingProvider: IParsingProvider) {}

  public encode(data: unknown): string {
    const stringified = this.parsingProvider.stringify(data);

    return Buffer.from(stringified).toString('base64');
  }

  public decode<T>(hash: string): T {
    const decoded = Buffer.from(hash, 'base64').toString('utf-8');

    return this.parsingProvider.parse(decoded);
  }
}
