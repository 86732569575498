import { Grid, useMediaQuery, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import '../../../index.css';
import Card from './Card';
import Carousel from '../../../components/Carousel';
import CustomHr from '../../../components/CustomHr';
import GreenBackground from '../../../components/GreenBackground';
import axilas from '../../../img/axilas.png';
import pernas from '../../../img/pernas.png';
import { Container } from '../../../styles/global';

const useStyles = makeStyles(theme => ({
  subtitle: {},
  slide: {
    width: '100%',
    '& .slick-dots': {
      '& li button::before': {
        fontSize: '10px',
        color: theme.palette.primary.light,
        opacity: 0.5,
      },
      '& li.slick-active button::before': {
        color: theme.palette.primary.main,
        opacity: 0.7,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '65%',
      '& .slick-list': {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '13px',
      },
    },
    [theme.breakpoints.down('455')]: {
      width: '85%',
    },
  },
  cards: {
    color: '#F00',
    '&:first-child': {
      marginRight: 15,
    },
  },
}));

export default function Packages() {
  const { t } = useTranslation();
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const classes = useStyles();

  const renderMotive = (motive, index) => (
    <div key={index}>
      <Grid
        item
        xs={12}
        key={index}
        container
        alignItems="center"
        justifyContent="center"
        className={classes.cards}
      >
        <Card data={motive} />
      </Grid>
    </div>
  );

  const whys = [
    {
      image: pernas,
      big: true,
      title: 'Promoções de Depilação a Laser',
      text: 'Encontre as melhores ofertas</br> de combos e pacotes do mês',
      link: '/pacotes',
    },
    {
      image: axilas,
      big: false,
      title: 'Pacotes de Depilação a Laser',
      text: 'Elimine os pelos<br> de uma área específica',
      link: '/pacotes',
    },
  ];

  return (
    <GreenBackground>
      <Container>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          style={{ marginBottom: '-155px' }}
        >
          <Grid item xs={11}>
            <Typography
              variant="h1"
              component="h2"
              color="textPrimary"
              align="center"
              gutterBottom
            >
              {t(
                'Valores da depilação a laser descomplicada até na hora de comprar!',
              )}
            </Typography>
            <CustomHr centered />
            <Typography
              variant="h3"
              color="textPrimary"
              className={classes.subtitle}
              align="center"
              gutterBottom
            >
              {t(
                'Pacotes e combos especiais de depilação a laser para quem não tem tempo, nem grana para perder',
              )}
            </Typography>
          </Grid>
          <Grid item container xs={11} md={7} justifyContent="center">
            {isXs ? (
              <Carousel
                autoPlay
                showArrows
                swipeable
                emulateTouch
                infiniteLoop
                stopOnHover
              >
                {whys.map(renderMotive)}
              </Carousel>
            ) : (
              whys.map(renderMotive)
            )}
          </Grid>
        </Grid>
      </Container>
    </GreenBackground>
  );
}
