import { useContext, useState, createContext } from "react";
import { trackData } from "../helpers/Trackers";

const EventsContext = createContext({});

function EventProvider({ children }) {
  const [eventType, setEventType] = useState("");

  const setEventsType = event => {
    if (
      event === "clickPopupCupomDescontoRedirectRegisterCustomer" ||
      event === "popupCupomDescontoNewCustomerSave"
    ) {
      trackData("/login", event, {});
    }
    setEventType(event);
  };

  return (
    <EventsContext.Provider
      value={{
        eventType,
        setEventsType,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
}

function useEvents() {
  const context = useContext(EventsContext);

  if (!context) {
    throw new Error("useAuth must be used within an EventProvider");
  }

  return context;
}

export { EventProvider, useEvents };
