import {
  InputHTMLAttributes,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  Fragment,
} from 'react';

import { useField } from 'formik';
import MaskedInput from 'react-text-mask';

import { Spinner } from 'shared/presentation/components/atoms';
import { TIconType, TMask } from 'shared/presentation/constants';
import { useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const getLabel = (label: string, required?: boolean) => {
  if (!required) return label;

  return label + ' *';
};

interface ITextInputProps {
  name: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  onFocus?: InputHTMLAttributes<HTMLInputElement>['onFocus'];
  maxLength?: InputHTMLAttributes<HTMLInputElement>['maxLength'];
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled'];
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder'];
  required?: InputHTMLAttributes<HTMLInputElement>['required'];
  className?: InputHTMLAttributes<HTMLInputElement>['className'];
  onValueChange?: (value: string) => void;

  label?: string;
  isLoading?: boolean;
  icon?: TIconType;
  mask?: TMask;

  textTransform?: 'uppercase' | 'lowercase';
  fieldValueFormatter?: (text: string) => string;
  backgroundColor?: string;
}

interface ITextInputRef {
  resetInnerValue(): void;
}

const TextInput: ForwardRefRenderFunction<ITextInputRef, ITextInputProps> = (
  {
    name,
    label,
    disabled,
    isLoading,
    icon: Icon = Fragment,
    mask,
    required,
    className,
    textTransform,
    fieldValueFormatter = text => text,
    onValueChange,
    backgroundColor,
    ...input
  },
  ref,
) => {
  const { theme } = useTheme();

  const [field, meta, helpers] = useField<string>(name);
  const maskedInputRef = useRef<MaskedInput>(null);
  const hasError = Boolean(meta.error && meta.touched);

  useImperativeHandle(
    ref,
    () => ({
      resetInnerValue: () => {
        const input = maskedInputRef.current?.inputElement as
          | HTMLInputElement
          | undefined;

        if (!input) return;

        input.value = '';
      },
    }),
    [],
  );

  return (
    <S.Container
      disabled={disabled}
      hasError={hasError}
      value={field.value}
      textTransform={textTransform}
      backgroundColor={backgroundColor}
      className={className}
    >
      {!!label && <label htmlFor={name}>{getLabel(label, required)}</label>}

      <div>
        <MaskedInput
          {...field}
          ref={maskedInputRef}
          id={name}
          name={name}
          mask={mask || false}
          onChange={event => {
            const value = fieldValueFormatter(event.target.value);
            helpers.setValue(value);
            onValueChange?.(value);
          }}
          guide={false}
          {...input}
        />

        {isLoading ? (
          <Spinner size={1} color={theme.palette.primary.main} />
        ) : (
          <Icon />
        )}
      </div>

      {hasError && <span>{meta.error}</span>}
    </S.Container>
  );
};

export default forwardRef(TextInput);
