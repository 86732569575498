import React, { useEffect, useState } from 'react';

import * as RadixAccordion from '@radix-ui/react-accordion';
import { FiMinus, FiPlus } from 'react-icons/fi';

import * as S from './styles';

interface IAccordionProps {
  header: React.ReactNode;
  disabled?: boolean;
}

const Accordion: React.FC<IAccordionProps> = ({
  header,
  disabled,
  children,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (disabled) setValue('');
  }, [disabled]);

  return (
    <RadixAccordion.Root
      type="single"
      collapsible
      value={value}
      onValueChange={setValue}
      disabled={disabled}
    >
      <S.Item value="item">
        <RadixAccordion.Header>
          <S.Trigger disabled={disabled}>
            {header}

            {value ? <FiMinus /> : <FiPlus />}
          </S.Trigger>
        </RadixAccordion.Header>

        <S.Content>{children}</S.Content>
      </S.Item>
    </RadixAccordion.Root>
  );
};

export default Accordion;
