import { TLanguage } from 'config/tenancy';

type TParams = {
  countryCode?: string;
  language?: TLanguage;
  message?: string;
  number?: string;
};

const BASE_URL = 'https://api.whatsapp.com/send';

export default function buildWhatsappLink({
  countryCode,
  number,
  message,
  language,
}: TParams) {
  const url = new URL(BASE_URL);

  if (language) url.searchParams.set('1', language);
  if (message) url.searchParams.set('text', message);
  if (number) {
    const wholeNumber = countryCode + number.replaceAll(' ', '');

    url.searchParams.set('phone', wholeNumber);
  }

  return url.toString();
}
