import { TCodeVerificationProccessType } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IVerificationCodeAvailabilityRepository } from 'shared/domain/repositories';
import { TFindParams } from 'shared/domain/repositories/IVerificationCodeAvailabilityRepository';

const PROCCESS_TRANSLATION: Record<TCodeVerificationProccessType, string> = {
  ADDITIVE: 'GERACAO_ADITIVO_CONTRATO',
  SCHEDULE: 'CADASTRO_AGENDA',
  CHECKOUT: 'ASSINATURA_CONTRATO',
};

export default class VectisVerificationCodeAvailabilityRepository
  implements IVerificationCodeAvailabilityRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public async find(params: TFindParams): Promise<{ avaiable: boolean }> {
    const { habilitarSolicitacaoCodigo } = await this.httpProvider.get<{
      habilitarSolicitacaoCodigo: boolean;
    }>('/codigoConfirmacao/configuracao', {
      params: {
        idEntidade: params.entityId,
        processo: PROCCESS_TRANSLATION[params.proccess],
      },
    });

    return { avaiable: habilitarSolicitacaoCodigo };
  }
}
