import styled from 'styled-components';

import { theme } from '../../theme';

const ColoredDottedH2 = styled.h2`
  color: ${theme.colors.secondary};
  font-size: 3.75rem;
  font-weight: bold;
  padding: 0 1rem;
  border-left: 4px solid ${theme.colors.primary};
  margin: 2rem 0;
`;

export default ColoredDottedH2;
