import { IconBase, IconType } from 'react-icons';

const ShieldWithCheck: IconType = props => (
  <IconBase
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M16 29.8333C16 29.8333 26.6667 24.5 26.6667 16.5V7.16666L16 3.16666L5.33337 7.16666V16.5C5.33337 24.5 16 29.8333 16 29.8333Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1818 13L14.1818 20L11 16.8182"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </IconBase>
);

export default ShieldWithCheck;
