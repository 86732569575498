export const QUESTIONS = {
  es: [
    {
      title: '¿Duele la depilación láser?',
      text: 'La tecnología que utilizamos es el láser de Alexandrite. El tratamiento no es doloroso y en la mayoría de los casos las sesiones son mucho mejor toleradas comparando con otros métodos de depilación.',
    },
    {
      title: '¿La depilación láser es realmente definitiva?',
      text: 'Sí, la depilación láser es definitiva para los vellos que están en la fase correcta. Para nuevos y que no están en esta fase, se deben realizar más sesiones, 10 son las indicadas.',
    },
    {
      title: '¿El método es eficaz para todos?',
      text: 'Personas que tienen determinadas condiciones hormonales, genética/familiar y la presencia de vellos con pigmentación blanca, pelirroja, rubia y también finos, pueden resistir al tratamiento y se necesitarán algunas sesiones de mantenimiento.',
    },
    {
      title: '¿Quién puede realizar la depilación láser?',
      text: 'Personas que tienen vellos gruesos y oscuros son ideales para el tratamiento. El láser de Alexandrite es contraindicado para personas que tiene la piel oscura, debido a la gran concentración de melanina, por eso contamos con evaluaciones previas y gratuitas para indicar o contraindicar el tratamiento.',
    },
    {
      title: '¿Cuáles son las ventajas y desventajas de la depilación láser?',
      text: 'Las ventajas son: resultado definitivo, menos dolor, mejor calidad y aspecto de la piel, más ahorro, más tiempo para aprovechar mejor la vida. Las desventajas son: la poca eficacia en vellos finos y claros.',
    },
    {
      title: '¿El vello puede volver a crecer?',
      text: 'Después de las 10 sesiones los vellos no volverán a crecer, sin embargo, puede haber determinadas condiciones hormonales especiales que pueden estimular el crecimiento de nuevos vellos y para eso se necesitarán sesiones de mantenimiento. Los vellos claros o pelirrojos no son eliminados.',
    },
    {
      title: '¿Cuáles partes del cuerpo puedo depilar con láser?',
      text: 'Todas, excepto las cejas.',
    },
    {
      title: '¿Cuánto tiempo dura una sesión?',
      text: 'La sesión varía de acuerdo con el área y la cantidad de vellos, por ejemplo, el bozo toma 2 minutos para realizar el procedimiento, las axilas tardan 5 minutos y la ingle apenas 10 minutos.',
    },
    {
      title: '¿Existe algún tipo de restricción para el tratamiento?',
      text: 'Sí, la depilación láser tiene algunos tipos de restricciones, como, por ejemplo: - persona con fototipo alto (piel oscura), pues el láser funciona conforme es atraído por la alta concentración de melanina; - personas bronceadas; - gestantes; - personas que utilizan Roacutan; - persona con herpes activa; - personas que utilizan algún tipo de ácido en la región que será depilada (es necesario suspender su uso algunos días antes y después de la sesión).',
    },
  ],
  'es-CR': [
    {
      title: '¿La depilación láser duele?',
      text: 'La tecnología que utilizamos es el láser de Alexandrite. El tratamiento no es doloroso y en la mayoría de los casos las sesiones son mucho mejor toleradas comparando con otros métodos de depilación.',
    },
    {
      title: '¿La depilación láser es definitiva?',
      text: 'Sí, la depilación láser es definitiva para los vellos que están en la fase correcta. Para nuevos y que no están en esta fase, se deben realizar más sesiones, 10 son las indicadas.',
    },
    {
      title: '¿La depilación láser es eficaz para todos?',
      text: 'Personas que tienen determinadas condiciones hormonales, genética/familiar y la presencia de vellos con pigmentación blanca, pelirroja, rubia y también finos, pueden resistir al tratamiento y se necesitarán algunas sesiones de mantenimiento.',
    },
    {
      title: '¿Quién puede realizarse depilación láser?',
      text: 'Personas que tienen vellos gruesos y oscuros son ideales para el tratamiento. El láser de Alexandrite es contraindicado para personas que tiene la piel oscura, debido a la gran concentración de melanina, por eso contamos con evaluaciones previas y gratuitas para indicar o contraindicar el tratamiento.',
    },
    {
      title: '¿Cuáles son las ventajas y desventajas de la depilación láser?',
      text: 'Las ventajas son: resultado definitivo, menos dolor, mejor calidad y aspecto de la piel, más ahorro, más tiempo para aprovechar mejor la vida. Las desventajas son: la poca eficacia en vellos finos y claros.',
    },
    {
      title: '¿El vello puede volver a crecer?',
      text: 'Después de las 10 sesiones los vellos no volverán a crecer, sin embargo, puede haber determinadas condiciones hormonales especiales que pueden estimular el crecimiento de nuevos vellos y para eso se necesitarán sesiones de mantenimiento. Los vellos claros o pelirrojos no son eliminados.',
    },
    {
      title: '¿Cuáles partes del cuerpo puedo depilar con láser?',
      text: 'Todas, excepto las cejas.',
    },
    {
      title: '¿Cuánto tiempo dura una sesión?',
      text: 'La sesión varía de acuerdo con el área y la cantidad de vellos, por ejemplo, el bozo toma 2 minutos para realizar el procedimiento, las axilas tardan 5 minutos y la ingle apenas 10 minutos.',
    },
    {
      title: '¿Existe algún tipo de restricción para el tratamiento?',
      text: 'Sí, la depilación láser tiene algunos tipos de restricciones, como, por ejemplo: - persona con fototipo alto (piel oscura), pues el láser funciona conforme es atraído por la alta concentración de melanina; - personas bronceadas; - gestantes; - personas que utilizan Roacutan; - persona con herpes activa; - personas que utilizan algún tipo de ácido en la región que será depilada (es necesario suspender su uso algunos días antes y después de la sesión).',
    },
  ],
};
