import { FC, useMemo } from 'react';

import lodash from 'lodash';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from './styles';
import { theme } from '../../constants/theme';
import { ThemeContext } from '../../hooks/useTheme';
import { ITheme } from '../../models/ITheme';
import 'react-loading-skeleton/dist/skeleton.css';

const lightTheme: ITheme = {
  name: 'light',
  ...theme,
};

const StyledComponentsThemeProvider: FC<{
  customTheme?: DeepPartial<ITheme>;
}> = ({ customTheme = {}, children }) => {
  const theme = useMemo(
    () => lodash.merge(lightTheme, customTheme),
    [customTheme],
  );

  const skeletonColors = {
    baseColor: theme.palette.neutral[100],
    highlightColor: theme.palette.neutral[200],
  };

  return (
    <ThemeContext.Provider value={{ theme }}>
      <ThemeProvider theme={theme}>
        <SkeletonTheme {...skeletonColors}>{children}</SkeletonTheme>

        <GlobalStyle />
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default StyledComponentsThemeProvider;
