import styled, { css } from 'styled-components';

export const ChatText = styled.button`
  ${({ theme }) => css`
    position: fixed;

    border-radius: 12px;

    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.text.white};

    padding: ${theme.layout.spacing(1, 3, 1, 1)};

    bottom: 112px;
    left: 15px;

    border: none;
    max-width: ${theme.layout.spacing(20)};

    box-shadow: ${theme.layout.shadows.popup};

    font-size: ${theme.typography.sizes.body1};
    font-weight: ${theme.typography.weight.regular};
    text-align: left;

    transition: all 0.2s;

    z-index: 5000;

    &:after {
      left: 35px;
      content: '';
      position: absolute;
      bottom: -25px;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-top-color: ${theme.palette.primary.main};
    }

    > button {
      position: absolute;
      right: -8px;
      top: -8px;

      width: 32px;
      height: 32px;
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.text.white};
      border-radius: 50%;

      font-size: 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: none;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }

    :hover {
      transform: scale(1.05);
    }
  `}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    position: fixed;
    z-index: 9999998;

    bottom: 80px;
    left: 32px;
    border: none;
    color: ${theme.palette.neutral[800]};
    background-color: ${theme.palette.neutral[50]};

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 28px;
    height: 28px;
    font-size: 20px;
  `}
`;
