import { TVariantLogoProps } from '../../constants';

const PtLogo: React.FC<TVariantLogoProps> = ({ colors, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 196 61"
    xmlSpace="preserve"
    width={size}
  >
    <g>
      <g>
        <path
          fill={colors.laser}
          d="M189.9,12v5.8h-2.9c-1.2,0-2.4,0.2-3.4,0.7c-1.1,0.5-2,1.1-2.8,1.9c-0.8,0.8-1.4,1.7-1.9,2.8
      c-0.5,1.1-0.7,2.2-0.7,3.4v14.7h-5.9V26.6c0-2,0.4-3.9,1.1-5.7c0.8-1.8,1.8-3.3,3.2-4.6c1.3-1.3,2.9-2.4,4.7-3.1
      c1.8-0.8,3.7-1.1,5.7-1.1H189.9z M151.7,20.4c-1.7,1.7-2.6,3.8-2.6,6.3c0,1.3,0.3,2.6,0.9,3.8l2.4-2.4l3.4-3.4
      c1.2-1.2,2.3-2.3,3.5-3.4c1.1-1.1,2-1.9,2.6-2.5c-1.7-0.8-3.4-1.1-5.2-0.8C154.7,18.2,153.1,19,151.7,20.4 M166.2,30.9l4.1,4.1
      l-2,2c-1.4,1.4-3,2.5-4.8,3.2c-1.8,0.7-3.7,1.1-5.6,1.1c-2,0-3.8-0.4-5.6-1.1c-1.8-0.7-3.4-1.8-4.8-3.2c-1.4-1.4-2.5-3.1-3.2-4.9
      c-0.7-1.8-1-3.6-1-5.5s0.3-3.7,1-5.5c0.7-1.8,1.8-3.4,3.2-4.8c1.4-1.4,3.1-2.5,4.9-3.2c1.8-0.7,3.6-1.1,5.5-1.1
      c1.9,0,3.7,0.4,5.5,1.1c1.8,0.7,3.4,1.8,4.9,3.2l2,2l-16.2,16.2c1.7,0.8,3.4,1.1,5.2,0.8c1.8-0.3,3.5-1.1,4.9-2.5L166.2,30.9z
       M138.6,12v5.8h-17.7c-0.8,0-1.5,0.3-2.1,0.8c-0.6,0.6-0.8,1.3-0.8,2.1s0.3,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9h11.7
      c1.2,0,2.4,0.2,3.4,0.7c1.1,0.5,2,1.1,2.8,1.9c0.8,0.8,1.4,1.7,1.9,2.8c0.5,1.1,0.7,2.2,0.7,3.4c0,1.2-0.2,2.4-0.7,3.4
      c-0.5,1.1-1.1,2-1.9,2.8c-0.8,0.8-1.7,1.4-2.8,1.9c-1.1,0.5-2.2,0.7-3.4,0.7h-17.6v-5.8h17.6c0.9,0,1.6-0.3,2.2-0.8
      c0.6-0.6,0.8-1.3,0.8-2.2c0-0.8-0.3-1.5-0.8-2.1c-0.6-0.6-1.3-0.8-2.2-0.8h-11.7c-1.2,0-2.4-0.2-3.4-0.7c-1.1-0.5-2-1.1-2.8-1.9
      c-0.8-0.8-1.4-1.7-1.9-2.8c-0.5-1.1-0.7-2.2-0.7-3.4c0-1.2,0.2-2.4,0.7-3.4c0.5-1.1,1.1-2,1.9-2.8c0.8-0.8,1.7-1.4,2.8-1.9
      c1.1-0.5,2.2-0.7,3.4-0.7H138.6z M104.8,35.4v-8.9c0-1.2-0.2-2.4-0.7-3.4c-0.5-1.1-1.1-2-1.9-2.8c-0.8-0.8-1.7-1.4-2.8-1.9
      c-1.1-0.5-2.2-0.7-3.4-0.7c-1.2,0-2.4,0.2-3.5,0.7c-1.1,0.5-2,1.1-2.8,1.9c-0.8,0.8-1.4,1.7-1.9,2.8c-0.5,1.1-0.7,2.2-0.7,3.4
      c0,1.2,0.2,2.4,0.7,3.5c0.5,1.1,1.1,2,1.9,2.8c0.8,0.8,1.7,1.4,2.8,1.9c1.1,0.5,2.2,0.7,3.5,0.7H104.8z M96,12
      c2,0,3.9,0.4,5.7,1.1c1.8,0.8,3.3,1.8,4.6,3.1c1.3,1.3,2.4,2.9,3.1,4.6c0.8,1.8,1.1,3.7,1.1,5.7v14.7H96c-2,0-3.9-0.4-5.7-1.1
      c-1.8-0.8-3.3-1.8-4.7-3.2c-1.3-1.3-2.4-2.9-3.2-4.7c-0.8-1.8-1.1-3.7-1.1-5.7c0-2,0.4-3.9,1.1-5.7c0.8-1.8,1.8-3.3,3.2-4.6
      c1.4-1.3,2.9-2.4,4.7-3.1C92.1,12.3,94,12,96,12 M73.4,0.2h5.8v41h-5.8V0.2z"
        />
        <path
          fill={colors.via}
          d="M64.5,35.6v-8.7c0-1.2-0.2-2.3-0.7-3.4c-0.4-1-1.1-2-1.9-2.8c-0.8-0.8-1.7-1.4-2.8-1.9
      c-1-0.5-2.2-0.7-3.4-0.7c-1.2,0-2.3,0.2-3.4,0.7c-1.1,0.5-2,1.1-2.8,1.9c-0.8,0.8-1.4,1.7-1.9,2.8c-0.5,1-0.7,2.2-0.7,3.4
      c0,1.2,0.2,2.3,0.7,3.4c0.5,1.1,1.1,2,1.9,2.8c0.8,0.8,1.7,1.4,2.8,1.9c1.1,0.5,2.2,0.7,3.4,0.7H64.5z M56.3,12.6
      c2,0,3.4,0.4,5.1,1.1c1.7,0.8,3.3,1.8,4.6,3.1c1.3,1.3,2.3,2.8,3.1,4.6c0.7,1.7,1.1,3.6,1.1,5.6v14.4H56.3c-2,0-3.8-0.4-5.6-1.1
      c-1.8-0.7-3.3-1.8-4.6-3.1c-1.3-1.3-2.4-2.9-3.1-4.6c-0.7-1.8-1.1-3.6-1.1-5.6c0-2,0.4-3.9,1.1-5.6c0.7-1.7,1.8-3.2,3.1-4.6
      c1.3-1.3,2.9-2.3,4.6-3.1C52.5,12.9,54.4,12.6,56.3,12.6 M34.2,12.6h5.2v28.8h-5.2V12.6z M31.5,12.4L16.8,40.7L2.2,12.4l5.6,0.1
      c0.6,1.2,1.8,3.2,2.5,4.7c0.8,1.5,1.5,3,2.3,4.5c0.8,1.5,1.5,3,2.3,4.4c0.7,1.4,1.4,2.6,1.9,3.7c0.6-1.2,1.4-2.7,2.2-4.3
      c0.8-1.6,1.7-3.2,2.5-4.8c0.8-1.6,1.6-3,2.2-4.4c0.7-1.3,1.6-3.1,1.9-3.8L31.5,12.4z"
        />
        <g>
          <rect x="2.6" y="44.8" width="187" height="0.5" fill={colors.line} />
          <path
            fill={colors.line}
            d="M191.7,43.3c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1
        C189.6,44.2,190.5,43.3,191.7,43.3"
          />
        </g>
      </g>
      <g>
        <path
          fill={colors.bottom}
          d="M34.6,53.3h2.1c0.5,0,0.9,0.1,1.3,0.2c0.4,0.2,0.7,0.4,1,0.6c0.3,0.3,0.5,0.6,0.7,1c0.2,0.4,0.2,0.8,0.2,1.2
      v0c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.6,0.5-1,0.6c-0.4,0.2-0.8,0.2-1.3,0.2h-2.1V53.3z M36.7,58.8
      c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.6,0.2-0.9v0c0-0.3-0.1-0.7-0.2-0.9
      c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.2-1-0.2h-1.4v4.8H36.7z"
        />
        <path
          fill={colors.bottom}
          d="M43.1,53.3h4.4v0.6h-3.7V56h3.3v0.6h-3.3v2.1h3.8v0.6h-4.5V53.3z"
        />
        <path
          fill={colors.bottom}
          d="M50.6,53.3h2.3c0.3,0,0.7,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.4,0.4,0.5,0.6
      c0.1,0.2,0.2,0.5,0.2,0.8v0c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1h-1.5v2.2
      h-0.7V53.3z M52.9,56.6c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.3-0.3,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.6v0
      c0-0.4-0.2-0.8-0.5-1c-0.3-0.2-0.7-0.3-1.2-0.3h-1.6v2.7H52.9z"
        />
        <path fill={colors.bottom} d="M58.2,53.3h0.7v6.1h-0.7V53.3z" />
        <path fill={colors.bottom} d="M62.4,53.3h0.7v5.5h3.4v0.6h-4.1V53.3z" />
        <path
          fill={colors.bottom}
          d="M71.8,53.3h0.6l2.8,6.2h-0.7l-0.7-1.6h-3.3l-0.7,1.6H69L71.8,53.3z M73.5,57.2l-1.4-3.1l-1.4,3.1H73.5z"
        />
        <path
          fill={colors.bottom}
          d="M79.8,60.5l0.7-1c-0.4-0.1-0.7-0.2-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.3-0.4-0.6-0.5-1
      c-0.1-0.4-0.2-0.7-0.2-1.1v0c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.2,1.2-0.2
      c0.3,0,0.5,0,0.8,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.5,0.4l-0.5,0.5c-0.3-0.2-0.5-0.4-0.8-0.6
      s-0.7-0.2-1.1-0.2c-0.3,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,1v0
      c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.2
      c0.3-0.2,0.6-0.4,0.9-0.7l0.5,0.4c-0.2,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.2
      c-0.2,0.1-0.5,0.1-0.7,0.1l-0.6,1.2L79.8,60.5z"
        />
        <path
          fill={colors.bottom}
          d="M88.5,53.3h0.6l2.8,6.2h-0.7l-0.7-1.6h-3.3l-0.7,1.6h-0.7L88.5,53.3z M87.4,52.7c0.1-0.3,0.2-0.6,0.3-0.7
      s0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1
      c0.1-0.1,0.1-0.2,0.2-0.3l0.4,0.1c-0.1,0.3-0.2,0.6-0.3,0.7s-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1
      c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3L87.4,52.7z M90.2,57.2l-1.4-3.1
      l-1.4,3.1H90.2z"
        />
        <path
          fill={colors.bottom}
          d="M97.7,59.5c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.2-1.2
      v0c0-0.4,0.1-0.8,0.2-1.2s0.4-0.7,0.6-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3
      c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.5,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0,0,0,0,0c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.6,1
      c-0.3,0.3-0.6,0.5-1,0.7C98.6,59.4,98.2,59.5,97.7,59.5z M97.7,58.9c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.8-0.5
      c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.6,0.2-1v0c0-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.8-0.5
      c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,1v0
      c0,0.3,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.8,0.5C97.1,58.8,97.4,58.9,97.7,58.9z"
        />
        <path
          fill={colors.bottom}
          d="M110.6,53.3h0.6l2.8,6.2h-0.7l-0.7-1.6h-3.3l-0.7,1.6h-0.7L110.6,53.3z M112.3,57.2l-1.4-3.1l-1.4,3.1H112.3z
      "
        />
        <path fill={colors.bottom} d="M121.2,53.3h0.7v5.5h3.4v0.6h-4.1V53.3z" />
        <path
          fill={colors.bottom}
          d="M130.6,53.3h0.6l2.8,6.2h-0.7l-0.7-1.6h-3.3l-0.7,1.6h-0.7L130.6,53.3z M132.3,57.2l-1.4-3.1l-1.4,3.1H132.3z
      "
        />
        <path
          fill={colors.bottom}
          d="M139,59.5c-0.5,0-0.9-0.1-1.3-0.2c-0.4-0.2-0.8-0.4-1.2-0.7L137,58c0.3,0.3,0.6,0.5,1,0.7
      c0.3,0.1,0.7,0.2,1.1,0.2c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.4,0.4-0.7v0c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
      c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.1-0.8-0.2c-0.4-0.1-0.7-0.2-0.9-0.3c-0.3-0.1-0.5-0.2-0.6-0.4c-0.2-0.1-0.3-0.3-0.4-0.5
      c-0.1-0.2-0.1-0.4-0.1-0.7v0c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.3,0.6-0.3c0.2-0.1,0.5-0.1,0.8-0.1
      c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.7,0.3,1,0.6l-0.4,0.5c-0.3-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-1,0.3
      c-0.2,0.2-0.3,0.4-0.3,0.7v0c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2
      c0.7,0.2,1.2,0.4,1.5,0.6c0.3,0.3,0.5,0.6,0.5,1.1v0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.7,0.3
      C139.6,59.5,139.3,59.5,139,59.5z"
        />
        <path
          fill={colors.bottom}
          d="M144.2,53.3h4.4v0.6h-3.7V56h3.3v0.6h-3.3v2.1h3.8v0.6h-4.5V53.3z"
        />
        <path
          fill={colors.bottom}
          d="M151.8,53.3h2.6c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.4c0.2,0.2,0.3,0.3,0.3,0.5s0.1,0.4,0.1,0.7v0
      c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2l1.9,2.5H156l-1.8-2.4h0h-1.7
      v2.4h-0.7V53.3z M154.3,56.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5v0
      c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.2-0.7-0.3-1.2-0.3h-1.9v2.5H154.3z"
        />
      </g>
    </g>
  </svg>
);

export default PtLogo;
