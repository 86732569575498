import { useQuery } from 'react-query';

import { TCodeVerificationProccessType } from 'shared/domain/entities';
import { makeFetchVerificationCodeAvailabilityUseCase } from 'shared/domain/useCases';

import { sharedQueries } from '../constants';

interface IParams {
  entityId: number;
  proccess: TCodeVerificationProccessType;
  onSuccess?: () => void;
}

export default function useVerificationCodeAvailability({
  entityId,
  proccess,
  onSuccess,
}: IParams) {
  const query = useQuery(
    sharedQueries.verificationCodeAvailability(entityId, proccess),
    async () => {
      return await makeFetchVerificationCodeAvailabilityUseCase().execute({
        entityId,
        proccess,
      });
    },
    { onSuccess },
  );

  return {
    avaiable: query.data?.avaiable || false,
    loading: query.isLoading,
  };
}
