import axios from "axios";

import { useContext, useState, createContext } from "react";

import { useSnackbar } from "notistack";
import { NewCartContext } from "./NewCartContext";

const RecommendationContext = createContext({});

function RecommendationProvider({ children }) {
  const { items: itemsCart } = useContext(NewCartContext);

  const { enqueueSnackbar } = useSnackbar();

  const [recommendations, setRecommendations] = useState([]);
  const [loadingRecommendations, setLoadingRecommendations] = useState(false);

  const getCartIds = async () => {
    const packages = [];
    const combos = [];

    itemsCart.forEach(item =>
      item.tipo === "PACOTE" ? packages.push(item.id) : combos.push(item.id)
    );

    return { packages, combos };
  };

  async function fetchRecommendation({ productId, isCombo }) {
    setLoadingRecommendations(true);

    let { packages, combos } = await getCartIds();

    const hasProductInCart =
      packages.includes(productId) || combos.includes(productId);

    if (!hasProductInCart) {
      if (isCombo) {
        combos = [...combos, productId];
      } else {
        packages = [...packages, productId];
      }
    }

    axios
      .post(`/product/recommendation/multiple`, {
        idCliente: localStorage.getItem("id") || null,
        packages,
        combos,
      })
      .then(response => {
        setRecommendations(response.data);
      })
      .catch(error => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoadingRecommendations(false);
      });
  }

  /**
   * Se o numero de parcelas mudar para um valor abaixo do limite,
   * desabilita a parcela reduzida;
   */

  return (
    <RecommendationContext.Provider
      value={{
        recommendations,
        fetchRecommendation,
        loadingRecommendations,
      }}
    >
      {children}
    </RecommendationContext.Provider>
  );
}

function useRecommendation() {
  const context = useContext(RecommendationContext);

  if (!context) {
    throw new Error("useAuth must be used within an RecommendationProvider");
  }

  return context;
}

export { RecommendationProvider, useRecommendation };
