import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CustomHr from '../../../components/CustomHr';
import VideoPresentation from '../../../components/VideoPresentation';
import ThumbPresentation from '../../../img/thumb/apresentacao.jpg';
import { Container } from '../../../styles/global';

export default function VideoArea() {
  const { t } = useTranslation();
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <div className="home-container">
      <Container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '24px' }}
        >
          <Grid item xs={12} lg={5}>
            <Typography variant="h1" component="h1">
              {t(
                'Depilação a Laser é na Vialaser você merece curtir o melhor da vida sem preocupação',
              )}
            </Typography>
            <CustomHr />
            <Typography component="p">
              Entenda porque a sua pele tem direito a fazer o melhor tratamento
              em depilação a laser do Brasil! A melhor tecnologia com muito mais
              conforto, segurança e rapidez em mais tons de pele.
            </Typography>
          </Grid>

          <VideoPresentation
            title="Video de apresentação"
            width={isXs ? 300 : 425}
            height={isXs ? 169 : 240}
            thumbnail={ThumbPresentation}
            src="https://vialaser.s3-sa-east-1.amazonaws.com/videos/VIALASER_VIDEOMANIFESTO_v2.mp4"
            iconSize={25}
          />
        </Grid>
      </Container>
    </div>
  );
}
