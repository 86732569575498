import { IBenefit } from 'shared/domain/entities/campaign';

export interface IVectisBenefit {
  chavePromocao: 'DESCONTO_PARCELA_4990' | 'DESCONTO_VALOR_100';
  urlIndicacao: string;
  cupomDisponivel?: string;
  quantidadeDescontoParcelaPorIndicacao?: number;
  valorDescontoParcelaPorIndicacao?: number;
  dataParcelaDescontoPorIndicacao: string;
  quantidadeCuponsIndicacao?: number;
  valorDescontoPromocaoIndicador?: number;
  valorDescontoPromocaoIndicado?: number;
  contratosReceberamDescontoParcelaPorIndicacao: Array<{
    idContrato: number;
    cdContrato: number;
    dataContrato: string;
    itens: Array<{
      idItemContrato: number;
      descricao: string;
    }>;
  }>;
  valorMinimoConversaoConcessaoBeneficio?: number;
}

const CAMPAIGN_DICTIONARY: Record<
  IVectisBenefit['chavePromocao'],
  IBenefit['campaign']
> = {
  DESCONTO_PARCELA_4990: 'INSTALLMENT_DISCOUNT',
  DESCONTO_VALOR_100: 'DISCOUNT_COUPON',
};

export class Benefit implements IBenefit {
  public campaign: IBenefit['campaign'];
  public recommendation: IBenefit['recommendation'];
  public amountEarned: IBenefit['amountEarned'];
  public minimumBudgetValue: IBenefit['minimumBudgetValue'];
  public discountedContracts: IBenefit['discountedContracts'];
  public coupon?: IBenefit['coupon'];
  public recommendedDiscountValue: IBenefit['recommendedDiscountValue'];

  constructor({
    chavePromocao,
    contratosReceberamDescontoParcelaPorIndicacao,
    urlIndicacao,
    cupomDisponivel,
    quantidadeCuponsIndicacao,
    quantidadeDescontoParcelaPorIndicacao,
    valorDescontoParcelaPorIndicacao,
    valorDescontoPromocaoIndicador,
    valorDescontoPromocaoIndicado,
    dataParcelaDescontoPorIndicacao,
    valorMinimoConversaoConcessaoBeneficio,
  }: IVectisBenefit) {
    const campaign = CAMPAIGN_DICTIONARY[chavePromocao];
    const amountKey = this.getAmountEarnedKey(campaign);

    const possibleAmounts = {
      quantidadeCuponsIndicacao,
      quantidadeDescontoParcelaPorIndicacao,
    };

    this.campaign = campaign;
    this.recommendation = {
      link: urlIndicacao,
      discount: {
        value:
          (campaign === 'INSTALLMENT_DISCOUNT'
            ? valorDescontoParcelaPorIndicacao
            : valorDescontoPromocaoIndicador) || 0,
        date: dataParcelaDescontoPorIndicacao,
      },
      givenCoupon: {
        value: valorDescontoPromocaoIndicado || 0,
      },
    };
    this.coupon = cupomDisponivel;
    this.amountEarned = possibleAmounts[amountKey] || 0;
    this.minimumBudgetValue = valorMinimoConversaoConcessaoBeneficio || 0;

    this.discountedContracts =
      contratosReceberamDescontoParcelaPorIndicacao.map(contract => ({
        id: contract.idContrato,
        items: contract.itens.map(item => ({
          id: item.idItemContrato,
          name: item.descricao,
        })),
      }));

    this.recommendedDiscountValue = valorDescontoPromocaoIndicado || 0;
  }

  private getAmountEarnedKey(campaign: IBenefit['campaign']) {
    if (campaign === 'DISCOUNT_COUPON') return 'quantidadeCuponsIndicacao';

    return 'quantidadeDescontoParcelaPorIndicacao';
  }
}
