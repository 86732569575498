import { ITheme } from '../models/ITheme';

export const darkPalette: ITheme['palette'] = {
  primary: {
    light: '#32BD57',
    main: '#47D76F',
    dark: '#78E394',
  },
  secondary: {
    light: '#8046B9',
    main: '#9E6AD2',
    dark: '#9D71D2',
  },
  warning: {
    light: '#F0D670',
    main: '#FAD541',
    dark: '#EDC112',
  },
  error: {
    light: '#DD5555',
    main: '#E43232',
    dark: '#C42121',
  },
  neutral: {
    900: '#F8FBFC',
    800: '#F1F8F9',
    700: '#E2EDF0',
    600: '#CBDCE1',
    500: '#94B0B8',
    400: '#647E88',
    300: '#476169',
    200: '#334D55',
    100: '#1E3238',
    50: '#0F212A',
  },
  text: {
    main: '#F9FBFB',
    light: '#BEC7CA',
    dark: '#181E21',
    white: '#F9FBFB',
  },
};
