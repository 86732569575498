import {
  TCodeVerificationProccessType,
  TItemType,
} from 'shared/domain/entities';

export const sharedQueries = {
  base: ['shared'] as const,

  acceptanceTerms: (budgetId: number | undefined) => [
    ...sharedQueries.base,
    'acceptanceTerms',
    budgetId,
  ],

  customerBenefit: (id: number | undefined) =>
    [...sharedQueries.base, 'customerBenefit', id] as const,

  addressData: (postalCode: string | undefined) => [
    ...sharedQueries.base,
    'addressData',
    postalCode,
  ],

  verifyNpsSessionFeedback: (id: number | undefined) =>
    [...sharedQueries.base, 'verifyNpsSessionFeedback', id] as const,

  verificationCodeAvailability: (
    entityId: number | undefined,
    proccess: TCodeVerificationProccessType | undefined,
  ) =>
    [
      ...sharedQueries.base,
      'verificationCodeAvailability',
      entityId,
      proccess,
    ] as const,

  items: (type: TItemType) => [...sharedQueries.base, 'items', type] as const,
};
