import Axios from 'axios';

import { DISCOUNT_TYPES, ITEM_TYPES } from './utils';

export async function fetchPromocoes() {
  return Axios.get('/promotions')
    .then(res => res.data)
    .then(data => {
      return data
        .sort((a, b) => a.id - b.id)
        .reduce((final, curr) => {
          const previous = final.findIndex(a => a.tipo === curr.tipo);
          if (previous >= 0 && final[previous].id < curr.id) {
            final.splice(previous, 1);
          }
          return [...final, curr];
        }, []);
    });
}

function calculateDiscountValue(item, discountPercentage) {
  const { valorTabela, valorEcommerce } = item;
  const appliedDiscount = ((valorTabela - valorEcommerce) * 100) / valorTabela;

  if (appliedDiscount < discountPercentage) {
    return valorTabela * (discountPercentage / 100);
  }
  return valorTabela * (appliedDiscount / 100);
}

const CALCULATORS = {
  [DISCOUNT_TYPES.PROGRESSIVE]: (config, context) => {
    const { items } = context;

    const maxItems = config.faixas.reduce(
      (a, b) => (a > b.quantidadeItens ? a : b.quantidadeItens),
      -1,
    );
    const nroItems = items.reduce((a, b) => {
      return b.tipo === ITEM_TYPES.COMBO ? a + b.pacotes.length : a + 1;
    }, 0);

    const qntPromocao = Math.min(maxItems, nroItems);
    const faixa = config.faixas.find(a => a.quantidadeItens === qntPromocao);
    if (!faixa) {
      return 0;
    }

    const discountPercentage = faixa.percentualDesconto % 100;

    const value = items.reduce((previousValue, item) => {
      return previousValue + calculateDiscountValue(item, discountPercentage);
    }, 0);

    return {
      value,
      percentage: faixa.percentualDesconto,
    };
  },
};
