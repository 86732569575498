import { cardForm } from './cardForm';
import { checkoutCustomerRegistration } from './checkoutCustomerRegistration';
import { completeRegistrationForm } from './completeRegistrationForm';
import { paymentLink } from './paymentLink';

const es = {
  ...paymentLink,
  ...cardForm,
  ...completeRegistrationForm,
  ...checkoutCustomerRegistration,
};

export default es;
