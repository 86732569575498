import { makeStyles, Select, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';

import arrow from '../img/seta-select-areas.svg';

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: '1rem',
    marginLeft: 0,
    marginRight: 0,
    '& > span': {
      marginRight: '8px',
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
  },
  labelTop: {
    alignItems: 'flex-start',
    marginBottom: '0.5em',
  },
  select: {
    borderRadius: '30px',
    border: 'none',
    // boxShadow: "2px 3px 5px -1px rgba(0, 0, 0, 0.2)",
    color: theme.palette.primary.main,
    background: theme.palette.cardsBackground.primary,
    '& > div': {
      padding: '10px 60px 10px 20px',
      '&:focus': {
        backgroundColor: 'rgba(77, 167, 71, 0.07)',
        borderRadius: '30px',
      },
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: '60px',
    },
    '& > fieldset': {
      border: 'none',
    },
  },
  selectLeftLabel: {
    marginLeft: '4px',
  },
  fullWidth: {
    width: '100%',
    padding: 0,
  },
  noLabel: {
    flexDirection: 'row',
    '& > span': {
      marginRight: '0',
    },
  },
  arrow: {
    top: 'calc(50% - 15px)',
    height: '30px',
    width: '30px',
    color: theme.palette.cardsBackground.primary,
  },
}));

export default function RoundedSelect(props) {
  const classes = useStyles();

  return (
    <FormControlLabel
      disabled={props.disabled}
      className={clsx(classes.label, {
        [classes.fullWidth]: props.fullWidth,
        [classes.noLabel]: !props.label,
        [classes.labelTop]: props.labelPlacement === 'top',
        [props.className]: props.className,
      })}
      label={props.label}
      labelPlacement={props.labelPlacement ? props.labelPlacement : 'start'}
      value={props.value}
      control={
        <Select
          disabled={props.disabled}
          IconComponent={iconProps => (
            <img
              alt="Abrir caixa de seleção"
              {...iconProps}
              className={clsx(classes.arrow, iconProps.className)}
              src={arrow}
            />
          )}
          className={clsx(classes.select, {
            [classes.fullWidth]: props.fullWidth,
            [classes.selectLeftLabel]:
              !props.labelPlacement || props.labelPlacement === 'start',
          })}
          variant="outlined"
          id={props.id}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        >
          {props.children}
        </Select>
      }
    />
  );
}
