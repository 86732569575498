import { TLanguage } from 'config/tenancy';
import { ICONS, TIconType } from 'shared/presentation/constants/icons';

type TLink = Record<TLanguage, string>;

const FACEBOOK_LINK: TLink = {
  pt: 'https://www.facebook.com/vialaserdepilacaobr',
  es: 'https://www.facebook.com/vialaserdepilacionpy',
  'es-CR':
    'https://www.facebook.com/people/Vialaser-Costa-Rica/100089848462680/',
};

const INSTAGRAM_LINK: TLink = {
  pt: 'https://www.instagram.com/vialaserdepilacaobr/',
  es: 'https://www.instagram.com/vialaserdepilacionpy/',
  'es-CR': 'https://www.instagram.com/vialaserdepilacioncr/',
};

const LINKEDIN_LINK: TLink = {
  pt: 'https://www.linkedin.com/company/vialaser',
  es: 'https://www.linkedin.com/company/vialaser',
  'es-CR': 'https://www.linkedin.com/company/vialaser',
};

const YOUTUBE_LINK: TLink = {
  pt: 'https://www.youtube.com/channel/UCI-hGSQN2i4l4Q-cYAcK-Yg',
  es: 'https://www.youtube.com/channel/UCI-hGSQN2i4l4Q-cYAcK-Yg',
  'es-CR': 'https://www.youtube.com/channel/UCI-hGSQN2i4l4Q-cYAcK-Yg',
};

const TWITTER_LINK: TLink = {
  pt: 'https://twitter.com/vialaserbr',
  es: 'https://twitter.com/vialaserbr',
  'es-CR': 'https://twitter.com/vialaserbr',
};

interface ISocial {
  key: string;
  icon: TIconType;
  link: TLink;
}

export const SOCIALS_LIST: ISocial[] = [
  {
    key: 'facebook',
    icon: ICONS.FACEBOOK,
    link: FACEBOOK_LINK,
  },
  {
    key: 'instagram',
    icon: ICONS.INSTAGRAM,
    link: INSTAGRAM_LINK,
  },
  { key: 'linkedin', icon: ICONS.LINKEDIN, link: LINKEDIN_LINK },
  { key: 'youtube', icon: ICONS.YOUTUBE, link: YOUTUBE_LINK },
  { key: 'twitter', icon: ICONS.TWITTER, link: TWITTER_LINK },
];

export const SOCIAL_LINKS = {
  FACEBOOK: FACEBOOK_LINK,
  INSTAGRAM: INSTAGRAM_LINK,
  LINKEDIN: LINKEDIN_LINK,
  YOUTUBE: YOUTUBE_LINK,
  TWITTER: TWITTER_LINK,
};
