export const validations = {
  'Card name can not have less than {{characters}} characters':
    'O nome não pode ter menos de {{characters}} caracteres',
  'Card name can not have more than {{characters}} characters':
    'O nome não pode ter mais de {{characters}} caracteres',
  'Inform the card number': 'Informe o número do cartão',
  "Inform the card's cvc": 'Informe o código do cartão',
  'Inform the expiry date': 'Informe a validade',
  'Inform the name printed on the card': 'Informe o nome impresso no cartão',
  'Inform the number of installments': 'Informe o número de parcelas',
  'It must be a valid date': 'Deve ser uma data válida',
  'It must have at least {{count}} digits':
    'Deve ter no mínimo {{count}} dígitos',
  'It must have {{count}} digits maximum':
    'Deve ter no máximo {{count}} dígitos',
  'It should contain only numbers': 'Deve conter apenas números',
  'Please, inform a valid name': 'Informe um nome válido',
  'Unrecognized flag': 'Bandeira não reconhecida',
};
