import { makeVerificationCodeRepository } from 'shared/infra/repositories';

import ValidateVerificationCodeUseCase from './implementations/ValidateVerificationCodeUseCase';

let instance: ValidateVerificationCodeUseCase | null = null;

export default function makeValidateVerificationCodeUseCase() {
  if (!instance) {
    const repository = makeVerificationCodeRepository();

    instance = new ValidateVerificationCodeUseCase(repository);
  }

  return instance;
}
