import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
100% {
  transform: rotate(360deg);
}
`;

const dash = keyframes`
 0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
`;

interface ISVGProps {
  size: number;
  color: string;
}

export const Svg = styled.svg<ISVGProps>`
  ${({ size, color }) => css`
    width: 1.5rem;
    transform-origin: center;
    animation: ${rotate} 2s linear infinite;

    width: ${size}rem;
    height: ${size}rem;

    circle {
      fill: none;
      stroke: ${color};
      stroke-width: 3;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  `}
`;
