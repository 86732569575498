import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IMicroEventsRepository } from 'shared/domain/repositories';
import { TCreateParams } from 'shared/domain/repositories/IMicroEventsRepository';

export default class VectisMicroEventsRepository
  implements IMicroEventsRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public async create({
    channel,
    event,
    userId,
  }: TCreateParams): Promise<void> {
    return this.httpProvider.post('microeventoscliente/registrar', {
      idCliente: userId,
      microEvento: event,
      canal: channel,
    });
  }
}
