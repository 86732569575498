import INpsRepository from 'shared/domain/repositories/INpsRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisNpsRepository from './implementations/VectisNpsRepository';

let instance: INpsRepository | null = null;

export default function makeNpsRepository() {
  if (!instance) {
    const provider = makeHTTPProvider();

    instance = new VectisNpsRepository(provider);
  }

  return instance;
}
