import React from 'react';

import { Helmet } from 'react-helmet';

import { TLanguage } from 'config/tenancy';
import { useLocale } from 'shared/presentation/contexts';

const defaultEnabledLanguages: TLanguage[] = ['es', 'es-CR', 'pt'];

interface IFacebookPixelScriptProps {
  enabledLanguages?: typeof defaultEnabledLanguages;
}

const FacebookPixelScript: React.FC<IFacebookPixelScriptProps> = ({
  enabledLanguages = defaultEnabledLanguages,
}) => {
  const { language } = useLocale();

  if (!enabledLanguages.includes(language)) return null;

  return (
    <Helmet
      script={[
        {
          innerHTML:
            '!function(f,b,e,v,n,t,s)' +
            '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?' +
            'n.callMethod.apply(n,arguments):n.queue.push(arguments)};' +
            "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';" +
            'n.queue=[];t=b.createElement(e);t.async=!0;' +
            't.src=v;s=b.getElementsByTagName(e)[0];' +
            "s.parentNode.insertBefore(t,s)}(window,document,'script'," +
            "'https://connect.facebook.net/en_US/fbevents.js');" +
            "fbq('init', '1341712986374563');" +
            "fbq('track', 'PageView');",
        },
      ]}
    >
      <meta
        name="facebook-domain-verification"
        content="uhkieuaap2iyqb0zxjmuaekuxl6v90"
      />
    </Helmet>
  );
};

export default FacebookPixelScript;
