import { useMemo } from 'react';

import { ICONS } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

const AcceptedPaymentMethods: React.FC = () => {
  const { t, language } = useLocale('shared');

  const METHODS = useMemo(
    () =>
      [
        {
          key: 'credit',
          text: <span>{t('Credit card')}</span>,
          Icon: ICONS.CREDIT_CARD,
        },
        {
          key: 'recurrent',
          text: <span>{t('Recurrent card')}</span>,
          Icon: ICONS.CREDIT_CARD,
        },
        {
          key: 'pix',
          text: <span>PIX</span>,
          Icon: ICONS.PIX,
          onlyOn: 'pt' as const,
        },
      ].filter(method => {
        if (method.onlyOn && method.onlyOn !== language) return false;

        return true;
      }),
    [language, t],
  );

  return (
    <S.Container>
      <h3>{t('Payment methods')}</h3>

      <ul>
        {METHODS.map(method => (
          <li key={method.key}>
            {method.text}

            <method.Icon />
          </li>
        ))}
      </ul>
    </S.Container>
  );
};

export default AcceptedPaymentMethods;
