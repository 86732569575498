export const emailUpdate = {
  'An error happened while retrieving the data, please try again later':
    'Um erro aconteceu aos buscar as informações, tente novamente mais tarde',
  'Email update': 'Atualização de e-mail',
  'Hello <1>{{name}}</1>! Please, confirm your document to procceed with your email update':
    'Olá <1>{{name}}</1>! Por favor, confirme seu CPF para prosseguir com a atualização do email.',
  '<0>{{name}}</0>, fill the field below with a new valid email':
    '<0>{{name}}</0>, preencha o campo abaixo com seu novo e-mail',
  'The email must be different than the current one':
    'O email precisa ser diferente do atual',
  'The link will expire in': 'O link vai expirar em',
  'The link will expire soon': 'O link irá expirar em breve',
  'Times up, your link has expired!': 'O tempo acabou, seu link expirou!',
  Update: 'Atualizar',
  'Your current email': 'Seu e-mail atual',
  'Your email was successfully updated!':
    'Seu e-mail foi atualizado com sucesso!',
};
