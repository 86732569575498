import { FC, Fragment, memo, useState } from 'react';

import { Listbox } from '@headlessui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'shared/presentation/components/atoms';
import { TIconType } from 'shared/presentation/constants';
import { useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

type TGetAdditionalItemsParams = {
  placeholder?: string;
  searchable?: boolean;
};

const getAdditionalItemsCount = (params: TGetAdditionalItemsParams) => {
  let count = 0;

  if (params.searchable) count++;
  if (params.placeholder) count++;

  return count;
};

interface ISelectProps {
  name: string;
  label?: string;
  placeholder?: string;
  searchable?: boolean;
  optionsAsAbsolute?: boolean;
  options: Array<{
    label: string;
    value: string;
  }>;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  disabled?: boolean;
  icon?: TIconType;
  loading?: boolean;
  backgroundColor?: string;
}

const Select: FC<ISelectProps> = ({
  name,
  label,
  options,
  placeholder,
  searchable,
  optionsAsAbsolute = false,
  onChange,
  onSearch,
  disabled,
  icon: Icon = Fragment,
  loading,
  backgroundColor,
}) => {
  const [field, meta, helpers] = useField<string>(name);

  const [filter, setFilter] = useState('');

  const { t } = useTranslation();
  const { theme } = useTheme();

  const hasError = Boolean(meta.error && meta.touched);

  const filteredOptions = options.filter(option => {
    if (!filter) return true;

    return option.label.toLowerCase().includes(filter.toLowerCase());
  });

  const selected = options.find(option => option.value === field.value);

  const numberOfItems =
    filteredOptions.length +
    getAdditionalItemsCount({ searchable, placeholder });

  return (
    <S.Container>
      {!!label && <label htmlFor={name}>{label}</label>}

      <Listbox
        value={field.value}
        onChange={value => {
          helpers.setValue(value);
          onChange?.(value);
          setFilter('');
        }}
        disabled={loading || disabled}
      >
        <Listbox.Button as={Fragment}>
          <S.Trigger
            value={field.value}
            $hasError={hasError}
            onClick={() => helpers.setTouched(true)}
            backgroundColor={backgroundColor}
          >
            {selected?.label || placeholder}

            {loading ? (
              <Spinner color={theme.palette.primary.main} size={1} />
            ) : (
              <Icon />
            )}
          </S.Trigger>
        </Listbox.Button>

        <S.Content
          numberOfItems={numberOfItems}
          $hasError={hasError}
          asAbsolute={optionsAsAbsolute}
        >
          {placeholder && (
            <Listbox.Option className="placeholder" value="">
              {placeholder}
            </Listbox.Option>
          )}

          {searchable && (
            <S.SearchInput
              placeholder={t('Buscar')}
              value={filter}
              onChange={event => {
                setFilter(event.target.value);
                if (onSearch) onSearch(event.target.value);
              }}
              onKeyDown={event => {
                event.stopPropagation();
              }}
            />
          )}

          {filteredOptions.map(option => (
            <Listbox.Option key={option.label} value={option.value}>
              {option.label}
            </Listbox.Option>
          ))}
        </S.Content>
      </Listbox>

      {hasError && <span className="error">{meta.error}</span>}
    </S.Container>
  );
};

export default memo(Select);
