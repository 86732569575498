import locale from 'modules/checkout/presentation/locale';
import routes from 'modules/checkout/presentation/routes';

import CheckoutContainer from './contexts';

const checkoutModule = {
  locale,
  routes,
  Container: CheckoutContainer,
};

export default checkoutModule;
