import { IFetchDistrictsDTO } from 'shared/domain/dtos';
import { IDistrictsRepository } from 'shared/domain/repositories';

export default class FetchDistrictsUseCase {
  constructor(private districtsRepository: IDistrictsRepository) {}

  public async execute({ cityId, search = {} }: IFetchDistrictsDTO) {
    return this.districtsRepository.findAll({ cityId, ...search });
  }
}
