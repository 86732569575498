import { IDistrict } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IDistrictsRepository, {
  TFindAllByParams,
} from 'shared/domain/repositories/IDistrictsRepository';
import { District, IVectisDistrict } from 'shared/infra/adapters';

const MAXIMUM_RETURNS_PER_SEARCH = 10;
export default class VectisDistrictsRepository implements IDistrictsRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public async findAll({
    cityId,
    name,
  }: TFindAllByParams): Promise<IDistrict[]> {
    const params: { bairro?: string } = {};
    if (name) params.bairro = name;

    const { bairros } = await this.httpProvider.get<{
      bairros: IVectisDistrict[];
    }>(`/bairros/${cityId}`, {
      params: { ...params, limit: MAXIMUM_RETURNS_PER_SEARCH },
    });

    return bairros.map(district => new District(district));
  }
}
