import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

interface IExpiryTimerContext {
  timeLeft: Date;
  setTimeLeft(date: Date): void;
}

export const ExpiryTimerContext: React.Context<IExpiryTimerContext> =
  createContext({} as IExpiryTimerContext);

export const useExpiryTimer = () => {
  const context = useContext(ExpiryTimerContext);

  if (isEmpty(context)) {
    throw new Error(
      'useExpiryTimer should be called within a ExpiryTimerContext',
    );
  }

  return context;
};
