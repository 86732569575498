import { styled, Dialog } from '@material-ui/core';

export const Modal = styled(Dialog)(({ theme }) => ({
  position: 'relative',
  '& .MuiPaper-root': {
    height: 498,
    width: 885,
    overflow: 'inherit',
    background: 'transparent',
    [theme.breakpoints.down('xs')]: {
      height: 169,
      width: 300,
    },
  },
  '& .MuiPaper-rounded': {
    borderRadius: 20,
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 900,
  },
  '& span': {
    position: 'absolute',
    color: theme.palette.background.default,
    top: -68,
    right: -55,
    fontSize: '1.2rem',
    fontWeight: 600,
    zIndex: 10,
    background: '#444',
    padding: '10px 15px',
    boxShadow: '0 5px 5px 1px rgba(0,0,0,0.5)',
    borderRadius: '50%',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      top: -40,
      right: -15,
      padding: '5px 10px',
    },
  },
  '& video': {
    borderRadius: 20,
  },
}));
