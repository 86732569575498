export class UseCaseError extends Error {
  private params?: Record<string, string>;

  constructor(message: string, params?: Record<string, string>) {
    super(message);
    this.params = params;
  }

  public translateMessage(
    translateFunction: (
      text: string,
      params?: Record<string, string>,
    ) => string,
  ) {
    const translatedMessage = translateFunction(this.message, this.params);

    if (translatedMessage === this.message) {
      console.warn(
        `Warning: the error message "${this.message}" has no matching translation key`,
      );
    }

    return translatedMessage;
  }
}
