import { IFetchContractTermsDTO } from 'shared/domain/dtos';
import { IContractTermsRepository } from 'shared/domain/repositories';

export default class FetchContractTermsUseCase {
  constructor(private contractTermsRepository: IContractTermsRepository) {}

  public async execute({ budgetId }: IFetchContractTermsDTO) {
    return this.contractTermsRepository.findByBudget(budgetId);
  }
}
