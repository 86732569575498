export const phoneUpdate = {
  'Phone update': 'Atualização de telefone',
  'Hello <1>{{name}}</1>! Please, confirm your document to procceed with your phone update':
    'Olá <1>{{name}}</1>! Por favor, confirme seu CPF para prosseguir com a atualização do telefone.',
  '<0>{{name}}</0>, fill the field below with a new valid phone':
    '<0>{{name}}</0>, preencha o campo abaixo com seu novo telefone',
  'The phone must be different than the current one':
    'O telefone precisa ser diferente do atual',
  'Your current phone': 'Seu telefone atual',
  'Your phone was successfully updated!':
    'Seu telefone foi atualizado com sucesso!',
};
