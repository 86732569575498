export const checkoutCustomerRegistration = {
  ADDRESS_PLACEHOLDER: 'Ex: Avenida Marechal Teodoro',
  CITY_PLACEHOLDER: 'Ex: Florianópolis',
  DISTRICT_PLACEHOLDER: 'Ex: Centro',
  DOCUMENT_PLACEHOLDER: '000.000.000-00',
  PHONE_PLACEHOLDER: '(00) 00000-0000',
  Address: 'Endereço',
  'Age confirmation': 'Confirmação de idade',
  'Alternative document': 'RG',
  Birthdate: 'Data de nascimento',
  City: 'Cidade',
  'Confirm and procceed': 'Confirmar e avançar',
  'Confirm and procceed to checkout': 'Confirmar e avançar para o checkout',
  'Customer registration': 'Cadastro do cliente',
  "Customer's data": 'Dados do cliente',
  District: 'Bairro',
  Document: 'CPF',
  'E-mail': 'E-mail',
  Error: 'Erro',
  "Fill in the customer's data": 'Preencha os dados do cliente',
  "In order to proceed, please confirm the customer's age":
    'Para prosseguir, por favor confirme a idade do cliente',
  'Incomplete customer': 'Cadastro incompleto',
  "Inform the legal guardian's document": 'Informe o CPF do responsável legal',
  'Legal guardian document': 'CPF do responsável legal',
  'Please, inform a document': 'Por favor, informe um CPF',
  'Please, inform a valid city': 'Por favor, informe uma cidade válida',
  'Please, inform a valid date': 'Por favor, informe uma data válida',
  'Please, inform a valid district': 'Por favor, informe um bairro válido',
  'Please, informa a valid postal code': 'Por favor, informe um CEP válido',
  'Please, inform an alternative document': 'Por favor, informe um RG',
  'Please, inform a postal code': 'Por favor, informe um CEP',
  "Please, inform the customer's birthdate":
    'Por favor, informe a data de nascimento do cliente',
  "Please, inform the customer's email":
    'Por favor, informe o e-mail do cliente',
  'Please, inform the street name': 'Por favor, informe o nome da rua',
  'Please, inform the street number': 'Por favor, informe o número da rua',
  'Please, select a city': 'Por favor, selecione uma cidade',
  'Please, select a district': 'Por favor, selecione um bairro',
  'Postal code': 'CEP',
  'Street No': 'Nº da rua',
  'The current customer is registered as a minor To proceed with the payment, please finish its register inside Vectis':
    'O cliente atual está cadastrado como menor de idade. Para prosseguir com o pagamento, por favor finalize seu cadastro no Vectis.',
  'The customer associated to this budget was not found':
    'O cliente associado a este orçamento não foi encontrado',
  "The customer's birthdate": 'A data de nascimento do cliente',
  "The customer's alternative document": 'O RG do cliente',
  "The customer's email": 'O email do cliente',
  "The customer's postal code": 'O CEP do cliente',
  'Please, inform a valid document': 'Por favor, informe um CPF válido',
  'Please, inform a valid e-mail': 'Por favor, informe um email válido',
  'Please, inform a valid name': 'Por favor, informe um nome válido',
  'Please, inform a valid number': 'Por favor, informe um número válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Please, inform a name and surname': 'Por favor digite nome e sobrenome',
  'Please, inform an existing document': 'Por favor, informe um CPF existente',
  'Please, inform your cellphone': 'Por favor, informe seu celular',
  'Please, inform your document': 'Por favor, informe seu CPF',
  'Please, inform your e-mail': 'Por favor, informe seu e-mail',
  'Please, inform your name': 'Por favor, informe seu nome',
  'Preferable billing day': 'Melhor dia para cobrança',
};
