import { useRef, useState } from 'react';

import { debounce } from 'lodash';

import { IItem } from 'shared/domain/entities';
import { PackageImage } from 'shared/presentation/components/atoms';
import { ICONS } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';
import {
  useCurrencyFormatter,
  useEventTracker,
  useMediaQuery,
} from 'shared/presentation/hooks';

import { useSearch } from './hooks';
import * as S from './styles';

const SearchIcon = ICONS.SEARCH;

const DEBOUNCE_TIMEOUT = 350;
const PACKAGE_SIZES = {
  MOBILE: 36,
  DESKTOP: 48,
};

const ITEM_URL_BY_TYPE = {
  COMBO: 'promocoes',
  PACKAGE: 'pacotes',
  PRODUCT: 'pacotes',
} as const;

const ListItem: React.FC<{ item: IItem }> = ({ item }) => {
  const formatter = useCurrencyFormatter();
  const isMobile = useMediaQuery('md');

  return (
    <li key={item.id}>
      <S.ItemLink to={`/${ITEM_URL_BY_TYPE[item.type]}/${item.ecommerceCode}`}>
        <PackageImage
          src={item.images.default}
          alt={item.title}
          isCombo={item.type === 'COMBO'}
          size={isMobile ? PACKAGE_SIZES.MOBILE : PACKAGE_SIZES.DESKTOP}
        />

        <div>
          <span>{item.title}</span>

          <div>
            <span className="from">{formatter.format(item.price.from)}</span>
            <span className="to">{formatter.format(item.price.to)}</span>
          </div>
        </div>
      </S.ItemLink>
    </li>
  );
};

const PackageSearchBox: React.FC = () => {
  const { t } = useLocale('shared');
  const searchController = useSearch();
  const eventTracker = useEventTracker();

  const [isOpen, setIsOpen] = useState(false);

  const isHoveringOptions = useRef(false);

  const hasAnyResult = Boolean(
    searchController.packages.length || searchController.combos.length,
  );

  return (
    <S.Container
      onBlur={() => {
        if (!isHoveringOptions.current) setIsOpen(false);
      }}
    >
      <S.InputContainer>
        <label htmlFor="package-search-box">
          <SearchIcon />
        </label>

        <input
          id="package-search-box"
          placeholder={t('Search packages at Vialaser')}
          onChange={debounce(event => {
            const value = event.target.value;

            searchController.setSearch(value);
            eventTracker.register('UsuarioPesquisou', {
              searchText: value,
            });
            setIsOpen(true);
          }, DEBOUNCE_TIMEOUT)}
          onFocus={() => {
            setIsOpen(true);
            eventTracker.register('UsuarioClicouNaPesquisa');
          }}
        />
      </S.InputContainer>

      {isOpen && hasAnyResult && (
        <S.Content
          onMouseEnter={() => {
            isHoveringOptions.current = true;
          }}
          onMouseLeave={() => {
            isHoveringOptions.current = false;
          }}
        >
          {!!searchController.combos.length && (
            <>
              <h3>{t('Combos')}</h3>

              <ul>
                {searchController.combos.map(item => (
                  <ListItem key={item.id} item={item} />
                ))}
              </ul>
            </>
          )}

          {!!searchController.packages.length && (
            <>
              <h3>{t('Packages')}</h3>

              <ul>
                {searchController.packages.map(item => (
                  <ListItem key={item.id} item={item} />
                ))}
              </ul>
            </>
          )}
        </S.Content>
      )}
    </S.Container>
  );
};

export default PackageSearchBox;
