import { TENANCY } from 'config/tenancy';
import IAddressRepository from 'shared/domain/repositories/IAddressRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisAddressRepository from './implementations/VectisAddressRepository';

let instance: IAddressRepository | null = null;

export default function makeAddressRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider({ language: TENANCY });

    instance = new VectisAddressRepository(httpProvider);
  }

  return instance;
}
