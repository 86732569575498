import React from "react";
import WhatsappIcon from "../../assets/icons/whatsapp";
import GreenColoredHeader from "../GreenColoredHeader";
import { Element } from "react-scroll";

import { Container } from "./styles";

const MAXIMUM_PLACES_PER_ROW = 3;

const placesListToMatrix = places => {
  let matrix = [];

  for (let i = 0; i < places.length; i++) {
    const matrixIndex = Math.floor(i / MAXIMUM_PLACES_PER_ROW);

    let matrixRow = matrix[matrixIndex];

    if (!matrixRow) {
      matrix[matrixIndex] = [places[i]];

      continue;
    }

    matrixRow.push(places[i]);
  }

  return matrix;
};

function WhereToFind({ locations = [] }) {
  const placesMatrix = placesListToMatrix(locations);

  return (
    <Element name="where-to-find">
      <Container>
        <GreenColoredHeader>¿DÓNDE ENCONTRAR?</GreenColoredHeader>

        <div>
          {placesMatrix.map((row, index) => (
            <ul key={index}>
              {row.map(place => (
                <li key={place.name}>
                  <h3>{place.name}</h3>

                  <address className="address">
                    <span>{place.address.top}</span>

                    <span>{place.address.bottom}</span>
                  </address>

                  <address>{place.location}</address>

                  {place.phone && <span>{place.phone}</span>}

                  <a
                    href={place.maps}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="maps"
                  >
                    Ver en el mapa
                  </a>

                  {place.url && (
                    <a
                      href={place.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whatsapp"
                    >
                      Llamar por WhatsApp
                      <WhatsappIcon />
                    </a>
                  )}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </Container>
    </Element>
  );
}

export default WhereToFind;
