import { FC, useState } from 'react';

import { Form, FormikProvider, useFormik } from 'formik';

import {
  IVerificationCode,
  TChannelType,
  TCodeVerificationProccessType,
} from 'shared/domain/entities';
import { Button, TextInput } from 'shared/presentation/components/molecules';
import { ICONS, MASKS } from 'shared/presentation/constants';
import { useLocale, useToast } from 'shared/presentation/contexts';
import { useTimeLeft } from 'shared/presentation/hooks';

import * as S from './styles';
import { TValidationData } from '../../constants';
import { maskEmail, maskPhoneNumber } from '../../helpers';
import {
  useSendVerificationCode,
  useValidateVerificationCode,
} from '../../hooks';
import { useDisabledChannels } from '../../store';

interface IValidateCode {
  entityId: number;
  proccess: TCodeVerificationProccessType;
  validationData: TValidationData;
  customer: {
    email?: string;
    phone?: string;
  };
  onReturn: () => void;
  onNext: (params: { id: number }) => void;
}

const ArrowLeftIcon = ICONS.ARROW_LEFT;

const ValidateCode: FC<IValidateCode> = ({
  entityId,
  proccess,
  validationData,
  customer,
  onReturn,
  onNext,
}) => {
  const { t, language } = useLocale('shared');
  const toast = useToast();

  const [, setDisabledChannels] = useDisabledChannels();
  const [expiration, setExpiration] = useState<IVerificationCode['expiration']>(
    validationData.code.expiration,
  );

  const validateCodeCtrl = useValidateVerificationCode({
    onSuccess: params => {
      setDisabledChannels([]);
      onNext(params);
    },
  });
  const sendCodeCtrl = useSendVerificationCode({
    onSuccess: data => {
      if (data.message) toast.success(data.message);
      setExpiration(data.expiration);
    },
  });

  const resendTimer = useTimeLeft(expiration.toSendAgain);
  const expirationTimer = useTimeLeft(expiration.toValidateCode);

  const form = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async ({ code }) => {
      await validateCodeCtrl.validate({
        code,
        entityId,
        proccess,
      });
    },
  });

  const messageByChannel: Record<TChannelType, string> = {
    EMAIL: t('Type the last code you received in your email {{email}}', {
      email: maskEmail(customer.email),
    }),
    WHATSAPP: t(
      'Type the last code you received in your whatsapp with final number of {{phone}}',
      {
        phone: maskPhoneNumber(customer.phone, language),
      },
    ),
    SMS: t(
      'Type the last code you received in your phone with final number of {{phone}}',
      {
        phone: maskPhoneNumber(customer.phone, language),
      },
    ),
  };

  return (
    <>
      <button
        className="modal__button"
        disabled={
          validateCodeCtrl.loading ||
          sendCodeCtrl.loading ||
          !resendTimer.finished
        }
        onClick={onReturn}
      >
        <ArrowLeftIcon /> {t('Choose other sending option')}
      </button>

      <div className="modal__header">
        <h2 className="modal__title">{t('Validation')}</h2>
        <p className="modal__message">
          {messageByChannel[validationData.channel]}
        </p>
      </div>

      <div className="modal__content">
        <FormikProvider value={form}>
          <Form className="modal__form">
            <TextInput
              name="code"
              placeholder={t('Verification code')}
              mask={MASKS.VALIDATION_CODE}
              icon={ICONS.KEY}
            />

            <Button
              type="submit"
              variant="primary"
              icon={ICONS.DOUBLE_CHECK}
              iconPosition="right"
              disabled={!form.isValid}
              loading={validateCodeCtrl.loading}
            >
              {t('Validate code')}
            </Button>

            <Button
              type="button"
              variant="default"
              icon={ICONS.REFRESH}
              iconPosition="right"
              loading={sendCodeCtrl.loading}
              disabled={validateCodeCtrl.loading || !resendTimer.finished}
              onClick={() => {
                sendCodeCtrl.send({
                  entityId,
                  proccess,
                  channel: validationData.channel,
                });
              }}
            >
              {resendTimer.finished
                ? t('Send again')
                : t('Send again in {{count}}s', {
                    count:
                      resendTimer.remaining.minutes * 60 +
                      resendTimer.remaining.seconds,
                  })}
            </Button>
          </Form>
        </FormikProvider>
      </div>

      <div className="modal__footer">
        <S.Message>
          {expirationTimer.finished
            ? t('* The time is up, send another code to continue')
            : t('The code is valid only for {{count}} minutes', {
                count: expirationTimer.remaining.minutes,
              })}
        </S.Message>
      </div>
    </>
  );
};

export default ValidateCode;
