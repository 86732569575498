import { createTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { transparentize } from 'polished';

import { theme } from 'shared/presentation/contexts/ThemeContext/constants/theme';

const palette = theme.palette;

const defaultTheme = createTheme({
  palette: {
    primary: { main: palette.primary.main },
    secondary: { main: palette.primary.main },
    text: {
      primary: palette.text.main,
      secondary: palette.text.white,
      tertiary: palette.text.light,
      disabled: palette.text.light,
      dark_graffite: palette.text.main,
    },
    background: {
      default: palette.neutral[50],
      paper: palette.neutral[100],
      secondary: palette.neutral[200],
    },
    cardsBackground: {
      primary: palette.neutral[50],
      secondary: palette.neutral[100],
    },
    cardsBackgroundButton: {
      primary: palette.primary.main,
      secondary: palette.neutral[200],
    },
    grey: theme.palette.neutral,
  } as PaletteOptions,
  typography: {
    fontFamily: '"Ubuntu", "sans-serif"',
    fontFamilyRoboto: '"Roboto", "sans-serif"',
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    body1: {
      fontWeight: 400,
      fontSize: theme.typography.sizes.body1,
    },
    body2: {
      fontSize: theme.typography.sizes.body2,
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 400,
    },
    h1: {
      fontSize: theme.typography.sizes.title1,
      lineHeight: 1.27,
      fontWeight: 'bold',
      letterSpacing: '0.1rem',
    },
    h2: {
      fontSize: theme.typography.sizes.title2,
      lineHeight: 1.27,
    },
    h3: {
      fontSize: theme.typography.sizes.title3,
      lineHeight: 1.27,
      fontWeight: 400,
    },
    h4: {
      fontSize: theme.typography.sizes.subtitle,
      lineHeight: 1.27,
      fontWeight: 400,
    },
    h5: {
      fontSize: theme.typography.sizes.body1,
      lineHeight: 1.27,
      fontWeight: 400,
    },
  } as TypographyOptions,
});
const { breakpoints } = defaultTheme;

const muiTheme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        [breakpoints.down('md')]: {
          textAlign: 'center',
          fontSize: '1.625rem',
        },
      },
      h2: {
        [breakpoints.down('md')]: {
          textAlign: 'center',
          fontSize: '1.25rem',
          lineHeight: 1.27,
        },
      },
      h3: {
        [breakpoints.down('md')]: {
          textAlign: 'center',
          fontSize: '1rem',
          lineHeight: 1.27,
        },
      },
    },
    MuiToolbar: {
      dense: {
        minHeight: '80px',
      },
    },
    MuiButton: {
      contained: {
        borderRadius: '80px',
        margin: '1rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: palette.primary.dark + ' !important',
          color: palette.text.white,
        },
      },
      outlined: {
        borderRadius: '80px',
        margin: '1rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
      },
      text: {
        borderRadius: '80px',
        margin: '1rem',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.25rem',
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.text.main,
        fontWeight: 'bold',
        fontSize: '1.25rem',
        marginBottom: '0.5em',
        '&$focused': {
          color: palette.text.main,
          '& fieldset$focused': {
            borderColor: palette.primary.main,
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.neutral[600],
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 300,
        '& input': {
          padding: '10px 14px',
          color: defaultTheme.palette.primary.main,
          background: palette.neutral[50],
          borderRadius: '30px',
        },
        '& fieldset': {
          borderRadius: '30px',
          border: 'none',
          boxShadow:
            '2px 3px 5px -1px ' + transparentize(0.7, palette.neutral[900]),
        },
        '&$focused': {
          borderColor: palette.primary.main,
          '& fieldset$focused': {
            borderColor: palette.primary.main,
          },
        },

        '&.Mui-error > fieldset': {
          boxShadow:
            '2px 3px 5px -1px ' + transparentize(0.7, palette.error.main),
        },
      },
    },
    MuiRadio: {
      root: { color: palette.neutral[500] },
    },
    MuiCard: {
      root: {
        borderRadius: '1em',
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'row',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        fontSize: '3.5rem',
        backgroundColor: palette.primary.light,
        color: 'white',
      },
    },
    MuiPickersToolbarButton: { toolbarBtn: { margin: 'unset' } },
    MuiPickersStaticWrapper: { staticWrapperRoot: { width: '100%' } },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-evenly',
        '& > div': {
          width: '14%',
        },
      },
      transitionContainer: {
        marginTop: 'unset',
        paddingTop: '12px',
        '&& > div': {
          marginTop: '-6px',
          position: 'relative',
        },
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        maxHeight: 'unset',
      },
      switchHeader: {
        fontSize: '4rem',
        backgroundColor: palette.primary.light,
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
      },
      dayLabel: {
        width: '15%',
        margin: 0,
        fontSize: '1rem',
        backgroundColor: palette.primary.light,
        color: 'white',
      },
      iconButton: {
        color: 'white',
        backgroundColor: 'unset',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        display: 'none',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 'none',
        justifyContent: 'flex-start',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        paddingBottom: '1rem',
        background: palette.primary.main,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 'none',
      },
    },
    MuiExpansionPanel: {
      root: {
        margin: 0,
        '& expanded': {
          margin: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 20,
      },
    },
  },
};

export default muiTheme;
