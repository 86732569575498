import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  column-gap: 0.5rem;
`;

interface IPageButtonProps {
  active: boolean;
}

export const PageButton = styled.button<IPageButtonProps>`
  ${({ theme, active }) => css`
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    border-radius: 0.5rem;

    cursor: pointer;

    font-size: ${theme.typography.sizes.body2};
    font-weight: ${theme.typography.weight.bold};

    svg {
      font-size: ${theme.typography.sizes.body1};
    }

    transition: background-color 0.2s;

    ${active
      ? css`
          background-color: ${theme.palette.secondary.main};
          color: ${theme.palette.text.white};

          &:hover {
            background-color: ${theme.palette.secondary.light};
          }
        `
      : css`
          background-color: ${theme.palette.neutral[200]};
          color: ${theme.palette.text.main};

          &:hover {
            background-color: ${lighten(0.1, theme.palette.neutral[200])};
          }
        `}

    &:disabled {
      cursor: no-drop;

      opacity: 0.7;

      background-color: ${theme.palette.neutral[200]};
      color: ${theme.palette.text.main};
      :hover {
        background-color: ${theme.palette.neutral[200]};
      }
    }
  `}
`;
