import translationEs from 'i18n/locales/es/translation.json';
import translationEsCr from 'i18n/locales/esCr/translation.json';
import translationPt from 'i18n/locales/pt/translation.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { TENANCY } from 'config/tenancy';
import appModules from 'shared/presentation/modules';

const appResources = appModules.locale;

export const resources = {
  pt: {
    ...appResources.pt,
    translations: translationPt,
  },
  es: {
    ...appResources.es,
    translations: translationEs,
  },
  'es-CR': {
    ...appResources['es-CR'],
    translations: translationEsCr,
  },
};

const languagedetector = new LanguageDetector();
languagedetector.addDetector({
  name: 'tenancy',

  lookup() {
    return TENANCY;
  },
});

i18n
  .use(languagedetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: {
      'es-CR': ['es'],
      default: ['pt'],
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'tenancy',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });
