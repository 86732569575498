import { TENANCY } from 'config/tenancy';
import ICitiesRepository from 'shared/domain/repositories/ICitiesRepository';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisCitiesRepository from './implementations/VectisCitiesRepository';

let instance: ICitiesRepository | null = null;

export default function makeCitiesRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider({ language: TENANCY });

    instance = new VectisCitiesRepository(httpProvider);
  }

  return instance;
}
