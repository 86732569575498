import { useMemo } from 'react';

import { TLanguage } from 'config/tenancy';
import { useLocale } from 'shared/presentation/contexts';

type TSpecifics = {
  currency: string;
  locale?: string;
  symbolReplacer?: (formatted: string) => string;
};

const LANGUAGE_SPECIFICS: Record<TLanguage, TSpecifics> = {
  pt: {
    currency: 'BRL',
    locale: 'pt-BR',
  },
  es: {
    currency: 'PYG',
    locale: 'es-PY',
    symbolReplacer: formatted => formatted.replace('Gs.', '₲'),
  },
  'es-CR': {
    currency: 'CRC',
    locale: 'es-CR',
  },
};

class Formatter extends Intl.NumberFormat {
  constructor(
    private symbolReplacer: TSpecifics['symbolReplacer'],
    ...args: ConstructorParameters<typeof Intl.NumberFormat>
  ) {
    super(...args);
  }

  public format(value: number): string;
  public format(value: number | bigint): string;
  public format(value: unknown): string {
    const result = super.format(value as any);

    if (this.symbolReplacer) return this.symbolReplacer(result);

    return result;
  }
}

export default function useCurrencyFormatter() {
  const { language } = useLocale();

  const formatter = useMemo(() => {
    const {
      currency,
      locale = language,
      symbolReplacer,
    } = LANGUAGE_SPECIFICS[language];

    return new Formatter(symbolReplacer, locale, {
      style: 'currency',
      currency,
    });
  }, [language]);

  return formatter;
}
