import { get } from 'lodash';

import { TLanguage } from 'config/tenancy';
import {
  COMPLETE_REGISTRATION_ABSENT_FIELDS_BY_TENANCY,
  COMPLETE_REGISTRATION_REQUIRED_FIELDS_BY_STATUS,
} from 'modules/checkout/presentation/constants';
import { ICustomer, TMinorityStatus } from 'shared/domain/entities';

const getFieldPaths = (status: TMinorityStatus, tenancy: TLanguage) => {
  const requiredFields =
    COMPLETE_REGISTRATION_REQUIRED_FIELDS_BY_STATUS[status || 'ADULT'];
  const absentFields = COMPLETE_REGISTRATION_ABSENT_FIELDS_BY_TENANCY[tenancy];

  return requiredFields.filter(field => !absentFields.includes(field));
};

export default function getMissingCustomerFields(
  customer: ICustomer | null,
  tenancy: TLanguage,
) {
  if (!customer) return null;

  const fieldPaths = getFieldPaths(customer.minorityStatus, tenancy);
  const fieldsToInput = fieldPaths.filter(path => !get(customer, path));

  return {
    rawData: customer,
    status: customer.minorityStatus,
    fields: fieldsToInput,
    isMinorityUncertain: fieldsToInput.includes('birthdate'),
    hasGuardian: Boolean(customer.guardian),
  };
}
