import { useMutation } from 'react-query';

import { IValidateVerificationCodeDTO } from 'shared/domain/dtos';
import { makeValidateVerificationCodeUseCase } from 'shared/domain/useCases';
import { useLocale, useToast } from 'shared/presentation/contexts';
import { useRequestErrorHandler } from 'shared/presentation/hooks';

interface IParams {
  onSuccess: (params: { id: number }) => void;
}

export default function useValidateVerificationCode(params: IParams) {
  const toast = useToast();
  const handleError = useRequestErrorHandler('shared');

  const { t } = useLocale('shared');

  const mutation = useMutation(
    async (values: IValidateVerificationCodeDTO) => {
      return await makeValidateVerificationCodeUseCase().execute(values);
    },
    {
      onSuccess: data => {
        if (!data?.valid) {
          return toast.error(data.message || t('Invalid code!'));
        }

        toast.success(t('Code validated successfully!'));
        params.onSuccess(data);
      },
      onError: error => handleError(error),
    },
  );

  return {
    loading: mutation.isLoading,
    validate: mutation.mutateAsync,
  };
}
