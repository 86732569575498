export const profile = {
  ADDRESS_PLACEHOLDER: 'Ex: Avenida Marechal Teodoro',
  CITY_PLACEHOLDER: 'Ex: Florianópolis',
  DISTRICT_PLACEHOLDER: 'Ex: Centro',
  DOCUMENT_PLACEHOLDER: 'XXX.XXX.XXX-XX',
  PHONE_PLACEHOLDER: '(XX) XXXXX-XXXX',
  'Additional information': 'Complemento',
  'Alternative document': 'RG',
  'Block, apartment, etc': 'Bloco, apartamento, etc',
  City: 'Cidade',
  Close: 'Fechar',
  District: 'Bairro',
  Document: 'CPF',
  Email: 'Email',
  'First name': 'Nome',
  'Full name': 'Nome completo',
  'My data': 'Meus dados',
  'Last name': 'Sobrenome',
  None: 'Nenhum',
  Phone: 'Telefone',
  'Please, inform a postal code': 'Por favor, informe um CEP',
  'Please, inform a valid city': 'Por favor, informe uma cidade válida',
  'Please, inform a valid district': 'Por favor, informe um bairro válido',
  'Please, inform a valid document': 'Por favor, informe um CPF válido',
  'Please, inform a valid e-mail': 'Por favor, informe um email válido',
  'Please, inform a valid name': 'Por favor, informe um nome válido',
  'Please, inform a valid number': 'Por favor, informe um número válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Postal code': 'CEP',
  Profile: 'Perfil',
  'Public place': 'Logradouro',
  Save: 'Salvar',
  Select: 'Selecionar',
  'This field is required': 'Este campo é obrigatório',
  'Update data': 'Atualizar dados',
  'Update my data': 'Atualizar meus dados',
  'View and update your profile data':
    'Visualize e atualize os dados do seu cadastro',
};
