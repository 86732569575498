export const typography = {
  family: 'Ubuntu, sans-serif',
  sizes: {
    title1: '2.25rem', // 36px
    title2: '1.5rem', // 24px
    title3: '1.125rem', // 18px
    subtitle: '1rem', // 16px
    body1: '1rem', // 16px
    body2: '0.875rem', // 14px
    caption: '0.75rem', // 12px
  },
  weight: {
    extraBold: '700',
    bold: '500',
    regular: '400',
    light: '300',
  },
};
