import styled, { css, keyframes } from 'styled-components';

import { CSSANIMATIONS } from 'shared/presentation/constants';
import { Link } from 'shared/presentation/contexts';

const slideIn = keyframes`
 0% {
  transform: translateX(-1000px);
 }

 100% {
  transform: translateX(0)
 }
`;

const slideOut = keyframes`
 0% {
  transform: translateX(0);
 }

 100% {
  transform: translateX(-1000px)
 }
`;

export const Container = styled.aside<{ visible: boolean }>`
  ${({ theme, visible }) => css`
    width: 80vw;
    height: 100vh;
    overflow: auto;

    display: flex;
    flex-direction: column;

    background-color: ${theme.palette.neutral[50]};

    ${visible
      ? css`
          animation: ${slideIn} ease-in-out 0.2s;
        `
      : css`
          animation: ${slideOut} ease-in-out 0.2s;
        `}

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    header {
      display: flex;
      padding: ${theme.layout.spacing(1.5)};
      flex-direction: column;
      gap: ${theme.layout.spacing(3)};

      border-bottom: 1px solid ${theme.palette.neutral[100]};
      background: ${theme.palette.neutral[200]};

      .title {
        display: flex;
        align-items: center;
        gap: ${theme.layout.spacing(1.5)};

        h3 {
          font-size: ${theme.typography.sizes.title3};
          font-weight: ${theme.typography.weight.regular};
        }
      }
    }

    > section {
      display: flex;
      padding: ${theme.layout.spacing(3, 2)};
      flex-direction: column;
      gap: ${theme.layout.spacing(3)};
    }
  `}
`;

export const RegisterSection = styled.section`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.layout.spacing(1.5, 0)};
    flex-direction: column;
    align-items: center;
    gap: ${theme.layout.spacing(1.5)};
    text-align: center;

    span {
      font-size: ${theme.typography.sizes.body2};
      font-weight: ${theme.typography.weight.regular};
    }

    a {
      color: ${theme.palette.neutral[800]};
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.bold};
    }
  `}
`;

export const RecommendWinLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.layout.spacing(2, 1.5)};
    align-items: center;
    gap: ${theme.layout.spacing(1)};
    align-self: stretch;

    border-radius: 1rem;
    background: ${theme.palette.secondary.dark};
    transition: all 0.2s;

    color: ${theme.palette.text.white};

    animation: ${CSSANIMATIONS.PULSE} 1.5s infinite;

    svg {
      font-size: 1.5rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: ${theme.layout.spacing(1)};
      flex: 1 0 0;
      text-align: center;

      h3 {
        font-size: ${theme.typography.sizes.title3};
        font-weight: ${theme.typography.weight.bold};
        flex: 1;
      }

      span {
        font-size: ${theme.typography.sizes.body2};
        font-weight: ${theme.typography.weight.regular};
        flex: 1;
      }
    }

    &:hover {
      color: ${theme.palette.text.white};
      background: ${theme.palette.secondary.light};
    }
  `}
`;
