import { CSSProperties } from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    margin: '0.938rem 0',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  forceLeftOnMobile: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: '16rem',
      left: '60px',
      border: `1px solid ${theme.palette.primary.main}`,
      outline: 'none',
      background: 'transparent',
    },
  },
}));

interface ICustomHrProps {
  color?: string;
  centered?: boolean;
  forceLeftOnMobile?: boolean;
  width?: string;
}

export default function CustomHr({
  color,
  centered,
  forceLeftOnMobile,
  width,
}: ICustomHrProps) {
  const classes = useStyles();
  const style: CSSProperties = {
    width: width || '75px',
  };

  if (color) {
    style.border = `1px solid ${color}`;
  }

  if (centered) {
    style.marginLeft = 'auto';
    style.marginRight = 'auto';
  }

  return (
    <hr
      className={clsx(classes.root, {
        [classes.forceLeftOnMobile]: forceLeftOnMobile,
      })}
      style={style}
    />
  );
}
