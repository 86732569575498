import { DISCOUNT_TYPES } from 'contexts/core/utils';

type TDiscountTypes = typeof DISCOUNT_TYPES;

type TState = {
  discounts: {
    [Key in TDiscountTypes[keyof TDiscountTypes]]: unknown;
  };
  coupon?: string;
};

export function removeCouponFromState(state: TState, origin: string) {
  const { discounts } = state;

  if (discounts[DISCOUNT_TYPES.FIXED]) {
    delete discounts[DISCOUNT_TYPES.FIXED];
  } else {
    delete discounts[DISCOUNT_TYPES.COUPON];
  }

  const newState: TState = {
    ...state,
    coupon: undefined,
    discounts,
  };

  localStorage.removeItem('@vialaser:codigoCupom');

  return newState;
}
