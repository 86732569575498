export const NORMAL_PAYMENT = 'CARTAO_ONLINE';
export const NORMAL_PAYMENT_1X = 'CARTAO_ONLINE_1X';
export const RECURRENT_PAYMENT = 'CARTAO_RECORRENTE';

export const PAYMENT_TYPES = {
  CARTAO_RECORRENTE: 'CARTAO_RECORRENTE',
  CARTAO_ONLINE: 'CARTAO_ONLINE',
  CARTAO_ONLINE_1X: 'CARTAO_ONLINE_1X',
  BOLETO_PARCELADO: 'BOLETO_PARCELADO',
  CREDIARIO: 'CREDIARIO',
  PIX: 'PIX',
  NUPAY: 'NUPAY',
  DEBITO_AUTOMATICO: 'DEBITO_AUTOMATICO',
  DINHEIRO: 'DINHEIRO',
} as const;

export const BUDGET_STATUS = {
  CLOSED: 'ORCAMENTO_FECHADO',
  CONTRACT_GENERATED: 'CONTRATO_GERADO',
} as const;
