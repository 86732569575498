import {
  Banner,
  FAQ,
  Footer,
  Navbar,
  Pitch,
  Promotion,
  Testimonials,
  WhereToFind,
} from '../components';
import { QUESTIONS } from '../constants/faq';
import { EXTERNAL_LINKS } from '../constants/links';
import { LOCATIONS } from '../constants/locations';
import { NAVBAR_ICONS, NAVBAR_LINKS } from '../constants/navbar';
import { GlobalStyle } from '../globalStyles';
import { Container } from '../styles';

function CostaRica() {
  return (
    <>
      <GlobalStyle />

      <Container>
        <Navbar links={NAVBAR_LINKS['es-CR']} icons={NAVBAR_ICONS['es-CR']} />

        <Banner />

        <Promotion
          promotion={{
            link: EXTERNAL_LINKS['es-CR'].PROMOTION,
            external: false,
          }}
        />

        <Pitch />

        <Navbar links={NAVBAR_LINKS['es-CR']} icons={NAVBAR_ICONS['es-CR']} />

        <WhereToFind locations={LOCATIONS['es-CR']} />

        <Testimonials />

        <Navbar links={NAVBAR_LINKS['es-CR']} icons={NAVBAR_ICONS['es-CR']} />

        <FAQ questions={QUESTIONS['es-CR']} />

        <Navbar links={NAVBAR_LINKS['es-CR']} icons={NAVBAR_ICONS['es-CR']} />

        <Footer>
          <p>Vialaser Costa Rica</p>
        </Footer>
      </Container>
    </>
  );
}

export default CostaRica;
