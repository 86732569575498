import React, { useEffect } from 'react';

import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { formatNumberToTwoDigitsMinimum } from 'modules/customer/presentation/helpers';
import { useTheme } from 'shared/presentation/contexts';
import { useMediaQuery, useTimeLeft } from 'shared/presentation/hooks';

import * as S from './styles';

interface ITimeLeftBarProps {
  callText: string;
  expirationDate: string;
  loading?: boolean;
  onTimeUp?: () => void;
}

const TimeLeftBar: React.FC<ITimeLeftBarProps> = ({
  callText,
  expirationDate,
  loading,
  onTimeUp,
}) => {
  const { t } = useTranslation('shared');
  const isMobile = useMediaQuery('md');
  const { theme } = useTheme();

  const timer = useTimeLeft(expirationDate);

  useEffect(() => {
    if (!onTimeUp) return;

    if (timer.finished) onTimeUp();
  }, [timer.finished, onTimeUp]);

  if (loading) {
    return (
      <S.Container>
        <p>{callText}</p>

        <div>
          <S.CountdownBox>
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton
                key={String(index)}
                width={isMobile ? 35 : 65}
                height={35}
                borderRadius={12}
                baseColor={theme.palette.secondary.light}
                highlightColor={darken(0.05, theme.palette.secondary.light)}
              />
            ))}
          </S.CountdownBox>
        </div>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <p>{callText}</p>

      {!timer.finished && (
        <div>
          <p>{t('Time left')}</p>

          <S.CountdownBox>
            <div>
              <strong>
                {formatNumberToTwoDigitsMinimum(timer.remaining.hours)}
              </strong>

              <span>{t('hour', { count: timer.remaining.hours })}</span>
            </div>

            <div>
              <strong>
                {formatNumberToTwoDigitsMinimum(timer.remaining.minutes)}
              </strong>

              <span>{t('minute', { count: timer.remaining.minutes })}</span>
            </div>

            <div>
              <strong>
                {formatNumberToTwoDigitsMinimum(timer.remaining.seconds)}
              </strong>

              <span>{t('second', { count: timer.remaining.seconds })}</span>
            </div>
          </S.CountdownBox>
        </div>
      )}
    </S.Container>
  );
};

export default TimeLeftBar;
