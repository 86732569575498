const REPEATED_NUMBERS_REGEX = /^(\d)\1+$/;

export default function isCIDIMEXValid(ciDimex: string) {
  const customerDIMEX = ciDimex.replace(/[^\d]+/g, '');

  if (customerDIMEX.match(REPEATED_NUMBERS_REGEX)) return false;

  if (
    customerDIMEX.length < 9 ||
    customerDIMEX.length === 10 ||
    customerDIMEX.length > 12
  ) {
    return false;
  }

  return true;
}
