import React from "react";
import ClinicsIcon from "../../assets/icons/clinics";
import HairRemovalIcon from "../../assets/icons/hairRemoval";
import PaymentIcon from "../../assets/icons/payment";
import PracticityIcon from "../../assets/icons/practicity";
import TechnologyIcon from "../../assets/icons/technology";
import ColoredDottedH2 from "../ColoredDottedH2";
import TransparentBigText from "../TransparentBigText";
import { Container } from "./styles";

const REASONS = [
  {
    title: "PRACTICIDAD",
    description:
      "Optá por una vida más práctica, invertí en un método que dure, en promedio, 10 minutos. Todo muy práctico para que tengas más tiempo para disfrutar de lo mejor de la vida sin pelitos.",
    icon: PracticityIcon,
    iconClassName: "smaller",
  },
  {
    title: "ALTA TECNOLOGIA",
    description:
      "Depilarse dejó de ser sinónimo de dolor hace mucho tiempo en Vialaser. Aquí tenemos una tecnología que enfría la piel adecuadamente durante las sesiones. Hace que todo sea mucho más cómodo, más rápido, sin dolor y con el mejor resultado.",
    icon: TechnologyIcon,
    iconClassName: "bigger",
  },
  {
    title: "EN CUALQUIER CLÍNICA",
    description:
      "Continuá tus sesiones de depilación en cualquiera de nuestras clínicas, sin pagar más. Cuando termina tu contrato, registramos tus datos biométricos para que disfrutes de nuestro servicio donde quiera que estés.",
    icon: ClinicsIcon,
  },
  {
    title: "EL PAGO ES FÁCIL",
    description:
      "En lugar de dividir tus paquetes en la tarjeta de la manera tradicional, en Vialaser puede usar el método de cobro recurrente. Mes a mes, sólo emitimos el pago de la cuota en la factura, sin comprometer el límite de tu tarjeta.",
    icon: PaymentIcon,
  },
  {
    title: "SESIONES SIN DOLOR",
    description:
      "En relación con otros métodos, como la cera, la depilación láser de Vialaser es prácticamente indolora. Tenemos una tecnología exclusiva, el láser Alexandrite, que hace que las sesiones sean más cómodas y rápidas.",
    icon: HairRemovalIcon,
  },
];

function Pitch() {
  return (
    <Container>
      <ColoredDottedH2>MOTIVOS PARA ELEGIR VIALASER</ColoredDottedH2>

      <ul>
        {REASONS.map(({ icon: Icon, ...reason }) => (
          <li key={reason.title}>
            <Icon className={reason.iconClassName} />

            <div>
              <h3>{reason.title}</h3>

              <p>{reason.description}</p>
            </div>
          </li>
        ))}
      </ul>

      <TransparentBigText>VIALASER</TransparentBigText>
    </Container>
  );
}

export default Pitch;
