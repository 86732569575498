import { SOCIALS_LIST } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

const VialaserSocials: React.FC = () => {
  const { language } = useLocale('shared');

  return (
    <S.Container>
      {SOCIALS_LIST.map(({ key, link, icon: Icon }) => (
        <a key={key} href={link[language]} rel="noopener noreferrer">
          <Icon />
        </a>
      ))}
    </S.Container>
  );
};

export default VialaserSocials;
