import { makeFlagParametersRepository } from 'shared/infra/repositories';

import FindDigitalSigningDisabilityUseCase from './implementations/FindDigitalSigningDisabilityUseCase';

let instance: FindDigitalSigningDisabilityUseCase | null = null;

export default function makeFindDigitalSigningDisabilityUseCase() {
  if (!instance) {
    const repository = makeFlagParametersRepository();

    instance = new FindDigitalSigningDisabilityUseCase(repository);
  }

  return instance;
}
