import { useState } from "react";
import PropTypes from "prop-types";
import { FiPlay } from "react-icons/fi";
import ModalVideo from "../ModalVideo";

import { VideoBorder, Image, Play } from "./styles";

export default function VideoPresentation({
  width,
  height,
  src,
  thumbnail,
  disableBorder,
}) {
  const [openVideo, setOpenVideo] = useState(false);

  return (
    <>
      <VideoBorder
        onClick={() => setOpenVideo(true)}
        hei={height}
        wid={width}
        disabled={disableBorder}
      >
        <Image src={thumbnail} height={height} width={width} alt="Clínica" />
        <Play>
          <FiPlay size={25} color="#FFF" />
        </Play>
      </VideoBorder>
      <ModalVideo
        open={openVideo}
        srcVideo={src}
        handleClose={() => setOpenVideo(false)}
      />
    </>
  );
}

VideoPresentation.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  disableBorder: PropTypes.bool,
};

VideoPresentation.defaultProps = {
  disableBorder: false,
};
