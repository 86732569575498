import { isEmpty } from 'lodash';

export default function addQueryParamToUrl(
  url: string,
  params?: Record<string, unknown>,
) {
  if (!params || isEmpty(params)) return url;

  const search = Object.entries(params)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${String(value)}`)
    .join('&');

  return `${url}?${search}`;
}
