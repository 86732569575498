import {
  Content as RadixContent,
  Arrow as RadixArrow,
  Trigger as RadixTrigger,
} from '@radix-ui/react-tooltip';
import styled, { css, keyframes } from 'styled-components';

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Trigger = styled(RadixTrigger)`
  background: none;
  border: 0;
`;

export const Content = styled(RadixContent)`
  ${({ theme }) => css`
    transform-origin: var(--radix-tooltip-content-transform-origin);
    animation: ${scaleIn} 0.2s ease-out;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    border-radius: 8px;
    background-color: ${theme.palette.neutral[50]};
  `}
`;

export const Arrow = styled(RadixArrow)`
  ${({ theme }) => css`
    fill: ${theme.palette.neutral[50]};
  `}
`;
