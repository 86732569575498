import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";

const Base = styled(IconBase)`
  enable-background: new 0 0 130 130;
  .st0 {
    fill: #63b76b;
  }
  .st1 {
    fill: #ffffff;
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const PaymentIcon = () => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 130 130"
    {...inproperProps}
  >
    <g>
      <g>
        <path
          className="st0"
          d="M65,7.83C33.48,7.84,7.84,33.48,7.83,65c0,31.52,25.64,57.17,57.17,57.17c31.53,0,57.16-25.64,57.17-57.17
			C122.16,33.48,96.53,7.84,65,7.83z M65,117.69c-14.53,0-27.69-5.91-37.24-15.45C18.22,92.7,12.31,79.53,12.31,65
			c0-14.53,5.91-27.69,15.45-37.24C37.31,18.22,50.47,12.31,65,12.31c14.53,0,27.69,5.91,37.24,15.45
			c9.54,9.55,15.45,22.71,15.45,37.24c0,14.53-5.91,27.7-15.45,37.24C92.69,111.78,79.53,117.69,65,117.69z"
        />
        <path
          className="st0"
          d="M51.88,53.82c-0.48,1.21-0.73,2.53-0.73,3.96c0,1.63,0.28,3.11,0.84,4.44c0.55,1.33,1.39,2.54,2.51,3.65
			c1.12,1.11,2.51,2.11,4.18,3.01c1.67,0.9,3.62,1.74,5.85,2.52c0.5,0.17,1.09,0.38,1.78,0.63c0.69,0.25,1.35,0.55,1.98,0.91
			c0.63,0.35,1.16,0.76,1.6,1.21c0.44,0.46,0.66,0.97,0.66,1.55c0,0.52-0.07,1.01-0.23,1.48c-0.16,0.46-0.43,0.87-0.79,1.23
			c-0.37,0.35-0.88,0.63-1.52,0.85c-0.64,0.21-1.43,0.32-2.38,0.32c-1.02,0-2-0.18-2.93-0.53c-0.93-0.36-1.87-0.85-2.81-1.48
			c-0.63-0.43-1.19-0.86-1.68-1.3c-0.49-0.44-0.9-0.79-1.21-1.05l-0.67-0.5l-0.53-0.17c-0.24,0-0.56,0.14-0.95,0.42l-4.82,4.6
			c-0.43,0.39-0.64,0.74-0.64,1.06c0,0.28,0.21,0.65,0.64,1.12l0.05,0.04l0.06,0.07l0.06,0.04l0.05,0.07l0.09,0.08
			c0.3,0.32,0.7,0.73,1.21,1.26c0.51,0.52,1.14,1.05,1.88,1.59c1.23,0.89,2.53,1.66,3.9,2.31c1.38,0.65,2.8,1.13,4.26,1.45v4.07
			c0,0.82,0.4,1.23,1.23,1.23h5.23c0.89,0,1.34-0.41,1.34-1.23v-3.99c1.78-0.31,3.42-0.85,4.91-1.6c1.5-0.75,2.79-1.69,3.89-2.83
			c1.1-1.13,1.95-2.42,2.57-3.89c0.61-1.45,0.92-3.06,0.92-4.8c0-1.93-0.34-3.57-1.02-4.93c-0.67-1.36-1.62-2.52-2.83-3.5
			c-1.21-0.97-2.65-1.82-4.31-2.54c-1.66-0.72-3.48-1.39-5.44-2.05c-1.02-0.34-1.92-0.65-2.69-0.93c-0.77-0.29-1.42-0.6-1.93-0.92
			c-0.52-0.33-0.91-0.68-1.17-1.06c-0.26-0.38-0.39-0.83-0.39-1.35c0-0.52,0.12-0.96,0.36-1.32c0.24-0.36,0.57-0.66,0.98-0.88
			c0.41-0.22,0.88-0.39,1.42-0.49c0.54-0.1,1.12-0.15,1.73-0.15c0.6,0,1.18,0.09,1.76,0.27c0.57,0.18,1.12,0.4,1.64,0.67
			s1.01,0.57,1.47,0.91c0.45,0.33,0.87,0.66,1.24,0.97l0.25,0.22l0.71,0.56l0.71,0.22l0.95-0.47l4.26-4.54l0.54-0.7l0.18-0.67
			l-0.38-0.87c-0.25-0.33-0.59-0.69-1.02-1.06c-0.42-0.37-0.92-0.76-1.49-1.16c-0.57-0.4-1.17-0.78-1.8-1.15
			c-0.99-0.54-1.97-0.98-2.97-1.32c-0.99-0.34-2.01-0.61-3.05-0.79v-3.73c0-0.37-0.15-0.67-0.46-0.89l-0.88-0.34H62.8
			c-0.41,0-0.72,0.12-0.92,0.36l-0.31,0.87v3.87c-1.62,0.32-3.07,0.8-4.37,1.46c-1.29,0.66-2.38,1.47-3.27,2.41
			C53.05,51.53,52.36,52.61,51.88,53.82z"
        />
        <path
          className="st0"
          d="M64.88,38c2.06,0,3.72-1.67,3.72-3.73V22.32c0-2.06-1.67-3.73-3.72-3.73c-2.06,0-3.73,1.67-3.73,3.73v11.95
			C61.15,36.33,62.81,38,64.88,38z"
        />
        <path
          className="st0"
          d="M76.39,41.09c1.67,1.2,4,0.81,5.2-0.87l5.11-7.15c1.2-1.67,0.81-4-0.87-5.2c-1.67-1.2-4.01-0.81-5.2,0.87
			l-5.11,7.15C74.32,37.56,74.71,39.89,76.39,41.09z"
        />
        <path
          className="st0"
          d="M48.56,40.22c1.2,1.67,3.53,2.06,5.2,0.87c1.67-1.2,2.06-3.53,0.86-5.2l-5.11-7.15
			c-1.2-1.68-3.52-2.06-5.2-0.87c-1.67,1.2-2.06,3.53-0.86,5.2L48.56,40.22z"
        />
        <path
          className="st0"
          d="M87.33,40.32l-4.7,4.69c7.35,5.32,12.14,13.96,12.14,23.7c0,16.13-13.12,29.25-29.25,29.25
			c-16.13,0-29.25-13.12-29.25-29.25c0-9.74,4.79-18.38,12.14-23.7l-4.7-4.69c-8.51,6.56-14.02,16.84-14.02,28.4
			c0,19.76,16.07,35.83,35.83,35.83c19.75,0,35.82-16.07,35.82-35.83C101.34,57.16,95.84,46.88,87.33,40.32z"
        />
      </g>
    </g>
  </Base>
);

export default PaymentIcon;
