import styled, { css } from 'styled-components';

export const Container = styled.div<{ color?: string }>`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.layout.spacing(2)};
    align-self: stretch;
    background-color: transparent;
    border: none;
    cursor: pointer;

    color: ${color || theme.palette.text.main};

    svg {
      font-size: 1.5rem;
    }

    span {
      font-family: ${theme.typography.family};
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.light};
      flex: 1;
      text-align: left;
    }

    .badge {
      background: ${theme.palette.error.light};
      color: ${theme.palette.text.white};

      border-radius: 50%;
      width: ${theme.layout.spacing(2)};
      height: ${theme.layout.spacing(2)};
      flex-shrink: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${theme.typography.sizes.caption};
      font-weight: ${theme.typography.weight.extraBold};
    }

    &:hover {
      color: ${theme.palette.secondary[theme.name]};
    }
  `}
`;
