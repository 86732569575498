type TBrowserData = {
  browser?: string;
  fullVersion?: string;
  version?: string;
};

interface IBrowserDataAssembler {
  getData(): Promise<TBrowserData>;
}

class NewUserAgentDataAssembler implements IBrowserDataAssembler {
  private FAKE_AND_SKIPPABLE_BRAND = /Not.*A.*Brand/;
  private NOT_RELEVANT_BROWSER = 'Chromium';
  private DEFAULT_EMPTY_BROWSER_DATA = {
    browser: undefined,
    fullVersion: undefined,
    version: undefined,
  };

  constructor(private data: NavigatorUAData) {}

  public async getData() {
    const { fullVersionList } = await this.data.getHighEntropyValues([
      'fullVersionList',
    ]);

    if (!fullVersionList) return this.DEFAULT_EMPTY_BROWSER_DATA;

    const accumulatedData = this.removeUnwantedFromFullVersionList(
      fullVersionList,
    ).reduce<{
      browsers: string[];
      fullVersions: string[];
      versions: string[];
    }>(
      (acc, actual) => {
        const [version] = actual.version.split('.');

        return {
          browsers: [...acc.browsers, actual.brand],
          fullVersions: [...acc.fullVersions, actual.version],
          versions: [...acc.versions, version],
        };
      },
      {
        browsers: [],
        fullVersions: [],
        versions: [],
      },
    );

    return {
      browser: accumulatedData.browsers.join(', '),
      fullVersion: accumulatedData.fullVersions.join(', '),
      version: accumulatedData.versions.join(', '),
    };
  }

  private removeUnwantedFromFullVersionList(list: NavigatorUABrandVersion[]) {
    const listWithoutFakeBrand = list.filter(
      browser => !browser.brand.match(this.FAKE_AND_SKIPPABLE_BRAND),
    );

    const hasDetectedManyBrowsers = listWithoutFakeBrand.length > 1;
    if (!hasDetectedManyBrowsers) return listWithoutFakeBrand;

    return listWithoutFakeBrand.filter(
      browser => !browser.brand.includes(this.NOT_RELEVANT_BROWSER),
    );
  }
}

class OldUserAgentAssembler implements IBrowserDataAssembler {
  private BROWSER_DETECTOR_STRINGS = {
    OPR: 'Opera',
    Edg: 'Microsoft Edge',
    MSIE: 'Microsoft Internet Explorer',
    Chrome: 'Chrome',
    Safari: 'Safari',
    Firefox: 'Firefox',
  } as const;

  private DEFAULT_EMPTY_BROWSER_DATA = {
    browser: undefined,
    fullVersion: undefined,
    version: undefined,
  };

  constructor(private agent: string) {}

  public async getData() {
    const detector = this.getBrowserDetector(this.agent);

    if (!detector) {
      return this.DEFAULT_EMPTY_BROWSER_DATA;
    }

    const versionSearchRegex = new RegExp(detector.detectorString + '.* ');

    const matches = this.agent.match(versionSearchRegex);

    if (!matches) return this.DEFAULT_EMPTY_BROWSER_DATA;

    const [versionContainingString] = matches;

    const [, versionString] = versionContainingString.split('/');
    const fullVersion = versionString.trim();
    const [version] = fullVersion.split('.');

    return {
      browser: detector.browser,
      fullVersion,
      version,
    };
  }

  private getBrowserDetector(agent: string) {
    for (const detectorString of Object.keys(this.BROWSER_DETECTOR_STRINGS)) {
      if (agent.includes(detectorString)) {
        return {
          detectorString,
          browser: this.BROWSER_DETECTOR_STRINGS[detectorString],
        };
      }
    }
  }
}

function makeBrowserDataAssembler() {
  if (navigator.userAgentData) {
    return new NewUserAgentDataAssembler(navigator.userAgentData);
  }

  return new OldUserAgentAssembler(navigator.userAgent);
}

export default async function getBrowserData() {
  const agent = navigator.userAgent;

  const DataSearcher = makeBrowserDataAssembler();

  const { browser, fullVersion, version } = await DataSearcher.getData();

  return { agent, browser, fullVersion, version };
}
