import { TPaymentMethodType } from 'shared/domain/entities';

export const checkoutQueries = {
  base: ['budget'] as const,

  findBudgetByHash: (hash: string | undefined) =>
    [...checkoutQueries.base, 'findBudgetByHash', hash] as const,

  cardInstallments: (
    budgetId: number | undefined,
    cardNumber: string | undefined,
    method: TPaymentMethodType | undefined,
  ) =>
    [
      ...checkoutQueries.base,
      'cardInstallments',
      budgetId,
      cardNumber,
      method,
    ] as const,

  automaticDebitInstallments: (budgetId: number | undefined) =>
    [...checkoutQueries.base, 'automaticDebitInstallments', budgetId] as const,

  citiesByName: (name: string | undefined) =>
    [...checkoutQueries.base, 'citiesByName', name] as const,

  districtsByCityAndName: (
    cityId: number | undefined,
    name: string | undefined,
  ) =>
    [...checkoutQueries.base, 'districtsByCityAndName', cityId, name] as const,

  budgetStatus: (budgetId: number | undefined) => [
    ...checkoutQueries.base,
    'budgetStatus',
    budgetId,
  ],

  digitalSigningDisabled: () =>
    [...checkoutQueries.base, 'digitalSigning'] as const,
};
