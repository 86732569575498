import React, { PropsWithChildren } from 'react';

import { Redirect as StandardRedirect } from 'react-router-dom';

import { addQueryParamToUrl } from '../../helpers';
import { TRoutePaths, TRouteQuery } from '../../models';

type TRedirectProps<T extends TRoutePaths> = TRouteQuery<T> extends never
  ? {
      from?: TRoutePaths;
      to: T;
      params?: never;
    }
  : {
      from?: TRoutePaths;
      to: T;
      params: TRouteQuery<T>;
    };

function Redirect<T extends TRoutePaths>({
  to,
  params,
  ...props
}: PropsWithChildren<TRedirectProps<T>>) {
  return <StandardRedirect {...props} to={addQueryParamToUrl(to, params)} />;
}

export default Redirect;
