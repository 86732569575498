import styled, { css } from 'styled-components';

export const Container = styled.section<{ maxWidth?: number }>`
  ${({ theme, maxWidth }) => css`
    width: 100%;
    ${maxWidth &&
    css`
      max-width: ${theme.layout.spacing(maxWidth)};
    `}
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 1rem;
    padding: ${theme.layout.spacing(1)};

    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.text.white};

    h2 {
      font-size: ${theme.typography.sizes.title3};
      font-weight: ${theme.typography.weight.extraBold};
    }

    ul {
      display: flex;
      list-style: none;
      gap: ${theme.layout.spacing(1)};

      li {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: ${theme.layout.spacing(0.5)};
        padding: ${theme.layout.spacing(1)};
        width: ${theme.layout.spacing(12)};
        border-radius: 0.5rem;

        background-color: ${theme.palette.neutral[50]};
        color: ${theme.palette.text.main};

        span {
          font-size: ${theme.typography.sizes.title3};
          font-weight: ${theme.typography.weight.bold};
        }

        strong {
          font-size: ${theme.typography.sizes.body2};
        }
      }
    }

    @media (max-width: ${theme.layout.breakpoints.md}) {
      flex-direction: column;
      border-radius: 0;
      text-align: center;
      justify-content: flex-start;
      gap: ${theme.layout.spacing(1)};

      ul {
        li {
          width: ${theme.layout.spacing(8)};
          flex-direction: row;
          justify-content: center;

          strong {
            font-size: 0;

            &::first-letter {
              font-size: ${theme.typography.sizes.title3};
            }
          }
        }
      }
    }
  `}
`;
