import { useMemo } from 'react';

import { HashLink } from 'react-router-hash-link';

import {
  CONTACTS,
  COUNTRY_CODES,
  DEFAULT_CUSTOMER_SERVICE_LINK,
} from 'shared/presentation/constants';
import {
  Link,
  TRoutePathsWithParams,
  useLocale,
} from 'shared/presentation/contexts';
import { buildWhatsappLink } from 'shared/presentation/helpers';

import * as S from './styles';

type TLink = { children: string } & (
  | { type: 'internal'; to: TRoutePathsWithParams }
  | { type: 'external' | 'hash'; to: string }
);

const FooterBottomLinks: React.FC = () => {
  const { t, language } = useLocale('shared');

  const NAVIGATION_SECTIONS = useMemo<Array<{ title: string; links: TLink[] }>>(
    () => [
      {
        title: 'Menu',
        links: [
          {
            type: 'internal',
            to: '/pacotes',
            children: t('Buy now'),
          },
          {
            type: 'internal',
            to: '/carrinho',
            children: t('Cart'),
          },
          {
            type: 'internal',
            to: '/agendamento',
            children: t('Schedulings'),
          },
          {
            type: 'internal',
            to: '/area-cliente',
            children: t('Customer area'),
          },
        ],
      },
      {
        title: 'Sobre nós',
        links: [
          {
            type: 'internal',
            to: '/quem-somos',
            children: t('Who we are'),
          },
          {
            type: 'external',
            to: 'https://vialaserdepilacao.gupy.io/',
            children: t('Work with us'),
          },
          {
            type: 'external',
            to: 'https://vialaser.com.br/franquias',
            children: t('Be franchised'),
          },
          {
            type: 'external',
            to: 'https://www.vialaser.com.br/blog/',
            children: 'Blog',
          },
        ],
      },
      {
        title: 'Ajuda',
        links: [
          {
            type: 'internal',
            to: '/politicas-de-privacidade',
            children: t('Privacy policies'),
          },
          {
            type: 'internal',
            to: '/termos-de-uso',
            children: t('Terms of use'),
          },
          {
            type: 'hash',
            to: '/#where-to-find',
            children: t('Where to find'),
          },
          {
            type: 'external',
            to: DEFAULT_CUSTOMER_SERVICE_LINK,
            children: t('Citizen Service'),
          },
          {
            type: 'external',
            to: buildWhatsappLink({
              message: t(
                "I'm interested in knowing more about laser hair removal promotions",
              ),
              number: CONTACTS[language],
              countryCode: COUNTRY_CODES[language],
              language,
            }),
            children: t('Sales center'),
          },
        ],
      },
    ],
    [t, language],
  );

  return (
    <S.Container>
      {NAVIGATION_SECTIONS.map(section => (
        <div key={section.title}>
          <h3>{section.title}</h3>

          <ul>
            {section.links.map(link => {
              if (link.type === 'external') {
                return (
                  <a key={link.to} href={link.to} rel="noopener noreferrer">
                    {link.children}
                  </a>
                );
              }

              if (link.type === 'hash') {
                return (
                  <HashLink key={link.to} to={link.to}>
                    {link.children}
                  </HashLink>
                );
              }

              return (
                <Link key={link.to} to={link.to as TRoutePathsWithParams}>
                  {link.children}
                </Link>
              );
            })}
          </ul>
        </div>
      ))}
    </S.Container>
  );
};

export default FooterBottomLinks;
