import { createRef, useEffect } from 'react';

import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { emitter } from '@marvelapp/react-ab-test';
import { IconButton } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import NotificationsContext from 'contexts/NotificationsContext';
import getValidLanguage from 'helpers/getValidLanguage';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import { Translation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import theme from 'Theme';

import appModules from 'shared/presentation/modules';

import { AuthProvider } from './contexts/AppContext';
import { BonificationProvider } from './contexts/BonificationContext';
import { EventProvider } from './contexts/EventsContext';
import { LoginProvider } from './contexts/LoginContext';
import { NewCartProvider } from './contexts/NewCartContext';
import { RecommendationProvider } from './contexts/RecommendationContext';
import SetMomentLocale from './helpers/SetMomentLocale';
import { trackData } from './helpers/Trackers';
import logo from './img/vialaser_logo.svg';
import Routes from './Routes';

import 'config/locale';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const APP_ORIGIN = {
  pt: process.env.REACT_APP_URL,
  es: process.env.REACT_APP_URL_PARAGUAY,
  'es-CR': process.env.REACT_APP_URL_COSTARICA,
};

emitter.addPlayListener((experimentNameCalled, variantShown) => {
  const optionalInformation = {
    experimentNameCalled,
    variantShown,
  };
  trackData(window.location.pathname, experimentNameCalled, {
    ...optionalInformation,
  });
});

const AppContainer = appModules.Container;

export default function App() {
  // add action to all snackbars
  const notistackRef = createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    const currentAreaCard = localStorage.getItem(
      'PUSHTELL-TestABC-Listagem-Produtos',
    );

    if (currentAreaCard === 'Desafiante B') {
      localStorage.removeItem('PUSHTELL-TestABC-Listagem-Produtos');
      window.location.reload();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Translation>
        {(t, { lng }) => {
          const origin = APP_ORIGIN[getValidLanguage(lng)];

          return (
            <Helmet>
              <meta charSet="utf-8" />
              <base href={origin} />
              <title>{t('Depilação a laser')}</title>
              <meta
                name="description"
                content={t('clínica de depilação a laser')}
              />
              <meta name="keywords" content={t('vialaser_keywords')} />
              <link rel="canonical" href={origin} />
              <meta property="og:title" content={t('vialaser_title')} />
              <meta
                property="og:description"
                content={t('vialaser_description')}
              />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={origin} />
              <meta property="og:image" content={logo} />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Helmet>
          );
        }}
      </Translation>
      <SnackbarProvider
        maxSnack={3}
        hideIconVariant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ref={notistackRef}
        action={key => (
          <IconButton
            style={{ color: '#fff' }}
            aria-label="Fechar"
            onClick={onClickDismiss(key)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
        )}
      >
        <SetMomentLocale />
        <AppContainer>
          <AuthProvider>
            <NewCartProvider>
              <EventProvider>
                <RecommendationProvider>
                  <BonificationProvider>
                    <LoginProvider>
                      <NotificationsContext>
                        <Routes />
                      </NotificationsContext>
                    </LoginProvider>
                  </BonificationProvider>
                </RecommendationProvider>
              </EventProvider>
            </NewCartProvider>
          </AuthProvider>
        </AppContainer>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
