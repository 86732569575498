import { TLanguage } from 'config/tenancy';

const EXPIRY_DATE = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

const DATE =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

const FULLNAME = /^[a-zA-Z\u00C0-\u00FF]+ [a-zA-Z \u00C0-\u00FF]+$/;

const VALID_NAME = /^[a-zA-Z \u00C0-\u00FF]+$/;

const PHONE: Record<TLanguage, RegExp> = {
  pt: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
  es: /^(\d{4}(-|\s)\d{5})$/,
  'es-CR': /^(\d{4}(-|\s)\d{4})$/,
};

const VALID_CARD_FLAGS =
  /^(?:3[47][0-9]{13}|(6541|6556|6509)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[01][0-9]|92[0-5])[0-9]{10}|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})\d{11}|9[0-9]{15}|(6304|6706|6709|6771)[0-9]{12,15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14,15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14,15}|564182[0-9]{10,13}|633110[0-9]{10,13}|62[0-9]{14,17}|4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/;

export const REGEXES = {
  DATE,
  EXPIRY_DATE,
  FULLNAME,
  PHONE,
  VALID_CARD_FLAGS,
  VALID_NAME,
};
