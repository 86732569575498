import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { QRCode } from 'react-qrcode-logo';

import { ICONS } from 'shared/presentation/constants';

import * as S from './styles';

const SIZE_PX = 250;
const QR_CODE_SIZE_PX = SIZE_PX - 15;

const IconQrCode = ICONS.QRCODE;

interface IPixQrCodeProps {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  digitableLine?: string;
}

const PixQrCode: React.FC<IPixQrCodeProps> = ({
  disabled,
  onClick,
  loading,
  digitableLine,
}) => {
  if (loading) return <Skeleton width={SIZE_PX} height={SIZE_PX} />;

  if (digitableLine) {
    return (
      <S.Container size={SIZE_PX}>
        <QRCode
          size={QR_CODE_SIZE_PX}
          qrStyle="squares"
          value={digitableLine.replace('\\', '')}
        />
      </S.Container>
    );
  }

  return (
    <S.Container size={SIZE_PX}>
      <IconQrCode />

      <button onClick={onClick} disabled={disabled} type="button">
        Gerar QRCode PIX
      </button>
    </S.Container>
  );
};

export default PixQrCode;
