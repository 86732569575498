import { useState } from 'react';

import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';

import { useRequestErrorHandler } from 'shared/presentation/hooks';

export type TUseSearch = {
  queryKeyFactory: (search: string, values: unknown) => readonly unknown[];
  queryFetchFactory: (search: string, values: unknown) => Promise<unknown>;
  dataToOptionsFormatter: (
    data: unknown,
  ) => Array<{ value: string; label: string }>;
  enabledFactory?: (search: string, values: unknown) => boolean;
};

export default function useSearch({
  queryKeyFactory,
  queryFetchFactory,
  enabledFactory,
  dataToOptionsFormatter,
}: TUseSearch) {
  const [search, setSearch] = useState('');

  const formik = useFormikContext();
  const handleError = useRequestErrorHandler('shared');

  const query = useQuery(
    queryKeyFactory(search, formik.values),
    () => queryFetchFactory(search, formik.values),
    {
      onError: error => handleError(error),
      select: dataToOptionsFormatter,
      enabled: enabledFactory?.(search, formik.values),
    },
  );

  return {
    isLoading: query.isLoading,
    data: query.data || [],
    setSearch,
  };
}
