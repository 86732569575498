export const signup = {
  'Already have an account? <1>Sign in!</1>': '',
  'And live your life free of hairs!': '',
  'Confirm password': '',
  Email: '',
  Fullname: '',
  'I accept the <1>Terms and conditions</1> and I authorize the use of my data according to the <3>Privacy policy</3>':
    'Acepto los <1>Términos y condiciones</1> y autorizo el uso de mis datos de acuerdo con la <3>Declaracion de privacidad</3>.',
  Password: '',
  Phone: '',
  'Please, inform a valid e-mail': 'Por favor, libere un email válido',
  'Please, inform a valid name': 'Por favor, libere un nombre válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Or register with your social networks': '',
  Register: '',
  'Register yourself': '',
  'Registered successfully!': '¡Registrado con éxito!',
  "The passwords don't match": '',
  'This field is required': 'Este campo es obligatorio',
  'Sign up': '',
  'Sign up with Google': '',
};
