import React from "react";

import models from "../../assets/img/banners_models.png";
import logo from "../../assets/img/logo_vialaser.svg";

import { Container } from "./styles";

function Banner() {
  return (
    <Container>
      <img src={logo} alt="Vialaser" className="logo" />

      <img src={models} alt="" className="models" />
    </Container>
  );
}

export default Banner;
