import { Card, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { FiArrowRight, FiCalendar, FiUser } from "react-icons/fi";
import Carousel from "../../components/Carousel";
import CustomHr from "../../components/CustomHr";
import blogImagePlaceholder from "../../img/blog_image_placeholder.svg";
import "../../index.css";
import apiBlog from "../../services/apiBlog";
import { Container } from "../../styles/global";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    maxWidth: 1380,
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  card: {
    height: "100%",
    display: "flex",
    background: theme.palette.cardsBackground.primary,
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "3px 4px 5px rgba(0,0,0,0.2)",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  cardWrapper: {
    paddingTop: "1rem",
    [theme.breakpoints.up("md")]: {
      padding: "0 1rem",
      "&:first-child": {
        marginLeft: "-1rem",
      },
      "&:last-child": {
        marginRight: "-1rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.9)",
      margin: "0 auto",
    },
  },
  imgPost: {
    height: 200,
    width: "100%",
    marginBottom: 10,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      objectFit: "inherit",
    },
  },
  title: {
    fontWeight: "bold",
  },
  text: {
    height: "90px",
    overflow: "hidden",
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 15,
    marginTop: 10,
    "& p": {
      marginTop: 0,
    },
  },
  link: {
    fontSize: "1.1rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 10,
    textAlign: "center",
    boxShadow: `0 -15px 25px 16px ${theme.palette.cardsBackground.primary}`,
  },
  bottom: {
    height: "50px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: "#666",
    fontWeight: 400,
    marginBottom: 10,
    "& span": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "5px",
        color: theme.palette.text.primary,
      },
    },
  },
  blog: {
    width: "100%",
    margin: "25px 0",
    transition: "padding-left 0.3s",
    "&:hover": {
      paddingLeft: "15px",
    },
    "&:focus": {
      border: 0,
    },
  },
  arrow: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: theme.palette.background.default,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Blog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const isXs = useMediaQuery(theme => theme.breakpoints.down("xs"));

  useEffect(() => {
    async function getPosts() {
      const responsePost = await apiBlog.get("posts?_embed&", {
        params: { per_page: 3 },
      });
      if (responsePost.data) {
        setPosts(responsePost.data);
      }
    }

    getPosts();
  }, []);

  const renderMotive = (post, index) => {
    const postTitle = post.title.rendered;
    const titleTruncatedMobile = `${postTitle.substring(0, 55)}...`;
    const titleTruncated = `${postTitle.substring(0, 70)}...`;
    // eslint-disable-next-line no-underscore-dangle
    const [postImage] = post._embedded["wp:featuredmedia"] ?? [
      { source_url: blogImagePlaceholder },
    ];
    return (
      <Grid
        item
        xs={12}
        md={4}
        key={index}
        container
        alignItems="center"
        justifyContent="center"
        className={classes.cardWrapper}
      >
        <Card className={classes.card}>
          {postImage && (
            <img
              className={classes.imgPost}
              src={postImage.source_url}
              alt={postTitle}
            />
          )}
          {isXs ? (
            <Typography
              align="center"
              variant="h2"
              className={classes.title}
              component="span"
            >
              {ReactHtmlParser(titleTruncatedMobile)}
            </Typography>
          ) : (
            <Typography
              align="center"
              variant="h2"
              className={classes.title}
              component="span"
            >
              {ReactHtmlParser(titleTruncated)}
            </Typography>
          )}

          <Typography
            align="center"
            variant="body1"
            className={classes.text}
            component="span"
          >
            {ReactHtmlParser(post.excerpt.rendered)}
          </Typography>

          <a
            href={post.link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {t("Ler mais")}
          </a>
          <div className={classes.bottom}>
            <span>
              <FiCalendar size={20} />
              {moment(post.modified_gmt).format("DD/MM/YYYY")}
            </span>
            <span>
              <FiUser size={20} />
              Lisa
            </span>
          </div>
        </Card>
      </Grid>
    );
  };

  return (
    <Container>
      <Grid
        className="home-container"
        container
        spacing={0}
        justifyContent="center"
      >
        <Grid item xs={11} md={12}>
          <Typography align="center" variant="h1" component="h2" gutterBottom>
            {t(
              "Dicas de depilação, beleza, saúde e muito mais no Blog da Lisa da Vialaser!"
            )}
          </Typography>
          <CustomHr centered />
          <Typography
            align="center"
            variant="h2"
            component="p"
            style={{ marginBottom: "0.938rem" }}
          >
            {t(
              "Temos muito conteúdo relacionado a depilação a laser, dicas de beleza e saúde e muito mais no Blog da Lisa! Confira nossos últimos posts"
            )}
          </Typography>
        </Grid>
        {isXs ? (
          <Carousel
            autoPlay
            swipeable
            showArrows={false}
            emulateTouch
            infiniteLoop
            stopOnHover={false}
          >
            {posts.map(renderMotive)}
          </Carousel>
        ) : (
          <Grid item container justifyContent="space-between" xs={12}>
            {posts.map(renderMotive)}
          </Grid>
        )}
        <a
          className={classes.blog}
          href="https://www.vialaser.com.br/blog"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Grid
            item
            container
            sm={12}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h3" color="primary">
                {t("Conferir mais dicas no blog da Vialaser")}
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.arrow}>
                <FiArrowRight size={20} />
              </div>
            </Grid>
          </Grid>
        </a>
      </Grid>
    </Container>
  );
}
