import { Spinner } from 'shared/presentation/components/atoms';
import { useTheme } from 'shared/presentation/contexts';

import { Container } from './styles';

interface IFullPageLoadingProps {
  type?: 'viewport' | 'flex';
}

const FullPageLoading: React.FC<IFullPageLoadingProps> = ({
  type = 'viewport',
}) => {
  const { theme } = useTheme();

  return (
    <Container type={type}>
      <Spinner color={theme.palette.primary.main} size={5} />
    </Container>
  );
};

export default FullPageLoading;
