import React from 'react';

import { getPaginationStructure } from './helpers';
import * as S from './styles';

interface IPaginationProps {
  totalPages: number;
  currentPage: number;
  onNavigateTo(page: number): void;
}

const Pagination: React.FC<IPaginationProps> = ({
  totalPages,
  currentPage,
  onNavigateTo,
}) => {
  const buttons = getPaginationStructure({
    currentPage,
    totalPages,
    onClick: onNavigateTo,
  });

  return (
    <S.Container>
      {buttons.map(button => (
        <S.PageButton {...button} key={button.key} />
      ))}
    </S.Container>
  );
};

export default Pagination;
