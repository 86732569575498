import React from 'react';

import { Helmet } from 'react-helmet';

import { TLanguage } from 'config/tenancy';
import { useLocale } from 'shared/presentation/contexts';

const URLS: Record<TLanguage, string> = {
  'es-CR': '',
  es: 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/e33c83bd-e8fa-4b2b-82a0-18023a638393-loader.js',
  pt: 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/649ec34a-69b8-4075-914f-d7dd91925370-loader.js',
};

const RdStationScript: React.FC = () => {
  const { language } = useLocale();

  return (
    <Helmet>
      <script defer async id="rd" src={URLS[language]} />
    </Helmet>
  );
};

export default RdStationScript;
