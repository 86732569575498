export const benefit = {
  'And a good friendship is where is get double the discount! Your friends <1>also have a discount of {{discount}}</1> to get their hair removal up to date!':
    'E amizade boa mesmo é quando tem desconto em dobro! Os seus amigos <1>também ganham {{discount}}</1> de desconto para garantirem a depilação em dia!',
  'As soon as three friends, that dont know Vialaser yet, register themselves, you will get an <1>{{value}} exclusive coupon</1> at your email for the purchase of any package':
    'Assim que três amigos, que ainda não conheçam a Vialaser, se cadastrarem, você receberá no seu e-mail um <1>cupom exclusivo de {{value}}</1> para a compra de qualquer pacote.',
  'A true friend is the one that refer <1>{{value}} in discounts</1> to you and your friends <3>Enjoy!</3>':
    'Amigo de verdade é quem indica. <1>{{value}} de desconto</1> para você e seus amigos. <3>Aproveite!</3>',
  'At Vialaser your recommendation means a looooot! <1>Up to {{value}} in discounts</1> of your installments <3>Enjoy!</3>':
    'Na Vialaser a sua indicação vale muuuuuuuito! <1>Até {{value}} de desconto</1> nas suas parcelas. <3>Aproveite!</3>',
  'Copy link': 'Copiar link',
  'Could not recommend the following people {{customers}}':
    'Não foi possível indicar as seguintes pessoas: {{customers}}',
  'Coupon valid only for purchases above {{value}}':
    '* Cupom válido para compras acima de {{value}}',
  'Link successfully copied!': 'Link copiado com sucesso!',
  'Loading data': 'Carregando dados...',
  Name: 'Nome',
  'Name of the recommended person': 'Nome da pessoa indicada',
  "Our friendship is worth millions and in Vialaser it's worth a discount! 😍%0aWin a {{discount}} discount on the purchase of a hair removal package at Vialaser by subscribing with my link, but remember, this cupon is only valid for purchases up to {{minValue}} %0a%0a{{link}}":
    'A nossa amizade é de milhões e na Vialaser ela vale desconto! 😍%0aGaranta {{discount}} de desconto na compra de um pacote de depilação a laser na Vialaser se inscrevendo no meu link, mas lembre-se, esse cupom é válido para compras acima de {{minValue}}. %0a%0a{{link}}',
  'Please, inform a fullname': 'Por favor, informe um nome completo',
  'Please, inform a valid number': 'Por favor, informe um número válido',
  PHONE_PLACEHOLDER: '(00) 00000-0000',
  Recommend: 'Indicar',
  Recommendation: 'Indicação',
  'Recommendations successfully made!': 'Indicações feitas com sucesso!',
  'Share your link via Whatsapp': 'Compartilhe seu link pelo WhatsApp',
  'The discount is valid only on purchases of packages worth more than <1>{{value}}</1>':
    'Desconto válido para compras de pacotes com valores acima de <1>{{value}}</1>',
  'The link could not be copied Please try again!':
    'Não foi possível copiar o link. Tente novamente!',
  'The link could not be shared Please try again!':
    'Não foi possível compartilhar o link. Tente novamente!',
  'You can send your personalized link to as many friends as you want <1>The more you indicate, the easier it is to win</1> For each friend who purchases a package over {{minimumBudgetValue}} you receive a <5>discount of {{value}} on your next installment</5> Your referrals <7>also receive a {{discount}} discount</7> to keep their waxing up to date':
    'Você pode enviar o seu link personalizado para quantos amigos quiser. <1>Quanto mais indicar, mais fácil de ganhar.</1> A cada amigo que adquirir um pacote acima de {{minimumBudgetValue}} você ganha um <5>desconto de {{value}} na sua próxima parcela.</5> Seus indicados <7>também ganham {{discount}} de desconto</7> para deixar a depilação em dia.',
};
