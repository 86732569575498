import Axios from 'axios';

export async function fetchPaymentMethods(type, tenancia) {
  return Axios.get(`/paymentmethods?tenancia=${tenancia}`).then(res => {
    return res.data.filter(d => d.tipo === type);
  });
}

export function defaultPaymentMethodFor(paymentType, methods) {
  return methods
    .sort(
      (a, b) =>
        b.numeroMaximoParcelas - a.numeroMaximoParcelas ||
        a.valorMinimoParcela - b.valorMinimoParcela,
    )
    .filter(method => method.formaPagamento === paymentType)
    .shift();
}

export async function fetchCreatePaymentPix(idBudget, dadosAssinaturaDigital) {
  const body = {
    idOrcamento: idBudget,
    cupom: '',
    dadosAssinaturaDigital,
  };

  return Axios.post(`/pix/criarCobranca`, body).then(res => {
    const { linhaDigitavel, dataVencimento } = res.data;

    return {
      linhaDigitavel,
      dataVencimento,
    };
  });
}

export function fetchBudgetPaymentStatus(idBudget) {
  return Axios.get(`/orcamentos/${idBudget}/status`).then(res => {
    const { value } = res.data;

    return {
      value,
    };
  });
}

export async function createNupayPayment(idBudget, dadosAssinaturaDigital) {
  const body = {
    idOrcamento: idBudget,
    cupom: '',
    dadosAssinaturaDigital,
  };

  return Axios.post(`/nupay/criarPagamento`, body).then(res => {
    const { paymentUrl, dataVencimento } = res.data;

    return {
      paymentUrl,
      dataVencimento,
    };
  });
}
