import { useMemo } from 'react';

import { Grid } from '@material-ui/core';
import '../../index.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { StoreFooter } from 'shared/presentation/components/organisms';
import { AppNavbar } from 'shared/presentation/components/templates';

import Blog from './Blog';
import FAQ from './FAQ';
import HomePresentation from './HomePresentation';
import Sugestions from './Sugestions';
import Testimonials from './Testimonials';
import VideoArea from './VideoArea';
import WhereToFind from '../../components/WhereToFind/WhereToFind';

export default function Home() {
  const WhereToFindMemo = useMemo(() => <WhereToFind />, []);

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Depilação a laser')}</title>
        <meta name="description" content={t('clínica de depilação a laser')} />
        <meta name="keywords" content={t('vialaser_keywords')} />
        <link rel="canonical" href="https://www.vialaser.com.br" />
        <meta property="og:title" content={t('vialaser_title')} />
        <meta property="og:description" content={t('vialaser_description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.vialaser.com.br" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <AppNavbar />

      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12}>
          <HomePresentation />
        </Grid>
        <Grid item xs={12}>
          <VideoArea />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <Sugestions />
        </Grid>
        <Grid item xs={12}>
          <Testimonials />
        </Grid>
        <Grid item xs={12}>
          <FAQ />
        </Grid>
        <Grid item xs={12}>
          <Blog />
        </Grid>
        <Grid item xs={12} id="where-to-find">
          {WhereToFindMemo}
        </Grid>
      </Grid>

      <StoreFooter />
    </>
  );
}
