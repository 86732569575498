import styled, { css } from 'styled-components';

export const Container = styled.a`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    transition: all 0.2s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: ${theme.palette.primary.light};
    }

    .badge {
      position: absolute;

      background: ${theme.palette.error.light};
      color: ${theme.palette.text.white};

      border-radius: 0.5rem;

      display: flex;
      padding: ${theme.layout.spacing(0.25, 0.75)};
      font-size: ${theme.typography.sizes.caption};
      font-weight: ${theme.typography.weight.extraBold};

      right: -50%;
      top: -25%;
    }
  `}
`;
