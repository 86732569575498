export const benefit = {
  'And a good friendship is where is get double the discount! Your friends <1>also have a discount of {{discount}}</1> to get their hair removal up to date!':
    '¡Y la amistad realmente buena es cuando hay un descuento doble! ¡Tus amigas <1>también obtienen un descuento de {{discount}}</1> para garantizar su depilación a tiempo!',
  'As soon as three friends, that dont know Vialaser yet, register themselves, you will get an <1>{{value}} exclusive coupon</1> at your email for the purchase of any package':
    'Tan pronto como tres amigos, que aún no conocen Vialaser, se registren, recibirás en tu correo electrónico un <1>cupón exclusivo de {{value}}</1> para la compra de cualquier paquete.',
  'At Vialaser your recommendation means a looooot! <1>Up to {{value}} in discounts</1> of your installments <3>Enjoy!</3>':
    'En Vialaser tu recomendación vale muuuuuucho! <1>Hasta {{value}} de descuento</1> en tus cuotas. <3>Aprovecha!</3>',
  'A true friend is the one that refer <1>{{value}} in discounts</1> to you and your friends <3>Enjoy!</3>':
    'Un verdadero amigo es el que recomienda. <1>{{value}} de descuento</1>  para ti y tus amigos. <3>Aproveche!</3>',
  'Copy link': 'Copiar enlace',
  'Could not recommend the following people {{customers}}':
    'No fue posible indicar a las siguientes personas: {{customers}}',
  'Coupon valid only for purchases above {{value}}':
    '* Cupón válido para compras superiores a {{value}}',
  'Link successfully copied!': '¡Enlace copiado con éxito!',
  'Loading data': 'Cargando datos...',
  Name: 'Nombre',
  'Name of the recommended person': 'Nombre de persona indicada',
  "Our friendship is worth millions and in Vialaser it's worth a discount! 😍%0aWin a {{discount}} discount on the purchase of a hair removal package at Vialaser by subscribing with my link, but remember, this cupon is only valid for purchases up to {{minValue}} %0a%0a{{link}}":
    'Nuestra amistad vale millones, ¡y en Vialaser vale un descuento! 😍%0aObtén un descuento de {{discount}} en la compra de un paquete de depilación láser en Vialaser al inscribirte en mi enlace, pero recuerda, este cupón es válido para compras superiores a {{minValue}}. %0a%0a{{link}}',
  'Please, inform a fullname': 'Por favor, informe el nombre completo',
  'Please, inform a valid number': 'Por favor, libere un numero válido',
  PHONE_PLACEHOLDER: '0000-00000',
  Recommend: 'Indicar',
  Recommendation: 'Indicación',
  'Recommendations successfully made!': '¡Indicaciones hechas con éxito!',
  'Share your link via Whatsapp': 'Comparte tu enlace a través de WhatsApp',
  'The discount is valid only on purchases of packages worth more than <1>{{value}}</1>':
    'Descuento válido para compras de paquetes con valores superiores a <1>{{value}}</1>',
  'The link could not be copied Please try again!':
    'No se puede copiar el enlace. ¡Inténtalo de nuevo!',
  'The link could not be shared Please try again!':
    'No se puede compartir el enlace. ¡Inténtalo de nuevo!',
  'You can send your personalized link to as many friends as you want <1>The more you indicate, the easier it is to win</1> For each friend who purchases a package over {{minimumBudgetValue}} you receive a <5>discount of {{value}} on your next installment</5> Your referrals <7>also receive a {{discount}} discount</7> to keep their waxing up to date':
    'Puedes enviar tu enlace personalizado a tantos amigos como quieras. <1>Cuanto más indiques, más fácil será ganar.</1> Por cada amigo que compre un paquete arriba de {{minimumBudgetValue}} obtienes un <5>descuento de {{value}} en tu próxima cuota.</5> Tus referidos <7>también reciben un descuento de {{discount}}</7> para mantener su depilación al día.',
};
