export const customerQueries = {
  base: ['customer'] as const,

  customer: (id: number | undefined) => [...customerQueries.base, id],
  customerCards: (
    customerId: number | undefined,
    contractId: number | undefined,
  ) => [...customerQueries.customer(customerId), 'cards', contractId],
  cities: (name: string) => [...customerQueries.base, 'cities', name],
  districts: (name: string, cityId: number | undefined) => [
    ...customerQueries.base,
    'districts',
    name,
    cityId,
  ],

  directives: (unitId: number) =>
    [...customerQueries.base, 'directives', unitId] as const,

  baseContracts: (customerId: number | undefined) =>
    [...customerQueries.customer(customerId), 'contracts'] as const,

  contract: (customerId: number | undefined, contractId: number | undefined) =>
    [
      ...customerQueries.baseContracts(customerId),
      'single',
      contractId,
    ] as const,
  contracts: (customerId: number | undefined, page: number) =>
    [...customerQueries.baseContracts(customerId), page] as const,
  infiniteContracts: (customerId: number | undefined) =>
    [...customerQueries.baseContracts(customerId), 'infinite'] as const,

  pendencies: (
    customerId: number | undefined,
    enterpriseId: number | undefined,
  ) =>
    [...customerQueries.base, 'pendencies', customerId, enterpriseId] as const,
  pendenciySimulations: (
    customerId: number | undefined,
    unitId: number | undefined,
    accounts: number[],
  ) =>
    [
      ...customerQueries.base,
      'pendencies',
      customerId,
      unitId,
      accounts.join(','),
    ] as const,

  reparceling: (hash: string) =>
    [...customerQueries.base, 'reparceling', hash] as const,
  reparcelingSimulation: (hash: string, cash: number) =>
    [...customerQueries.base, 'reparceling', hash, 'simulation', cash] as const,

  unitPaymentMethods: (unitId: number) =>
    [...customerQueries.base, 'unit', unitId, 'paymentMethods'] as const,

  pixStatus: (id: number | undefined) =>
    [...customerQueries.base, 'pix', 'status', id] as const,

  dataUpdateRequest: (hash: string) =>
    [...customerQueries.base, 'updateRequest', hash] as const,
};
