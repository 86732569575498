import React from "react";
import { Link } from "react-router-dom";
import WhatsappColoredIcon from "../../assets/icons/whatsappColored";

import { Container } from "./styles";

function Promotion({ promotion: { link, external }, contactLink }) {
  return (
    <Container>
      <div className="description">
        <div>
          <h1>
            GANASTE <strong>3</strong> SESIONES
          </h1>

          <h2> DE DEPILACIÓN LÁSER GRATUITAS</h2>

          <span>
            Válido para axilas en las mujeres y línea de barba en los
            caballeros.
          </span>
        </div>

        {external ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            ¡QUIERO GANAR!
          </a>
        ) : (
          <Link to={link}>¡QUIERO GANAR!</Link>
        )}
      </div>

      <div className="strip">
        {contactLink && (
          <>
            <WhatsappColoredIcon />

            <a href={contactLink} target="_blank" rel="noopener noreferrer">
              CONSULTAR PRECIOS DE PAQUETES Y COMBOS
            </a>
          </>
        )}
      </div>
    </Container>
  );
}

export default Promotion;
