import { Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '& .slide': {
    padding: 0,
  },
  '& .social': {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '8rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& svg': {
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const CarrouselItem = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
});

export const BannerButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: '4rem',
  [theme.breakpoints.down('md')]: {
    bottom: '3rem',
  },
  '& span': {
    padding: '0.6rem 3rem',
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  marginTop: '-10vh',
  [theme.breakpoints.down('md')]: {
    marginTop: '-8vh',
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '-6vh',
  },
}));
