import { useEffect, useMemo, useState } from 'react';

function calculateTimeLeft(endTime: Date) {
  const difference = +endTime - +new Date();
  if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

  const remainingSeconds = difference / 1000;
  const remainingMinutes = remainingSeconds / 60;
  const remainingHours = remainingMinutes / 60;
  const remainingDays = remainingHours / 24;

  return {
    days: Math.floor(remainingDays),
    hours: Math.floor(remainingHours % 24),
    minutes: Math.floor(remainingMinutes % 60),
    seconds: Math.floor(remainingSeconds % 60),
  };
}

export default function useTimeLeft(date: string) {
  const setting = useMemo(
    () => ({
      startTime: new Date(),
      endTime: new Date(date),
    }),
    [date],
  );

  const [timer, setTimer] = useState(calculateTimeLeft(setting.endTime));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimer(calculateTimeLeft(setting.endTime));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return {
    remaining: timer,
    finished: setting.endTime < new Date(),
  };
}
