import { useQuery } from 'react-query';

import { checkoutQueries } from 'modules/checkout/presentation/constants';
import { makeFindDigitalSigningDisabilityUseCase } from 'shared/domain/useCases';
import { useRequestErrorHandler } from 'shared/presentation/hooks';

// The query returns if the digital signing is disabled, but we want to see if it's enabled
export default function useDigitalSigningAvailability() {
  const handleError = useRequestErrorHandler('checkout');

  const query = useQuery(
    checkoutQueries.digitalSigningDisabled(),
    () => makeFindDigitalSigningDisabilityUseCase().execute(),
    {
      onError: error => handleError(error),
      initialData: true,
    },
  );

  return {
    enabled: !query.data,
    loading: query.isLoading,
  };
}
