import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FaHeart } from 'react-icons/fa';
import StarRatingComponent from 'react-star-rating-component';

import Carousel from '../../components/Carousel';
import carolinaLeal from '../../img/ImagesDepo/Carolina.jpeg';
import kaynaraPaixao from '../../img/ImagesDepo/kaynara paixao.jpeg';
import nataliaPessoa from '../../img/ImagesDepo/Natalia.jpeg';
import samiaRocha from '../../img/ImagesDepo/samia rocha.jpeg';

const useStyles = makeStyles(theme => ({
  root: {
    heigth: '60vh',
    padding: '20px 0',
    textAlign: 'center',
    '& .slick-dots': {
      '& li button::before': {
        fontSize: '10px',
        color: theme.palette.primary.light,
        opacity: 0.5,
      },
      '& li.slick-active button::before': {
        color: theme.palette.primary.main,
        opacity: 0.7,
      },
    },
  },
  imageHolder: {
    width: '100%',
    height: '170px',
  },
  testimonialHolder: {
    borderRadius: '40px',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  testimonialTextHolder: {
    width: '100%',
    padding: '1rem',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  bgImg: {
    width: '100%',
    height: '34.3%;',
    '& span': {
      width: '100% !important',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userAvatar: {
    height: '100px',
    width: '100px',
    border: '5px solid #fff',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '15px',
    '& img': {
      width: '100%',
      height: '-webkit-fill-available',
      objectFit: 'cover',
    },
  },
  testimonialText: {
    height: '70%',
    overflow: 'hidden',
    fontSize: 14,
  },
  stateText: {
    fontWeight: 'regular',
    fontSize: '20px',
    color: '#000',
  },
  text: {
    fontWeight: '300',
    fontSize: '20px',
    position: 'relative',
    zIndex: '3',
    paddingTop: '5px',
    paddingBottom: '5px',
    top: '-36px',
    background: 'rgba(130, 51, 236, 0.8)',
    color: '#fff',
    borderRadius: '0px 0px 6px 6px',
  },
  starIcon: {
    fontSize: '22px',
    '& .dv-star-rating-star': {
      padding: '0 5px',
    },
  },
}));

const testimonialsList = [
  {
    id: 1,
    urlImagem: 'https://picsum.photos/400/150?random=1',
    nome: 'Kaynara Paixão',
    urlAvatarImg: kaynaraPaixao,
    urlAvatarImgWebp: kaynaraPaixao,
    depoimento: 'Depo1',
    rating: 5,
  },
  {
    id: 2,
    urlImagem: 'https://picsum.photos/400/150?random=5',
    nome: 'Carolina Leal',
    urlAvatarImg: carolinaLeal,
    urlAvatarImgWebp: carolinaLeal,
    depoimento: 'Depo6',
    rating: 5,
  },
  {
    id: 3,
    urlImagem: 'https://picsum.photos/400/150?random=5',
    nome: 'Natália Pessoa',
    urlAvatarImg: nataliaPessoa,
    urlAvatarImgWebp: nataliaPessoa,
    depoimento: 'Depo3',
    rating: 5,
  },
  {
    id: 4,
    urlImagem: 'https://picsum.photos/400/150?random=3',
    nome: 'Sâmia Rocha',
    urlAvatarImg: samiaRocha,
    urlAvatarImgWebp: samiaRocha,
    depoimento: 'Depo2',
    rating: 5,
  },
];

const filterTestimonials = testimonialsList => {
  if (
    window.location.pathname === '/voce-ganhou' ||
    window.location.pathname === '/has-ganado-3-sesiones'
  ) {
    return testimonialsList.filter(testimonial => testimonial.id > 2);
  }
  return testimonialsList;
};

const TestimonialList = () => {
  const isSM = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const renderAreas = (testimonials, index) => (
    <Grid
      xs={12}
      sm={12}
      md={6}
      lg={3}
      key={index.toString()}
      item
      justifyContent="center"
      container
      alignItems="center"
    >
      <div className={classes.testimonialHolder} key={testimonials.id}>
        <div className={classes.header}>
          <div className={classes.userAvatar}>
            <picture>
              <source
                type="image/webp"
                srcSet={testimonials.urlAvatarImgWebp}
              />
              <source type="image/jpeg" srcSet={testimonials.urlAvatarImg} />
              <img alt={testimonials.nome} src={testimonials.urlAvatarImg} />
            </picture>
          </div>

          <div>
            <Typography variant="h2" component="p">
              {testimonials.nome}
            </Typography>
          </div>
        </div>

        <div className={classes.testimonialTextHolder}>
          <Typography
            variant="body1"
            className={classes.testimonialText}
            gutterBottom
          >
            {t(testimonials.depoimento)}
          </Typography>
        </div>

        <div className={classes.starIcon}>
          <StarRatingComponent
            name="nota"
            editing={false}
            starColor={theme.palette.primary.main}
            renderStarIcon={() => <FaHeart />}
            value={testimonials.rating}
          />
        </div>
      </div>
    </Grid>
  );

  return (
    <div className={classes.root}>
      {isSM ? (
        <Carousel
          autoPlay
          showArrows
          swipeable
          emulateTouch
          infiniteLoop
          stopOnHover
        >
          {filterTestimonials(testimonialsList).map(renderAreas)}
        </Carousel>
      ) : (
        <Grid
          container
          spacing={8}
          justifyContent="center"
          alignItems="flex-start"
        >
          {filterTestimonials(testimonialsList).map(renderAreas)}
        </Grid>
      )}
    </div>
  );
};

export default TestimonialList;
