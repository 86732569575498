export const cardForm = {
  'Name printed on card': 'Nome impresso no cartão',
  'Card number': 'Número do cartão',
  'Expiry date': 'Data de vencimento',
  Cvc: 'Código de segurança',
  'Better billing day': 'Melhor dia para cobrança',
  Installments: 'Parcelas',

  'Card name can not have less than 2 and more than 26 characters':
    'O nome não pode ter menos de 2 e mais de 26 caracteres.',
  'Installments ({{installments}}x of {{price}} without interest)':
    'Número de parcelas ({{installments}}x de {{price}} sem juros)',
  'You must inform the billing day': 'Informe o melhor dia de cobrança',

  'The day must be greater than or equal to {{day}}':
    'O dia deve ser maior ou igual a {{day}}',
  'The day must be lower than or equal to {{day}}':
    'O dia deve ser menor ou igual a {{day}}',

  'Inform the card number to be able select installments':
    '* Informe o número do seu cartão para poder selecionar as parcelas.',
};
