import styled, { css } from 'styled-components';

const Separator = styled.hr<{ color?: string; $size?: number }>`
  ${({ theme, color, $size }) => css`
    border: 0.5px solid ${color || theme.palette.primary.main};
    border-radius: 5px;

    ${$size
      ? css`
          width: ${theme.layout.spacing($size)};
        `
      : css`
          width: 100%;
        `}
  `}
`;

export default Separator;
