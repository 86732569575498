import axios from 'axios';

import apiAWS from './apiAws';

const actionList = [
  {
    id: 200,
    message: 'Aberto link de pagamento',
    event: 'aberto',
  },
  {
    id: 201,
    message: 'Pagamento efetuado',
    event: 'pagamento_efetuado',
  },
  {
    id: 300,
    message: 'Orçamento inválido, por favor tente refazer a compra',
    event: 'falha_checkout',
  },
  {
    id: 301,
    message: 'CONTRATO_GERADO - Este orçamento já foi pago',
    event: 'falha_checkout',
  },
  {
    id: 302,
    message: 'Erro POST /budget - Status 401 Unauthorized',
    event: 'falha_checkout',
  },
  {
    id: 303,
    message: 'Número do cartão ou bandeira inválido',
    event: 'falha_checkout',
  },
  {
    id: 304,
    message: 'Valor máximo de parcelas inválido',
    event: 'falha_checkout',
  },
  {
    id: 400,
    message: 'Erro ao comunicar com o servidor',
    event: 'falha_checkout',
  },
  {
    id: 401,
    message: '',
    event: 'falha_checkout',
  },
];

class Tracking {
  static async trackAction(actionId, budgetId, actionMessage) {
    const action = actionList.find(act => act.id === actionId);

    if (!action) return console.error('Ação de track não encontrada');

    if (actionMessage) action.message = actionMessage;

    const newAction = {
      ...action,
      budgetId,
    };

    await axios.post('budget/paymentLinkEvent', newAction);
  }

  static async dataInfoTrack(data) {
    await apiAWS.post('development', JSON.stringify(data));
  }
}

export default Tracking;
