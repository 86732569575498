export const contracts = {
  Contracts: 'Contratos',
  'Days overdue': 'Días retrasados',
  '{{days}} days': '{{days}} días',
  'Final installment value': 'Valor final de la cuota',
  'Fine of': 'Multa de',
  'Load more': 'Carga más',
  'More details': 'Mas detalles',
  Overdue: 'Vencidas',
  'Original installment value': 'Valor original de la cuota',
  Paid: 'Pagadas',
  Pay: 'Pagar',
  'Pay all debts': 'Pagar todas las deudas',
  'Payment methods': 'Metodos de pago',
  Pending: 'A vencer',
  'View your contracts, handle your pendencies and see payment options':
    'Vea sus contratos, resuelva asuntos pendientes y vea opciones de pago',
  "Your don't have any contracts yet": 'Aún no tienes ningún contrato.',
};
