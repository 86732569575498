import { IItem } from 'shared/domain/entities';

export interface IVectisItem {
  id: number;
  codigoEcommerce?: string;
  descricao: string;
  tipo: 'PACOTE' | 'COMBO' | 'PRODUTO';
  quantidadeSessoes: number;
  urlImagem?: string;
  urlImagemBaixaResolucao?: string;
  urlImagemBaixaResolucaoEcommerce?: string;
  urlImagemFemininaBaixaResolucao?: string;
  urlImagemMasculinaBaixaResolucao?: string;
  valorPacote?: number;
  menorPreco: number;
  pacotesCombo?: Array<{
    idPacote: number;
    pacote: IVectisItem;
    valorPacoteCombo: number;
  }>;
}

const ITEM_TYPE_DICTIONARY: Record<IVectisItem['tipo'], IItem['type']> = {
  COMBO: 'COMBO',
  PACOTE: 'PACKAGE',
  PRODUTO: 'PRODUCT',
};

export class Item implements IItem {
  public id: IItem['id'];
  public ecommerceCode: IItem['ecommerceCode'];
  public title: IItem['title'];
  public type: IItem['type'];
  public sessions: IItem['sessions'];
  public images: IItem['images'];
  public price: IItem['price'];

  constructor({
    id,
    codigoEcommerce,
    descricao,
    tipo,
    quantidadeSessoes,
    urlImagem,
    urlImagemBaixaResolucao,
    urlImagemBaixaResolucaoEcommerce,
    urlImagemFemininaBaixaResolucao,
    urlImagemMasculinaBaixaResolucao,
    valorPacote,
    menorPreco,
    pacotesCombo = [],
  }: IVectisItem) {
    this.id = id;
    this.title = descricao;
    this.ecommerceCode =
      codigoEcommerce || this.buildEcommerceCodeFromTitle(descricao);
    this.type = ITEM_TYPE_DICTIONARY[tipo];
    this.sessions = quantidadeSessoes;
    this.images = {
      default:
        urlImagem ||
        urlImagemBaixaResolucao ||
        urlImagemBaixaResolucaoEcommerce,
      female: urlImagemFemininaBaixaResolucao,
      male: urlImagemMasculinaBaixaResolucao,
    };
    this.price = {
      from: valorPacote || this.getPriceFromSubItems(pacotesCombo),
      to: menorPreco,
    };
  }

  private buildEcommerceCodeFromTitle(title: string) {
    const encoded = encodeURIComponent(title);

    return encoded.replaceAll('%20', '+');
  }

  private getPriceFromSubItems(items: IVectisItem['pacotesCombo'] = []) {
    let value = 0;

    for (const item of items) {
      value += item.pacote.valorPacote || 0;
    }

    return value;
  }
}
