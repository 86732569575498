import { IFetchItemsDTO } from 'shared/domain/dtos';
import { IItemsRepository } from 'shared/domain/repositories';

export default class FetchItemsUseCase {
  constructor(private itemsRepository: IItemsRepository) {}

  public async execute({ type }: IFetchItemsDTO) {
    return this.itemsRepository.findAll({ type });
  }
}
