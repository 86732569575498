export const Questions = [
  {
    name: 'Panel1',
    question: 'Depilação a laser dói?',
    answer: 'home-tecnologia',
  },
  {
    name: 'Panel2',
    question: 'Mas e as peles negras?',
    answer: 'home-pele-negra',
  },
  {
    name: 'Panel3',
    question: 'Depilação a laser é realmente definitiva?',
    answer: 'home-definitiva',
  },
  {
    name: 'Panel4',
    question: 'O método é eficaz para todos?',
    answer: 'home-hormonios',
  },
  {
    name: 'Panel5',
    question: 'Quem pode fazer depilação a laser?',
    answer: 'home-pelos',
  },
  {
    name: 'Panel6',
    question: 'Quais vantagens e desvantagens da depilação a laser?',
    answer: 'home-vantagens',
  },
  {
    name: 'Panel7',
    question: 'O pelo pode voltar a crescer?',
    answer: 'home-eficacia',
  },
];
