import { TItemType } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IItemsRepository } from 'shared/domain/repositories';
import { Item, IVectisItem } from 'shared/infra/adapters';

const URL_BY_TYPE: Record<TItemType, string> = {
  COMBO: 'combosvenda',
  PACKAGE: 'pacotes',
  PRODUCT: 'pacotes',
};

const REVERSE_TYPE_DICTIONARY: Record<TItemType, IVectisItem['tipo']> = {
  COMBO: 'COMBO',
  PACKAGE: 'PACOTE',
  PRODUCT: 'PRODUTO',
};

export default class VectisItemsRepository implements IItemsRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public findAll: IItemsRepository['findAll'] = async ({ type }) => {
    const items = await this.httpProvider.get<Omit<IVectisItem, 'tipo'>[]>(
      URL_BY_TYPE[type],
    );

    return items.map(
      item => new Item({ ...item, tipo: REVERSE_TYPE_DICTIONARY[type] }),
    );
  };
}
