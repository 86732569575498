import { IContract } from 'modules/customer/domain/entities';
import { ICONS } from 'shared/presentation/constants';

type TParams = {
  contract: IContract;
  formatter: Intl.NumberFormat;
};

export default function getContractDetailsItems({
  contract,
  formatter,
}: TParams) {
  return [
    {
      key: 'total',
      Icon: ICONS.CURRENCY,
      content: formatter.format(contract.total),
    },
    {
      key: 'method',
      Icon: ICONS.BAG,
      content: contract.purchase.paymentMethod,
    },
    {
      key: 'purchase',
      Icon: ICONS.CARD,
      content: contract.purchase.installmentPlan,
    },
    {
      key: 'date',
      Icon: ICONS.CALENDAR,
      content: contract.purchase.date,
    },
  ].filter(item => item.content);
}
