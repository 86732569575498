import styled, { css } from 'styled-components';

export const Container = styled.nav`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.layout.spacing(2, 1.5)};
    align-items: center;
    gap: ${theme.layout.spacing(2)};

    background-color: ${theme.palette.neutral[50]};
  `}
`;

export const DrawerButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.text.main};
`;
