import { makeContractTermsRepository } from 'shared/infra/repositories';

import FetchContractTermsUseCase from './implementations/FetchContractTermsUseCase';

let instance: FetchContractTermsUseCase | null = null;

export default function makeFetchContractTermsUseCase() {
  if (!instance) {
    const contractTermsRepository = makeContractTermsRepository();

    instance = new FetchContractTermsUseCase(contractTermsRepository);
  }

  return instance;
}
