import { FC, useState } from 'react';

import {
  CONTACT_DEFAULT_MESSAGES,
  CONTACTS,
  COUNTRY_CODES,
  ICONS,
} from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';
import { buildWhatsappLink } from 'shared/presentation/helpers';

import * as S from './styles';

const IconX = ICONS.X;
const IconWhatsApp = ICONS.WHATSAPP;

const WhatsAppFloatingButton: FC = () => {
  const { language } = useLocale('shared');

  const [shouldShowButton, setShouldShowButton] = useState<boolean>(true);

  if (!shouldShowButton) return null;

  const link = buildWhatsappLink({
    countryCode: COUNTRY_CODES[language],
    number: CONTACTS[language],
    language,
    message: CONTACT_DEFAULT_MESSAGES[language],
  });

  return (
    <S.Container>
      <div>
        <button type="button" onClick={() => setShouldShowButton(false)}>
          <IconX />
        </button>

        <a href={link} target="_blank" rel="noreferrer">
          <IconWhatsApp />
        </a>
      </div>
    </S.Container>
  );
};

export default WhatsAppFloatingButton;
