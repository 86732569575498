import { GoogleMap, LoadScript } from "@react-google-maps/api";
import PropType from "prop-types";
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

function MapComponent({ onLoaded, children }) {
  const { isUpMd } = useContext(AppContext);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
      <GoogleMap
        onLoad={onLoaded}
        id="units-map"
        mapContainerStyle={{
          height: isUpMd ? "100%" : "100vw",
          maxHeight: isUpMd ? 750 : "",
        }}
        zoom={15}
        center={{
          lat: -28.4754181,
          lng: -48.9948116,
        }}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  );
}

MapComponent.propTypes = {
  onLoaded: PropType.func.isRequired,
};

export default MapComponent;
