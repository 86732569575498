import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IErrorLoggerRepository, {
  TCreateParams,
} from 'shared/domain/repositories/IErrorLoggerRepository';

export default class ErrorLoggerRepository implements IErrorLoggerRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public async create(params: TCreateParams): Promise<void> {
    await this.httpProvider.post('/logerro/gerarlog', {
      chave: params.key,
      stackTrace: params.stackTrace,
      dados: JSON.stringify({
        source: 'SITE',
        ...params.data,
      }),
    });
  }
}
