import authenticationModule from 'modules/authentication/presentation/authentication.module';
import checkoutModule from 'modules/checkout/presentation/checkout.module';
import customerModule from 'modules/customer/presentation/customer.module';
import landingPagesModule from 'modules/landingPages/presentation/landingPages.module';
import sharedModule from 'shared/presentation/shared.module';

import { ModuleAggregator } from './aggregator';

type TModuleName =
  | 'shared'
  | 'landingPages'
  | 'checkout'
  | 'customer'
  | 'authentication';

const appModules = new ModuleAggregator<TModuleName>()
  .use('shared', sharedModule)
  .use('authentication', authenticationModule)
  .use('landingPages', landingPagesModule)
  .use('customer', customerModule)
  .use('checkout', checkoutModule)
  .build();

export default appModules;
