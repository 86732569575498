import { IconBase } from "react-icons";
import styled from "styled-components";
import React from "react";
import { theme } from "../../theme";

const Base = styled(IconBase)`
  enable-background: new 0 0 300 300;
  .st0 {
    fill: #ffffff;
  }
  .st1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${theme.colors.primary};
  }
`;

const inproperProps = {
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  xmlSpace: "preserve",
};

const WhatsappColoredIcon = () => (
  <Base
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
    {...inproperProps}
  >
    <g>
      <g>
        <polygon
          className="st0"
          points="43.1,190.72 49.96,229.5 37.19,258.65 90.2,252.79 155.61,269.31 224.25,249.78 268.58,200.2
			276.84,122.82 236.27,58.96 170.91,28.92 116.82,34.17 72.5,57.46 43.86,101.03 31.93,137.09 		"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M8.07,296.12l26.78-79c-11.94-20.62-18.23-44.06-18.23-68c0-75.33,61.74-136.62,137.63-136.62
					c75.89,0,137.62,61.29,137.62,136.62c0,75.33-61.74,136.61-137.62,136.61c-22.36,0-44.46-5.42-64.1-15.7L8.07,296.12z
					 M154.25,25.17c-68.91,0-124.97,55.6-124.97,123.95c0,22.61,6.19,44.74,17.91,63.98l1.54,2.53l-20.62,60.83l63.34-20.13
					l2.38,1.31c18.38,10.09,39.27,15.43,60.42,15.43c68.9,0,124.96-55.6,124.96-123.94C279.2,80.77,223.15,25.17,154.25,25.17z
					 M154.25,264.98c-21.9,0-43.14-6.05-61.64-17.51l-51.53,16.37l16.77-49.47c-13.31-19.3-20.32-41.78-20.32-65.25
					c0-63.89,52.36-115.87,116.73-115.87c64.36,0,116.71,51.98,116.71,115.87C270.96,213.01,218.61,264.98,154.25,264.98z
					 M94.47,233.59l2.52,1.65c17.02,11.17,36.82,17.07,57.26,17.07c57.37,0,104.05-46.29,104.05-103.19
					c0-56.91-46.68-103.2-104.05-103.2c-57.38,0-104.06,46.3-104.06,103.2c0,21.87,6.85,42.78,19.8,60.48l1.95,2.66l-10.82,31.93
					L94.47,233.59z M189.74,216.91c-5.76,0-14.58-2.02-33.73-9.5c-20.69-8.08-41.01-25.68-57.23-49.56l-0.53-0.77
					c-4.3-5.67-14.39-20.69-14.39-36.81c0-17.22,8.66-26.39,12.36-30.31l0.58-0.61c5.02-5.44,10.93-6.03,13.23-6.03
					c1.25,0,2.49,0.08,3.7,0.15c0.86,0.06,1.69,0.11,2.47,0.11l0.3-0.02c3.72-0.23,9.11,0.46,12.78,9.23
					c0.9,2.14,2.22,5.34,3.62,8.7c2.37,5.72,5.61,13.55,6.14,14.63c1.97,3.87,2.07,7.67,0.26,11.26l-0.44,0.88
					c-0.9,1.83-1.75,3.55-3.64,5.75c-0.52,0.61-1.05,1.24-1.59,1.89c-1.12,1.34-2.27,2.73-3.39,3.87
					c2.62,4.29,8.29,12.71,16.04,19.59c9.53,8.43,17.7,11.94,22.08,13.83c1,0.43,1.81,0.78,2.41,1.08c0,0,0,0,0,0
					c2.77-3.17,7.49-8.79,9.1-11.18c5.01-7.49,12.01-4.97,14.64-4.02c3.73,1.34,22.47,10.57,22.66,10.67l1.71,0.83
					c2.91,1.4,5.42,2.6,7.05,5.27l0.02,0.02c2.61,4.33,1.13,13.71-1.35,20.6c-3.6,9.99-18.93,18.62-27.12,19.34
					c-0.66,0.06-1.21,0.21-1.78,0.35C193.9,216.58,192.07,216.91,189.74,216.91z M110.04,95.98c-0.5,0-2.25,0.14-3.92,1.95
					l-0.68,0.72c-3.11,3.3-8.9,9.43-8.9,21.61c0,12.61,9.06,25.53,11.84,29.19c0.17,0.22,0.46,0.64,0.88,1.27
					c14.79,21.78,33.03,37.72,51.36,44.88c25.27,9.87,29.24,8.92,32.13,8.22c0.95-0.23,2.14-0.51,3.61-0.65
					c4.23-0.37,14.74-6.57,16.34-11.02c1.49-4.13,1.93-7.65,1.98-9.57c-0.4-0.2-0.85-0.42-1.26-0.61l-1.86-0.91
					c-5.36-2.65-19.1-9.3-21.31-10.09c-0.12-0.04-0.23-0.08-0.33-0.12c-2.97,4.11-8.71,10.76-10.22,12.47
					c-2.63,3-7.31,5.86-14.53,2.28c-0.44-0.22-1.05-0.47-1.79-0.79c-4.68-2.01-14.42-6.2-25.48-15.98
					c-9.84-8.73-16.68-19.44-19.17-23.64c-2.9-4.96-2.1-9.74,2.38-14.22c0.76-0.76,1.79-1.99,2.78-3.18
					c0.59-0.71,1.18-1.42,1.74-2.07c0.83-0.96,1.15-1.62,1.87-3.07l0.32-0.64c-0.03-0.06-0.06-0.12-0.1-0.19
					c-0.59-1.18-2.53-5.84-6.53-15.48c-1.38-3.34-2.7-6.52-3.6-8.65c-0.28-0.68-0.53-1.15-0.71-1.46c-1.32,0.06-2.57-0.03-3.96-0.12
					C111.99,96.04,111.01,95.98,110.04,95.98z M214.62,181.22L214.62,181.22C214.62,181.22,214.62,181.22,214.62,181.22z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st1"
              d="M285.54,149.12c0,71.94-58.78,130.28-131.29,130.28c-23.03,0-44.66-5.88-63.47-16.21l-72.69,23.1
					l23.7-69.89c-11.96-19.64-18.83-42.66-18.83-67.27c0-71.96,58.78-130.29,131.3-130.29C226.76,18.84,285.54,77.17,285.54,149.12
					L285.54,149.12z M154.25,39.59c-60.88,0-110.39,49.13-110.39,109.54c0,23.96,7.8,46.16,21.02,64.22L51.1,254.02l42.41-13.48
					c17.43,11.43,38.31,18.11,60.74,18.11c60.86,0,110.38-49.13,110.38-109.53C264.63,88.72,215.11,39.59,154.25,39.59L154.25,39.59
					z M220.55,179.13c-0.81-1.33-2.96-2.13-6.17-3.73c-3.22-1.59-19.05-9.33-22-10.39c-2.95-1.06-5.11-1.6-7.24,1.59
					c-2.15,3.19-8.32,10.39-10.2,12.52c-1.87,2.14-3.74,2.4-6.96,0.8c-3.22-1.59-13.6-4.98-25.89-15.84
					c-9.56-8.48-16.02-18.92-17.91-22.12c-1.87-3.19-0.19-4.92,1.41-6.51c1.45-1.44,3.22-3.73,4.83-5.59
					c1.61-1.87,2.15-3.21,3.22-5.34c1.07-2.13,0.54-3.99-0.27-5.59c-0.8-1.59-7.24-17.31-9.93-23.71c-2.67-6.38-5.35-5.32-7.24-5.32
					c-1.87,0-4.02-0.26-6.17-0.26c-2.15,0-5.63,0.8-8.58,3.99c-2.95,3.19-11.26,10.92-11.26,26.63c0,15.71,11.52,30.91,13.13,33.02
					c1.61,2.14,22.26,35.43,54.98,48.22c32.72,12.78,32.72,8.52,38.62,7.98c5.89-0.52,19.04-7.72,21.72-15.18
					C221.35,186.85,221.35,180.45,220.55,179.13L220.55,179.13z M220.55,179.13"
            />
          </g>
        </g>
      </g>
    </g>
  </Base>
);

export default WhatsappColoredIcon;
