import { FC, Fragment } from 'react';

import { TLanguage } from 'config/tenancy';

import { TModule } from './definitions';

export class ModuleAggregator<
  Modules extends string,
  U extends Record<string, object> | null = null,
> {
  private modules = new Map<Modules, TModule>();

  public use<T extends Modules, W extends object>(
    key: T,
    module: TModule<W>,
  ): U extends null
    ? ModuleAggregator<Modules, { [key in T]: W }>
    : ModuleAggregator<Modules, NonNullable<U> & { [key in T]: W }> {
    this.modules.set(key as any, module as any);

    return this as unknown as U extends null
      ? ModuleAggregator<Modules, { [key in T]: W }>
      : ModuleAggregator<Modules, NonNullable<U> & { [key in T]: W }>;
  }

  public build() {
    const modules = [...this.modules.entries()].map(([key, module]) => ({
      key,
      module,
    }));

    const resolved = modules.reduce(
      (acc, actual) => ({
        locale: this.localeResolver(
          acc.locale,
          actual.module.locale,
          actual.key,
        ) as Record<TLanguage, NonNullable<U>>,
        Container: this.containerResolver(
          acc.Container,
          actual.module.Container,
        ),
        routes: this.routesResolver(acc.routes, actual.module.routes),
      }),
      {
        locale: {
          pt: {},
          es: {},
          'es-CR': {},
        } as Record<TLanguage, NonNullable<U>>,
        Container: Fragment as FC,
        routes: [] as NonNullable<TModule['routes']>,
      },
    );

    return resolved;
  }

  private localeResolver(
    accumulated: NonNullable<TModule['locale']>,
    next: TModule['locale'],
    key: Modules,
  ) {
    if (!next) return accumulated;

    const languages = Object.keys(accumulated);

    const aggregated = languages.reduce((acc, lng) => {
      const moduleLocale = next[lng];
      const languageNamespacedLocale = acc[lng];

      const mergedLocale = {
        ...languageNamespacedLocale,
        [key]: moduleLocale,
      };

      return {
        ...acc,
        [lng]: mergedLocale,
      };
    }, accumulated);

    return aggregated;
  }

  private containerResolver(
    Accumulated: NonNullable<TModule['Container']>,
    Next: TModule['Container'],
  ) {
    if (!Next) return Accumulated;

    const Aggregated: FC = props => (
      <Accumulated>
        <Next {...props} />
      </Accumulated>
    );

    return Aggregated;
  }

  private routesResolver(
    accumulated: NonNullable<TModule['routes']>,
    next: TModule['routes'],
  ) {
    if (!next) return accumulated;

    return [...accumulated, ...next];
  }
}
