import { useMemo } from 'react';

import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';

import { getValidLanguage } from '../helpers';

export default function useLocale<T extends Namespace>(namespace?: T) {
  const { t, i18n } = useTranslation(namespace);
  const shared = useTranslation('shared');

  const language = useMemo(
    () => getValidLanguage(i18n.language),
    [i18n.language],
  );

  return { t, language, sharedT: shared.t };
}
