import { styled } from '@material-ui/core/styles';

export const VideoBorder = styled('div')(({ theme, hei, wid, disabled }) => ({
  position: 'relative',
  height: hei || '100px',
  width: wid || '100px',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    content: disabled ? '' : "''",
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '20px',
    left: '-20px',
    border: `"1px solid ${theme.palette.primary.main}"`,
    background: theme.palette.primary.main,
    borderRadius: 20,
    zIndex: -1,
  },
  [theme.breakpoints.down('md')]: {
    margin: '2rem auto 0',
    '&::before': {
      left: '-20px',
      top: '20px',
    },
  },
}));

export const Image = styled('img')({
  bottom: 20,
  borderRadius: 20,
  cursor: 'pointer',
});

export const Play = styled('div')({
  position: 'absolute',
  height: 60,
  width: 60,
  border: '2px solid #FFF',
  borderRadius: 50,
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
