import Axios from 'axios';

import ErrorHandler from '../helpers/ErrorHandler';

function handleError(error, t, enqueueSnackbar) {
  const errorMsg = ErrorHandler(error);
  enqueueSnackbar(t(errorMsg), {
    variant: 'error',
  });
}

async function fetchPaymentMethodsByDiscountPercentage(
  unitId,
  t,
  enqueueSnackbar,
) {
  try {
    const url = {
      PIX: Axios.get('getBudgetDiscountPercentage?paymentType=PIX'),
      CARTAO_ONLINE: Axios.get(
        'getBudgetDiscountPercentage?paymentType=CARTAO_ONLINE',
      ),
      CARTAO_RECORRENTE: Axios.get(
        `getBudgetDiscountPercentage?unitId=${unitId}&paymentType=CARTAO_RECORRENTE`,
      ),
    };

    const [pix, cart, recurrent] = await Promise.all([
      url.PIX,
      url.CARTAO_ONLINE,
      url.CARTAO_RECORRENTE,
    ]);

    return {
      pixPercentage: pix.data.percentualDesconto,
      cartPercentage: cart.data.percentualDesconto,
      recurrentPercentage: recurrent.data.percentualDesconto,
    };
  } catch (error) {
    handleError(error, t, enqueueSnackbar);
  }
}

const fetchCart = {
  getPaymentMethodsByDiscountPercentage:
    fetchPaymentMethodsByDiscountPercentage,
};

export default fetchCart;
