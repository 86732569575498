export const theme = {
  colors: {
    body: '#FFFFFF',
    primary: '#63B76B',
    primaryDark: '#4DA747',
    secondary: '#8033D0',
    textBright: '#FFFFFF',
    text: '#565656',
  },
  typography: {
    family: {
      ubuntu: 'Ubuntu, Arial, Helvetica, sans-serif',
      beauSans: "'PF BeauSans Pro', Helvetica, sans-serif",
    },
  },
  breakpoints: {
    md: '578px',
  },
};
