import { IRegisterErrorDTO } from 'shared/domain/dtos';
import { IErrorLoggerRepository } from 'shared/domain/repositories';

export default class RegisterErrorUseCase {
  constructor(private errorLoggerRepository: IErrorLoggerRepository) {}

  public async execute(params: IRegisterErrorDTO) {
    return await this.errorLoggerRepository.create(params);
  }
}
