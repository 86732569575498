import React from "react";
import { Link } from "react-scroll";

import { Container } from "./styles";

const makeLinks = links =>
  links
    .map(({ text, href, external }) =>
      external ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        <Link to={href}>{text}</Link>
      )
    )
    .reduce(
      (acc, item) =>
        acc ? (
          <>
            {acc} <div className="verticalLine" /> {item}
          </>
        ) : (
          <>{item}</>
        ),
      null
    );

function Navbar({ links = [], icons = [] }) {
  const navLinks = makeLinks(links);

  return (
    <Container>
      <div className="links">{navLinks}</div>

      <div className="icons">
        <span>Contactá con Vialaser</span>

        {icons.map(({ Icon, href, key }) => (
          <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            <Icon />
          </a>
        ))}
      </div>
    </Container>
  );
}

export default Navbar;
