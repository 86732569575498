export const testimonials = {
  'Initally I was afraid of laser hair removal because I have dark skin, and the fear of wounds and stains was huge When I met Vialaser and they explained me about the techonology used and the care I should take with my skin the fear ended and everything worked out right! My session was quick and painless I left without any burning, stains or discomfort and I was excited for the next sessions and to buy new packages for other areas I highly recommend it!':
    'Inicialmente tenía miedo de someterme a la depilación láser porque tengo la piel negra y el miedo a las lesiones y las imperfecciones era grande. Cuando conocí a Vialaser y me explicaron sobre la tecnología utilizada y sobre el cuidado que debería tener, ¡el miedo desapareció y funcionó realmente bien! Mi sesión fue rápida e indolora. Salí sin quemaduras, manchas, quemaduras ni molestias y ya estaba emocionado de programar otras sesiones y comprar nuevos paquetes de otras áreas. ¡Lo recomiendo altamente!',
  'I am very happy with the results I got at Vialaser, they are visible at the first session, I loved it very much And the service is also excellent, they clear all our doubts and are very thoughtfull! I liked it a lot, and recommend it!':
    'Estoy muy contento con los resultados que obtuve en Vialaser, en la primera sesión ya son visibles, me encantó tanto. ¡Sin mencionar el excelente servicio, aclaran todas nuestras dudas y están super atentos! Me gustó mucho, ¡lo recomiendo!',
  "I'm living a dream, that is my hair removal With each session I'm happier and accomplished with the results and the professionals' service Really, Vialaser has the most painless and comfortable method I've ever known, and I recommend it to everyone! 🥰":
    'Estoy haciendo realidad un sueño, que es hacer mi depilación láser. Cada sesión me siento más feliz y satisfecho con los resultados y el cuidado de los profesionales allí. Realmente Vialaser tiene el método más cómodo e indoloro que jamás haya conocido, ¡se lo recomiendo a todos! 🥰',
  "It was surprising to find there is hair removal for dark skin The service is really good, I was well treated sinced the first session and well informed about sessions' steps":
    'Fue sorprendente descubrir que existe la depilación láser para pieles negras. El servicio es muy bueno, desde la primera sesión me tratan muy bien y me informan del paso a paso de las sesiones.',
};
