export const EXTERNAL_LINKS = {
  es: {
    PROMOTION:
      'https://estetica.vialaserdepilacion.com.py/has-ganado-3-sesiones',
    WHATSAPP:
      'https://api.whatsapp.com/send?1=es_ES&phone=595217289303&text=Estoy%20interesado%20en%20la%20depilaci%C3%B3n%20l%C3%A1ser',
  },
  'es-CR': {
    PROMOTION: 'has-ganado-3-sesiones',
    WHATSAPP:
      'https://api.whatsapp.com/send?1=es_ES&phone=50670999808&text=Estoy%20interesado%20en%20la%20depilaci%C3%B3n%20l%C3%A1ser',
  },
};
