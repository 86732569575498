import { useQuery } from 'react-query';

import { IBenefit } from 'shared/domain/entities';
import { makeFindUserBenefitUseCase } from 'shared/domain/useCases';
import { sharedQueries } from 'shared/presentation/constants';

type TParams = {
  userId: number | undefined;
  origin: string;
  key?: string;
  onSuccess?: (benefit: IBenefit) => void;
};

export default function useCustomerBenefit(params: TParams) {
  const query = useQuery(
    sharedQueries.customerBenefit(params.userId),
    () => {
      if (!params.userId) throw new Error();

      return makeFindUserBenefitUseCase().execute({
        userId: params.userId,
        origin: params.origin,
        key: params.key,
      });
    },
    {
      enabled: !!params.userId,
      onSuccess: params.onSuccess,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  return {
    loading: query.isLoading,
    benefit: query.data,
  };
}
